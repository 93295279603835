import React, { useState, useEffect, useRef, Suspense } from 'react'
import styled from 'styled-components'
import { AiFillCheckCircle } from "react-icons/ai";
import MyContext from '../utils/contexts/MyContext';
import { useContext } from 'react';


const Subscription = (props) => {
    const [showSubscribeButton, setshowSubscribeButton] = useState(true);
    const [textli, settextli]= useState([]);
    const {currentreportid, reportplayers} = useContext(MyContext)
    useEffect((()=>{
      let prop_token = window.localStorage.getItem('token')
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getdescription/?rep_id=${currentreportid}`,{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Token ${prop_token}`
        },
      }).then(res=>res.json())
      .then(
        res=>{
          let description_list = res['description_list']
          console.log('description = ', description_list)
          settextli(description_list)
        }
      )
    }),[])

    let defaultplayers  = ['Amazon', 'Flipkart', 'Myntra', 'Meesho', 'Nykaa', 'Purplle', 'Pepperfry', 'Bigbasket', 'Blinkit', 'Dunzo']
    let defPlayers = ['Amazon', 'Flipkart']
    const handleClick = () => {
        // Update the state by incrementing the count
        setshowSubscribeButton(false)
        let email = window.localStorage.getItem('email')
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/sendmail/?email=${email}`, {
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
            },
          })
          .then(res=>res.json())
          .then(
            res=>{
              console.log(res)
            }
          )
      };

    const showPropsPlayers = (playerarr) =>{
      console.log('propsPlayers = ', playerarr)
      const namesArray = playerarr.map((obj) => obj.name);
      return namesArray.join(', ')
    }

  return (

    <SubscribeBox>
       <Heading>To access below data, please subscribe to our premium subscritpion</Heading>
        {textli.length > 0
        ? textli.map((val, index) => {
            if (val.trim() !== '') {
              return (
                <SubscribeText key={index}>
                  <AiFillCheckCircle />
                  {val}
                </SubscribeText>
              );
            }
            return null; // If val is an empty string, return null to skip rendering
          }):<>
        <SubscribeText><AiFillCheckCircle/>  Platform metrics (includes Gross and Shipped: GMV, Items and ASP with a Category filter)</SubscribeText>
        <SubscribeText><AiFillCheckCircle/>Geographic view of - Platform wise performance of GMV & Leaders overall India</SubscribeText>
        <SubscribeText><AiFillCheckCircle/>Industry cancellation metrics</SubscribeText>
        <SubscribeText><AiFillCheckCircle/>Platform wise User & Order Metrics</SubscribeText>
        <SubscribeText><AiFillCheckCircle/> Platform wise revenue metrics</SubscribeText>
        </>}
        {/* {<SubscribeText><AiFillCheckCircle/> Players we cover -  {props.players.length>0?showPropsPlayers(props.players):defaultplayers.join(', ')} and many more</SubscribeText>} */}
        {<SubscribeText><AiFillCheckCircle/> Players we cover -  {false?showPropsPlayers(props.players):defaultplayers.join(', ')} and many more</SubscribeText>}
        <SubscribeText><AiFillCheckCircle/> Features - </SubscribeText>
        <FeaturesText>1. Visual presentation of data on - Annually, Quarterly & Monthly view provided (Both CY & FY)</FeaturesText>
        <FeaturesText>2. Insights & definitions provided for graphs & graph parameters</FeaturesText>
        <FeaturesText>3. Conversion of currency from USD to INR or vice versa</FeaturesText>
        {showSubscribeButton?<SubscribeButton onClick={handleClick}>Subscribe</SubscribeButton>:<AltSubscribe >Thank you for showing interest, our sales team will contact you soon</AltSubscribe>}
    </SubscribeBox>
    // <Subscribe>
    //     Subscribe for report
    // </Subscribe>
  )
}

export default Subscription


const Subscribe = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  color:black;
  font-family:system-ui;
  font-size:48px;
  min-height:70vh;
`
const Heading = styled.div`

    font-weight:600;
    font-size:22px;
    margin-top:10px;
`

const SubscribeBox = styled.div`
    margin-left:45px;
    background-color:white;
    font-size:18px;
    padding-top:20px;
    padding-left:20px;
    padding-bottom:20px;
    width:078vw;
    border-radius:10px;
`
const AltSubscribe = styled.div`
border:1px solid black;
padding-left:10px;
line-height:50px;
`

const SubscribeButton = styled.button`
    background-color:#51E0DF;
    border:1px solid black;
    margin-top:10px;
    height:50px;
    width:200px;
    /* border:None; */

`
const SubscribeText = styled.div`
    font-weight:400;
    font-size:16px;
    margin-top:10px;
`

const FeaturesText = styled.div`
    font-weight:400;
    font-size:16px;
    margin-left:30px;
`
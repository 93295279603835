import React, { useState, useEffect, useRef, Suspense } from 'react'
import TreeMenu from 'react-simple-tree-menu';
import styled from 'styled-components';
import {  TailSpin } from  'react-loader-spinner'
import { ListGroupItem, ListGroup } from 'reactstrap';
import Modal from 'react-modal';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import { Navigate} from 'react-router-dom';
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import DynamicComponent from '../../pages/DynamicComponent'
import DjangoEmbed from '../../pages/DjangoEmbed';
import Frontpage from '../../pages/Frontpage';
import Internet from '../../pages/Internet';
import Subscription from '../../pages/Subscription';
import MyDropdown from '../DropDown/dropdown';
import { FaAmazon, FaTrafficLight,FaUsers, FaDeezer,FaAlignLeft, FaKeyboard, FaCity } from 'react-icons/fa';
import{MdOutlineSummarize, MdMonetizationOn, MdInsights, MdDashboard, MdOutlinePersonalVideo} from 'react-icons/md';
import { ImProfile } from "react-icons/im";
import {SiCoveralls, SiSimpleanalytics,SiFlipkart, SiPaytm, SiTata} from "react-icons/si"
import {FiUsers} from "react-icons/fi"
import {AiOutlineFileSearch, AiOutlineMobile , AiTwotoneVideoCamera} from 'react-icons/ai'
import { GiBreakingChain, GiHealthNormal, GiCarWheel, GiClothes,GiMedicines, GiVideoConference, GiHamburgerMenu } from "react-icons/gi";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
// import {GoFileSymlinkDirectory} from 'react-icons/go'
import { BiCategory, BiBookContent, BiCartAlt, BiFridge } from "react-icons/bi";
import { IoFastFood } from "react-icons/io5";
import {FaCarAlt, FaBusinessTime ,FaBabyCarriage, FaRegFileAudio, FaTruck} from 'react-icons/fa';
import {BsGear,  BsFillCloudArrowDownFill, BsTag} from 'react-icons/bs'
import MyContext from '../../utils/contexts/MyContext';



const PowerBiFrame = (parent_props) => {
    const [newReportPages, setnewReportPages] = useState([])
    const [allNodes,setallNodes] = useState([])
    const [treearr, setTreearr] = useState([])
    const [treeziparr, setTreeziparr] = useState([])
    const [labelSelected, setLabelSelected] = useState(null);
    const [myPages, setMyPages] = useState([]);
    const [showLoader, setshowLoader] = useState(false);
    const [treemenucollapse, settreeMenuColapse] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [yearIndex, setyearIndex] = useState(0);
    const [currencytype, setCurrencyType] = useState('')
    const [currencyval, setCurrencyVal] = useState(79)
    const [currencyarr, setCurrencyArr] = useState([])
    const [conversiontype, setConversionType] = useState('Custom')
    const [moneymodalIsOpen, setMoneyModalIsOpen] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [renderComponentB, setRenderComponentB] = useState(false);
    const [dropDownData, setDropDownData] = useState([])
    const [dummynodes, setDummyNodes]  = useState([])
    const [filterarr, setfilterarr] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedpage, setSelectedPage] = useState('Consumer Internet')
    const [reportarr, setNewReportArr] = useState([])
    const [showReport, setshowReport] = useState(false);
    const [FirstPage, setFirstPage] = useState([]);
    const [showcurrencybar, setshowCurrencyBar] = useState(false);
    const [filterVal, setFilterVal] = useState(null)
    const [showExcel, setShowExcel] = useState(false);
    const [excelLoader,setExcelLoader] = useState(false);
    const { setcurr_reportid, setreportplayers } = React.useContext(MyContext);

    let [iconDict, setIconDict] = useState({
        'Sector Summary':<MdOutlineSummarize/>,
        'Sector Summary 2.0':<MdOutlineSummarize/>,
        'Overall':<SiCoveralls/>,
        'Traditional Brands':<BsTag/>,
        'SAAS':<BsFillCloudArrowDownFill/>,
        'Category':<BiCategory/>,
        'Amazon Specific':<FaAmazon />,
        'Paytm mall':<SiPaytm/>,
        'Flipkart':<SiFlipkart/>,
        'Shopclues':<BiCartAlt/>,
        'Traffic':<FaTrafficLight/>,
        'Engagement':<FaUsers/>,
        'Streams Analysis':<SiSimpleanalytics/>,
        'Monetization':<MdMonetizationOn/>,
        'User Profile':<FiUsers/>,
        'Tatacliq':<SiTata/>,
        'Engagement Profile':<AiOutlineFileSearch/>,
        'Sector Insights':<MdInsights/>,
        'Content':<BiBookContent/>,
        'Top Line Estimates':<FaDeezer/>,
        'Fulfilment Metrics':<FaAlignLeft/>,
        'Unit Economics':<MdMonetizationOn/>,
        'Keyboard':<FaKeyboard/>,
        'City Split':<FaCity/>,
        'Supply Chain Metrics':<GiBreakingChain/>,
        'Revenue Metrics':<RiMoneyDollarBoxFill/>,
        'Fintech':<RiMoneyDollarBoxFill/>,
        'Operational Metrics':<BiCartAlt/>,
        'Online Retail':<BiCartAlt/>,
        'Social Commerce Specific':<MdMonetizationOn/>,
        'Food Delivery':<IoFastFood/>,
        'Used Cars':<FaCarAlt/>,
        'Real Money Gaming':<RiMoneyDollarBoxFill/>,
        'Edtech':<BiBookContent/>,
        'eHealth':<GiHealthNormal/>,
        'Mobility':<GiCarWheel/>,
        // 'D2C Omni':<GoFileSymlinkDirectory/>,
        'Eb2b':<FaBusinessTime/>,
        // 'Consumer Internet':<ImConnection/>,
        // 'Baby Care':<FaBabyCarriage/>,
        // 'Mobile':<AiOutlineMobile/>,
        // 'Fashion':<GiClothes/>,
        'Electronics & Large/Small appliances':<BiFridge/>,
        'Epharma':<GiMedicines/>,
        // 'Grocery':<GiFruitBowl/>,
        'OTT_Video':<AiTwotoneVideoCamera/>,
        'OTT Audio':<FaRegFileAudio/>,
        'Content S&M':<BiBookContent/>,
        'Sector Summary (WIP)':<MdOutlineSummarize/>,
        'Sector Summary 2.0 (WIP)':<MdOutlineSummarize/>,
        'Company Profile (WIP)':<ImProfile />,
        'Overall (WIP)':<SiCoveralls/>,
        'Category (WIP)':<BiCategory/>,
        'Amazon Specific (WIP)':<FaAmazon />,
        'Amazon':<FaAmazon/>,
        'Traffic (WIP)':<FaTrafficLight/>,
        'Engagement (WIP)':<FaUsers/>,
        'Streams Analysis (WIP)':<SiSimpleanalytics/>,
        'Monetization (WIP)':<MdMonetizationOn/>,
        'User Profile (WIP)':<FiUsers/>,
        'Engagement Profile (WIP)':<AiOutlineFileSearch/>,
        'Sector Insights (WIP)':<MdInsights/>,
        'Online Education (WIP)':<BiBookContent/>,
        'Top Line Estimates (WIP)':<FaDeezer/>,
        'Online Education':<BiBookContent/>,
        'Fulfilment Metrics (WIP)':<FaAlignLeft/>,
        'Unit Economics (WIP)':<MdMonetizationOn/>,
        'Keyboard (WIP)':<FaKeyboard/>,
        'Dashboard (WIP)':<MdDashboard/>,
        'City Split (WIP)':<FaCity/>,
        'Supply Chain Metrics (WIP)':<GiBreakingChain/>,
        'Revenue Metrics (WIP)':<RiMoneyDollarBoxFill/>,
        'Operational Metrics (WIP)':<BiCartAlt/>,
        'Online Retail ':<BiCartAlt/>,
        'Social Commerce Specific (WIP)':<MdMonetizationOn/>,
        'Food Tech (WIP)':<IoFastFood/>,
        'Used Cars (WIP)':<FaCarAlt/>,
        'Real Money Gaming ':<RiMoneyDollarBoxFill/>,
        'EdTech (WIP)':<BiBookContent/>,
        'eHealth (WIP)':<GiHealthNormal/>,
        'Ride Hailing':<GiCarWheel/>,
        // 'D2C Omni (WIP)':<GoFileSymlinkDirectory/>,
        'eB2B (WIP)':<FaBusinessTime/>,
        // 'Consumer Internet (WIP)':<ImConnection/>,
        'Baby Care (WIP)':<FaBabyCarriage/>,
        'Mobile (WIP)':<AiOutlineMobile/>,
        'Fashion (WIP)':<GiClothes/>,
        'Electronics & Large/Small appliances (WIP)':<BiFridge/>,
        'Epharma (WIP)':<GiMedicines/>,
        // 'Grocery (WIP)':<GiFruitBowl/>,
        'Shortform Video (WIP)':<GiVideoConference/>,
        'OTT_Video (WIP)':<AiTwotoneVideoCamera/>,
        'OTT Audio (WIP)':<FaRegFileAudio/>,
        'Content S&M (WIP)':<BiBookContent/>,
        'Digital Content':<MdOutlinePersonalVideo/>,
        'eLogistics':<FaTruck/>,
        'Meesho':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/meesho.jpeg'/>,
        'Tatacliq':<img  style = {{'width':'15px', 'height':'10px'}}src = '/Images/tatacliq.jpeg'/>,
        'Snapdeal':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/snapdeal.jpeg'/>,
        'Ajio':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/a.jpeg'/>,
        'Clubfactory':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/clubfactory.jpeg'/>,
        'Jabong':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/jabong.jpeg'/>,
        'Koovs':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/koovs.jpeg'/>,
        'LimeRoad':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/limeroad.jpeg'/>,
        'Myntra':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/myntra.jpeg'/>,
        'Nykaa Fashion':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/nykaa.jpeg'/>,
        'Shein':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/shein.jpeg'/>,
        'Nykaa':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/nykaa.jpeg'/>,
        'Purplle':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/purplle.jpeg'/>,
        'Pepperfry':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/pepperfry.jpeg'/>,
        'Urban Ladder':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/urbanladder.jpeg'/>,
        'Delhivery':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/delhivery.png'/>,
        'Ecom Express':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/ecomexpress.png'/>,
        'Shadowfax':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/shadowfax.png'/>,
        'Xpressbees':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/xpressbee.png'/>,
        'Dailyhunt':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/Dailyhunt.png'/>,
        'Sharechat':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/sharechat.png'/>,
        'Glance':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/glance.png'/>,
        'Instagram':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/insta.png'/>,
        'YouTube':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/yt.png'/>,
        'Facebook':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/fb.png'/>,
        'Apple Music':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/applemusic.png'/>,
        'YouTube Music':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/ytmusic.png'/>,
        'Resso':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/resso.png'/>,
        'Spotify':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/spotify.png'/>,
        'Wynk':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/wynk.png'/>,
        'JioSaavn':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/jiosaavn.png'/>,
        'Gaana':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/gaana.png'/>,
        'AZ Prime Video':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/amazonprime.png'/>,
        'MX Player':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/mxplayer.png'/>,
        'Netflix':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/netflix.png'/>,
        'Voot':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/voot.png'/>,
        'Josh':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/josh.png'/>,
        'Moj':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/moj.png'/>,
        'Hotstar':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/hotstar.png'/>,
        'Moj Lite+ (MX TAKATAK)':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/hotstar.png'/>,
        'Winzo':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/winzo.png'/>,
        'MPL':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/mpl.png'/>,
        'Rush':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/rush.png'/>,
        'Junglee Rummy':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/junglee_rummy.png'/>,
        'Ace2Three':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/a23.png'/>,
        'Rummy Culture':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/rummy_culture.png'/>,
        'Rummy Circle':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/rummy_circle.png'/>,
        'Spartan Poker':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/spartan_poker.png'/>,
        'Pokerbaazi':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/pokerbaazi.png'/>,
        'Adda52':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/adda52.png'/>,
        'Zupee':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/zuppee.png'/>,
        'MyTeam11':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/MyTeam11.png'/>,
        'My11Circle':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/MyTeam11Circle.png'/>,
        'Dream 11':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/Dream11.png'/>,
        'BB Daily':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/bb_daily.png'/>,
        'Supr Daily':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/supr_daily.png'/>,
        'Milk Basket':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/milkbasket.png'/>,
        'Country Delight':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/country_delight.png'/>,
        'FTH Daily':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/fth_daily.png'/>,
        'Blinkit':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/blinkit.png'/>,
        'BB Now':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/bb_now.png'/>,
        'Swiggy Instamart':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/Swiggy.png'/>,
        'Dunzo Grocery':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/dunzo.png'/>,
        'Zepto':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/zepto.png'/>,
        'BB Now':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/bb_now.png'/>,
        'Swiggy Stores':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/Swiggy.png'/>,
        'Bigbasket':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/bb_now.png'/>,
        'Jiomart':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/jiomart.png'/>,
        'Amazon Grocery':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/amazon_grocery.png'/>,
        'Flipkart Grocery':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/flipkart.png'/>,
        'Dmart Ready':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/dmart.png'/>,
        'Pickily':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/pickily.png'/>,
        'Fraazo':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/fraazo.png'/>,
        'Swiggy Genie':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/Swiggy.png'/>,
        'Citymall':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/citymall.png'/>,
        'Dealshare':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/dealshare.png'/>,
        'Grofers':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/grofers.png'/>,
        'Apollo 247':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/apollo247.png'/>,
        'Flipkart Health Plus':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/flipkart_health_plus.png'/>,
        'Netmeds':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/netmeds.png'/>,
        'PharmEasy':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/Pharmeasy.png'/>,
        'TATA 1mg':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/1mg.png'/>,
        'Medplus':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/medplus.png'/>,
        'Redcliffe Labs':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/redcliff_labs.png'/>,
        'Healthians':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/healthians.png'/>,
        'OrangeHealth':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/orangehealth.png'/>,
        'Dhani':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/dhani.png'/>,
        'Truemeds':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/truemeds.png'/>,
        'MedPlus':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/netmeds.png'/>,
        'Ola':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/ola_cabs.png'/>,
        'Uber':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/uber.png'/>,
        'Rapido':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/rapido.png'/>,
        'Cars24':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/cars_24.png'/>,
        'Spinny':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/spinny.png'/>,
        'Ola Cars':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/ola_cabs.png'/>,
        'CarDekho':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/cardekho.png'/>,
        'FirstCry':<img  style = {{'width':'10px', 'height':'10px'}}src = '/Images/firstcry.png'/>,
      })
    
    useEffect(()=>{
       let client_id = window.localStorage.getItem("clientID")
        let root_rep = parent_props.root_rep
        let rep_id = parent_props.initialOpenNodes[0]
        console.log('rep_id= ', rep_id)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreports/?rep_id=${rep_id}&client_id=${client_id}`, {
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
        },
      })
      .then(res=>res.json())
      .then(
        res=>{
          setMyPages(res)
          setallNodes(res)
        }
      )

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreportaccess/?client_id=${client_id}`, {
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
        },
      })
      .then(res=>res.json())
      .then(
        res=>{
          setNewReportArr(res)
        }
      )

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/dummynodes/`,{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
        },
      }).then(res=>res.json())
      .then(
        res=>{
          setDummyNodes(res)
        }
      )
      let prop_token = window.localStorage.getItem('token')
      setshowLoader(true)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreportpages/?rep_id=${parent_props.initialOpenNodes}`,{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Token ${prop_token}`
        },
      }).then(res=>res.json())
      .then(
        res=>{
          console.log('init = ', res)
          if(res.length>=1){
            let firstpage = res.shift()
            if(firstpage.page_type==='httpsAddress' || firstpage.page_type==='component'){
              setRenderComponentB(true)
            }else{
              setRenderComponentB(false)
            }
            let firstpage_arr = [firstpage]
            setFirstPage(firstpage_arr)
            setnewReportPages(res)
            setshowLoader(false)
          }
        }
      )
    },[])

    let getNodeName = (key,all_nodes) =>{
      let nodes = all_nodes
      for(let i =0; i<nodes.length;i++){
        // search in top layer
        if(nodes[i].key==key){
          // //console.log('lognode=',nodes[i])
          return nodes[i].label
        }else{
          if(nodes[i].nodes.length>0){
            let node_name = getNodeName(key, nodes[i].nodes)
            if(node_name){
              return node_name
            }
          }else{
            //console.log('node array was empty')
          }
        }

      }
      // return labels correponding to key
    }

    const getParents = (props)=>{
      let parent_string_arr = props.parent.split('/')
      // ['9', '25', '26', '44']
      let parents = []
      let all_nodes = allNodes
      // my pages is a list with tree structure
      if(parent_string_arr[0]!==''){
        for(let i =0; i<parent_string_arr.length; i++){
          let nodename = getNodeName(parent_string_arr[i],all_nodes)
          if(nodename){
            parents.push(nodename)
          }
        }
      }
      return [parents, parent_string_arr]
    }
    


    let handleClickTree = (reportname, key, finalized, index = -1 , key_id = -1)=>{
        console.log('key_id=',parseInt(key_id))
        window.localStorage.setItem('report' , reportname)
        if(index===treearr.length-1 & key===-2){
          // disable last click. also disable clicks on non links
          return
        }if (key===-2 & dummynodes.includes(reportname)){
          return
        }
        if(key_id==9){
          // update to take from backend
          window.localStorage.setItem('report' , reportname)
          setTreeziparr([])
          setSelectedPage(reportname)
          console.log(reportname)
          setshowCurrencyBar(false)
          setshowReport(false)
          return
        }
        let found = false
        for (let i =0; i<reportarr.length;i++){
          if(reportarr[i].report_name===reportname){
            window.localStorage.setItem('start_date',reportarr[i].start_date)
            window.localStorage.setItem('end_date', reportarr[i].end_date)
            setShowExcel(reportarr[i].excel_access)
            console.log('start_date=',  window.localStorage.getItem("start_date"))
            console.log('end_date', window.localStorage.getItem("end_date"))
            found = true
            break
          }
        }
        if (found===false){
          window.localStorage.setItem('start_date', null)
          window.localStorage.setItem('end_date', null)
        }
        setshowLoader(true)
        let nodechildren_got = false
        // console.log('key=', key)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/nodechildren/?key=${key}`, {
          method:'GET',
          headers:{
            'Content-Type': 'application/json',
          },
        })
        .then(res=>res.json())
        .then(
          res =>{
            // key=25 fro onlien retail
            if(key === 25){
              setShowDropDown(false)
            }
            else if (res.length>=1){
              setShowDropDown(true)
              for(let i=0; i<res.length; i++){
                if(iconDict[res[i].label]){
                  res[i].icon = iconDict[res[i].label]
                }else {
                  res[i].icon = <GiHamburgerMenu/>
                }
              }
              setreportplayers(res)
              setDropDownData(res)
            }else{
              setShowDropDown(false)
            }
            nodechildren_got = true
          }
        )
        if (key === -1){
          nodechildren_got = true
          window.localStorage.setItem('report' , reportname)
          setSelectedPage(reportname)
          setShowDropDown(true)
          if (finalized===true){
            window.localStorage.setItem('finalized', 'true')
          }else{
            window.localStorage.setItem('finalized', 'false')
          }
        }
        if (key === -2){
          nodechildren_got = true
          window.localStorage.setItem('report' , reportname)
          setSelectedPage(reportname)
          setSelectedOption(null)
          setfilterarr(['1'])
          // check where the clicked report lies in array if it lies in range we show dropdown
          for(let i= 0; i<treearr.length; i++){
            if(treearr[i]===reportname){
              if(i>1){
                // breadcrumbs will be of type consumer inter/OR/hori/somthing. we only want ot show dirpp down for after 2nd breadcrumb
                setShowDropDown(true)
                break
              }else{
                setShowDropDown(false)
                break
              }
            }
          }

          console.log('index = ', index,treearr.length)
          // console.log('foundnode = ', getNodeDetails(myPages,reportname))
          if(true){
            let arr = treearr
            for(let i = treearr.length-1; i>index; i--){
              arr.pop()
            }
            setTreearr(arr)
            let zip_arr = treeziparr
            for (let i=treeziparr.length-1;i>index;i--){
                zip_arr.pop()
            }
            setTreeziparr(zip_arr)
          }
        }
        let prop_token = window.localStorage.getItem('token')
        let rep_id = parseInt(key_id)
        setcurr_reportid(rep_id)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreportpages/?rep_id=${rep_id}`, {
          method:'GET',
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Token ${prop_token}`
          }
        })
        .then(res=>res.json())
        .then(
          res=>{
            if(res.length<1){
              setshowReport(false)
              setshowCurrencyBar(false)
            }else{
            let firstpage = res.shift()
              if(firstpage.page_type==='httpsAddress' || firstpage.page_type==='component'){
                setRenderComponentB(true)
              }else{
                setRenderComponentB(false)
              }
            let firstpage_arr = [firstpage]
            setFirstPage(firstpage_arr)
              setshowReport(true)
            }
            setnewReportPages(res)
            // if(nodechildren_got===true){
            //   setshowLoader(false)
            // }
            setshowLoader(false)
            
          }
        )

        //console.log(reportname)
      }
    
    const onOptionSelect = (option) => {
        console.log('opt=',option)
        setFilterVal(option.filter_value)
        setfilterarr(option.filter.split(','))
        // when we are selecting from drop down we need to remove last element of breadcrumb and add new selected in its place
        if(selectedOption!==null){
          treearr.pop()
          treearr.push(option.label)
          treeziparr.pop()
          treeziparr.push([option.label, option.key_val])
        }else{
          treearr.push(option.label)
          treeziparr.push([option.label, option.key_val])
        }
        setSelectedOption(option.label);
        setshowCurrencyBar(true)
        handleClickTree(option.label, -1 , option.finalized , -1 , option.key_val)
      };


    const DEFAULT_PADDING = 16;
    const ICON_SIZE = 3;
    const LEVEL_SPACE = 10

    const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? <IoIosArrowDown/> : <IoIosArrowForward/>}</span>;

    const ListItem = ({
      level = 0,
      hasNodes,
      isOpen,
      label,
      subscribed = false,
      searchTerm,
      openNodes,
      toggleNode,
      matchSearch,
      focused,
      key,
      ...props
    }) => (
      <ListGroupItem
      // props enabling clicking/navigating nodes
        {...props}
        style={{
          paddingLeft:  ICON_SIZE + level * LEVEL_SPACE,
          cursor: level!==0?'pointer':'auto',
          boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
          zIndex: focused ? 999 : 'unset',
          position: 'relative',
          // backgroundColor:'#18183E', 
          backgroundColor:labelSelected===label & level !== 0?'#2323C8':'#18183E',
          color:level===0?'white':subscribed?'white':'grey',
          fontWeight:subscribed?'bold':'normal',
          border:'none',
          // zIndex:10,
          fontSize:level===0?20:17,
          // '&:hover':{    does not work. Onhover does not work inline styling
          //   backgroundColor:'red'
          // }
        }}
        onClick={e => {
            // this onclick conflicts with oclick defined below
            // make your get parents function here
          window.reports = []
          setSelectedOption(null)
          setLabelSelected(label)

          if(hasNodes===false){
            window.localStorage.setItem('report', label)
            
            if(props.finalized){
              setshowCurrencyBar(true)
              window.localStorage.setItem('finalized', 'true')
            }else{
              setshowCurrencyBar(false)
              window.localStorage.setItem('finalized', 'false')
            }
            console.log('props for filter=',props )
            if(props.filter!==null && props.filter!==''){
              setfilterarr(props.filter.split(','))
              window.localStorage.setItem('filterval', props.filter_value)
              setFilterVal(props.filter_value)
              console.log('filterval = ', props.filter_value)
            }else{
              setfilterarr([])
              setFilterVal(null)
            }
            setSelectedPage(label)
            let arr = currencyarr
            let currency_type = window.localStorage.getItem('currency')
            if(currency_type==='INR'){
              setActiveIndex(0)
              window.localStorage.setItem('currency', 'INR')
            }else{
              setActiveIndex(1)
              window.localStorage.setItem('currency', 'USD')
            }
            let year_type = window.localStorage.getItem('year')
            if(year_type==='CY'){
              setyearIndex(0)
              window.localStorage.setItem('year', 'CY')
            }else{
              setyearIndex(1)
              window.localStorage.setItem('year', 'FY')
            }
          }

          if(hasNodes && toggleNode){
            if(level===0){
              console.log('toggle diabled')
            }else{
              toggleNode()
              console.log('props = ', props)
              if(props.filter!==null && props.filter!==''){
                setfilterarr(props.filter.split(','))
                window.localStorage.setItem('filterval', props.filter_value)
                setFilterVal(props.filter_value)
                console.log('filterval = ', props.filter_value)
              }else{
                setfilterarr([])
                setFilterVal(null)
              }
              if([25,67].includes(props.key_val)){
                setSelectedPage(label)
                let p_arr = getParents(props)
                let parent_arr = p_arr[0]
                parent_arr.push(label)
                let key_arr = p_arr[1]
                key_arr.push(props.key_val.toString())
                setTreearr(parent_arr)
                let zip = parent_arr.map(function(e, i) {
                    return [e, key_arr[i]];
                    });
                setTreeziparr(zip)
                handleClickTree(label, props.key_val, props.node_type,-1, props.key_val)
                if(props.key_val===25){
                  setshowCurrencyBar(true)
                  window.localStorage.setItem('finalized', 'false')
                }else{
                  window.localStorage.setItem('finalized', 'true')
                }
              }
            }
          }else{
            setRenderComponentB(false)
            let p_arr = getParents(props)
            let key_arr =  p_arr[1]
            key_arr.push(props.key_val.toString())
            let parent_arr = p_arr[0]
            parent_arr.push(label);
            // const zip = (parent_arr, key_arr) => parent_arr.map((k, i) => [k, key_arr[i]]);

            var zip = parent_arr.map(function(e, i) {
            return [e, key_arr[i]];
            });
            setTreeziparr(zip)
            setTreearr(parent_arr)
            handleClickTree(label, props.key_val, props.node_type,-1, props.key_val)
          }
          e.stopPropagation();
        }}
      >
        <div style={{display:'flex', justifyContent:'space-between'}}>
        <div>{iconDict[label]} {label}</div>
          {hasNodes && (
            <div
              style={{ display: 'inline-block' }}
              // onClick={e => {
              //   if(hasNodes && toggleNode){
              //     toggleNode()
              //   }
              //   // hasNodes && toggleNode && toggleNode();
              //   e.stopPropagation();
              // }}
            >
              {/* <ToggleIcon on={isOpen} /> */}
              {level!==0?<ToggleIcon on={isOpen} />:<ToggleButton  display = {treemenucollapse?'block':'none'} onClick = {handleTreeMenuCollapse}><GiHamburgerMenu/></ToggleButton>}
            </div>
          )}
          
        </div>
      </ListGroupItem>
    );
    
    let handleCurrencyClick = (index) => {
      setActiveIndex(index);
      if(index===0){
        window.localStorage.setItem('currency', 'INR')
        //console.log(window.localStorage.getItem('currency'))
        setCurrencyType('INR')
      }else{
        window.localStorage.setItem('currency', 'USD')
        //console.log(window.localStorage.getItem('currency'))
        setCurrencyType('USD')
      }

      const money_converter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
            table: "Currency Table",
            column: "Currency"
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
            {
                operator: "Is",
                value: window.localStorage.getItem('currency')
            }
        ] 
      }
      for(let i = 0; i<window.reports.length; i++){
        window.reports[i].getActivePage().then(
          (activePage=>{
            activePage.getVisuals().then(
              (visuals=>{
                let slicers = visuals.filter(function (visual) {
                  return visual.type === "slicer";
              });
                slicers.forEach(async (slicer) => {
                const state = await slicer.getSlicerState();  
                
                if(state.targets[0].column==='Currency'){
                  let target_slicer = visuals.filter(function (visual) {
                    return visual.type === "slicer" && visual.name === slicer.name;             
                })[0];
                  await target_slicer.setSlicerState({ filters: [money_converter] });
                }
            })      
              })
            )
          })
        )
      }
      // let curr = window.localStorage.getItem('currency')
      // let year = window.localStorage.getItem('year')
      // let email = window.localStorage.getItem('email')
      // const uploadData = new FormData();
      // uploadData.append('email', email);
      // uploadData.append('year', year);
      // uploadData.append('currency', curr)
      // fetch(`${process.env.REACT_APP_API_ENDPOINT}/usercurrency/`, {
      //     method: 'POST',
      //     body: uploadData
      //   }).then(data => data.json())
      //   .then( data => {
      //         console.log(data)
      //     })
      //   .catch(error => {
      //     // setSignIn(false);
      //     alert('System Error.Contact Admin')
      //     console.log(error)
      // })

    };
    let handleYearClick = (index) =>{
      setyearIndex(index);
      if(index===0){
        window.localStorage.setItem('year', 'CY')
      }else{
        window.localStorage.setItem('year', 'FY')
      }

      const year_converter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
            table: "Date Parameter",
            column: "Year_Type"
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
            {
                operator: "Is",
                value: window.localStorage.getItem('year')
            }
        ]
      }
      //console.log('wrs = ', window.reports)
      for(let i= 0;i<window.reports.length; i++){
        window.reports[i].getActivePage().then(
          (activePage=>{
            activePage.getVisuals().then(
              (visuals=>{
                let slicers = visuals.filter(function (visual) {
                  return visual.type === "slicer";
              });
                slicers.forEach(async (slicer) => {
                const state = await slicer.getSlicerState();  

                if(state.targets[0].column==='Year_Type'){
                  let target_slicer = visuals.filter(function (visual) {
                    return visual.type === "slicer" && visual.name === slicer.name;             
                })[0];
                  await target_slicer.setSlicerState({ filters: [year_converter] });
                }
            })      
              })
            )
          })
        )
      }


    }

    let handleGearClick = ()=>{
      let val = window.localStorage.getItem('currency_val')
      let conversion_type = window.localStorage.getItem('conversion_type')
      if(conversion_type!==null){
        setConversionType(conversion_type)
      }else{
        setConversionType('Custom')
      }
      if(val!==null){
        console.log(val)
        setCurrencyVal(val)
      }else{
        setCurrencyVal(79)
      }
      setMoneyModalIsOpen(true)
    }

    let incCurrency = ()=>{
      let new_curr = currencyval+1
      if(new_curr>85){
        new_curr=85
      }
      setCurrencyVal(new_curr)
    }
    let decCurrency = ()=>{
      let new_curr = currencyval-1
      if(new_curr<65){
        new_curr = 65
      }
      setCurrencyVal(new_curr)

    }

    let handlemodalSubmitClicked = ()=>{

      if(conversiontype==='Custom'){
        //console.log('custom')
        // //console.log(currencyval)
        window.localStorage.setItem('conversion_type', 'Custom')
        console.log(window.localStorage.getItem('conversion_type'))
        console.log(currencyval)
        window.localStorage.setItem('currency_val', currencyval)
        const currency_valuation = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
              table: "Currency input",
              column: "Currency input"
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Is",
          conditions: [
              {
                  operator: "Is",
                  value: currencyval
              }
          ] 
        }
        const usd_selector  = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
              table: "Currency_USD_Type",
              column: "Type"
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Is",
          conditions: [
              {
                  operator: "Is",
                  value: conversiontype
              }
          ] 
        }
        //console.log('wrs=', window.reports)

        for (let i =0;i<window.reports.length;i++){
          window.reports[i].getActivePage().then(
            (activePage=>{
              activePage.getVisuals().then(
                (visuals=>{
                  // //console.log('visuals=',visuals)
                  let slicers = visuals.filter(function (visual) {
                    return visual.type === "slicer";
                }
                );
                  slicers.forEach(async (slicer) => {
                    const state = await slicer.getSlicerState();    
                    //console.log("Slicer name: \"" + slicer.name + "\"\nSlicer state:\n", state);

                    //not using state as it will change on page load.page laod code for 1st
                    
                    if(state.targets[0].column==="Type"){
                      let target_slicer = visuals.filter(function (visual) {
                        return visual.type === "slicer" && visual.name === slicer.name;             
                    })[0];
                      await target_slicer.setSlicerState({ filters: [usd_selector] });
                    }

                    if(state.targets[0].column==="Currency input"){
                      let custom_usd_slicer = visuals.filter(function (visual) {
                        return visual.type === "slicer" && visual.name === slicer.name;             
                    })[0]
                      await custom_usd_slicer.setSlicerState({ filters: [currency_valuation] });
                    }

              })      
                  // //console.log('slicer=', slicers)
                })
              )
            })
          )
        }
      }else{
        window.localStorage.setItem('conversion_type', 'Dynamic')
        console.log(window.localStorage.getItem('conversion_type'))
        const usd_selector  = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
              table: "Currency_USD_Type",
              column: "Type"
          },
          filterType: models.FilterType.Advanced,
          logicalOperator: "Is",
          conditions: [
              {
                  operator: "Is",
                  value: conversiontype
              }
          ] 
        }
        for(let i=0;i<window.reports.length;i++){
          window.reports[i].getActivePage().then(
            (activePage=>{
              activePage.getVisuals().then(
                (visuals=>{
                  // //console.log('visuals=',visuals)
                  let slicers = visuals.filter(function (visual) {
                    return visual.type === "slicer";
                }
                );
                  slicers.forEach(async (slicer) => {
                    const state = await slicer.getSlicerState();    
                    //console.log("Slicer name: \"" + slicer.name + "\"\nSlicer state:\n", state);

                    //not using state as it will change on page load.page laod code for 1st
                    
                    if(state.targets[0].column==="Type"){
                      let target_slicer = visuals.filter(function (visual) {
                        return visual.type === "slicer" && visual.name === slicer.name;             
                    })[0];
                      await target_slicer.setSlicerState({ filters: [usd_selector] });
                    }

              })      
                  // //console.log('slicer=', slicers)
                })
              )
            })
          )
        }
      }
      setMoneyModalIsOpen(false);
    }

    let handleTreeMenuCollapse = ()=>{
      settreeMenuColapse(!treemenucollapse)
      
      for(let i = 0; i<window.reports.length;i++){
        window.reports[i].getActivePage().then(
          (activePage=>{
            if(i==0){
              let active_ht = activePage.defaultSize.height
            let active_width = activePage.defaultSize.width
            let width = document.getElementsByClassName('report-style-class-newreport'+i)[0].offsetWidth;
            let ht = ((active_ht/active_width)*width)
            console.log(active_ht,active_width, width,ht)
            document.getElementsByClassName('report-style-class-newreport'+i)[0].style.height = ht+'px';
            }else{
              let active_ht = activePage.defaultSize.height
            let active_width = activePage.defaultSize.width
            let x = i-1
            let width = document.getElementsByClassName('report-style-class-newreport'+x+1)[0].offsetWidth;
            let ht = ((active_ht/active_width)*width)
            console.log(active_ht,active_width, width,ht)
            document.getElementsByClassName('report-style-class-newreport'+x+1)[0].style.height = ht+'px';
            }
            }))

      }
      }


    let downloadExcel = ()=>{
        setExcelLoader(true)
        setShowExcel(false)
        let client_id = window.localStorage.getItem("clientID")
        let report_name = window.localStorage.getItem("report")
        let prop_token = window.localStorage.getItem("token")
        console.log(client_id, report_name)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/newexcel/?client_id=${client_id}&report_name=${report_name}`,{
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Token ${prop_token}`
            }
          }).then((res) => res.json())
          .then(
            res => {
                console.log('link= ', res)
                const link = document.createElement('a');
                link.href = res['excel_link'];
                link.download = report_name;
                link.click();
            
                // window.open(res['excel_link'], "_blank")

                setExcelLoader(false)
                setShowExcel(true)
            }
            )
            .catch( error => {
              console.error(error)
            })
      }


    const platform_option = dropDownData


    const datefilter = {
        $schema: "http://powerbi.com/product/schema#basic",
      target: {
      
      
      
            table: "date_table",
      
      
      
            column: "date"
      
      
      
        },
      
      
      
        filterType: models.FilterType.Advanced,
      
      
      
        logicalOperator: "And",
      
      
      
        conditions: [
      
      
      
            {
                operator: "GreaterThanOrEqual",
                value: window.localStorage.getItem("start_date")+"T00:00:00.000Z"
            },
      
      
      
            {
      
      
      
                operator: "LessThanOrEqual",
      
      
      
                value: window.localStorage.getItem("end_date")+"T00:00:00.000Z"
      
      
      
            }
      
      
      
        ]
      
      
      
      };
      const datefilter_max = {
        $schema: "http://powerbi.com/product/schema#basic",
      target: {
            table: "db_date_table",
            column: "DB_Date"
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "And",
        conditions: [
            {
              operator:"GreaterThanOrEqual",
              value: window.localStorage.getItem("start_date")+"T00:00:00.000Z"
            },
            {
                operator: "LessThanOrEqual",
                value: window.localStorage.getItem("end_date")+"T00:00:00.000Z"
            }
        ]
      };
      const currencyFilter = {$schema:"http://powerbi.com/product/schema#basic",target:{table:"CurrencyTable", "column":"Currency"},operator:"In",values:["INR"],filterType:models.FilterType.BasicFilter};
      
      const TestProdfilter = {

        $schema: "http://powerbi.com/product/schema#basic",
    
        target: {
    
            table: "Workspace_Table",
    
            column: "Workspace_Name"
    
        },
    
        operator: "In",
    
        
        values:[process.env.REACT_APP_ENVIRONMENT]
    
    };
    
     
    

      const basicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "content_data main_data",
        "column": "Players"},
      
        operator: "In",
        values:[filterVal],
        // values: [window.localStorage.getItem('report')],
        filterType: models.FilterType.BasicFilter
      };

      const ssFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "ss_content_data player",
        "column": "Players"},
      
        operator: "In",
        values:[filterVal],
        // values: [window.localStorage.getItem('report')],
        filterType: models.FilterType.BasicFilter
      };
      
      const categoryfilter = {$schema:"http://powerbi.com/product/schema#basic",
      target:{table:"ss_content_data parameter",column:"Group_Categories"},
      operator:"In",values:[filterVal]};

      const industryfilter = {
        $schema: "http://powerbi.com/product/schema#basic",
    target: {
        table: "content_data industry",
        column: "industry_name"
    },
    operator: "In",
    values: [filterVal]
      }

      const sectorfilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "Grocery_Sector_Table",
            column: "Sector"
        },
        operator: "In",
        values: [filterVal]
      }

      const medicalCategoryFilter = {
        $schema:"http://powerbi.com/product/schema#basic",
        target:{table:"ss_content_data parameter",column:"Group_Categories"},
        operator:"In",values:[filterVal]
      } 

      let filter_arr = [datefilter, datefilter_max, TestProdfilter]
      // let filter_arr = []
      // if (parent_props.initialOpenNodes=='275'){
      //   filter_arr.push(basicFilter)
      // }
      for(let i=0; i<filterarr.length;i++){
        if(filterarr[i]==1){
          filter_arr.push(basicFilter)
        }else if(filterarr[i]==='categories'){
          filter_arr.push(categoryfilter)
        } else if(filterarr[i]==='industry'){
          filter_arr.push(industryfilter)
        } else if (filterarr[i]==='sector'){
          console.log('sectorfilter')
          filter_arr.push(sectorfilter)
        } else if (filterarr[i]==='medical'){
          console.log('medicalfilter')
          filter_arr.push(medicalCategoryFilter)
        } else if (filterarr[i]==='ssFilter'){
          console.log('ssFilter')
          filter_arr.push(ssFilter)
        }
      }
    

    if(window.localStorage.getItem('loginStatus')!=='true'){
        return <Navigate to = "/"/>
      }

      // const treeData = [
      //   {
      //     key: 'first-level-node-1',
      //     label: 'Death',
      //     name:'pain', // any other props you need, e.g. url
      //     nodes: [
      //       {
      //         key: 'second-level-node-1',
      //         label: 'cote',
      //         name:'killer',
      //         nodes: [
      //           {
      //             key: 'third-level-node-1',
      //             label: 'code',
      //             name:'purgatory',
      //             subscribed:true,
      //             nodes: [] // you can remove the nodes property or leave it as an empty array
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     key: 'first-level-node-2',
      //     label: 'geass',
      //     name:'plain',
      //     subscribed:true
      //   },
      // ];

      const treeData = [
        {
          key: 'first-level-node-1',
          label: 'Performance',
          name:'Performance', // any other props you need, e.g. ur
        },
      ];

      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
        //console.log('modalopen')
      }
      function closeModal() {
        setIsOpen(false);
        setMoneyModalIsOpen(false);
      }

  return (
    <div>
        <BodyContainer>
          <SideMenuContainer width={treemenucollapse ? '20vw' : '10px'}>
              <TreeMenu
              style = {{width:'25vw'}}
              data={myPages}
              initialOpenNodes = {parent_props.initialOpenNodes}
              onClickItem={({ key, label, ...props }) => {
              }}
              >
                {({ search, items, searchTerm }) => {
                  // const nodesForRender = getNodesForRender(items, searchTerm);
                  return (
                  <div style={{paddingLeft:'10px', marginTop:'5px'}}>
                    <ListGroup>
                      {items.map(props => (
                        <ListItem  {...props} />
                      ))}
                    </ListGroup>
                  </div>
            )}}
            </TreeMenu>
          </SideMenuContainer>

          {showLoader===false?<PowerBiDiv width={treemenucollapse ? '80vw' : '99vw'}>
                  <div style={{'background':'#F5F8FC', 'minHeight':'10vh', 'marginLeft':'-7px'}}>
                    <HeaderDiv>
                    {treemenucollapse?<></>:<button  style={{'height':'40px', 'borderRadius':'50%', 'width':'40px', 'backgroundColor':'#18183E', 'color':'white', 'border':'0px', 'verticalAlign':'middle' }} onClick={handleTreeMenuCollapse}><GiHamburgerMenu/></button>}<span style={{'fontSize':'33px', 'fontWeight':'bold', 'fontFamily':'system-ui', 'verticalAlign':'middle'}}>{selectedpage}</span>
                    </HeaderDiv>
                    
                    <div className='breadcrumbs' style={treemenucollapse?{'marginLeft':'3vw' ,'marginBottom':'10px'}:{'marginLeft':'3.5vw' ,'marginBottom':'10px'}}>                       
                    {parent_props.headarr.length>0?<>{parent_props.headarr.map(( val, i)=><BreadCrumbSpan>{val} / </BreadCrumbSpan>)}{treeziparr.map(( val, i)=><BreadCrumbSpan onClick={(e)=>{handleClickTree(val[0], -2 , '-1', i, val[1])}}>{val[0]} / </BreadCrumbSpan>)}</>:<span className='breadcrumbs'>Consumer Internet</span>}
                    </div>

                    {showcurrencybar?<Currency marginLeft = {treemenucollapse?'3vw':'3.5vw'}columns={treemenucollapse?'0.5fr 0.15fr 1fr 0fr 0.9fr 1fr 1.7fr 1.44fr 1.2fr 0.11fr':'0.5fr 0.15fr 1fr 0fr 0.9fr 1fr 1.7fr 2.25fr 1.2fr 0.3fr'}>
                      <Descurr>Currency</Descurr>
                      <Inr>
                        <Currencybutton bgcolor={activeIndex === 0 ? '#26CDCC' : 'white'}
                         color={activeIndex === 1 ? '#333333' : '#333333'}
                        onClick={() => handleCurrencyClick(0)}>
                          INR
                        </Currencybutton>
                        <Currencybutton bgcolor={activeIndex === 1 ? '#26CDCC' : 'white'}
                        color={activeIndex === 1 ? '#333333' : '#333333'}
                        onClick={() => handleCurrencyClick(1)}>
                          USD
                        </Currencybutton>
                      </Inr>
                      <Gear><BsGear style={{ 'fontSize': "20px" }} onClick={()=>handleGearClick()}/></Gear>
                      <Cyfy>
                        <Currencybutton bgcolor={yearIndex === 0 ? '#26CDCC' : 'white'}
                         color={yearIndex === 1 ? '#333333' : '#333333'}
                        onClick={() => handleYearClick(0)}>CY</Currencybutton>
                        <Currencybutton bgcolor={yearIndex === 1 ? '#26CDCC' : 'white'}
                        color={yearIndex === 0 ? '#333333' : '#333333'}
                        onClick={() => handleYearClick(1)}>FY</Currencybutton>
                      </Cyfy>
                      <ExcelDiv>
                       {showExcel?<ExcelButton onClick={()=>downloadExcel()}>Download Data</ExcelButton>:null}
                        {excelLoader?<TailSpin
                        height="20"
                        width="20"
                        color="#18183E"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        // wrapperStyle={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%, -50%)"}}
                        wrapperClass=""
                        visible={true}
                      />:null}
                      </ExcelDiv>
                      <Dropdn>
                      {showDropDown?<MyDropdown options={platform_option}
                      onOptionSelect={onOptionSelect}
                      prev_value = {selectedOption}
                      default_head = {'Select Platform'}
                      />:null}
                      </Dropdn>
                    </Currency>:<Currency marginLeft = {treemenucollapse?'3vw':'3.5vw'}columns={treemenucollapse?'0.5fr 0.15fr 1fr 0fr 0.9fr 1fr 1.7fr 1.44fr 1.2fr 0.11fr':'0.5fr 0.15fr 1fr 0fr 0.9fr 1fr 1.7fr 2.25fr 1.2fr 0.3fr'}>
                      <Descurr></Descurr>
                      <Inr>
                      </Inr>
                      <Gear></Gear>
                      <Cyfy>
                      </Cyfy>
                      <ExcelDiv>
                      </ExcelDiv>
                      <Dropdn>
                      {showDropDown?<MyDropdown options={platform_option}
                      onOptionSelect={onOptionSelect}
                      prev_value = {selectedOption}
                      default_head = {'Select Platform'}
                      />:null}
                      </Dropdn>
                    </Currency>}
                      <div>
                      <Modal
                      isOpen={moneymodalIsOpen}
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div>Please select USD conversion rate</div>
                      <form>
                      <input type="radio" id="html" name="fav_language"  onClick={()=>{setConversionType('Dynamic')}} value = 'Dynamic'checked={conversiontype === 'Dynamic'}/>
                      <label  style={{'marginLeft':'5px'}}>Dynamic</label><br/>
                      <input type="radio" id="css" name="fav_language" onClick={()=>{setConversionType('Custom')}} value="Custom" checked={conversiontype === 'Custom'}/>
                      <label  style={{'marginLeft':'5px'}}> Custom</label><br/>
                      </form>
                      <div>
                        <button style={{'marginRight':'5px', 'borderRadius':'5px', 'width':'30px'}} onClick = {decCurrency}>-</button>
                        <input  value={currencyval}/>
                        <button style={{'marginLeft':'5px', 'borderRadius':'5px', 'width':'30px'}} onClick = {incCurrency}>+</button>
                      </div>
                      <button style={{'color':'white', 'backgroundColor':'#4867AA','marginTop':'10px', 'borderRadius':'6px'}} onClick={handlemodalSubmitClicked}>Submit</button>
                    </Modal>
                    </div>
                  </div>
                  {showReport?<PowerBiDiv>
                  {FirstPage.map((index,i) => {
                    // console.log('index =', index)
                    if (index.page_type==='component'){
                      const Component = DynamicComponent(index.address);
                      console.log('firstpage')
                      return (
                        <Suspense key={index} fallback={<div>Loading...</div>}>
                          <Component val = {index.component_variable}/>
                        </Suspense>
                      );
                    }else if(index.page_type==='httpsAddress'){
                      let url = ''
                      if (index.component_variable) {
  
                          const jsonString = index.component_variable.replace(/'/g, '"');
                          // Parse the JSON string into an object
                          const componentObject = JSON.parse(jsonString);
  
                          if (componentObject.name.startsWith("$")) {
                              // Replace the value with the desired value, e.g., 'Maaza'
                              componentObject.name = parent_props.value;
                            }
  
                          // Create a query string from the componentObject
                          const queryParams = new URLSearchParams(componentObject).toString();
                          console.log('query_params = ', queryParams)
                          url = `${index.address}?${queryParams}`;
                        }else{
                          url = index.address
                        }
                    
                      // const url = queryParams ? `${index.address}?${queryParams}` : index.address;
                      console.log('url = ', url)
                      return(
                        <>
                        <DjangoEmbed address={url}/>
                        </>
                      )
                    }else{
                      if(index.component_variable){
                      
                        let jsonObj = JSON.parse(index.component_variable.replace(/'/g, '"'))
                        for (let key in jsonObj) {
                          if (jsonObj.hasOwnProperty(key)) {
                              let value = jsonObj[key]
                              if (value.startsWith("$")) {
                                value = parent_props.value;
                              }
                            if(key==='player'){
                              const basicFilter1 = {
                                $schema: "http://powerbi.com/product/schema#basic",
                                target: {
                                  table: "content_data main_data",
                                "column": "Players"},
                              
                                operator: "In",
                                values:[value],
                                // values: [window.localStorage.getItem('report')],
                                filterType: models.FilterType.BasicFilter
                              };
                              if(index.free_page===true){
                                filter_arr = []
                              }
                              filter_arr.push(basicFilter1)
                            }else if(key==='categories'){
                              const categoryfilter1 = {$schema:"http://powerbi.com/product/schema#basic",
                            target:{table:"ss_content_data parameter",column:"Group_Categories"},
                            operator:"In",values:[value]};
                              filter_arr.push(categoryfilter1)
                            } else if(key==='industry'){
                              const industryfilter1 = {
                                $schema: "http://powerbi.com/product/schema#basic",
                                target: {
                                    table: "content_data industry",
                                    column: "industry_name"
                                },
                                operator: "In",
                                values: [value]
                              }
                              filter_arr.push(industryfilter1)
                            } else if (key==='sector'){
                              const sectorfilter1 = {
                                $schema: "http://powerbi.com/product/schema#basic",
                                target: {
                                    table: "Grocery_Sector_Table",
                                    column: "Sector"
                                },
                                operator: "In",
                                values: [value]
                              }
                              filter_arr.push(sectorfilter1)
                            } else if (key==='medical'){
                                const medicalCategoryFilter1= {
                                $schema:"http://powerbi.com/product/schema#basic",
                                target:{table:"ss_content_data parameter",column:"Group_Categories"},
                                operator:"In",values:[value]
                              } 
                              filter_arr.push(medicalCategoryFilter1)
                            }
                          }
                        }
                        }
                      return(
                        <div key={index.id}>
                          {/* {index.has_address===true &&(<><DjangoEmbed address={index.address}/></>)} */}
                          {true && (<><PowerBIEmbed
                           embedConfig = {{
                            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                            id: index['powerbi_report_id'],
                            //get from props
                            embedUrl:index['url'],
                            accessToken: index['embed'],
                            tokenType: models.TokenType.Embed,
                            filters: filter_arr,
                            settings: {
                              // background:models.BackgroundType.Transparent,
                              layoutType:models.LayoutType.Custom,
                              customLayout:{
                                displayOption:models.DisplayOption.FitToPage
                              },
                              panes: {
                                filters: {
                                  expanded: false,
                                  visible: false,
                                },
                              },
                              navContentPaneEnabled:false
                            }
                          }}
                          eventHandlers = {
                            new Map([
                              ['loaded', async function (event, report) {
                                //console.log('Report loaded');
                                setRenderComponentB(true)
                                if(true){
                                  const filter = {
                                    $schema: "http://powerbi.com/product/schema#advanced",
                                    target: {
                                        table: "content_data player",
                                        column: "player_name"
                                    },
                                    filterType: models.FilterType.Advanced,
                                    logicalOperator: "Is",
                                    conditions: [
                                        {
                                            operator: "Is",
                                            value: window.localStorage.getItem("player_name")
                                        }
                                    ]
                                };
                                // filter if there is a player in dropdown menu instead of page
                                const filter_player_dropdown = {
                                  $schema: "http://powerbi.com/product/schema#advanced",
                                    target: {
                                        table: "content_data main_data",
                                        column: "Players"
                                    },
                                    filterType: models.FilterType.Advanced,
                                    logicalOperator: "Is",
                                    conditions: [
                                        {
                                            operator: "Is",
                                            value: window.localStorage.getItem('drop_dn_player_name')
                                        }
                                    ]
                                }
                                const money_converter = {
                                  $schema: "http://powerbi.com/product/schema#advanced",
                                  target: {
                                      table: "Currency Table",
                                      column: "Currency"
                                  },
                                  filterType: models.FilterType.Advanced,
                                  logicalOperator: "Is",
                                  conditions: [
                                      {
                                          operator: "Is",
                                          value: window.localStorage.getItem('currency')
                                      }
                                  ]
                                }
                                const year_converter = {
                                  $schema: "http://powerbi.com/product/schema#advanced",
                                  target: {
                                      table: "Date Parameter",
                                      column: "Year_Type"
                                  },
                                  filterType: models.FilterType.Advanced,
                                  logicalOperator: "Is",
                                  conditions: [
                                      {
                                          operator: "Is",
                                          value: window.localStorage.getItem('year')
                                      }
                                  ]
                                }
                                const currency_valuation = {
                                  $schema: "http://powerbi.com/product/schema#advanced",
                                  target: {
                                      table: "Currency input",
                                      column: "Currency input"
                                  },
                                  filterType: models.FilterType.Advanced,
                                  logicalOperator: "Is",
                                  conditions: [
                                      {
                                          operator: "Is",
                                          value: 71
                                      }
                                  ]
                                }
                                const usd_selector  = {
                                  $schema: "http://powerbi.com/product/schema#advanced",
                                  target: {
                                      table: "Currency_USD_Type",
                                      column: "Type"
                                  },
                                  filterType: models.FilterType.Advanced,
                                  logicalOperator: "Is",
                                  conditions: [
                                      {
                                          operator: "Is",
                                          value: 'Custom'
                                      }
                                  ]
                                }
                    
                                report.getActivePage().then(
                                  (activePage=>{
                                    let active_ht = activePage.defaultSize.height
                                    let active_width = activePage.defaultSize.width
                                    let width = document.getElementsByClassName('report-style-class-newreport'+i)[0].offsetWidth;
                                    let ht = ((active_ht/active_width)*width)
                                    const removeLink = () => {
                                      const links = document.querySelectorAll('link[href^="https://content.powerapps.com/resource/powerappsportal/dist/preform"]');
                                      links.forEach((link) => {
                                        link.remove();
                                      });
                                    };
                                    removeLink()
                                  //   if(i==0){
                                  //     if(window.localStorage.getItem('finalized')==='false'){
                                  //       document.getElementsByClassName('report-style-class-newreport'+i)[0].style.marginTop = '-12vh';
                                  //     }
                                  //   }
                                    document.getElementsByClassName('report-style-class-newreport'+i)[0].style.height = ht+'px';
                                    document.getElementsByClassName('report-style-class-newreport'+i)[0].style.backgroundColor = '#F5F8FC'
                                    document.getElementsByClassName('report-style-class-newreport'+i)[0].children[0].style.border = '0px';
                                    document.getElementsByClassName('report-style-class-newreport'+i)[0].children[0].style.backgroundColor = '#F5F8FC';
                                    // document.getElementsByClassName('report-style-class-newreport'+i)[0].querySelector('iframe').style.marginLeft = '-10px';
                                    activePage.getVisuals().then(
                                      (visuals=>{
                                        // //console.log('visuals=',visuals)
                                        let slicers = visuals.filter(function (visual) {
                                          return visual.type === "slicer";
                                      }
                                      );
                                        slicers.forEach(async (slicer) => {
                                          const state = await slicer.getSlicerState();
                                          //console.log("Slicer name: \"" + slicer.name + "\"\nSlicer state:\n", state);
                                          if(state.targets[0].column==="player_name"){
                                            //console.log('slicer_name=',slicer)
                                            let target_slicer = visuals.filter(function (visual) {
                                              return visual.type === "slicer" && visual.name === slicer.name;
                                          })[0];
                                            await target_slicer.setSlicerState({ filters: [filter] });
                                          }
                                          //not using state as it will change on page load.page laod code for 1st
                                          if(state.targets[0].column==='Players' && window.localStorage.getItem('filter_on_company')==='true'){
                                            let target_slicer = visuals.filter(function (visual) {
                                              return visual.type === "slicer" && visual.name === slicer.name;
                                          })[0];
                                            await target_slicer.setSlicerState({ filters: [filter_player_dropdown] });
                                          }
                                          if(state.targets[0].column==='Currency'){
                                            let target_slicer = visuals.filter(function (visual) {
                                              return visual.type === "slicer" && visual.name === slicer.name;
                                          })[0];
                                            await target_slicer.setSlicerState({ filters: [money_converter] });
                                          }
                                          if(state.targets[0].column==='Year_Type'){
                                            let target_slicer = visuals.filter(function (visual) {
                                              return visual.type === "slicer" && visual.name === slicer.name;
                                          })[0];
                                            await target_slicer.setSlicerState({ filters: [year_converter] });
                                          }
                                    })
                                        // //console.log('slicer=', slicers)
                                      })
                                    )
                                  })
                                )
                                }
                              }],
                              ['rendered', function () {
                              }],
                              ['buttonClicked', function(event, report){
                                let ques_name = event.detail['title']
                                let company_name = ''
                                window.report.getActivePage().then(
                                  (activePage=>{
                                    activePage.getVisuals().then(
                                      (visuals=>{
                                        let slicers = visuals.filter(function (visual) {
                                          return visual.type === "slicer";
                                      });
                                        slicers.forEach(async (slicer) => {
                                        const state = await slicer.getSlicerState();
                                        // //console.log("Slicer name: \"" + slicer.name + "\"\nSlicer state:\n", state);
                                      //   if(state.targets[0].column==="player_name"){
                                      //     company_name=state.filters[0].values[0]
                                      //     // company_name=window.sessionStorage.getItem("player_name")
                                      //     openModal(company_name, ques_name)
                                      //   }
                    
                                    })
                                        // //console.log('slicer=', slicers)
                                      })
                                    )
                                  })
                                )
                              }],
                              ['error', function (event) {console.log('powerbi_error=',event.detail);}]
                            ])
                          }
                    
                          cssClassName = { "report-style-class-newreport"+i}
                          getEmbeddedComponent = {async(embeddedReport) => {
                            if(window.reports === undefined) {
                              window.reports=[]
                            }
                            window.reports.push(embeddedReport);
                            // console.log(window.reports)
                          }
                    
                        }
                         /></>)}
      
                        </div>
                      )
  
                    }
                })}
                {renderComponentB && newReportPages.map((index,i) => {
                  if (index.page_type==='component'){
                    const Component = DynamicComponent(index.address);
                    console.log('others')
                    return (
                      <Suspense key={index} fallback={<div>Loading...</div>}>
                        <Component val = {index.component_variable}/>
                      </Suspense>
                    );
                  }else if(index.page_type==='httpsAddress'){
                    let url = ''
                    if (index.component_variable) {

                        const jsonString = index.component_variable.replace(/'/g, '"');
                        // Parse the JSON string into an object
                        const componentObject = JSON.parse(jsonString);

                        if (componentObject.name.startsWith("$")) {
                            // Replace the value with the desired value, e.g., 'Maaza'
                            componentObject.name = parent_props.value;
                          }

                        // Create a query string from the componentObject
                        const queryParams = new URLSearchParams(componentObject).toString();
                        console.log('query_params = ', queryParams)
                        url = `${index.address}?${queryParams}`;
                      }else{
                        url = index.address
                      }
                  
                    // const url = queryParams ? `${index.address}?${queryParams}` : index.address;
                    console.log('url = ', url)
                    return(
                      <>
                      <DjangoEmbed address={url}/>
                      </>
                    )
                  }else{
                    if(index.component_variable){
                      
                    let jsonObj = JSON.parse(index.component_variable.replace(/'/g, '"'))
                    console.log('filterobj=', jsonObj)
                    for (let key in jsonObj) {
                      if (jsonObj.hasOwnProperty(key)) {
                          let value = jsonObj[key]
                          if (value.startsWith("$")) {
                            value = parent_props.value;
                          }
                        if(key==='player'){
                          const basicFilter1 = {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                              table: "content_data main_data",
                            "column": "Players"},
                          
                            operator: "In",
                            values:[value],
                            // values: [window.localStorage.getItem('report')],
                            filterType: models.FilterType.BasicFilter
                          };
                          if(index.free_page===true){
                            filter_arr = []
                          }
                          console.log(index.free_page, filter_arr)
                          filter_arr.push(basicFilter1)
                        }else if(key==='categories'){
                          const categoryfilter1 = {$schema:"http://powerbi.com/product/schema#basic",
                        target:{table:"ss_content_data parameter",column:"Group_Categories"},
                        operator:"In",values:[value]};
                          filter_arr.push(categoryfilter1)
                        } else if(key==='industry'){
                          const industryfilter1 = {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "content_data industry",
                                column: "industry_name"
                            },
                            operator: "In",
                            values: [value]
                          }
                          filter_arr.push(industryfilter1)
                        } else if (key==='sector'){
                          const sectorfilter1 = {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "Grocery_Sector_Table",
                                column: "Sector"
                            },
                            operator: "In",
                            values: [value]
                          }
                          filter_arr.push(sectorfilter1)
                        } else if (key==='medical'){
                            const medicalCategoryFilter1= {
                            $schema:"http://powerbi.com/product/schema#basic",
                            target:{table:"ss_content_data parameter",column:"Group_Categories"},
                            operator:"In",values:[value]
                          } 
                          filter_arr.push(medicalCategoryFilter1)
                        }
                      }
                    }
                    }
                    
                    return(
                      <div key={index.id}>
                        {true && (<><PowerBIEmbed
                         embedConfig = {{
                          type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                          id: index['powerbi_report_id'],
                          //get from props
                          embedUrl:index['url'],
                          accessToken: index['embed'],
                          tokenType: models.TokenType.Embed,
                          filters: filter_arr,
                          settings: {
                            // background:models.BackgroundType.Transparent,
                            layoutType:models.LayoutType.Custom,
                            customLayout:{
                              displayOption:models.DisplayOption.FitToPage
                            },
                            panes: {
                              filters: {
                                expanded: false,
                                visible: false,
                              },
                            },
                            navContentPaneEnabled:false
                          }
                        }}
                        eventHandlers = {
                          new Map([
                            ['loaded', async function (event, report) {
                              //console.log('Report loaded');
                              if(true){
                                const filter = {
                                  $schema: "http://powerbi.com/product/schema#advanced",
                                  target: {
                                      table: "content_data player",
                                      column: "player_name"
                                  },
                                  filterType: models.FilterType.Advanced,
                                  logicalOperator: "Is",
                                  conditions: [
                                      {
                                          operator: "Is",
                                          value: window.localStorage.getItem("player_name")
                                      }
                                  ]
                              };
                              // filter if there is a player in dropdown menu instead of page
                              const filter_player_dropdown = {
                                $schema: "http://powerbi.com/product/schema#advanced",
                                  target: {
                                      table: "content_data main_data",
                                      column: "Players"
                                  },
                                  filterType: models.FilterType.Advanced,
                                  logicalOperator: "Is",
                                  conditions: [
                                      {
                                          operator: "Is",
                                          value: window.localStorage.getItem('drop_dn_player_name')
                                      }
                                  ]
                              }
                              const money_converter = {
                                $schema: "http://powerbi.com/product/schema#advanced",
                                target: {
                                    table: "Currency Table",
                                    column: "Currency"
                                },
                                filterType: models.FilterType.Advanced,
                                logicalOperator: "Is",
                                conditions: [
                                    {
                                        operator: "Is",
                                        value: window.localStorage.getItem('currency')
                                    }
                                ]
                              }
                              const year_converter = {
                                $schema: "http://powerbi.com/product/schema#advanced",
                                target: {
                                    table: "Date Parameter",
                                    column: "Year_Type"
                                },
                                filterType: models.FilterType.Advanced,
                                logicalOperator: "Is",
                                conditions: [
                                    {
                                        operator: "Is",
                                        value: window.localStorage.getItem('year')
                                    }
                                ]
                              }
                              const currency_valuation = {
                                $schema: "http://powerbi.com/product/schema#advanced",
                                target: {
                                    table: "Currency input",
                                    column: "Currency input"
                                },
                                filterType: models.FilterType.Advanced,
                                logicalOperator: "Is",
                                conditions: [
                                    {
                                        operator: "Is",
                                        value: 71
                                    }
                                ]
                              }
                              const usd_selector  = {
                                $schema: "http://powerbi.com/product/schema#advanced",
                                target: {
                                    table: "Currency_USD_Type",
                                    column: "Type"
                                },
                                filterType: models.FilterType.Advanced,
                                logicalOperator: "Is",
                                conditions: [
                                    {
                                        operator: "Is",
                                        value: 'Custom'
                                    }
                                ]
                              }
                  
                              report.getActivePage().then(
                                (activePage=>{
                                  let active_ht = activePage.defaultSize.height
                                  let active_width = activePage.defaultSize.width
                                  let width = document.getElementsByClassName('report-style-class-newreport'+i+1)[0].offsetWidth;
                                  let ht = ((active_ht/active_width)*width)
                                  const removeLink = () => {
                                    const links = document.querySelectorAll('link[href^="https://content.powerapps.com/resource/powerappsportal/dist/preform"]');
                                    links.forEach((link) => {
                                      link.remove();
                                    });
                                  };
                                  removeLink()
                                //   if(i==0){
                                //     if(window.localStorage.getItem('finalized')==='false'){
                                //       document.getElementsByClassName('report-style-class-newreport'+i+1)[0].style.marginTop = '-12vh';
                                //     }
                                //   }
                                  document.getElementsByClassName('report-style-class-newreport'+i+1)[0].style.height = ht+'px';
                                  document.getElementsByClassName('report-style-class-newreport'+i+1)[0].style.backgroundColor = '#F5F8FC'
                                  document.getElementsByClassName('report-style-class-newreport'+i+1)[0].children[0].style.border = '0px';
                                  document.getElementsByClassName('report-style-class-newreport'+i+1)[0].children[0].style.backgroundColor = '#F5F8FC';
                                  // document.getElementsByClassName('report-style-class-newreport'+i+1)[0].querySelector('iframe').style.marginLeft = '-10px';
                                  activePage.getVisuals().then(
                                    (visuals=>{
                                      // //console.log('visuals=',visuals)
                                      let slicers = visuals.filter(function (visual) {
                                        return visual.type === "slicer";
                                    }
                                    );
                                      slicers.forEach(async (slicer) => {
                                        const state = await slicer.getSlicerState();
                                        //console.log("Slicer name: \"" + slicer.name + "\"\nSlicer state:\n", state);
                                        if(state.targets[0].column==="player_name"){
                                          //console.log('slicer_name=',slicer)
                                          let target_slicer = visuals.filter(function (visual) {
                                            return visual.type === "slicer" && visual.name === slicer.name;
                                        })[0];
                                          await target_slicer.setSlicerState({ filters: [filter] });
                                        }
                                        //not using state as it will change on page load.page laod code for 1st
                                        if(state.targets[0].column==='Players' && window.localStorage.getItem('filter_on_company')==='true'){
                                          let target_slicer = visuals.filter(function (visual) {
                                            return visual.type === "slicer" && visual.name === slicer.name;
                                        })[0];
                                          await target_slicer.setSlicerState({ filters: [filter_player_dropdown] });
                                        }
                                        if(state.targets[0].column==='Currency'){
                                          let target_slicer = visuals.filter(function (visual) {
                                            return visual.type === "slicer" && visual.name === slicer.name;
                                        })[0];
                                          await target_slicer.setSlicerState({ filters: [money_converter] });
                                        }
                                        if(state.targets[0].column==='Year_Type'){
                                          let target_slicer = visuals.filter(function (visual) {
                                            return visual.type === "slicer" && visual.name === slicer.name;
                                        })[0];
                                          await target_slicer.setSlicerState({ filters: [year_converter] });
                                        }
                                  })
                                      // //console.log('slicer=', slicers)
                                    })
                                  )
                                })
                              )
                              }
                            }],
                            ['rendered', function () {
                            }],
                            ['buttonClicked', function(event, report){
                              let ques_name = event.detail['title']
                              let company_name = ''
                              window.report.getActivePage().then(
                                (activePage=>{
                                  activePage.getVisuals().then(
                                    (visuals=>{
                                      let slicers = visuals.filter(function (visual) {
                                        return visual.type === "slicer";
                                    });
                                      slicers.forEach(async (slicer) => {
                                      const state = await slicer.getSlicerState();
                                      // //console.log("Slicer name: \"" + slicer.name + "\"\nSlicer state:\n", state);
                                      if(state.targets[0].column==="player_name"){
                                        company_name=state.filters[0].values[0]
                                        // company_name=window.sessionStorage.getItem("player_name")
                                        // openModal(company_name, ques_name)
                                      }
                  
                                  })
                                      // //console.log('slicer=', slicers)
                                    })
                                  )
                                })
                              )
                            }],
                            ['error', function (event) {console.log('powerbi_error=',event.detail);}]
                          ])
                        }
                  
                        cssClassName = { "report-style-class-newreport"+i+1}
                        getEmbeddedComponent = {async(embeddedReport) => {
                          if(window.reports === undefined) {
                            window.reports=[]
                          }
                          window.reports.push(embeddedReport);
                          // console.log(window.reports)
                        }
                  
                      }
                       /></>)}
    
                      </div>
                    )

                  }
                })}
                  </PowerBiDiv>:<>
                 {window.localStorage.getItem('report')===null?treemenucollapse?<Frontpage/>:<Internet/>:window.localStorage.getItem('report')==='Consumer Internet'?treemenucollapse?<Frontpage/>:<Frontpage/>:<Subscription players = {dropDownData}/>}
                  </>}
          </PowerBiDiv>:<div>
            <TailSpin
              height="80"
              width="80"
              color="#2323C8"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
              wrapperClass=""
              visible={true}/>
              <div style = {{ position: "fixed", top: "60%", left: "50%", transform: "translate(-50%, -50%)"}}>This may take a while</div>
          </div>}
        </BodyContainer>

    </div>
          )
}

export default PowerBiFrame

const BodyContainer = styled.div`
display:flex;
min-height:90vh;
background-color:#F5F8FC;
overflow-x:hidden;

`

const SideMenuContainer = styled.div`
  overflow-y:hidden;
  /* overflow-x:hidden; */
  /* width:20vw; */
  width:${props => props.width};
  background-color:#18183E;
  color:white;
  z-index:10;
`
const PowerBiDiv = styled.div`
width:${props => props.width};
background-color:#F5F8FC;
display:flex;
flex-direction:column;
margin-left:-10px;
margin-right:-30px;
`

const HeaderDiv = styled.div`
padding-left:3vw;
padding-top:5px;
`

const Currency = styled.div`
  margin-left:${props => props.marginLeft};
  margin-bottom:10px;
  display: grid; 
  grid-auto-rows: 1fr; 
  grid-template-columns: ${props => props.columns}; 
  /* grid-template-columns: 0.5fr 0.15fr 1fr 0fr 0.9fr 1fr 2.95fr 1.2fr 0.3fr; */
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
  "Descurr . Inr . Gear Cyfy  ExcelDiv . Dropdn .";
`

const Descurr = styled.div`
  grid-area:Descurr;
  line-height:34px;
  display:flex;
  align-items:center;
`
const Inr = styled.div`
  grid-area:Inr;
  display:flex;
  align-items:center;
`
const Gear = styled.div`
  grid-area:Gear;
  line-height:34px;
  display:flex;
  align-items:center;
  /* background-color:Blue; */
`
const Cyfy = styled.div`
  grid-area:Cyfy;
  display:flex;
  align-items:center;
  /* background-color:Green; */
`

const ExcelDiv = styled.div`
  grid-area:ExcelDiv;
  display:flex;
  align-items:center;
  justify-content:space-between;

`
const Dropdn = styled.div`
  grid-area:Dropdn;
`

const Currencybutton = styled.button`
background-color: ${props => props.bgcolor};
color:${props => props.color};
border: 1px solid black;
width:58px;
height:34px;
font-size:14px;
outline: none !important;
`

const customStyles = {
    content: {
      top: '50%',
      left: '55%',
      right: '45%',
      bottom: 'auto',
      height: 230,
      width:400,
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflow:'scroll',
    },
  };

  const ToggleButton = styled.button`
display:${props => props.display};
height:35px; 
width:35px; 
display:flex;
align-items:center;
justify-content:center;
background-color:#18183E;
border-radius:50%;
color:white;
border:0px solid black;
outline: none !important;
`
const BreadCrumbSpan = styled.span`
  &:hover{
    color:#2323C8;
    cursor:pointer;
}
`

const ExcelButton = styled.button`
background-color:#26CDCB;
border: 1px solid black;
height:34px;
font-size:14px;
outline: none !important;

`
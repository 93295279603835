import React from "react";
import styled from "styled-components";
import { RotatingLines } from "react-loader-spinner";
import { TailSpin } from "react-loader-spinner";

const Loader = ({ position = "fixed", height = "100vh", bgColor="rgba(230, 230, 230, 0.4)" }) => {
  return (
    <IconWrapper height={height} position={position} bgColor={bgColor}>
      {/* <RotatingLines
        strokeColor="#0099FF"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      /> */}
      <LoaderContent>
        <TailSpin
          height="80"
          width="80"
          color="#0099FE"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperClass=""
          visible={true}
        />
        <div>This may take a while</div>
      </LoaderContent>
    </IconWrapper>
  );
};

export default Loader;

const IconWrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  position: ${(props) => props.position};
  top: 0;
  left: 0;
  z-index: 5;
  overflow: hidden;
`;

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: space-between;
`;

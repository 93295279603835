import styled from "styled-components";
import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import {
  AVAILABLE_INVITES_ENDPOINT,
  SENT_INVITES_ENDPOINT,
  SEND_INVITE_ENDPOINT,
} from "../../../constants/constants";
import DotIcon from "../../../components/svg/DotIcon";
import Emailcon from "../../../components/svg/EmailIcon";
import PartitionLine from "../../../components/svg/PartitionLine";
import ThumbIcon from "../../../components/svg/ThumbIcon";
import WarningIcon from "../../../components/svg/WarningIcon";
import ProfilePlaceholder from "../../../components/svg/ProfilePlaceholder";
import CheckIcon from "../../../components/svg/CheckIcon";
import { FaCheck } from "react-icons/fa";
import Loader from "../../../components/Loader";
import InvitationCard from "../InvitationCard";
import { media } from "../../../utils/media";

const InvitationPage = ({ selectedArticle }) => {
  const [availableInvitaionsNo, setAvailableInvitaionsNo] = useState(0);
  const [usedInvitaionsNo, setUsedInvitaionsNo] = useState(0);
  const [isInvitationsfetching, setIsInvitaionsLoading] = useState(false);
  const [isSentInvitatnsFetching, setSentInvitatnsFetching] = useState(false);
  const [sentInvitationsList, setSentInvitationsList] = useState([]);
  const [invitationValues, setInvitationValues] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const fetchAvailableInvitations = () => {
    const userEmail = window.localStorage.getItem("email");
    setIsInvitaionsLoading(true);
    axios
      .get(`${AVAILABLE_INVITES_ENDPOINT}/?email=${userEmail}`)
      .then((response) => response.data)
      .then((available_inivites_res) => {
        if (available_inivites_res.length > 0) {
          setAvailableInvitaionsNo(available_inivites_res[0].available_invites);
          setUsedInvitaionsNo(available_inivites_res[0].used_invites);
        }
        setIsInvitaionsLoading(false);
      })
      .catch((error) => {
        setIsInvitaionsLoading(false);
        console.error("Error in fetching available invitations -", error);
      });
  };

  const fetchSentInvites = () => {
    const userEmail = window.localStorage.getItem("email");
    setSentInvitatnsFetching(true);
    axios
      .get(`${SENT_INVITES_ENDPOINT}/?email=${userEmail}`)
      .then((response) => response.data)
      .then((sent_inivites_res) => {
        setSentInvitationsList(sent_inivites_res);
        setSentInvitatnsFetching(false);
      })
      .catch((error) => {
        setSentInvitatnsFetching(false);
        console.error("Error in fetching sent invitations -", error);
      });
  };
  useEffect(() => {
    fetchAvailableInvitations();
    fetchSentInvites();
  }, []);

  useEffect(() => {
    if (!isInvitationsfetching && !isSentInvitatnsFetching) {
      setInvitationValues(
        Array(
          Math.max(availableInvitaionsNo - sentInvitationsList.length, 0)
        ).fill({
          name: "",
          email: "",
          enabled: false,
          errorName: "",
          errorEmail: "",
        })
      );
    }
  }, [availableInvitaionsNo, isInvitationsfetching, isSentInvitatnsFetching]);

  const handleInputChange = (index, field, value) => {
    const admin =
      window.localStorage.getItem("is_admin") === "true" ? true : false;
    const emailRegex = admin
      ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      : /^(?!.*@(?:gmail\.|yahoo\.)[^.\s]+)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let name = invitationValues[index].name;
    let isValidName = name.length ? true : false;
    let email = invitationValues[index].email;
    let isValidEmail = emailRegex.test(email);
    let isDublicateEmail = false;
    let errorName = invitationValues[index].errorName;
    let errorEmail = invitationValues[index].errorEmail;
    if (field === "email") {
      email = value;
      isDublicateEmail =
        sentInvitationsList.filter((invitation) => invitation.email === value)
          .length > 0;
      isValidEmail = emailRegex.test(value);
      errorEmail = isValidEmail
        ? isDublicateEmail
          ? "This email address already invited !"
          : ""
        : "Enter a valid corporate email address!";
    } else {
      name = value;
      isValidName = value.length ? true : false;
      errorName = isValidName ? "" : "Not a valid name";
    }
    const enabled = isValidName && isValidEmail && !isDublicateEmail;

    const updatedInvitations = [...invitationValues];
    updatedInvitations[index] = {
      name,
      email,
      enabled,
      errorName,
      errorEmail,
    };
    setInvitationValues(updatedInvitations);
  };

  const onSendInvite = (index) => {
    setShowLoader(true);
    const targetEmail = invitationValues[index].email;
    const targetName = invitationValues[index].name;
    const uploadData = new FormData();
    uploadData.append("user", window.localStorage.getItem("email"));
    uploadData.append("target_email", targetEmail);
    uploadData.append("target_name", targetName);
    fetch(SEND_INVITE_ENDPOINT, {
      method: "POST",
      body: uploadData,
    })
      .then((data) => data.json())
      .then((data) => {
        const newInvitation = {
          accepted: false,
          created: new Date().toISOString(), // Set to current date and time
          email: targetName,
          name: targetName,
          id: null,
          inviter: null,
          key: "",
          sent: new Date().toISOString(), // Set to current date and time
        };
        const updatedSentInvitations = [...sentInvitationsList, newInvitation];
        setSentInvitationsList(updatedSentInvitations);

        const updatedInvitationValues = invitationValues.filter(
          (_, i) => i !== index
        );
        setInvitationValues(updatedInvitationValues);
        fetchAvailableInvitations();
        fetchSentInvites();
        setShowLoader(false);
      })
      .catch((error) => {
        // console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <InvitationsContainer>
      {isInvitationsfetching || isSentInvitatnsFetching ? (
        "Loading..."
      ) : (
        <>
          {true ? (
            <InvitationsBody>
              <Emailcon />
              <Title>
                You can invite{" "}
                <span className="number">
                  {Math.max(
                    availableInvitaionsNo - sentInvitationsList.length,
                    0
                  )}
                </span>{" "}
                People
              </Title>
              <Description>
                {Math.max(
                  availableInvitaionsNo - sentInvitationsList.length,
                  0
                ) ? (
                  <>
                    Enter the email address of the person you wish to invite.
                    <br /> The added email address will receive the invitation
                    link.
                  </>
                ) : (
                  <>
                    Contact{" "}
                    <a
                      href="mailto:operations@redseerconsulting.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="mail">
                        operations@redseerconsulting.com
                      </span>
                    </a>{" "}
                    to enable the{" "}
                    <span className="bold">
                      <br />
                      "Send Invitations"
                    </span>{" "}
                    feature.
                  </>
                )}
              </Description>
              <InvitationCardsWrap>
                {sentInvitationsList.map((invitation, index) => (
                  <div key={index}>
                    <InvitationCardWrap>
                      <InvitationRow>
                        <CardTitle>Invitation {index + 1}</CardTitle>
                        {invitation.accepted ? (
                          <StatusAccepted>
                            <IconContainer>
                              <ThumbIcon />
                            </IconContainer>{" "}
                            Accepted
                          </StatusAccepted>
                        ) : (
                          <StatusPending>
                            <IconContainer>
                              <WarningIcon />
                            </IconContainer>{" "}
                            Pending
                          </StatusPending>
                        )}
                      </InvitationRow>
                      <Row>
                        <LeftRow>
                          <ProfilePlaceholder />
                          <NameBox>{invitation.name || "Name"}</NameBox>{" "}
                          <DotIcon width={3} height={3} />
                          <EmailHideMobile><EmailBox>{invitation.email}</EmailBox></EmailHideMobile>
                        </LeftRow>

                        <RightRow>
                          <TimeStampRow>
                            <IconContainer>
                              <CheckIcon />
                            </IconContainer>
                            <TimeStampBox>
                              Invitation Sent on{" "}
                              {moment(invitation.created).format("DD MMM. YY")}
                            </TimeStampBox>
                          </TimeStampRow>
                          {invitation.accepted ? (
                            <DisabledButtonBox>Send Invite</DisabledButtonBox>
                          ) : (
                            <SentButtonBox>
                              Invite Sent{" "}
                              <FaCheck
                                color="#09f"
                                style={{ marginLeft: "0.5rem" }}
                              />
                            </SentButtonBox>
                          )}
                        </RightRow>
                      </Row>
                    </InvitationCardWrap>
                    {index + 1 < availableInvitaionsNo && <PartitionLine />}
                  </div>
                ))}
                {invitationValues.map((invitation, index) => (
                  <div key={index + sentInvitationsList.length}>
                    <InvitationCard
                      invitationNumber={index + 1 + sentInvitationsList.length}
                      index={index}
                      name={invitation.name}
                      email={invitation.email}
                      errorName={invitation.errorName}
                      errorEmail={invitation.errorEmail}
                      enabled={invitation.enabled}
                      sentInvitationsList={sentInvitationsList}
                      onValueChange={handleInputChange}
                      onInviteClick={() => onSendInvite(index)}
                    />
                    {index + 1 + sentInvitationsList.length <
                      availableInvitaionsNo && <PartitionLine />}
                  </div>
                ))}
              </InvitationCardsWrap>
            </InvitationsBody>
          ) : (
            "Something Went Wrong, Please contact Tech Team!"
          )}
          {showLoader && <Loader />}
        </>
      )}
    </InvitationsContainer>
  );
};

export default InvitationPage;

const InvitationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(3.2vw - 0px);
  background-color: transparent;
  width: 100%;
  ${media.small`
    
      padding: calc(2.2vw - 0px);
    
    `}
`;

const InvitationsBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));

    ${media.small`
        padding: 20px;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12));
      
      `}
`;

const Title = styled.div`
  color: var(--secondary-dark-color, #54595e);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 8px 0 6px;
  line-height: normal;
  .number {
    font-size: 26px;
  }
    ${media.small`
        .number {
    font-size: 13px;
  }
        font-size: 10px;
  margin: 4px 0 3px;
      
      
      `}
`;

const Description = styled.div`
  color: #44444466;
  width: 500px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
  .bold {
    color: #00000099;
  }
  .mail {
    color: #09f;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
    ${media.small`
      
  width: 250px;
  font-size: 9.5px;
  margin-bottom: 20px;

      `}
`;

const InvitationCardsWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  width: 100%;
  ${media.small`
     padding: 0;
  width: 100%; 
    
    `}
`;

const InvitationCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 145px;
  padding: 25px;
  background-color: #ffffff;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CardTitle = styled.div`
  color: var(--secondary-dark-color, #54595e);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space:nowrap;
  ${media.small`
  font-size: 10px;

    `}
`;

const InvitationRow = styled.div`
  display: flex;
  width: 200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${media.small`
      width: 100px;

    
    `}
`;

const LeftRow = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: align-left;
  align-items: center;
`;

const RightRow = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NameBox = styled.div`
  color: #4a4a4a;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 6px;
  ${media.small`
    
      font-size: 10px;
  padding: 0 3px;
    `}
`;

const EmailBox = styled.div`
  color: #4a4a4a;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0 6px;
  ${media.small`
      font-size: 10px;
  padding: 0 3px;
    `
  }
`;

const StatusAccepted = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 105px;
  height: 28px;
  padding: 6px 14px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #d4f5dd;
  color: #21af4a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  ${media.small`
    width: 60px;
  height: 15px;
  padding: 3px 7px;
  flex-shrink: 0;
  border-radius: 18px;
  font-size: 8px;
    
    `}
`;

const StatusPending = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 99px;
  height: 28px;
  padding: 6px 14px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #ffe5e4;
  color: #e93735;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
    ${media.small`
    width: 60px;
  height: 15px;
  padding: 3px 7px;
  flex-shrink: 0;
  border-radius: 16px;
  font-size: 8px;
    
    `}
`;

const IconContainer = styled.div`
  padding-right: 6px;
  ${media.small`
      padding-right: 3px;

    `}
`;

const SentButtonBox = styled.div`
  display: flex;
  width: 300px;
  height: 60px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #09f;
  background: #fff;
  color: #09f;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  :hover {
    cursor: default;
  }
    ${media.small`
      
       width: 100px;
  height: 20px;
  padding: 3px 5px;
  gap: 3px;
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #09f;
  font-size: 9px;
      
      
      `}
`;

const DisabledButtonBox = styled.div`
  display: flex;
  width: 300px;
  height: 60px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #c4d3dd;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  :hover {
    cursor: not-allowed;
  }

  ${media.small`
    
    width: 80px;
  height: 20px;
  padding: 3px 5px;

  gap: 3px;
  flex-shrink: 0;
  border-radius: 25px;

  font-size: 10px;
    
    `}
`;

const TimeStampRow = styled.div`
  display: flex;
  width: 220px;
  flex-direction: row;
  justify-content: align-left;
  align-items: center;
  ${media.small`
      width: 100px;

    `}
`;

const TimeStampBox = styled.div`
  color: #21af4a;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  ${media.small`
      font-size: 8px;

    `}
`;


const EmailHideMobile=styled.div`

${media.small`
  display:none;
  
  `}
`
import React, { useState } from "react";
import { BarChart, Bar, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import SortIcon from "../SortIcon";
import FilterIcon from "../FilterIcon";
import { TbTriangleFilled } from "react-icons/tb";
import { TbTriangleInvertedFilled } from "react-icons/tb";

const CustomBarGraph = ({ data }) => (
  <BarChart width={100} height={30} data={[data]} layout="vertical">
    <XAxis type="number" hide={true} domain={[0, 100]} />
    <YAxis type="category" dataKey="name" hide={true} />
    <Bar dataKey="value" stackId="rst" fill="#FFA500"></Bar>
    <Bar dataKey="diff" stackId="rst" fill="yellow"></Bar>
  </BarChart>
);

const DisplayTooltip = ({ type, value, current_value, growth }) => {
  return (
    <ToolTipWrapper>
      {type && value ? (
        <div>
          <span style={{ color: "#9e9e9e", fontSize: "12px" }}>{type} - </span>
          <span style={{ fontSize: "12px", marginLeft: "23px" }}>{value}%</span>
        </div>
      ) : null}
      <div>
        <span style={{ color: "#9e9e9e", fontSize: "12px" }}>
          Current Value -{" "}
        </span>
        <span style={{ fontSize: "12px", marginLeft: "20px" }}>
          {current_value}
        </span>
      </div>
      <div>
        <span style={{ color: "#9e9e9e", fontSize: "12px" }}>
          YOY Growth -{" "}
        </span>
        <span style={{ fontSize: "12px", marginLeft: "28px" }}>
          {growth}%{" "}
          {growth >= 0 ? (
            <TbTriangleFilled style={{ color: "green" }} />
          ) : (
            <TbTriangleInvertedFilled style={{ color: "red" }} />
          )}
        </span>
      </div>
    </ToolTipWrapper>
  );
};

const ToolTipWrapper = styled.div`
  display: block;
  position: absolute;
  left: 20%;
  top: 80%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 100;
  width: 200px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 0px #00000040;
`;

const StyledTable = ({
  visiblePlayer = [],
  visibleParameter = [],
  sortedParameter,
  sortedDirection,
  setSortedColumn,
  setSortedDirection,
  parameterRange,
  onRangeFilterApply,
  onFirstColumnDataClick = () => null,
}) => {
  const onColumnSortClick = (parameter) => {
    if (sortedParameter) {
      if (parameter.id === sortedParameter.id) {
        if (sortedDirection === "asc") {
          setSortedDirection("desc");
        } else {
          setSortedDirection("asc");
        }
      } else {
        setSortedColumn(parameter);
        setSortedDirection("asc");
      }
    } else {
      setSortedColumn(parameter);
      setSortedDirection("asc");
    }
  };

  return (
    <TableScrollBody>
      <Table>
        <thead>
          <tr>
            <th>Players</th>
            <th>Sector</th>
            {visibleParameter.map((col, idx) => (
              <th key={idx}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    maxHeight: "70px",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {col.parameter_display_name}
                  </div>
                  <Sortbutton>
                    <FilterIcon
                      low={col.low}
                      high={col.high}
                      filteredRange={parameterRange[col.parameter] || []}
                      onApplySelected={(selectedRanges) =>
                        onRangeFilterApply(col.parameter, selectedRanges)
                      }
                    />
                    <SortIcon
                      sorting={
                        sortedParameter?.id === col.id ? sortedDirection : ""
                      }
                      onSortingClick={() => onColumnSortClick(col)}
                    />
                  </Sortbutton>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {visiblePlayer.map((player, idx) => (
            <tr key={idx}>
              <td
                onClick={() => {
                  if (player.reportData) {
                    onFirstColumnDataClick(player.reportData);
                  }
                }}
              >
                <ProfileLink
                  islink={Boolean(player.reportData) ? "true" : "false"}
                >
                  {player.name}
                </ProfileLink>
              </td>
              <td>{player.industry_name}</td>
              {visibleParameter.map((col, idx) => {
                return <GraphComponent key={idx} col={col} player={player} />;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableScrollBody>
  );
};

const GraphComponent = ({ col, player }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const paramData = player.paramData[col.parameter];
  return paramData ? (
    <td key={col.parameter}>
      <BarWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {col.display_type === "MarketShare" && (
          <CustomBarGraph
            data={{
              name: player.name,
              value: paramData.market_share,
              current_value: paramData.current_value,
              diff: 100,
            }}
          />
        )}
        {paramData.current_value}
        <Growthspan growth={paramData.growth}>
          {paramData.growth}%{paramData.growth >= 0 ? "↑" : "↓"}
        </Growthspan>
        {isHovered && (
          <DisplayTooltip
            type={col.display_type === "MarketShare" ? "Market Share" : null}
            value={paramData.market_share.toFixed(2)}
            current_value={paramData.current_value}
            growth={paramData.growth}
          />
        )}
      </BarWrapper>
    </td>
  ) : (
    <td key={col.parameter}>
      <EmptyContent>-</EmptyContent>
    </td>
  );
};

export default StyledTable;

const Table = styled.table`
  border-collapse: collapse;
  width: auto;
  background-color: #ffffff;

  th {
    padding: 15px;
    text-align: left;
    border: 1px solid #d1d1d1;
    width: 500px;
    background-color: #d4eeff;
    min-height: 55px;
  }
  td {
    padding: 15px;
    text-align: left;
    border: 1px solid #d1d1d1;
    width: 500px;
    min-height: 55px;
  }

  tr:first-child th,
  tr:first-child td {
    border-top: none;
  }

  tr:last-child th,
  tr:last-child td {
    border-bottom: none;
  }

  th:first-child,
  td:first-child {
    border-left: none;
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }
`;

const ProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    font-weight: ${(props) => (props.islink === "true" ? 500 : 400)};
  }
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Growthspan = styled.span`
  background-color: ${(props) => (props.growth >= 0 ? "#d4efdc" : "#f8d2d4")};
  margin-left: 10px;
  padding: 10px;
  border-radius: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableScrollBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 120px;
  background-color: #ffffff;
`;

const Sortbutton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 44px;
  position: relative;
`;

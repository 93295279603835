import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { media } from "../../utils/media";

const WaterFallWalletShare = ({
  width,
  height,
  industry_dict,
  barHeight,
  barWidth,
}) => {
  const [revdata, setrevdata] = useState(null);
  const [graphkeys, setgraphkeys] = useState([]);
  const [paramarr, setparamarr] = useState([]);
  const [year, setyear] = useState("2023");
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);

  let industry_id = IndustryDict[industry_dict].industry_id;

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.8);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getrevenuedata = async () => {
      if (!industry_id) {
        industry_id = 10;
      }
      const result = await axios.get(
        `${process.env.REACT_APP_WEBFORM_ENDPOINT}/revpool/?industry_id=${industry_id}`
      );
      let input = result.data[result.data.length - 1];
      let inputlast = result.data[result.data.length - 2];
      setyear(input["name"]);
      let total = 100;
      const data = [
        {
          name: "Total",
          value: total,
          pv: 0,
          pp: 0,
        },
      ];
      for (const key in (input, inputlast)) {
        if (input.hasOwnProperty(key) && key !== "id" && key !== "name") {
          total = total - input[key];
          data.push({
            name: key,
            value: input[key],
            pv: total,
            pp: input[key] - inputlast[key],
          });
        }
      }
      setrevdata(data);
      //   setgraphkeys(Object.keys(result.data[0]).slice(2))
    };

    getrevenuedata();
  }, []);

  return (
    <OuterDiv>
            <Title>Revenue Waterfall</Title>
      <GraphDimension width={width} height={height}>
        <HeaderFy
        >
          For C.Y. {year}
        </HeaderFy>
        {revdata && (
           <>
          <WebDiv>
            <BarChart
              width={chartWidth*1.1}
              height={400}
              data={revdata}
              margin={{
                top: 15,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Bar dataKey="pv" stackId="a" fill="transparent" barSize={200} />
              <Bar dataKey="value" stackId="a" fill="#82ca9d" barSize={200}>
                <LabelList
                  dataKey="value"
                  position="top"
                  content={({ x, y, width, height, index, value }) => {
                    const currentItem = revdata[index];
                    const ppColor = currentItem.pp >= 0 ? "green" : "red";
                    const label = `${value} (${currentItem.pp >= 0 ? "+" : ""}${
                      currentItem.pp
                    }pp yoy)`;
                    return (
                      <text
                        x={x + width / 2}
                        y={y - 4}
                        fill={ppColor}
                        textAnchor="middle"
                        dominantBaseline="bottom"
                      >
                        {value !== 100 ? label : null}
                      </text>
                    );
                  }}
                />

                {revdata.map((item, index) => {
                  if (revdata[index].pp < 0) {
                    return <Cell key={index} fill="#ABC6FC" />;
                  }
                  if (item.name === "Total") {
                    return <Cell key={index} fill="#4CBF6E" />;
                  } else {
                    return <Cell key={index} fill="#74A1FB" />;
                  }
                })}
              </Bar>
            </BarChart>
          </WebDiv>
                    <MobileDiv>
                    <BarChart
                      width={chartWidth*1.1}
                      height={300}
                      data={revdata}
                      margin={{
                        top: 15,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                      style={{backgroundColor:"white"}}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" style={{fontSize:"9px", alignItems:"center"}}/>
                      <YAxis />
                      <Bar dataKey="pv" stackId="a" fill="transparent" barSize={50} />
                      <Bar dataKey="value" stackId="a" fill="#82ca9d" barSize={50}>
                        <LabelList
                          dataKey="value"
                          position="top"
                          content={({ x, y, width, height, index, value }) => {
                            const currentItem = revdata[index];
                            const ppColor = currentItem.pp >= 0 ? "green" : "red";
                            const label = `${value} (${currentItem.pp >= 0 ? "+" : ""}${
                              currentItem.pp
                            }pp yoy)`;
                            return (
                              <text
                                x={x + width / 2}
                                y={y - 4}
                                fill={ppColor}
                                textAnchor="middle"
                                dominantBaseline="bottom"
                                style={{fontSize:"10px"}}
                              >
                                {value !== 100 ? label : null}
                              </text>
                            );
                          }}
                        />
        
                        {revdata.map((item, index) => {
                          if (revdata[index].pp < 0) {
                            return <Cell key={index} fill="#ABC6FC" />;
                          }
                          if (item.name === "Total") {
                            return <Cell key={index} fill="#4CBF6E" />;
                          } else {
                            return <Cell key={index} fill="#74A1FB" />;
                          }
                        })}
                      </Bar>
                    </BarChart>
                  </MobileDiv>
                 </>
        )}
          <Footer
      >
        *Assuming overall gmv for the sector is 100
      </Footer>
      </GraphDimension>
    
    </OuterDiv>
  );
};

const OuterDiv=styled.div`
  padding-left: 3.5vw;

`
const GraphDimension = styled.div`
  width: calc(${(props) => props.width} - 45px);
  height: calc(${(props) => props.height}+50px);
  box-shadow: 2px 2px 4px 0px #00000040;
  ${media.small`
      width: calc(${(props) => props.width} - 20px);
  height: calc(${(props) => props.height}+25px);
    `}
`;
const Title = styled.div`
  font-size: 25px;
  font-weight: 500;

  height: 10%;
  letter-spacing: 0em;
  text-align: left;
  background-color: #eef9ff;

  ${media.small`

      font-size: 18px;
      height: 8%;

    
    `}
`;

const WebDiv=styled.div`
background-color: white;
 height: 90%;
${media.small`
  display:none
  `}

`;
const MobileDiv=styled.div`
display:none;
${media.small`
  display:block;
  background:white;
  `}

`;

const Footer=styled.div`
   text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    background-color: white;
   width: 100%;
${media.small`
  font-size:12px;
      padding-top: 10px;
    padding-bottom: 10px;
  `}
`;

const HeaderFy=styled.div`
   background-color: white;
   padding-left: 30px;
  margin-top: 15px;
 padding-top: 10px;
    padding-bottom: 10px;
    width:100%;
    ${media.small`
        margin-top: 10px;

      `}
`;

export default WaterFallWalletShare;

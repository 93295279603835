import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import {
  WEBFORM_TOKEN,
  HOME_URL,
  TAG_ENDPOINT,
  SIGNIN_URL,
  THESIS_FILTER_ENDPOINT,
  PARAMETER_GROUP_ENDPOINT,
} from "../../constants/constants";
import MultiSelect from "../MultiSelect";
import SearchIcon from "../svg/SearchIcon";
import { useNavigate } from "react-router-dom";
import StyledTable from "../StyledTable";
import Loader from "../Loader";
import axios from "axios";
import moment from "moment";
import ExternalLinkIcon from "../svg/ExternalLinkIcon";
import { Switch } from "antd";
import LineGraph from "../LineGraph";
import RatingCircle from "../RatingCircle";
import MasterTable from "../MasterTable";
import InternetCheck from "../svg/InternetCheck";
import DefaultPin from "../svg/DefaultPin";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import USDandFyrow from "../USDandFYrow";
import { models } from "powerbi-client";
import ScoreBargraph from "../ScoreBarGraph";
import PlayerLink from "../svg/PlayerLink";
import { media } from "../../utils/media";
import Select from "react-select";
import { escapeSelector } from "jquery";
import { TbTriangleInvertedFilled } from "react-icons/tb";
import { TbTriangleFilled } from "react-icons/tb";
import { Placeholder } from "reactstrap";
import {
  onCompanyProfileClickCT
} from "../../utils/clevertap";

const Fintable = ({
  ind_id,
  report,
  dropDownData,
  onPlayerClick = () => null,
  frequency = "monthly",
  end_date = moment(moment().startOf("month")).format(
    "YYYY-MM-DD"
  ),
  table_type = "iil_finntable_listing",
}) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  // API whole data
  const [clientId, setClientId] = useState(
    Number(window.localStorage.getItem("clientID")) || null
  );
  const [allCompanies, setAllCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);

  const [isDataLoaded, setDataLoaded] = useState(false);
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const [visibleRowsCount, setVisibleRowsCount] = useState(10);
  const [conversiontype, setConversionType] = useState("Custom");
  const [currencyval, setCurrencyVal] = useState(79);
  let subDictionary = JSON.parse(window.localStorage.getItem("subDictionary"));
  const [selectedHeader, setSelectedHeader] = useState("Last Year");
  const [dropdndata, setdropdndata] = useState([]);
  const [dropdndataoption, setdropdndataoption] = useState(null);
  const [defaultoption, setDefaultOption] = useState(null);
  const [tableDate,setTableDate]=useState(null);

  const currency = window.localStorage.getItem("currency")
    ? window.localStorage.getItem("currency")
    : "INR";

  const conversionType = window.localStorage.getItem("conversion_type")
    ? window.localStorage.getItem("conversion_type")
    : "Dynamic";

  const custUsdExchange =
    conversionType === "Dynamic"
      ? null
      : window.localStorage.getItem("currency_val")
      ? Number(window.localStorage.getItem("currency_val"))
      : 80;

  const customUSDconvert = window.localStorage.getItem("currency_val");

  const exchangeRate = window.localStorage.getItem("EXCHANGE_RATE")
    ? JSON.parse(window.localStorage.getItem("EXCHANGE_RATE"))
    : {};

  const getLabel = (dataDate) => {
    const dataDateObj = moment(dataDate, "YYYY-MM-DD");
    if (frequency === "monthly") {
      return dataDateObj.format("MMM'YY");
    } else if (frequency === "quarterly") {
      const quarter = Math.floor((dataDateObj.month() + 3) / 3);
      switch (quarter) {
        case 1:
          return `JFM'${dataDateObj.format("YY")}`;
        case 2:
          return `AMJ'${dataDateObj.format("YY")}`;
        case 3:
          return `JAS'${dataDateObj.format("YY")}`;
        case 4:
          return `OND'${dataDateObj.format("YY")}`;
        default:
          return "Invalid Date";
      }
    }
  };

  const getNameHeader = (count) => {
    if (count <= 1) {
      return (
        <CountTextWrap>
          <HeaderNameDiv>Name</HeaderNameDiv>
          <CountText>({count} Result)</CountText>
        </CountTextWrap>
      );
    }
    return (
      <CountTextWrap>
        <HeaderNameDiv>Name</HeaderNameDiv>
        <CountText>({count} Results)</CountText>
      </CountTextWrap>
    );
  };

  const [columnsData, setColumnsData] = useState([
    {
      key: "player",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: null,
    },
  ]);
  const [parameterRange, setParameterRange] = useState({});
  const [parameterValueFilter, setParameterValueFilter] = useState({});
  const [styledRowsData, setStyledRowsData] = useState([]);

  const [compSearchString, setCompSearchString] = useState(
    useQuery().get("company") || ""
  );

  const [indReportMapping, setIndReportMapping] = useState([]);
  const [indWiseCompReport, setIndWiseCompReport] = useState([]);
  const [isReportMappingLoaded, setReportMappingLoaded] = useState(false);

  const [industryIdList, setIndustryIdList] = useState([]);
  const [dataMonth, setDataMonth] = useState(null);
  const [parameterList, setParameterList] = useState(null);
  const [tags, setTags] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [valuationList, setValuationList] = useState([
    {
      id: 1,
      name: "0 - 100 Mn (USD)",
      low: 0,
      high: 100,
      selected: false,
    },
    {
      id: 2,
      name: "100 Mn - 500 Mn (USD)",
      low: 100,
      high: 500,
      selected: false,
    },
    {
      id: 3,
      name: "500 Mn - 2 Bn (USD)",
      low: 500,
      high: 2000,
      selected: false,
    },
    {
      id: 4,
      name: "2 Bn - 5 Bn (USD)",
      low: 2000,
      high: 5000,
      selected: false,
    },
    {
      id: 5,
      name: "5 Bn - 10 Bn (USD)",
      low: 5000,
      high: 10000,
      selected: false,
    },
    {
      id: 6,
      name: "10 Bn + (USD)",
      low: 10000,
      high: 100000000,
      selected: false,
    },
  ]);
  const [sortedParameter, setSortedParameter] = useState(null);
  const [sortedDirection, setSortedDirection] = useState("");
  const DisplayTooltip = ({ data }) => {
    return (
      <TooltipContainer>
        <TooltipRow>
          <Label>Current Value - </Label>
          <Value>{data.tipValue ? Math.round(data.tipValue * 1000) / 1000 : "0"}</Value>
        </TooltipRow>
        <TooltipRow>
          <Label>YOY Growth - </Label>
          <GrowthValue>
            {data.lastValue
              ? Math.round(
                  ((data.value - data.lastValue) / data.lastValue) * 100000
                ) / 1000
              : "0"}
            %{" "}
            {data.value - data.lastValue >= 0 ? (
              <TbTriangleFilled style={{ color: "green" }} />
            ) : (
              <TbTriangleInvertedFilled style={{ color: "red" }} />
            )}
          </GrowthValue>
        </TooltipRow>
      </TooltipContainer>
    );
  };

  const [scaledrate, setScaledrate] = useState(10000000);
  const navigate = useNavigate();

  const treeziparr = [
    { key: 1, name: "Home" },
    { key: 2, name: "India Internet Landscape" },
  ];

  let handleClickTree = (index) => {
    if (treeziparr[index].key === 1) {
      navigate(HOME_URL);
    }
  };

  const setSelectedIndustry = (selectedIndustry) => {
    setIndustryList(
      industryList.map((ind) => {
        return {
          ...ind,
          selected: selectedIndustry.some(
            (selectedInd) => selectedInd.industry_id === ind.industry_id
          ),
        };
      })
    );
  };
  const setSelectedValuation = (selectedValuationList) => {
    setValuationList(
      valuationList.map((valuation) => {
        return {
          ...valuation,
          selected: selectedValuationList.some(
            (selectedValuation) => selectedValuation.id === valuation.id
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (!window.localStorage.getItem("loginStatus")) navigate(SIGNIN_URL);
  }, []);

  const [isParameterset, setisParameterset] = useState(false);

  const handleHeaderClick = (header) => {};

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        PARAMETER_GROUP_ENDPOINT,
        {
          table_type: table_type,
          industry_id: ind_id,
          profile: "overall",
          data_category: "overall",
          data_frequency: frequency,
          end_date: end_date,
        },
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          setTableDate(responseData.industry_date)
          let updatedParameters = [
            {
              key: "player",
              value: getNameHeader(responseData.players_list.length),
              sorting: false,
              range_filter: false,
              value_filter: false,
              min_width: null,
            },
          ];
          let uniqueParameterTypes = [
            ...new Set(response.data.parameter_list.map((item) => item.type)),
          ];
          let newArray = uniqueParameterTypes.map((parameterType) => ({
            value: parameterType,
            label: parameterType,
          }));

          setdropdndata(newArray);
          if (dropdndataoption) newArray = dropdndataoption;
          else {
            newArray = newArray[0];
            setDefaultOption(newArray);
          }

          responseData.parameter_list
            .sort((a, b) => a.sequence - b.sequence)
            .map((parObj) => {
              if (parObj.type === newArray.label) {
                updatedParameters.push({
                  key: parObj.parameter,
                  value: (
                    <HeaderWrapper>
                      {parObj.is_currency && parObj.is_scaled ? (
                        currency === "INR" ? (
                          <HeaderText>{parObj.name + "(INR Cr)"}</HeaderText>
                        ) : (
                          <HeaderText>{parObj.name + "(USD Mn)"}</HeaderText>
                        )
                      ) : !parObj.is_currency && parObj.is_scaled ? (
                        currency === "INR" ? (
                          <HeaderText>{parObj.name + "(Cr)"}</HeaderText>
                        ) : (
                          <HeaderText>{parObj.name + "(Mn)"}</HeaderText>
                        )
                      ) : parObj.is_currency && !parObj.is_scaled ? (
                        currency === "INR" ? (
                          <HeaderText>{parObj.name + "(INR)"}</HeaderText>
                        ) : (
                          <HeaderText>{parObj.name + "(USD)"}</HeaderText>
                        )
                      ) : currency === "INR" ? (
                        <HeaderText>{parObj.name + "(#)"}</HeaderText>
                      ) : (
                        <HeaderText>{parObj.name + "(#)"}</HeaderText>
                      )}
                    </HeaderWrapper>
                  ),
                  sorting: parObj.sorting,
                  is_scaled: parObj.is_scaled,
                  is_currency: parObj.is_currency,
                  // range_filter: parObj.range_filter,
                  // range_filter_data: parObj.range_filter_data,
                  // value_filter: parObj.value_filter,
                  // min_width: parObj.col_width,
                });
              }
            });
            if(updatedParameters.length>1)
          setColumnsData(updatedParameters);
          const industry_id_set = new Set();
          let industryDataList = [];
          let companiesData = responseData.players_list.map((comp) => {
            if (
              !industry_id_set.has(comp.industry_id) &&
              comp.player_id
              // && comp.sector_profile_id in subDictionary
            ) {
              industry_id_set.add(comp.industry_id);
              industryDataList.push({
                company_id: comp.player_id,
                industry_id: comp.industry_id,
                player_name: comp.player_name,
                industry_name: comp.industry_name,
                selected: false,
              });
            }
            // if (comp.sector_profile_id in subDictionary)
            return {
              ...comp,
              param_data: responseData.players_data[comp.player_id],
            };
          });

          companiesData = companiesData
            .sort((a, b) => {
              if (a.industry_id === 10 && b.industry_id !== 10) {
                return -1;
              } else if (a.industry_id !== 10 && b.industry_id === 10) {
                return 1;
              } else {
                return 0;
              }
            })
            .sort((a, b) => {
              if (a.industry_id === 18 && b.industry_id !== 18) {
                return -1;
              } else if (a.industry_id !== 18 && b.industry_id === 18) {
                return 1;
              } else {
                return 0;
              }
            });
          setAllCompanies(companiesData);
          setVisibleCompanies(companiesData);
          const industry_id_list = Array.from(industry_id_set);
          setIndustryIdList(industry_id_list);
          setIndustryList(industryDataList);

          setDataLoaded(true);
        } else {
          console.error(
            "Error in fetching parameter group in sector platform table ",
            response.error.message
          );
          setDataLoaded(true);
        }
      })
      .catch((error) => {
        console.error(
          "Error in fetching parameter group for sector platform table -",
          error
        );
        setDataLoaded(true);
      });

    let newIndustryReportMapping = [];
    let newReportTree = JSON.parse(window.localStorage.getItem("newReporTree"));
    if (newReportTree) {
      newIndustryReportMapping = getNodesAtLevel(newReportTree, 3);
      setIndReportMapping(newIndustryReportMapping);

      // }
      // API CALL
      let newIndustryWiseCompanyReport = {};
      newIndustryWiseCompanyReport =
        groupLevel5NodesByParentLevel3(newReportTree);

      setIndWiseCompReport(newIndustryWiseCompanyReport);
      setReportMappingLoaded(true);
    }
  }, [dropdndataoption, currency]);

  useEffect(() => {
    const ColumnsValueFilter = {};
    const valueFilterColumns = columnsData.filter((col) => col.value_filter);
    allCompanies.forEach((comp) => {
      valueFilterColumns.forEach((col) => {
        if (!ColumnsValueFilter[col.key]) {
          ColumnsValueFilter[col.key] = new Set();
        }
        if (comp.param_data[col.key].sortingValue) {
          ColumnsValueFilter[col.key].add(
            comp.param_data[col.key].sortingValue
          );
        }
      });
    });
    valueFilterColumns.forEach((col) => {
      ColumnsValueFilter[col.key] = Array.from(
        ColumnsValueFilter[col.key] || []
      ).map((name, idx) => ({
        id: idx,
        name: name,
        selected: false,
      }));
    });
    setParameterValueFilter(ColumnsValueFilter);
  }, [allCompanies]);

  const onRangeFilterApply = (parameter_id, selectedRange) => {
    setParameterRange({ ...parameterRange, [parameter_id]: selectedRange });
  };

  const onValueFilterApply = (parameter_id, valueFilter) => {
    setParameterValueFilter({
      ...parameterValueFilter,
      [parameter_id]: valueFilter,
    });
  };
  let firstkey = null;
  useEffect(() => {
    let rowsData = [];

    visibleCompanies &&
      visibleCompanies.forEach((company) => {
        if (company) {
          const redirectRep = IndustryDict[company.company_profile_id] || null;
          const spReport = IndustryDict[company.sector_profile_id] || null;
          const isSubscribed = company.company_profile_id in subDictionary;

          let rowData = {
            player: {
              value: (
                <>
                  <PlayerWrap>
                    <div>
                      {company.icon_url ? (
                        <StyledImage
                          src={company.icon_url}
                          alt="Company Icon"
                        />
                      ) : (
                        <StyledImage
                          src="https://aredstorage.blob.core.windows.net/images/companylogo/100%20x%20100/default_icon.png"
                          alt="Default Company Icon"
                        />
                      )}
                    </div>
                    <CompanyNameWrap>
                      <ColumnWarp>
                        <SectorProfile>
                          <CpProfileLink
                            islink={Boolean(redirectRep) ? "true" : "false"}
                            onClick={() => {
                              if (Boolean(redirectRep)) {
                                const url = `/Report3/?val=${redirectRep.report_name}&key=${redirectRep.id}&filter=${redirectRep.filter}&filter_value=${redirectRep.filter_value}`;
                                window.open(url, "_blank");
                              }
                              onCompanyProfileClickCT(redirectRep.report_name,redirectRep.id,spReport.report_name,spReport.id)
                            }}
                          >
                            {company.player_name}
                            {isSubscribed ? <InternetCheck /> : null}
                          </CpProfileLink>
                          {/* <PlayerLink
                            onClick={() => {
                              {
                                company.website_url &&
                                  window.open(company.website_url);
                              }
                            }}
                            style={{
                              cursor: company.website_url
                                ? "pointer"
                                : "default",
                            }}
                          /> */}
                        </SectorProfile>
                        <SpProfileLink
                          islink="true"
                          onClick={() => {
                            if (Boolean(spReport)) {
                              const url = `/Report3/?val=${spReport.report_name}&key=${spReport.id}&filter=${spReport.filter}&filter_value=${spReport.filter_value}`;
                            }
                          }}
                        >
                          <div>{report}</div>
                        </SpProfileLink>
                      </ColumnWarp>
                    </CompanyNameWrap>
                  </PlayerWrap>
                </>
              ),
              sortingValue: company.player_name,
              isSubscribed: isSubscribed,
              toolTip: null,
            },
          };

          let isfirstKey = true;
          columnsData &&
            columnsData.forEach((col) => {
              if (col.key !== "player" && company.param_data) {
                const param = company.param_data[col.key];
                if (isfirstKey) {
                  firstkey = col.key;
                  isfirstKey = false;
                }
                rowData[col.key] = {};
                const date = new Date(param.parameterDate);
                date.setFullYear(date.getFullYear() - 1);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
                const day = String(date.getDate()).padStart(2, "0");

                const lastparadate = `${year}-${month}-${day}`;
                if ("value" in param && param.value) {
                  rowData[col.key].value = Math.round(param.value * 10) / 10;
                  rowData[col.key].tipValue = Math.round(param.value * 1000) / 1000;
                  if (
                    ((col.is_scaled && col.is_currency) ||
                      (col.is_scaled && !col.is_currency)) &&
                    currency === "INR"
                  ) {
                    rowData[col.key].value =
                      Math.round((param.value * 10) / 10000000) / 10;
                      rowData[col.key].tipValue =
                      Math.round((param.value * 1000) / 10000000) / 1000;
                  } else if (
                    col.is_scaled &&
                    col.is_currency &&
                    currency === "USD"
                  ) {
                    rowData[col.key].value =
                      Math.round(
                        (param.value * 10) /
                          (exchangeRate[param.parameterDate]?.m_value * 1000000)
                      ) / 10;
                      rowData[col.key].tipValue =
                      Math.round(
                        (param.value * 1000) /
                          (exchangeRate[param.parameterDate]?.m_value * 1000000)
                      ) / 1000;
                    if (conversionType === "Custom")
                    {
                      rowData[col.key].value =
                        Math.round(
                          (param.value * 10) / (customUSDconvert * 1000000)
                        ) / 10;
                        rowData[col.key].tipValue =
                        Math.round(
                          (param.value * 1000) / (customUSDconvert * 1000000)
                        ) / 1000;

                      }
                  } else if (
                    col.is_scaled &&
                    !col.is_currency &&
                    currency === "USD"
                  ) {
                    rowData[col.key].value =
                      Math.round((param.value * 10) / 1000000) / 10;
                      rowData[col.key].tipValue =
                      Math.round((param.value * 1000) / 1000000) / 1000;
                  } else if (
                    !col.is_scaled &&
                    col.is_currency &&
                    currency === "USD"
                  ) {
                    rowData[col.key].value =
                      Math.round(param.value*10 / exchangeRate[param.parameterDate]?.m_value)/10;
                      rowData[col.key].tipValue =
                      Math.round(param.value*1000 / exchangeRate[param.parameterDate]?.m_value)/1000;
                    if (conversionType === "Custom")
                    {
                      rowData[col.key].value =
                        Math.round((param.value * 10) / customUSDconvert) / 10;
                        rowData[col.key].tipValue =
                        Math.round((param.value * 1000) / customUSDconvert) / 1000;
                    }
                  }
                }
                if ("lastValue" in param && param.lastValue) {
                  rowData[col.key].lastValue =
                    Math.round(param.lastValue * 10) / 10;
                  if (
                    ((col.is_scaled && col.is_currency) ||
                      (col.is_scaled && !col.is_currency)) &&
                    currency === "INR"
                  ) {
                    rowData[col.key].lastValue =
                      Math.round((param.lastValue * 10) / 10000000) / 10;
                  } else if (
                    col.is_scaled &&
                    col.is_currency &&
                    currency === "USD"
                  ) {
                    rowData[col.key].lastValue =
                      Math.round(
                        (param.lastValue * 10) /
                          (exchangeRate[lastparadate]?.m_value * 1000000)
                      ) / 10;
                    if (conversionType === "Custom")
                      rowData[col.key].lastValue =
                        Math.round(
                          (param.lastValue * 10) / (customUSDconvert * 1000000)
                        ) / 10;
                  } else if (
                    col.is_scaled &&
                    !col.is_currency &&
                    currency === "USD"
                  ) {
                    rowData[col.key].lastValue =
                      Math.round((param.lastValue * 10) / 1000000) / 10;
                  } else if (
                    !col.is_scaled &&
                    col.is_currency &&
                    currency === "USD"
                  ) {
                    rowData[col.key].lastValue =
                      param.lastValue / exchangeRate[lastparadate]?.m_value;
                    if (conversionType === "Custom")
                      rowData[col.key].lastValue =
                        Math.round((param.lastValue * 10) / customUSDconvert) /
                        10;
                  }
                }

                if ("sortingValue" in param && param.value) {
                  rowData[col.key].sortingValue = param.value;
                  if (
                    ((col.is_scaled && col.is_currency) ||
                      (col.is_scaled && !col.is_currency)) &&
                    currency === "INR"
                  )
                    rowData[col.key].sortingValue = param.value / 10000000;
                  else if (
                    col.is_scaled &&
                    col.is_currency &&
                    currency === "USD"
                  )
                    rowData[col.key].sortingValue =
                      param.value /
                      (exchangeRate[param.parameterDate]?.m_value * 1000000);
                  else if (
                    col.is_scaled &&
                    !col.is_currency &&
                    currency === "USD"
                  )
                    rowData[col.key].sortingValue = param.value / 1000000;
                  else if (
                    !col.is_scaled &&
                    col.is_currency &&
                    currency === "USD"
                  )
                    rowData[col.key].sortingValue =
                      param.value / exchangeRate[param.parameterDate]?.m_value;
                }
                if ("parameterDate" in param) {
                  rowData[col.key].parameterDate = param.parameterDate;
                }
              }
            });
          rowsData.push(rowData);
        }
      });

    const sortedDirectioncustom = "desc";
    rowsData = rowsData.sort((a, b) => {
      // const getFirstKey = (obj) => Object.keys(obj).find((key) => !isNaN(key));

      const firstKeyA = firstkey;
      const firstKeyB = firstkey;

      const aVal = a[firstKeyA]?.sortingValue || 0;
      const bVal = b[firstKeyB]?.sortingValue || 0;

      if (typeof aVal === "number" && typeof bVal === "number") {
        return sortedDirectioncustom === "asc" ? aVal - bVal : bVal - aVal;
      } else if (typeof aVal === "string" && typeof bVal === "string") {
        return sortedDirectioncustom === "asc"
          ? aVal.localeCompare(bVal)
          : bVal.localeCompare(aVal);
      } else {
        return 0;
      }
    });
    rowsData = rowsData.map((row) => {
      const updatedRow = {};
      Object.keys(row).forEach((key) => {
        if (key !== "player") {
          updatedRow[key] = {
            ...row[key],
            value: row[key] ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    verticalAlign: "middle",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {row[key].value?
                  <>
                  <GrowthSpanVal>
                    {currency === "INR"
                      ? Intl.NumberFormat("en-IN").format(row[key].value)
                      : Intl.NumberFormat("en-US").format(row[key].value)}
                  </GrowthSpanVal>
                  </>
                  :
                  <>
                  <GrowthSpanVal>
                    {0}
                  </GrowthSpanVal>
                  </>
                  }
                  {row[key].lastValue && row[key].value? (
                    <>
                      <Growthspan growth={row[key].value - row[key].lastValue}>
                        {(
                          ((row[key].value - row[key].lastValue) /
                            row[key].lastValue) *
                          100
                        ).toFixed(2)}
                        %{row[key].value - row[key].lastValue >= 0 ? "↑" : "↓"}
                      </Growthspan>
                    </>
                  ) : (
                    <>
                      <Growthspan growth={0}>
                        {0}%
                        { "↑" }
                      </Growthspan>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div    style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                verticalAlign: "middle",
                height: "100%",
                width: "100%",
              }}>
                <div>0</div>
                
              </div>
            ),
            toolTip: (
              <>
                <DisplayTooltip data={row[key]} />
              </>
            ),
          };
        } else {
          updatedRow[key] = {
            ...row[key],
          };
        }
      });
      return updatedRow;
    });

    // setStyledRowsData(rowsData);

    if (sortedParameter) {
      setStyledRowsData(
        [...rowsData]
          .sort((a, b) => {
            const aVal = a[sortedParameter]?.sortingValue;
            const bVal = b[sortedParameter]?.sortingValue;
            if (aVal == null && bVal == null) {
              return 0;
            } else if (aVal == null) {
              return 1;
            } else if (bVal == null) {
              return -1;
            }

            if (typeof aVal === "number" && typeof bVal === "number") {
              return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
            } else if (typeof aVal === "string" && typeof bVal === "string") {
              return sortedDirection === "asc"
                ? aVal.localeCompare(bVal)
                : bVal.localeCompare(aVal);
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            if (a["player"].isSubscribed && !b["player"].isSubscribed) {
              return -1;
            }
            if (!a["player"].isSubscribed && b["player"].isSubscribed) {
              return 1;
            }
            return 0;
          })
      );
    } else {
      setStyledRowsData(
        [...rowsData].sort((a, b) => {
          if (a["player"].isSubscribed && !b["player"].isSubscribed) {
            return -1;
          }
          if (!a["player"].isSubscribed && b["player"].isSubscribed) {
            return 1;
          }
          return 0;
        })
      );
    }
  }, [
    visibleCompanies,
    parameterRange,
    parameterValueFilter,
    isReportMappingLoaded,
    currency,
    customUSDconvert,
    conversionType,
  ]);

  const setSortedColumn = (sortedCol) => {
    setSortedParameter(sortedCol);
  };

  useEffect(() => {
    if (sortedParameter) {
      setStyledRowsData(
        [...styledRowsData]
          .sort((a, b) => {
            const aVal = a[sortedParameter]?.sortingValue;
            const bVal = b[sortedParameter]?.sortingValue;
            if (aVal == null && bVal == null) {
              return 0;
            } else if (aVal == null) {
              return 1;
            } else if (bVal == null) {
              return -1;
            }

            if (typeof aVal === "number" && typeof bVal === "number") {
              return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
            } else if (typeof aVal === "string" && typeof bVal === "string") {
              return sortedDirection === "asc"
                ? aVal.localeCompare(bVal)
                : bVal.localeCompare(aVal);
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            if (a["player"].isSubscribed && !b["player"].isSubscribed) {
              return -1;
            }
            if (!a["player"].isSubscribed && b["player"].isSubscribed) {
              return 1;
            }
            return 0;
          })
      );
    }
  }, [sortedParameter, sortedDirection]);

  const stringContains = (target, query) => {
    const targetString = target.replace(/\s/g, "").toLowerCase();
    const queryString = query.replace(/\s/g, "").toLowerCase();
    return targetString.includes(queryString);
  };

  const tagsCompare = (comTags, selectedTag) => {
    // return comTags.some((obj1) =>
    //   selectedTag.some((obj2) => obj1.id === obj2.id)
    // );
    return selectedTag.every((obj2) =>
      comTags.some((obj1) => obj1.id === obj2.id)
    );
  };
  function handleSelectoption(option) {
    setdropdndataoption(option);
    setDataLoaded(false);
    setDefaultOption(option);
  }

  useEffect(() => {
    let selectedTags = tags.filter((tag) => tag.selected) || tags;
    const selectedIndustryIdList = industryList
      .filter((ind) => ind.selected)
      .map((ind) => ind.industry_id);

    const selectedValuationList = valuationList.filter(
      (series) => series.selected
    );

    const newFilteredCompanies = allCompanies

      .filter((comp) =>
        selectedTags.length === 0 ? true : tagsCompare(comp.tags, selectedTags)
      )
      .filter((comp) =>
        selectedIndustryIdList.length === 0
          ? true
          : selectedIndustryIdList.includes(comp.industry_id)
      )
      .filter((comp) => {
        if (selectedValuationList.length) {
          if (comp.valuation) {
            if (
              selectedValuationList.some(
                (range) =>
                  comp.valuation >= range.low && comp.valuation <= range.high
              )
            )
              return true;
            return false;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    setVisibleCompanies(newFilteredCompanies);
  }, [tags, industryList, valuationList, allCompanies]);

  // #//////////
  // ###########
  // #//////////
  // ###########
  // #//////////
  // ###########
  // Fetching CP powerbi profiles
  function getNodesAtLevel(data, level, result = []) {
    data.forEach((node) => {
      if (node.level === level) {
        result.push(node);
      }
      if (node.children && node.children.length > 0) {
        getNodesAtLevel(node.children, level, result);
      }
    });
    return result;
  }
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
      zIndex: 99,
    }),
  };

  const customStylesMobiles = {
    menu: (provided) => ({
      ...provided,
      fontSize: "10px",
      zIndex: 99,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "9px",
  }),
  };

  function groupLevel5NodesByParentLevel3(data) {
    const result = {};

    function traverse(nodes) {
      nodes.forEach((node) => {
        if (node.level === 3 && node.children && node.children.length > 0) {
          node.children.forEach((child) => {
            if (
              child.level === 4 &&
              child.children &&
              child.children.length > 0
            ) {
              if (!result[node.industry_id]) {
                result[node.industry_id] = [];
              }
              result[node.industry_id].push(
                ...child.children.map((level5Node) => ({
                  ...level5Node,
                  sectorNode: node,
                }))
              );
            }
          });
        }
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      });
    }

    traverse(data);

    return result;
  }
  return (
    <>
      <PageWrapper>
        <DataSection>
          <ShadowWrap>
            {isDataLoaded ? (
              <>
                <HeaderSection>
                  <TableMenuLeft>
                    For the {frequency === "quarterly" ? "Quarter" : "Month"} of{" "}
                    {getLabel(tableDate)}
                  </TableMenuLeft>
                  {dropdndata && (
                    <>
                      <StyledSelect
                        name="columns"
                        options={dropdndata}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => handleSelectoption(e)}
                        defaultValue={defaultoption}
                        styles={customStyles}
                      />
                      <StyledSelectMobile  
                        name="columns"
                        options={dropdndata}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => handleSelectoption(e)}
                        defaultValue={defaultoption}
                        styles={customStylesMobiles}/>
                    </>
                  )}
                </HeaderSection>
               {
                columnsData.length>1?
                <MasterTable
                  columnsData={columnsData}
                  rowsData={styledRowsData}
                  sortedColKey={sortedParameter}
                  setSortedColKey={setSortedParameter}
                  sortedDirection={sortedDirection}
                  setSortedDirection={setSortedDirection}
                  headerBackground={"#FFFFFF"}
                  onRangeFilterApply={onRangeFilterApply}
                  filteredRange={parameterRange}
                  valueFilterData={parameterValueFilter}
                  onValueFilterApply={onValueFilterApply}
                />
                :null
               }
              </>
            ) : (
              <>
                <LoadingMessage>Loading...</LoadingMessage>
                <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
              </>
            )}
          </ShadowWrap>
        </DataSection>
      </PageWrapper>
    </>
  );
};

export default Fintable;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const LHSTitlediv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  font-weight: 500;
  font-size: 30px;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 30%;
  height: 40px;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 22px;
  top: 5px;
`;

const CpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
  }
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
  ${media.small`
font-size:12px;
`}
`;

const SpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#A0A6A9" : "#A0A6A9")};
  text-decoration: ${(props) =>
    props.islink === "true" ? "underline" : "none"};
  font-size: 12px;
  font-style: italic;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  text-align: left;
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    color: ${(props) => (props.islink === "true" ? "#A0A0A0" : "#A0A6A9")};
  }
  ${media.small`
font-size:10px;
`}
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 700px;

  ${media.small`
  font-size:12px;
  `}
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderNameDiv = styled.div`
  font-size: 16px;

  ${media.small`
  font-size: 12px;
 

  `}
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  ${media.small`
  font-size: 12px;
  padding-left: 5px;
  line-height: 12px;

  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Element1 = styled.div`
  font-size: 20px;
  font-weight: 700;
  ${media.small`
  font-size:14px;
  `}
`;

const Element2 = styled.div`
  font-size: 10px;
  font-weight: 400;
  ${media.small`
  font-size:10px;
  `}
`;

const Element3 = styled.div`
  font-size: 10px;
  font-weight: 300;
  ${media.small`
  font-size:10px;
  `}
`;

const Growthspan = styled.span`
  background-color: ${(props) => (props.growth >= 0 ? "#d4efdc" : "#f8d2d4")};
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-radius: 20px;
  ${media.small`
  font-size:12px;
  `}
`;

const GrowthSpanVal = styled.div`
  font-size: 17px;
  align-content: center;
  ${media.small`
  font-size:12px;
  `}
`;
const Loaddiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 2px;
  background-color: #d9d9d9;
  margin: 3.5vw;
`;

const Textdiv = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7fcff;
  padding: 0 10px;
  cursor: pointer;
  color: #5cbeff;
`;

const USDWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 3.5vw 0px;

  margin-bottom: 1%;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
`;

const ShadowWrap = styled.div`
  box-shadow: 2px 2px 4px 0px #00000040;
  position: relative;
`;

const SectorProfile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 15px 15px;
  background-color: #ffffff;
`;

const TableMenuLeft = styled.div`
  ${media.small`

font-size:12px;
`}
`;

const SectorHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  font-weight: 500;
  font-size: 30px;
  ${media.small`
  font-size:20px;
  `}
`;

const IndustrySizeWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  height: 100%;
  width: 100%;
`;

const YoyFontWrap = styled.div`
  font-size: 14px;
  ${media.small`
font-size:11px;
`}
`;

const RedseerScoreWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const RedirectTextWarp = styled.span`
  font-size: 15px;
  ${media.small`
font-size: 10px;
`}
`;

const FilterWarp = styled.div`
  width: 20%;
  height: 40px;
  display: flex;
  ${media.small`
width:50%;
align-items:center;
`}
`;

const PlayerSubHeader = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  font-size: 13.5px;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#adddf4" : "transparent")};
  :hover {
    background-color: #adddf4;
  }
  ${media.small`
  height: 20px;
  font-size:8px;
  `}

  ${media.medium`
  height: 30px;
  font-size:10px;
  `}
`;

const PlayerWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 1px;
  margin-right: 5px;
  border: 0.5px solid #d1d1d1;
  border-radius: 5px;
  ${media.small`
  width: 40px;
  height: 40px;
  padding: 0.5px;
  margin-right: 3px;
  border: 0.25px solid #d1d1d1;
  border-radius: 3px;
  display:none;
  `}
`;

const CompanyNameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // background: red;
  ${media.small`
  // justify-content: flex-start;
  `}
`;

const ColumnWarp = styled.div`
  display: flex;
  flex-direction: column;
  // background:blue;
`;
const TooltipContainer = styled.div``;

const TooltipRow = styled.div``;

const Label = styled.span`
  color: #9e9e9e;
  font-size: 12px;
  ${media.small`
      font-size: 9px;

    `}
`;

const Value = styled.span`
  font-size: 12px;
  margin-left: 20px;
  ${media.small`
      font-size: 9px;
  margin-left: 10px;

    `}
`;

const GrowthValue = styled.span`
  font-size: 12px;
  margin-left: 28px;
  ${media.small`
      font-size: 9px;
  margin-left: 14px;

    `}
`;

const StyledSelect = styled(Select)`
  width: 10%;
  ${media.small`
display:none  `}
`;

const LoadingMessage = styled.div`
  min-height: 360px;
  padding: 15px 15px 15px;
`;

const StyledSelectMobile = styled(Select)`
display:none;
  ${media.small`
display:block;
  `}
`;

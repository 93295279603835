import React from "react";
import styled from "styled-components";

const PiechartIcon = ({
  width,
  height,
  color = "#20a6ff",
  rotate = 0,
  onIconClick = () => null,
  className,
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      rotate={rotate}
      onClick={onIconClick}
      className={className}
    >
      <svg
        viewBox="0 0 24 24"
        width={`100%`}
        height={`100%`}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.21 15.89A10 10 0 1 1 8 2.83" stroke={`${color}`} />
        <path d="M22 12A10 10 0 0 0 12 2v10z" stroke={`${color}`} />
      </svg>
    </IconWrapper>
  );
};

export default PiechartIcon;

const IconWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : 18)}px;
  width: ${(props) => (props.width ? props.width : 18)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  }
`;

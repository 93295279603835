import React from 'react'
import Header from '../components/Header'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Pdf = (props) => {
    const search = useLocation().search;
    let address = new URLSearchParams(search).get('address')
    console.log('address = ', address)
    if(!address){
        address = "https://redseerconsutling-my.sharepoint.com/personal/vaseka_redseerconsulting_com/_layouts/15/embed.aspx?UniqueId=24f19ea2-d8e6-4bd6-bc0b-d1fecb5f2641"
    }

  return (
    <div>
        <Header/>
        <iframe src={address} width="105%" height="1000px" frameBorder="0" scrolling="no" allowFullScreen title="Analysis ppt merged.pdf"></iframe>
    </div>
  )
}

export default Pdf
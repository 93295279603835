import { openDB } from 'idb';
const dbVersion = 2;
const dbName = 'app-db';

async function getDb() {
  return openDB(dbName, dbVersion, {
    upgrade(db, oldVersion) {
      if (oldVersion < 1) {
        // Changes for version 1
        db.createObjectStore('counter');
        db.createObjectStore('feature');
        db.createObjectStore('newreportaccessdata');
      }
      if (oldVersion < 2) {
        // Changes for version 2
        db.createObjectStore('quicklinksnodesarr');
      }
    }
  });
}

export async function saveToDb(storeName, data) {
  const db = await getDb();
  await db.put(storeName, data, 'dataKey');
}

export async function getFromDb(storeName) {
  const db = await getDb();
  return await db.get(storeName, 'dataKey');
}





import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PiechartIcon from "../../svg/PiechartIcon";
import AdminConsoleIcon from "../../svg/AdminConsole";
import { media } from "../../../utils/media";

const UserCard = ({onCloseProfile = () => null}) => {
  const navigate = useNavigate();
  const Invite = () => {
    window.location.href = "/invitations";
  };

  const handleSignOut = () => {
    window.localStorage.setItem("loginStatus", "false");
    console.log("signout");
    window.localStorage.clear();
    window.location.assign("/");
    onCloseProfile();

  };

  useEffect(() => {
    window.addEventListener("scroll", onCloseProfile);

    return () => {
      window.removeEventListener("scroll", onCloseProfile);
    };
  }, []);

  const investor_id = window.localStorage.getItem("investor_id");
  const client_id = Number(window.localStorage.getItem("clientID"));
  return (
    <UserCardDiv>
      <ProfileIcon
        src={
          window.localStorage.getItem("gender_male") === "false"
            ? "/Images/userWomen.png"
            : "/Images/userMen.png"
        }
      />
      <Namediv>
        {window.localStorage.getItem("user_name") !== undefined
          ? window.localStorage.getItem("user_name")
          : window.localStorage.getItem("email").split("@")[0]}
      </Namediv>
      <Emaildiv>{window.localStorage.getItem("email")}</Emaildiv>
      <Invitediv
        onClick={() => {
          Invite();
        }}
      >
        <StyledImage src="/Images/adduser.png" alt="" />
        <VanishDiv>Invite</VanishDiv>
      </Invitediv>
      {investor_id && (
        <Portfoliodiv
          onClick={() => {
            navigate("/portfolio");
          }}
        >
          <div style={{ height: "18px", marginRight: "10px" }}>
            <PiechartIcon />
          </div>
          <VanishDiv>My Portfolio</VanishDiv>
        </Portfoliodiv>
      )}
      {(client_id === 1 || client_id === 53) && (
        <AdminConsolediv
          onClick={() => {
            navigate("/admin-console");
          }}
        >
          <div style={{ height: "18px", marginRight: "10px" }}>
            <AdminConsoleIcon />
          </div>
          <VanishDiv>Admin Console</VanishDiv>
        </AdminConsolediv>
      )}
      <SignOutDiv
        onClick={() => {
          handleSignOut();
        }}
      >
        <StyledImage src="/Images/usersignout.png" alt="" />{" "}
        <VanishDiv>Sign Out</VanishDiv>
      </SignOutDiv>
    </UserCardDiv>
  );
};

export default UserCard;

const UserCardDiv = styled.div`
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  border: 5px;
  z-index: 15;
  background-color: #ffffff;
  border-radius: 10px;
  ${media.small`
    font-size: 10px;
  `}
`;

const Namediv = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  ${media.small`
    font-size: 10px;
  `}
`;

const Emaildiv = styled.div`
  text-align: center;
  font-size: 10px;
  padding: 0px 20px 15px;
  border-bottom: 1px solid #c9c9c9;
  ${media.small`
    padding: 0px 15px 10px;
  `}
`;

const Invitediv = styled.div`
  width: 100%;
  color: #20a6ff;
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
  ${media.small`
    font-size: 12px;
  `}
`;

const VanishDiv = styled.span``;

const Portfoliodiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #20a6ff;
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
  ${media.small`
    font-size: 12px;
  `}
`;

const SignOutDiv = styled.div`
  width: 100%;
  color: #20a6ff;
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
  ${media.small`
    font-size: 12px;
  `}
`;

const AdminConsolediv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #20a6ff;
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
  ${media.small`
    font-size: 12px;
  `}
`;

const StyledImage = styled.img`
  height: 18px;
  margin-right: 10px;
`;

const ProfileIcon = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-top: 30px;
  ${media.medium`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-top: 20px;
  `}
  ${media.small`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 20px;
  `}
`;

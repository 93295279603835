import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import ArrowIcon from "../svg/ArrowIcon.js";
import CheckBox from "../CheckBox";
import { media } from "../../utils/media";

const MultiSelect = ({
  id,
  labelName,
  variant = "normal",
  type,
  options = [
    {
      name: "Hotstar",
      id: 13,
      selected: false,
    },
    {
      name: "Zee5",
      id: 14,
      selected: false,
    },
  ],
  keyFieldName,
  valueFieldName,
  optionTypes = [],
  optionMapKey = "",
  optionTypeMapKey = "",
  selectedOptions = [],
  onSelectedChange = () => {},
  mutileSelect = true,
  mutileSelectText,
  placeholder = "Select...",
  searchPlaceholder = "Search...",
  required,
  onBlur,
  disabled = false,
  is_compulsory = false,
  showCheckBox = true,
  sortOptions = false,
  selectAllText = "Select All",
  selectNoneText = "Select None",
  borderRadius = 5,
  className,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState([]);
  const wrapperRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    setVisibleOptions(
      sortOptions
        ? options
            .sort((a, b) => a[valueFieldName].localeCompare(b[valueFieldName]))
            .sort((a, b) => b.selected - a.selected)
        : options
    );
  }, [options]);

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  useEffect(() => {
    if (!isOptionsOpen) {
      setSearchValue("");
      setVisibleOptions(
        sortOptions
          ? options
              .sort((a, b) =>
                a[valueFieldName].localeCompare(b[valueFieldName])
              )
              .sort((a, b) => b.selected - a.selected)
          : options
      );
    }
  }, [isOptionsOpen]);

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
    const filteredOption = options.filter((obj) => {
      const substring = e.target.value.toLowerCase();
      const string = obj[valueFieldName].toLowerCase();
      return string.includes(substring);
    });
    setVisibleOptions(
      sortOptions
        ? filteredOption
            .sort((a, b) => a[valueFieldName].localeCompare(b[valueFieldName]))
            .sort((a, b) => b.selected - a.selected)
        : filteredOption
    );
    if (e.key === "Delete" || e.key === "Backspace") {
      console.log("backsapce cliked");
    }
  };

  const onOptionSelect = (option) => {
    if (mutileSelect) {
      const isSelectedClicked = Boolean(
        selectedOptions.filter(
          (obj) => obj[keyFieldName] === option[keyFieldName]
        ).length
      );
      if (isSelectedClicked) {
        const remainingSelected = selectedOptions.filter(
          (obj) => obj[keyFieldName] !== option[keyFieldName]
        );
        onSelectedChange(remainingSelected);
      } else {
        let result = selectedOptions.filter(
          (obj) => obj[keyFieldName] !== option[keyFieldName]
        );
        result.push(option);
        onSelectedChange(result);
      }
    } else {
      onSelectedChange([option]);
      setIsOptionsOpen(false);
    }
  };

  const onSelectAll = () => {
    /* only mutileSelect = true */
    onSelectedChange(options);
  };

  const onSelectNone = () => {
    /* only mutileSelect = true */
    onSelectedChange([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOptionsOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsOptionsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOptionsOpen]);

  switch (variant) {
    case "normal": {
      return (
        <Wrapper className={className}>
          {/* <LabelRow>
            <InputLabel htmlFor={labelName}>{labelName}</InputLabel>
            {is_compulsory && <span className="redtext">*</span>}
          </LabelRow> */}
          <SelectedContainer
            id={id}
            type={type}
            onBlur={(e) => !disabled && onBlur && onBlur(e)}
            disabled={disabled}
            optionsopen={isOptionsOpen.toString()}
            ref={isOptionsOpen ? wrapperRef : null}
            borderRadius={borderRadius}
          >
            <SelectedOption onClick={() => toggleDropdown()}>
              {selectedOptions.length ? (
                <DropdownValue>
                  {mutileSelect
                    ? `${mutileSelectText || "Selected"} [${
                        selectedOptions.length
                      }]`
                    : selectedOptions[0]?.[valueFieldName]}
                </DropdownValue>
              ) : (
                <Placeholder>{placeholder}</Placeholder>
              )}
            </SelectedOption>
            <ArrowContainer
              disabled={disabled}
              ref={isOptionsOpen ? wrapperRef : null}
            >
              <ArrowIcon
                rotate={isOptionsOpen ? 270 : 90}
                disabled={disabled}
                onIconClick={() => !disabled && toggleDropdown()}
              />
              {isOptionsOpen && (
                <Dropdown borderRadius={borderRadius}>
                  <SearchBox
                    required={required}
                    value={searchValue}
                    onChange={onInputChange}
                    id={id}
                    placeholder={searchPlaceholder}
                    type={type}
                    onBlur={(e) => !disabled && onBlur && onBlur(e)}
                    disabled={disabled}
                    ref={searchRef}
                  ></SearchBox>
                  {mutileSelect && visibleOptions.length ? (
                    <ActionRow>
                      <Action onClick={onSelectAll}>{selectAllText}</Action>
                      <Action onClick={onSelectNone}>{selectNoneText}</Action>
                    </ActionRow>
                  ) : null}
                  <ScrollWrapper>
                    {visibleOptions.length ? <ScrollShadow /> : null}
                    <DropdownScroll>
                      {optionTypes.length
                        ? optionTypes.map((optionType, typeIndex) => {
                            let typeRelatedOptions = visibleOptions.filter(
                              (obj) =>
                                obj[optionMapKey] ===
                                optionType[optionTypeMapKey]
                            );

                            return typeRelatedOptions.length ? (
                              <div key={typeIndex}>
                                <DropdownOptionType>
                                  {optionType[optionTypeMapKey]}
                                </DropdownOptionType>
                                {typeRelatedOptions.map((option, index) => {
                                  const isselected = selectedOptions.filter(
                                    (obj) =>
                                      obj[keyFieldName] === option[keyFieldName]
                                  ).length;
                                  return (
                                    <DropdownOption
                                      key={index}
                                      isselected={
                                        showCheckBox ? false : isselected
                                      }
                                      onClick={() => {
                                        onOptionSelect(option);
                                      }}
                                      paddingLeft={22}
                                    >
                                      {showCheckBox && (
                                        <CheckBoxWrapper>
                                          <CheckBoxIcon value={isselected} />
                                        </CheckBoxWrapper>
                                      )}
                                      <DropdownValue>
                                        {option[valueFieldName]}
                                      </DropdownValue>
                                    </DropdownOption>
                                  );
                                })}
                              </div>
                            ) : null;
                          })
                        : visibleOptions.map((option, index) => {
                            const isselected = selectedOptions.filter(
                              (obj) =>
                                obj[keyFieldName] === option[keyFieldName]
                            ).length;
                            return (
                              <DropdownOption
                                key={index}
                                isselected={showCheckBox ? false : isselected}
                                onClick={() => {
                                  onOptionSelect(option);
                                }}
                              >
                                {showCheckBox && (
                                  <CheckBoxWrapper>
                                    {" "}
                                    <CheckBoxIcon value={isselected} />
                                  </CheckBoxWrapper>
                                )}
                                <DropdownValue>
                                  {option[valueFieldName]}
                                </DropdownValue>
                              </DropdownOption>
                            );
                          })}
                    </DropdownScroll>
                  </ScrollWrapper>
                </Dropdown>
              )}
            </ArrowContainer>
          </SelectedContainer>
        </Wrapper>
      );
    }
    default: {
      return <p>Invalid Variant Type</p>;
    }
  }
};

export default MultiSelect;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 200px;
  width: 250px;
  height: 40px;
  font-size: 13px;
  ${media.medium`
    height: 30px;
    font-size: 12px;
  `}
  ${media.small`
    height: 20px;
    font-size: 11px;
      width: 150px;
  `}
`;

// const InputLabel = styled.div`
//   padding: 5px;
//   font-size: 0.8rem;
//   color: rgb(114, 114, 114);
// `;

// const LabelRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   .redtext {
//     color: #f44336;
//     font-size: 16px;
//   }
// `;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 24px);
  height: 100%;
  padding: 5px 5px 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.medium`
    width: calc(100% - 24px);
    padding: 5px 5px 5px 10px;
  `}

  ${media.small`
    width: calc(100% - 24px);
    padding: 5px 0px 5px 5px;
  `}
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: ${(props) => props.borderRadius}px;
  width: 100%;
  transition: 0.2s ease-out;
  transition-property: border;
  background-color: ${(props) => (props.disabled ? "#efefef4d" : "#FFFFFF")};
  position: relative;
`;

const SearchBox = styled.input`
  margin: 8px;
  padding: 5px;
  border-radius: 2px;
  height: 26px;
  transition: 0.2s ease-out;
  transition-property: border;
  border: 1px solid #cccccc;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  :focus {
    border: 1px solid #0099ff;
    outline: none;
  }
  :hover {
    border: 1px solid #0099ff;
    outline: none;
    box-shadow: 0 0 10px #d4eeff;
  }
  :disabled {
  }

  ${media.medium`
    margin: 7px;
    height: 24px;
    font-size: 14px;
  `}

  ${media.small`
    margin: 5px;
    height: 20px;
    font-size: 12px;
  `}
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 6px 7px;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding: 0px 8px;
  background-color: #f2f2f2;
  color: #262e40;
  height: 24px;
  border-radius: 12px;
  min-width: 50px;
  cursor: pointer;

  ${media.medium`
    font-size: 12px;
  `}

  ${media.small`
    font-size: 11px;
  `}
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 34px;
  height: 30px;

  ${media.medium`
    width: 30px;
    height: 24px;
  `}

  ${media.small`
    width: 24px;
    height: 20px;
  `}

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: ${(props) => props.borderRadius}px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 42px;
  width: calc(100% + 2px);
  left: -1px;
  z-index: 6;
  cursor: default;

  ${media.medium`
    top: 32px;
  `}

  ${media.small`
    top: 22px;
  `}
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

const ScrollShadow = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    transparent 100%
  );
  ${media.medium`
    height: 3px;
  `}

  ${media.small`
    height: 3px;
  `}
`;

const DropdownScroll = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-top: 5px;
  overflow: auto;
`;
//

const DropdownOptionType = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 38px;
  padding: 8px;
  background-color: ${(props) => (props.isselected ? "#0099FF" : null)};
  color: ${(props) => (props.isselected ? "#FFFFFF" : "#000000")};
  &:hover {
    background-color: ${(props) =>
      props.isselected ? "#0099FF" : "#e5e5e560"};
    cursor: pointer;
  }
`;

const DropdownOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 6px 7px;
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px;` : null};
  background-color: ${(props) => (props.isselected ? "#0099FF" : null)};
  color: ${(props) => (props.isselected ? "#FFFFFF" : "#000000")};
  &:hover {
    background-color: ${(props) =>
      props.isselected ? "#0099FF" : "#e5e5e560"};
    cursor: pointer;
  }

  ${media.medium`
    height: 35px;
    padding: 6px 7px;
  `}

  ${media.small`
    height: 30px;
    padding: 5px;
  `}
`;

const CheckBoxIcon = styled(CheckBox)`
  width: 20px;
  height: 20px;

  ${media.medium`
    hwidth: 20px;
    height: 20px;
  `}

  ${media.small`
    width: 18px;
    height: 18px;
  `}
`;

const DropdownValue = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Placeholder = styled.div`
  color: #969799;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CheckBoxWrapper = styled.div`
  margin-right: 5px;
`;

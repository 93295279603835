import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  INDUSTRIES_URL_ENDPOINT,
  USERS_URL_ENDPOINT,
  MEDIA_URL_ENDPOINT,
} from "../../constants/constants";
import DotIcon from "../svg/DotIcon";
import { media } from "../../utils/media";

const ArticleDetail = ({ selectedArticle }) => {
  const [article, setArticle] = useState(selectedArticle);
  const [industries, setIndustries] = useState([]);
  const [isIndustryLoading, setIndustryLoading] = useState(true);
  const [author, setAuthor] = useState(null);
  const [isAuthorLoading, setIsAuthorLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    axios
      .get(INDUSTRIES_URL_ENDPOINT)
      .then((response) => response.data)
      .then((industries) => {
        setIndustries(industries);
        setIndustryLoading(false);
      })
      .catch((error) => {
        console.error("Error in fetching industries-", error);
        setIndustryLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${MEDIA_URL_ENDPOINT}/${article.data?.featured_media}`)
      .then((response) => response.data)
      .then((attachmentRes) => {
        setImageData(attachmentRes);
      })
      .catch((error) => {
        console.error("Error in fetching imageData -", error);
      });
  }, []);

  useEffect(() => {
    // if (article) {
    //   axios
    //     .get(`${USERS_URL_ENDPOINT}/${article?.data?.author}`)
    //     .then((response) => response.data)
    //     .then((author) => {
    //       setAuthor(author);
    //       setIsAuthorLoading(false);
    //     })
    //     .catch((error) => {
    //       console.error("Error in fetching author -", error);
    //       setIndustryLoading(false);
    //     });
    // }
    if (article?.type === "report") {
      axios
        .get(`${MEDIA_URL_ENDPOINT}/${article?.data?.acf?.report_file_link}`)
        .then((response) => response.data)
        .then((attachmentRes) => {
          setAttachment(attachmentRes);
        })
        .catch((error) => {
          console.error("Error in fetching attachment -", error);
        });
    }
  }, [article]);

  return (
    <ArticlesContainer>
      {isIndustryLoading || isAuthorLoading ? (
        "Loading..."
      ) : (
        <>
          <ArticleWrapper>
            <Title
              dangerouslySetInnerHTML={{
                __html: article?.data?.title?.rendered || "",
              }}
            />
            {/* <Description>
              {article?.data?.yoast_head_json?.description}
            </Description> */}
            {/* {author && (
              <AuthorProfile>
                <AuthorImage
                  src={author.yoast_head_json?.og_image?.[0]?.url || ""}
                />
                <AuthorText>{author.yoast_head_json?.title || ""}</AuthorText>
              </AuthorProfile>
            )} */}
            <ImageContainer>
              <Image src={imageData?.source_url || ""} />
            </ImageContainer>
            <ArticleMetaRow>
              <NameAndTime>
                <IndustryName>
                  {article?.data?.industry?.[0]
                    ? industries.filter(
                        (item) => item.id === article?.data?.industry?.[0]
                      )?.[0]?.name || ""
                    : ""}
                </IndustryName>
                &nbsp;
                <DotIcon />
                &nbsp;
                <MetaText>
                  {article?.data?.yoast_head_json?.twitter_misc?.[
                    "Est. reading time"
                  ] + " read"}
                </MetaText>
                &nbsp;
                <DotIcon />
                &nbsp;
                <MetaText>
                  {article?.data?.date
                    ? moment(article?.data?.date).format("MMMM D, YYYY")
                    : ""}
                </MetaText>
              </NameAndTime>
              <SocialMedia></SocialMedia>
            </ArticleMetaRow>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <style>
                    img {
                      max-width: 100%;
                      height: auto; /* maintain aspect ratio */
                    }
                  </style>
                  ${
                    article?.data?.content.rendered ||
                    article?.data?.excerpt.rendered
                  }
                `,
              }}
            />
            {attachment && (
              <LinkContainer>
                <ReportBoxLink href={attachment?.source_url} target="_blank">
                  View the Report
                </ReportBoxLink>
              </LinkContainer>
            )}
            {/* <AuthorSection>
              <AuthorText>About the Author</AuthorText>
              <Description>{author.description || ""}</Description>
              <AuthorRow>
                <AuthorProfile>
                  <AuthorImage
                    src={author.yoast_head_json?.og_image?.[0]?.url || ""}
                  />
                  <AuthorText>{author.yoast_head_json?.title || ""}</AuthorText>
                </AuthorProfile>
                {author.link && (
                  <ConnectBox href={author.link} target="_blank">
                    Connect with{" "}
                    {author.yoast_head_json?.title?.split(" ")?.[0] || "Author"}
                  </ConnectBox>
                )}
              </AuthorRow>
            </AuthorSection> */}
          </ArticleWrapper>
        </>
      )}
    </ArticlesContainer>
  );
};

export default ArticleDetail;

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(3.2vw - 0px);
  background-color: transparent;
  width: 100%;
`;

const Title = styled.div`
  color: var(--neutral, #262e40);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
  ${media.small`
      font-size: 20px;   
    `}
`;

const Description = styled.div`
  color: var(--grey, #444);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 0;
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08) !important;
  ${media.small`
    
      padding: 30px;

    `}
}
`;

const AuthorProfile = styled.div`
  display: flex;
  justify-content: column;
  align-items: center;
  height: 45px;
  margin: 10px 0;
`;

const AuthorRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const AuthorImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 45px;
  margin-right: 10px;
`;

const AuthorText = styled.div`
  color: #1f1f1f;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ConnectBox = styled.a`
  color: #444444;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 15px 15px 0px 15px;
  :hover {
    color: #444444;
    text-decoration: none;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-top: min(45%, 682px);
  position: relative;
  margin: 10px 0 10px 0;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ArticleMetaRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px 0;
`;
const NameAndTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IndustryName = styled.div`
  color: var(--neutral, #262e40);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const MetaText = styled.div`
  color: var(--2, #282b32);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  width: 220px;
  height: 30px;
`;

const AuthorSection = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
  background: #ebf7ff;
  width: 100%;
  margin-top: 10px;
`;

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const ReportBoxLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0099ff;
  color: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 50px;
  width: 200px;
  height: 50px;
  :hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MultiSelect from "../../../components/MultiSelect";
import ImageGraph from "../../../components/ImageGraph";

const CompanyGridView = ({
  visibaleCompDataList = [],
  industryList = [],
  setSelectedIndustry = () => null,
  pairedParams,
  iconData,
}) => {
  const [selectedPairedParam, setSelectedPairedParam] = useState([]);
  const [xmax, setxmax] = useState(26.46);
  const [ymax, setymax] = useState(15.01);
  const [paramx, setparamx] = useState("");
  const [paramy, setparamy] = useState("");
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (selectedPairedParam) {
      setSelectedPairedParam(pairedParams.slice(0, 1));
    }
  }, [pairedParams]);

  useEffect(() => {
    if (selectedPairedParam.length) {
      setparamx(selectedPairedParam[0].x_parameter_name);
      setparamy(selectedPairedParam[0].y_parameter_name);
      let graph_data = formatData(
        visibaleCompDataList,
        selectedPairedParam[0].xparameter,
        selectedPairedParam[0].yparameter,
        iconData
      );
      setGraphData(graph_data.transformedData);
      setxmax(graph_data.maxX);
      setymax(graph_data.maxY);
    }
  }, [selectedPairedParam, visibaleCompDataList]);

  function formatData(data, param_x, param_y, iconData) {
    let maxX = -Infinity;
    let maxY = -Infinity;
    const iconsMap = new Map(
      iconData.map((icon) => [icon.player_id, icon.file])
    );
    let transformedData = [];
    data.forEach((companyData) => {
      if (companyData.paramData[param_x] && companyData.paramData[param_y]) {
        const currentValueX = parseFloat(
          companyData.paramData[param_x].current_value
        );
        const currentValueY = parseFloat(
          companyData.paramData[param_y].current_value
        );
        if (currentValueX > maxX) {
          maxX = currentValueX;
        }
        if (currentValueY > maxY) {
          maxY = currentValueY;
        }
        transformedData.push({
          image: iconsMap.get(companyData.id) || null,
          name: companyData.name || "",
          x: currentValueX,
          y: currentValueY,
        });
      }
    });
    return { transformedData, maxX, maxY };
  }

  return (
    <Wrapper>
      <TableMenu>
        <TableMenuLeft></TableMenuLeft>
        <TableMenuRight>
          <Label>Select Sectors:</Label>
          <MultiSelect
            options={industryList}
            onSelectedChange={setSelectedIndustry}
            keyFieldName="industry_id"
            valueFieldName="industry_name"
            selectedOptions={industryList.filter((obj) => obj.selected)}
            mutileSelect={true}
            placeholder="Select Parameters.."
          />
          <Label>Select Parameter:</Label>
          <SelectWraper>
            <MultiSelect
              options={pairedParams}
              keyFieldName="id"
              valueFieldName="parameter_type"
              onSelectedChange={setSelectedPairedParam}
              selectedOptions={selectedPairedParam}
              mutileSelect={false}
              placeholder="Select Parameter.."
            />
          </SelectWraper>
        </TableMenuRight>
      </TableMenu>
      {paramx && paramy && graphData.length ? (
        <ImageGraph
          xmax={xmax}
          ymax={ymax}
          paramx={paramx}
          paramy={paramy}
          graphdata={graphData}
        />
      ) : (
        <NoDataMessage>No data found</NoDataMessage>
      )}
    </Wrapper>
  );
};

export default CompanyGridView;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanySearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 30px;
  align-items: center;
  background-color: #ffffff;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 60%;
  height: 60px;
  position: relative;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const TableMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  min-height: 56px;
  background-color: #ffffff;
  align-items: center;
`;

const TableMenuLeft = styled.div`
  margin-left: 24px;
`;

const TableMenuRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const SelectWraper = styled.div`
  margin-left: 10px;
`;

const Label = styled.div`
  margin: 0 5px 0px 10px;
`;

const ScatterChartWrapperdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 150px;
  width: 100%;
  padding: 50px 10px;
  background-color: #ffffff;
`;

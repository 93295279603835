import React, { useState,useRef,useEffect } from 'react';
import { GiBreakingChain, GiConsoleController, GiEvilFork, GiHealthNormal, GiCarWheel, GiClothes,GiMedicines,GiFruitBowl, GiVideoConference, GiHamburgerMenu } from "react-icons/gi";
import { FaAmazon, FaTrafficLight,FaUsers, FaDeezer,FaAlignLeft, FaKeyboard, FaCity } from 'react-icons/fa';
import {MdKeyboardArrowDown} from 'react-icons/md'


function LinkDropDown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(!isOpen);
    // window.localStorage.setItem('dropdnOption', option.label)
    // console.log('op=', option)
    props.onOptionSelect(option);
    // setIsOpen(false);
  };

  let option1 = [{ id: 1, name: 'Option 1', icon: <GiHamburgerMenu/> },
  { id: 2, name: 'Option 2', icon: <GiHamburgerMenu/> },
  { id: 3, name: 'Option 3', icon: <GiHamburgerMenu/> }]

  return (
    <div ref={dropdownRef}className="dropdown">
      {props.prev_value===null?<button className="dropdown-button" onClick={toggleDropdown}>
        {props.default_head}<MdKeyboardArrowDown/>
      </button>:
      <button className="dropdown-button" style={props.buttonstyle} onClick={toggleDropdown}>
     { props.prev_value}<MdKeyboardArrowDown/>
      </button>}
      {isOpen ? (
        <ul className="menu" style={props.style}>
            {props.options.map((option) => (
            <li
              key={option.label}
              className="dropdown-option"
            >
                <a
                href={option.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                    e.preventDefault();
                    handleOptionClick(option);
                }}>
              <span className="option-icon">{option.icon}</span>
              <span className="option-text">{option.label}</span>
                </a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}

export default LinkDropDown;

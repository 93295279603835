import React from 'react'
import styled from 'styled-components'
import { useState } from 'react';

const Contact = () => {

    const [width, setWidth] = useState(window.innerWidth);
    let login = (e)=>{
    console.log('submit')        

    }
    return (
        width>768 ?(<PageContainer>
            <SideImg>
            <img src = '/Images/sideimg.png' alt = ''/>
            </SideImg>
             <Wrapper>
                <ImgDiv>
                    <img src = '/Images/benchmark_logo.png' alt = ''/>
                </ImgDiv>


                <LoginForm>
                    <h3>Reach Us !</h3>
                    <AddressContainer>
                        <emailnamediv>Email:</emailnamediv>
                        <emailDetailsdiv>Query@beeroute.in</emailDetailsdiv>
                        <Companydiv>Company :</Companydiv>
                        <CompanyDetailsdiv>Beeroute Research Private Limited</CompanyDetailsdiv>
                        <Addressdiv>Address : </Addressdiv>
                        <AddressDetailsdiv>Beeroute Research Pvt Ltd, Prestige Obelisk, Kasturba Rd, Ambedkar Veedhi, Sampangi Rama Nagar, Bangalore, Karnataka 560001</AddressDetailsdiv>
                    </AddressContainer>
                </LoginForm>
                <SocialLogin>
                    Our Socials <button ><img src='/Images/linkedin.png'/></button><button ><img src='/Images/insta.png'/></button><button ><img src='/Images/yt.png'/></button> 
                </SocialLogin>
                <SignIn>
                    Already have an account? <a href='/'>Sign In</a>
                </SignIn>
                <FooterDiv>
                    Terms and Conditions | Privacy Policy  | Contact Us
                </FooterDiv>
            </Wrapper>
        </PageContainer>):(
        <PageContainer>
             <Wrapper>
                <ImgDiv>
                    <img src = '/Images/benchmark_logo.png' alt = ''/>
                </ImgDiv>
                <LoginForm>
                <form onSubmit={(e)=>login(e)}>
                    <h4>Create Free Account</h4>
                    <div className="form-group">
                        <label></label>
                        <input type = 'text' name = 'fullname' id='fullname' placeholder='Full Name' className="form-control" />
                        <label></label>
                        <input type = 'text' name = 'email' id='email' placeholder='Email' className="form-control" />
                        <label></label>
                        <input type = 'text' name = 'phone' id='phone' placeholder='Your Phone Number' className="form-control" />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block" style={{backgroundColor:'blue' , border:'None'}} >Sign Up</button>
                </form>
                </LoginForm>
                <SocialLogin>
                    {/* Sign Up with <button><img src = '/Images/google_icon.png'/></button> &nbsp;<button><img src = '/Images/ms_icon.png'/></button> */}
                </SocialLogin>
                <SignIn>
                    Already have an account? <a>Sign In</a>
                </SignIn>
                <FooterDiv>
                    {/* Terms and Conditions | Privacy Policy  | Contact Us */}
                </FooterDiv>
            </Wrapper>
        </PageContainer>
        )
      )
}

export default Contact

const PageContainer = styled.div`
height:100vh;
display:flex;
@media (max-width:768px){
    overflow-x:hidden
}
`

const Wrapper = styled.div`
/* margin-top: 10vh; */
/* text-align:center; */
background-color:white;
width:40%;
display:flex;
flex-direction:column;
align-items:center;
@media (max-width:768px){
  width:100%;
}
`

const ImgDiv = styled.div`
/* border :1px solid red; */
margin-bottom: 40px;
margin-top:35px;
width:35vw;
flex-grow:1;
display:flex;
align-items:center;
justify-content:center;
img{

}
`
const AddressContainer  =styled.div`
margin-top:20px;
display: grid; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 0.5fr 1.5fr; 
  grid-template-rows: 0.8fr 0.8fr 1.4fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "email emailDetails"
    "Company CompanyDetails"
    "Address AddressDetails"; 
    
`

const emailnamediv = styled.div`
    grid-area:email;
`

const emailDetailsdiv = styled.div`
    grid-area:emailDetails;
`

const Companydiv = styled.div`
    grid-area:Company;
`

const CompanyDetailsdiv = styled.div`
    grid-area:CompanyDetails;
`

const Addressdiv = styled.div`
    grid-area:Address;
`

const AddressDetailsdiv = styled.div`
    grid-area:AddressDetails;
`




const LoginForm = styled.div`
margin-bottom: 10px;
/* flex-grow:1; */
width: 450px;
margin: auto;
margin-bottom: 20px;

@media (max-width:768px){
    width: 320px;
    margin: auto;
}
`

const SocialLogin = styled.div`
margin-bottom: 10px;
/* flex-grow:1; */
display:flex;
align-items:center;
justify-content:center;
height:15vh;
button{
    background-color:white;
    border: None;
    img{
        height:30px;
    }
}
@media (max-width:768px){
    margin-top:20px;
}

`

const SignIn = styled.div`
margin-bottom: 10px;
flex-grow:1;

`

const FooterDiv = styled.div`
border-top :1px solid black;
margin-bottom: 10px;
/* flex-grow:1; */
height:7vh;
width:40vw;
color:blue;
text-align:center;
`

const LoginInner = styled.div`
  width: 450px;
  margin: auto;
  background: #ffffff;
  /* padding: 40px 55px 45px 55px; */
  img{
    position:absolute;
    /* width: 210px;
    height: 90px; */
    left: 61vw;
    /* top: 160px; */
    top:22vh;
  }
  @media (max-width:768px){
    width: 400px;
    margin: auto;
    padding: 40px 55px 45px 55px;
    img{
      position:absolute;
      width: 176px;
      height: 45px;
      left: 30vw;
      top: 160px;
    }
}
`

const SideImg = styled.div`
background-color:#1C1C6C;
width:60%;
overflow:hidden;
img{
  height:100%;
  width: 100%;
}
`
import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ReferenceLine } from 'recharts';
import Select from "react-select";
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCircle } from "react-icons/fa6";
import { Text } from 'recharts';
import { useRef } from 'react';
import RenderCustomShape from './RenderCustomShape';
import SChart from './SChart';
import { FaBullseye } from 'react-icons/fa';
import { media } from '../../utils/media';


const data = [
    { name: 'Uber', x: 25, y: 90 },
    { name: 'Zomato', x: 40, y: 65 },
];

const CustomScatterChart = ({ind_id}) => {
    const [dropdndata, setdropdndata] = useState([])
    const [zdropdndata, setzdropdndata] = useState([])
    const [zselect, setzselect] = useState([])
    const [zdefault, setzdefault] = useState([])
    const [xdropdndata, setxdropdndata] = useState([])
    const [ydropdndata, setydropdndata] = useState([])
    const [xselected, setxselected] = useState(false)
    const [yselected, setyselected] = useState(false)
    const [defaultValue, setDefaultValue] = useState(null);
    const [paramtypedata, setparamtypedata] = useState([])
    const [zparamtypedata, setzparamtypedata] = useState([])
    const [xparamtypedata, setxparamtypedata] = useState([])
    const [yparamtypedata, setyparamtypedata] = useState([])
    const [showCustom, setshowCustom] = useState(false)
    const [uniqueplayers, setuniqueplayers] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [graphdata, setgraphdata] = useState([]);
    const [xgraphdata, setxgraphdata] = useState([]);
    const [ygraphdata, setygraphdata] = useState([]);
    const [icondata, seticondata] = useState([]);
    const [paramx, setparamx] = useState('');
    const [paramy, setparamy] = useState('');
    const [paramxID, setparamxID] = useState('');
    const [paramyID, setparamyID] = useState('');
    const [month, setmonth] = useState(null)
    const [chartWidth, setChartWidth] = useState(0);
    const [colors, setColors] = useState([]);
    const [pairedcolors, setpairedColors] = useState([]);
    const [renderedDots, setRenderedDots] = useState([]);
    const [renderednameDots, setRenderednameDots] = useState([]);
    const [graphdataUpdated, setGraphdataUpdated] = useState(false);
    const [showLog, setShowLog] = useState(false);

    const refxmax = useRef(null);
    const refymax = useRef(null);
    const refxmin = useRef(null);
    const refymin = useRef(null);
    const chartRef = useRef();
    const xpx = useRef();
    const ypx = useRef();
    const xwdpx= useRef();



    useEffect(() => {
        const updateWidth = () => {
            const width = Math.max(window.innerWidth * 0.93 + 10, 0);
            setChartWidth(width);
        };

        window.addEventListener('resize', updateWidth);
        updateWidth(); // Initial calculation

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    useEffect(() => {
      if (graphdataUpdated) { // You'll need a state variable to track if graphdata has been updated
        handleSelectZOptions(zdropdndata[0], zparamtypedata).then(() => {
          setGraphdataUpdated(false); // Set it back to false after handling
        });
      }
    }, [graphdataUpdated, zdropdndata, zparamtypedata]); 

    useEffect(()=>{
        const getdropdown = async() =>{
                let industry_id = ind_id
                if (!industry_id){
                    industry_id = 10
                }
                const response = await axios.get(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/alternateparametertype/?industry_id=${industry_id}`)
                const zresponse = await axios.get(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/zparams/?industry_id=${industry_id}`)
                const xresponse = await axios.get(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/xparams/?industry_id=${industry_id}`)
                const yresponse = await axios.get(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/yparams/?industry_id=${industry_id}`)
                // const ddata = await(paramdropdownservice(industry_id))
                let data = response.data
                let zdata = zresponse.data
                let xdata = xresponse.data
                let ydata = yresponse.data
                setxparamtypedata(xdata)
                setyparamtypedata(ydata)
                setparamtypedata(data)
                setzparamtypedata(zdata)
                let uniqueParameterTypes = [...new Set(data.map(item => item.parameter_type))];
                var found = false
                let idx = 0
                for (idx = 0;idx<uniqueParameterTypes.length; idx++){
                  if (uniqueParameterTypes[idx]==='GMV vs Growth Rate'){
                    found = true
                    break
                  }
                }
                if(found){
                  uniqueParameterTypes.splice(idx, 1)
                  uniqueParameterTypes.splice(0,0,'GMV vs Growth Rate')
                }

                let newArray = uniqueParameterTypes.map(parameterType => ({
                    value: parameterType,
                    label: parameterType
                }));

                let uniqueZParameterTypes = [...new Set(zdata.map(item => item.parameter_type))];
                let newZArray = uniqueZParameterTypes.map(parameterType => ({
                    value: parameterType,
                    label: parameterType
                }));
                let uniqueXParameterTypes = [...new Set(xdata.map(item => item.parameter_type))];
                let newXArray = uniqueXParameterTypes.map(parameterType => ({
                    value: parameterType,
                    label: parameterType
                }));

                let uniqueYParameterTypes = [...new Set(ydata.map(item => item.parameter_type))];
                let newYArray = uniqueYParameterTypes.map(parameterType => ({
                    value: parameterType,
                    label: parameterType
                }));

                setxdropdndata(newXArray)
                setydropdndata(newYArray)
                setzdropdndata(newZArray)
                
                if (newArray.length > 0) {
                  // newarray = [{val, label}, {...}, ...]
                    await handleSelectOptions(newArray[0], data)
                    setDefaultValue(newArray[0]);
                    // defval = newArray[0]
                  }
                  newArray.push({value:'Custom',
                    label: 'Custom'})
                setdropdndata(newArray)

                if (newZArray.length > 0) {
                  // handleSelectZOptions(newZArray[0], zdata)
                  setzdefault(newZArray[0]);
                }
              }
        getdropdown()
    },[])

    useEffect(() => {
      if (chartRef.current) {
        // Get the internal state of the chart, which includes the scale functions
        const { xAxisMap, yAxisMap } = chartRef.current.state;
        

        if(xAxisMap && yAxisMap){
        const xAxis = xAxisMap[0];
        const yAxis = yAxisMap[0];
        xwdpx.current = xAxis.width
        // Get the scale functions
        const xScale = xAxis.scale;
        const yScale = yAxis.scale;
  
        // Convert pixel to data value
        const pixelX = 100; // Example pixel value for x
        const pixelY = 100; // Example pixel value for y
        let dataX = xScale.invert(pixelX-100);
        let dataY = yScale.invert(pixelY-20);
        if (dataX<0){
          dataX = -dataX
        }
        if (dataY<0){
          dataY = -dataY
        }

        xpx.current = pixelX
        ypx.current = pixelY
  
        }
       
      }
    }, [graphdata]);

    
    const renderCustomShape = (props) => {
        const { cx, cy, payload } = props;
        const imgSrc = payload.image
        let playerColor = payload.color
        let name = payload.name
        const textSize = payload.size;
        const newDot = { cx, cy, name};
        return (
          <Text
            x={cx}
            y={cy}
            fontSize={payload.size.height} // Use calculated size for font size
            textAnchor="middle"
            verticalAnchor="middle"
            fontWeight="bold"
          >
            {payload.name} 
          </Text>)
    }
    
    const estimateTextSize = (name, fontSize) => ({ width: name.length * fontSize * 0.45, height: fontSize });

    const resolveOverlaps = (data, maxX = refxmax.current, maxY = refymax.current, minX = refxmin.current, minY = refymin.current) => {
      let xrange = maxX - minX;
      let yrange = maxY - minY;
      let effectivehtpx = 520; // You should use a dynamic or passed-in value here
      let effectivewidthpx = xwdpx.current; // You should use a dynamic or passed-in value here
      let pxtoxmultiplier = effectivewidthpx / xrange;
      let pxtoymultiplier = effectivehtpx / yrange;
    
      let yblockheightpx = (520 / 10); // You should use a dynamic or passed-in value here
      let xblockwidthpx = ((chartWidth - 140) / 50); // You should use a dynamic or passed-in value here
      let yblockheighty = yrange / yblockheightpx;
      let xblockwidthx = xrange / xblockwidthpx;
    
      const adjustedData = [...data]; // Create a copy to avoid directly mutating the input
      let overlap;
      const maxIterations = 100; // Set a sensible limit to iterations
      let iterations = 0;
    
      do {
        overlap = false;
        iterations++; // Increment the number of iterations
        if (iterations >= maxIterations) {
          break; // Exit the loop if the maximum iterations is reached
        }
        for (let i = 0; i < adjustedData.length; i++) {
          for (let j = i + 1; j < adjustedData.length; j++) {
            let dx = adjustedData[j].x - adjustedData[i].x;
            let dy = adjustedData[j].y - adjustedData[i].y;
            let requiredDistanceX = xblockwidthx; // Assuming this is the minimum x distance to not overlap
            let requiredDistanceY = yblockheighty; // Assuming this is the minimum y distance to not overlap
    
            if (Math.abs(dx) < requiredDistanceX && Math.abs(dy) < requiredDistanceY) {
              overlap = true; // Set overlap to true to indicate that we need another iteration
              let adjustX = (requiredDistanceX - Math.abs(dx)) / 2;
              let adjustY = (requiredDistanceY - Math.abs(dy)) / 2;
    
              // Determine direction of adjustment
              if (dx > 0) { // If j is to the right of i, push j further right and i further left
                adjustedData[i].x -= adjustX;
                adjustedData[j].x += adjustX;
              } else { // If j is to the left of i, push j further left and i further right
                adjustedData[i].x += adjustX;
                adjustedData[j].x -= adjustX;
              }
    
              if (dy > 0) { // If j is below i, push j further down and i further up
                adjustedData[i].y -= adjustY;
                adjustedData[j].y += adjustY;
              } else { // If j is above i, push j further up and i further down
                adjustedData[i].y += adjustY;
                adjustedData[j].y -= adjustY;
              }
            }
          }
        }
      } while (overlap);
    
      return adjustedData;
    };


    function transformData(data, param_x, param_y, iconData = [], pairData) {
        let maxX = -Infinity; // Initialize with the lowest possible number
        let maxY = -Infinity; // Initialize with the lowest possible number
        let minX = Infinity
        let minY = Infinity
        // const iconsMap = new Map(iconData.map(icon => [icon.player_id, icon.file]));
        const transformedData = data.reduce((acc, item) => {
          let playerEntry = acc.find(entry => entry.name === item.player_name);
          let playerColor = pairData.find(val=>val[0]===item.player_name)
          if(!playerColor){
            playerColor=["","black"]
          }
          if (!playerEntry) {
            playerEntry = { name: item.player_name, x: null, y: null, image: null, color:playerColor[1] };
            acc.push(playerEntry);
          }
      
          if (item.parameter === param_x) {
            const currentValueX = parseFloat(item.current_value);
            playerEntry.x = currentValueX;
            if (currentValueX > maxX) { // Update maxX if current value is greater
              maxX = currentValueX;
            }
            if (currentValueX < minX) {
              minX = currentValueX
            } // Update minX if current value is lower
          } else if (item.parameter === param_y) {
            const currentValueY = parseFloat(item.current_value);
            playerEntry.y = currentValueY;
            if (currentValueY > maxY) { // Update maxY if current value is greater
              maxY = currentValueY;
            }
            if (currentValueY<minY){
              minY = currentValueY
            }
          }
      
          return acc;
        }, []);
      
        return { transformedData, maxX, maxY, minX, minY };
      }
    
    function transformIndiData(data, param_x, param_y) {
      let maxX = -Infinity; // Initialize with the lowest possible number
      let maxY = -Infinity; // Initialize with the lowest possible number
      let minX = Infinity
      let minY = Infinity
      const transformedData = data.reduce((acc, item) => {
        let playerEntry = acc.find(entry => entry.name === item.player_name);
        if (!playerEntry) {
          playerEntry = { name: item.player_name, x: null, y: null};
          acc.push(playerEntry);
        }
        if (item.parameter === param_x) {
          const currentValueX = parseFloat(item.current_value);
          playerEntry.x = currentValueX;
          if (currentValueX > maxX) { // Update maxX if current value is greater
            maxX = currentValueX;
          }
          if (currentValueX < minX) {
            minX = currentValueX
          } // Update minX if current value is lower
        } else if (item.parameter === param_y) {
          const currentValueY = parseFloat(item.current_value);
          playerEntry.y = currentValueY;
          if (currentValueY > maxY) { // Update maxY if current value is greater
            maxY = currentValueY;
          }
          if (currentValueY<minY){
            minY = currentValueY
          }
        }
    
        return acc;
      }, []);
    
      return { transformedData, maxX, maxY, minX, minY };
    }


    const handleSelectOptions = async(option, paramdata = [])=>{
        setzselect(zdefault)
        if(option.value ==='Custom'){
          setshowCustom(true)
          return
        }else{
          setshowCustom(false)
          setxselected(false)
          setyselected(false)
          setxgraphdata([])
          setygraphdata([])
        }

        let arr = []
        if (paramdata.length>0){
            arr = paramdata.filter((val)=>val.parameter_type===option.value)
        }else{
            arr = paramtypedata.filter((val)=>val.parameter_type===option.value)
        }
        let industry_id =  ind_id
        if (!industry_id){
            industry_id = 10
        }
        setparamx(arr[0].x_parameter_name)
        setparamy(arr[0].y_parameter_name)
        let param_arr = [arr[0].xparameter, arr[0].yparameter]
        const postData = {
            "param_arr":param_arr,
            "industry_id":industry_id
          };
        const response = await axios.post(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/parametervalues/`, postData);
        let data = response.data
        let date = new Date(data[0].start_date);
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear().toString().slice(-2);
        let month_name = `${month}'${year}`;
        setmonth(month_name)
        const players = new Set();
        data.forEach(entry => players.add(entry.player_name));
        let uniq_players = Array.from(players);

        let color_arr = ['rgb(143,0,255)', 'rgb(0,0,255)', 'rgb(0, 255, 0)','rgb(255, 146, 51)', 'rgb(255, 251, 0)', 'rgb(255, 0, 0)']
        const generatedColors = color_arr.slice(0, uniq_players.length);
        while (generatedColors.length < uniq_players.length) {
            generatedColors.push(generateRandomColor());
        }
        const pairedArray = uniq_players.map((player, index) => [player, generatedColors[index]]);
        setpairedColors(pairedArray)

        const playerIds = new Set();
        data.forEach(entry => playerIds.add(entry.player));
        let player_arr = JSON.stringify(Array.from(playerIds));
 
        
        let graph_data = transformData(data,arr[0].xparameter,arr[0].yparameter, [], pairedArray)
        let adjustData = graph_data.transformedData.map(item => ({
          ...item,
          size: estimateTextSize(item.name, 10), // Add text size estimation
        }));


        adjustData= resolveOverlaps(adjustData, graph_data.maxX, graph_data.maxY, graph_data.minX, graph_data.minY);   
        
        let items = adjustData
        const minItemX = adjustData.reduce((min, item) => item.x < min.x ? item : min, items[0]);
        const minItemY = adjustData.reduce((min, item) => item.y < min.y ? item : min, items[0]);

        const maxItemX = adjustData.reduce((max, item) => item.x > max.x ? item : max, items[0]);
        const maxItemY = adjustData.reduce((max, item) => item.y > max.y ? item : max, items[0]);
        
        setgraphdata(adjustData)

        refxmax.current = maxItemX.x+0.2*maxItemX.x
        refymax.current = maxItemY.y+0.2*maxItemY.y
        let min_x = 0
        let min_y = 0

        if(0===minItemX.x){
          min_x = 0
        }else{
          min_x = minItemX.x-0.2*minItemX.x
        }

        if(0===minItemY.y){
          min_y = 0
        }else{
          min_y = minItemY.y-0.2*minItemY.y
        }
        refxmin.current = min_x
        refymin.current = min_y
        setGraphdataUpdated(true);
        
    }

    const handleSelectZOptions = async(option, paramdata = [])=>{
      setzselect(option)
      let arr = []
      if (paramdata.length>0){
          arr = paramdata.filter((val)=>val.parameter_type===option.value)
      }else{
          arr = zparamtypedata.filter((val)=>val.parameter_type===option.value)
      }

      let industry_id =  ind_id
      if (!industry_id){
          industry_id = 10
      }

      let param_arr = [arr[0].zparameter]
      const postData = {
          "param_arr":param_arr,
          "industry_id":industry_id
        };
      
      const response = await axios.post(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/parametervalues/`, postData);
      let data = response.data
      data.sort((a,b)=>(parseFloat(a.current_value)-parseFloat(b.current_value)))
      let minval = data[0].current_value
      let maxval = data[data.length-1].current_value
      let heightMap = {};
      let currentHeight = 10; // Starting height
      data.forEach((item,i) => {
        item.name = item.player_name;
        if(parseFloat(maxval/minval)<12 && 1<parseFloat(maxval/minval)){
          currentHeight = 10+(item.current_value/minval)
          setShowLog(false)
        }else{
          currentHeight = 10+Math.log(item.current_value/minval);
          setShowLog(true)
        }
        heightMap[item.player_name] = currentHeight;
      });
      let graph_data = graphdata
      let newGraphData = graphdata.map(item => {
        if (heightMap[item.name] !== undefined) {
          return {
            ...item,
            size: estimateTextSize(item.name, heightMap[item.name])
          };
        }
        return item;
      });
 
      let newadjustedGraphData = newGraphData
      newadjustedGraphData = resolveOverlaps(newGraphData); 
      // Update the state with the new graph data array
      setgraphdata(newadjustedGraphData);

    }

    const handleSelectOptionsX = async(option, paramdata = [])=>{
      let arr = []
      if (paramdata.length>0){
        arr = paramdata.filter((val)=>val.parameter_type===option.value)
    }else{
        arr = xparamtypedata.filter((val)=>val.parameter_type===option.value)
    }
    setparamx(arr[0].x_parameter_name)
    setparamxID(arr[0].xparameter)
    let industry_id =  ind_id
    if (!industry_id){
        industry_id = 10
    }

    let param_arr = [arr[0].xparameter]
    const postData = {
        "param_arr":param_arr,
        "industry_id":industry_id
      };
    const response = await axios.post(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/parametervalues/`, postData);
    let data = response.data
    setxgraphdata(data)
    if(data && ygraphdata.length>0){
      let wholedata = data.concat(ygraphdata)
      let graph_data = transformIndiData(wholedata,arr[0].xparameter, paramyID)
      let adjustData = graph_data.transformedData.map(item => ({
        ...item,
        size: estimateTextSize(item.name, 10), // Add text size estimation
      }));
      let adjustedGraphData = adjustData
      adjustedGraphData = resolveOverlaps(adjustData, graph_data.maxX, graph_data.maxY, graph_data.minX, graph_data.minY);        


      setgraphdata(adjustedGraphData)
      let items = adjustData
        const minItemX = adjustData.reduce((min, item) => item.x < min.x ? item : min, items[0]);
        const minItemY = adjustData.reduce((min, item) => item.y < min.y ? item : min, items[0]);

        const maxItemX = adjustData.reduce((max, item) => item.x > max.x ? item : max, items[0]);
        const maxItemY = adjustData.reduce((max, item) => item.y > max.y ? item : max, items[0]);
        refxmax.current = maxItemX.x+0.2*maxItemX.x
        refymax.current = maxItemY.y+0.2*maxItemY.y
        let min_x = 0
        let min_y = 0

        if(0===minItemX.x){
          min_x = 0
        }else{
          min_x = minItemX.x-0.2*minItemX.x
        }

        if(0===minItemY.y){
          min_y = 0
        }else{
          min_y = minItemY.y-0.2*minItemY.y
        }
      refxmin.current = min_x
      refymin.current = min_y
      setSelectedOptions(arr)
    }
    }


    const handleSelectOptionsY = async(option, paramdata = []) =>{
      let arr = []
      if (paramdata.length>0){
        arr = paramdata.filter((val)=>val.parameter_type===option.value)
    }else{
        arr = yparamtypedata.filter((val)=>val.parameter_type===option.value)
    }
      setparamy(arr[0].y_parameter_name)
      setparamyID(arr[0].yparameter)
      if (paramdata.length>0){
        arr = paramdata.filter((val)=>val.parameter_type===option.value)
    }else{
        arr = yparamtypedata.filter((val)=>val.parameter_type===option.value)
    }

    let industry_id =  ind_id
    if (!industry_id){
        industry_id = 10
    }

    let param_arr = [arr[0].yparameter]
    const postData = {
        "param_arr":param_arr,
        "industry_id":industry_id
      };
    const response = await axios.post(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/parametervalues/`, postData);
    let data = response.data
    setygraphdata(data)
    if(data && xgraphdata.length>0){
      let wholedata = data.concat(xgraphdata)
      let graph_data = transformIndiData(wholedata,paramxID, arr[0].yparameter)
      let adjustData = graph_data.transformedData.map(item => ({
        ...item,
        size: estimateTextSize(item.name, 10), // Add text size estimation
      }));
      let adjustedGraphData = adjustData;
      adjustedGraphData = resolveOverlaps(adjustData, graph_data.maxX, graph_data.maxY, graph_data.minX, graph_data.minY);        


      setgraphdata(adjustedGraphData)

        let items = adjustData
        const minItemX = adjustData.reduce((min, item) => item.x < min.x ? item : min, items[0]);
        const minItemY = adjustData.reduce((min, item) => item.y < min.y ? item : min, items[0]);

        const maxItemX = adjustData.reduce((max, item) => item.x > max.x ? item : max, items[0]);
        const maxItemY = adjustData.reduce((max, item) => item.y > max.y ? item : max, items[0]);
      
        refxmax.current = maxItemX.x+0.2*maxItemX.x
        refymax.current = maxItemY.y+0.2*maxItemY.y
        let min_x = 0
        let min_y = 0

        if(0===minItemX.x){
          min_x = 0
        }else{
          min_x = minItemX.x-0.2*minItemX.x
        }

        if(0===minItemY.y){
          min_y = 0
        }else{
          min_y = minItemY.y-0.2*minItemY.y
        }

      refxmin.current = min_x
      refymin.current = min_y
      setGraphdataUpdated(true)
      setSelectedOptions(arr)
    }
    }
      

    const generateRandomColor = () => {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);
      return `rgb(${red}, ${green}, ${blue})`;
    };


    return(
          <Outerdiv>
              <Selectdiv>
              <Monthdiv>For the month of {month}</Monthdiv>
              
                <div style={{display:'flex', alignItems:'center'}}>
                  <FirstSelect>Plot by : </FirstSelect>
                      {defaultValue && <Select
                      name="columns"
                      options={dropdndata}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectOptions}
                      defaultValue={defaultValue}
                            />}
                </div>

                  <div  style={{display:'flex', alignItems:'center'}}>
                    <FirstSelect>Text Size :</FirstSelect>
                      {defaultValue && zdefault && <Select
                      name="columns"
                      options={zdropdndata}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectZOptions}
                      defaultValue={zdefault}
                      value = {zselect}
                            />}
                  </div>
              </Selectdiv>
              
              {showCustom &&
              <Customdiv>
                <CustomSelectdiv>
             <Select
                    name="columns"
                    options={xdropdndata}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleSelectOptionsX}
                          />
                          </CustomSelectdiv>
              <span  style={{'lineHeight':'38px',  marginLeft:'40px',flexDirection:"column",display:"flex",alignContent:"center",alignItems:"center"}}>vs</span>
              {<CustomSelectdiv>
              <Select
                      name="columns"
                      options={ydropdndata}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectOptionsY}
                      isDisabled = {xgraphdata.length>0?false:true}
                            />
              </CustomSelectdiv>}
              </Customdiv>}
              <ScatterChartWrapperdiv>
                  <ScatterChart width={chartWidth} height={600}   margin={{ top: 20, right: 50, bottom: 30, left: 20 }} ref={chartRef}>
                          <ReferenceLine x={(refxmin.current+refxmax.current)/2} stroke="black" strokeDasharray="3 3" />
                          <ReferenceLine y={(refymin.current+refymax.current)/2} stroke="black" strokeDasharray="3 3" />
                          <XAxis dataKey="x" padding={{ left: 100, right: 20 }} type="number" domain={[refxmin.current, refxmax.current]} allowDataOverflow={true}  label={{ value: paramx, position: 'insideBottom',offset:-10 }} ticks={[parseFloat(refxmin.current).toFixed(2), parseFloat(refxmax.current).toFixed(2)]}/>
                          <YAxis dataKey="y" padding={{ top: 20, bottom: 20 }} type="number" domain={[refymin.current, refymax.current]} allowDataOverflow={true} label={{ value: paramy, angle: -90, position: 'inside' }} ticks = {[parseFloat(refymin.current).toFixed(2), parseFloat(refymax.current).toFixed(2)]}/>
                          <Scatter data={graphdata}  shape={renderCustomShape}/>
                  </ScatterChart>
              </ScatterChartWrapperdiv>
              {showLog?<Footerdiv>Note : Logarithmic Scale utilised for Optimal Text Size Representation</Footerdiv>:null}
          </Outerdiv>
    )
};

export default CustomScatterChart;

const Selectdiv = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:2px;
    margin-right:20px;
    ${media.small`
          margin-top:1px;

      flex-direction:column;
         
      `}
`
const Customdiv = styled.div`
  display:flex;
  justify-content:center;
  margin-top:10px;
  ${media.small`
      margin-top:5px;
flex-direction:column;  
    `}
`

const ScatterChartWrapperdiv = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:white;
`

const LegendDiv = styled.div`
  margin-left:70px;
`

const Outerdiv=styled.div`
margin:3.5vw;
margin-top:15px;
padding-top:10px;
background:white;
box-shadow: 2px 2px 4px 0px #00000040;

${media.small`
margin-top:8px;
padding-top:5px;
  `}
`

const Monthdiv=styled.div`

margin-left:15px;
${media.small`
font-size:15px;
  
  `}
`
const FirstSelect=styled.span`
line-height:38px;
 margin-right:20px;
margin-left:20px;
${media.small`
  margin-left:15px;
font-size:15px;
  `}
`

const CustomSelectdiv=styled.div`
display:flex ;
margin-left:20px;
flex-direction:column;
${media.small`
    align-items:center;
margin-left:0px;
  `}
`

const Footerdiv=styled.div`
margin-left:15px;
padding-bottom:10px;
${media.small`
  font-size:10.5px;
  
  `}
`
import React from 'react'
import Header from '../components/Header'
import styled from 'styled-components'
import { BsEmojiLaughingFill} from 'react-icons/bs'
import { BiChair, BiBookContent } from 'react-icons/bi'
import { useState } from 'react'
import { AiOutlineShoppingCart, AiFillMedicineBox, AiFillVideoCamera, AiOutlineMobile } from 'react-icons/ai'
import { MdDeliveryDining, MdOutlineMonitor, MdTrolley } from 'react-icons/md'
import { BsSunFill, BsHeadphones, BsInstagram } from 'react-icons/bs'
import { GiClothes, GiCarWheel, GiLipstick } from 'react-icons/gi'
import { FaBaby, FaCarAlt, FaTruck } from 'react-icons/fa'
import { IoFastFood } from "react-icons/io5";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import {GiHealthNormal} from 'react-icons/gi'





const Industries = () => {

    const [sectorData, setSectorData] = useState([
        ['Fashion', <GiClothes/> ],
        ['Home and Furniture',<BiChair/>],
        ['BabyCare',<FaBaby/>],
        ['Pharma',<AiFillMedicineBox/>],
        ['Horizontals',<MdTrolley/>],
        ['Electronics',<AiOutlineMobile/>],
        ['Beauty and Personal care',<GiLipstick/>],
        ['Quick Commerce',<AiOutlineShoppingCart/>],
        ['Slotted Delivery',<MdDeliveryDining/>],
        ['Next Day Morning',<BsSunFill/>],
        ['eLogistics', <FaTruck/>],


])

    const[activeLink, setActiveLink] = useState('onlineretail')

    const alldata = [
        ['Quick Commerce',<AiOutlineShoppingCart/>],
        ['Slotted Delivery',<MdDeliveryDining/>],
        ['Next Day Morning',<BsSunFill/>],
        ['Audio Streaming',<BsHeadphones/>],
        ['Video Streaming',<MdOutlineMonitor/>],
        ['Social Media',<BsInstagram/>],
        ['ShortForm Video',<AiFillVideoCamera/>],
        ['Fashion', <GiClothes/> ],
        ['Home and Furniture',<BiChair/>],
        ['BabyCare',<FaBaby/>],
        ['Pharma',<AiFillMedicineBox/>]
    ]
    
    const grocerydata = [
        ['Food Delivery', <IoFastFood/>],
        ['Ride Hailing', <GiCarWheel/>],
        ['Online Education',<BiBookContent/>],
        ['E Health',<GiHealthNormal/>],
        ['Used Cars', <FaCarAlt/>],
    ]
    
    const digitaldata  =[
        ['Audio Streaming',<BsHeadphones/>],
        ['Video Streaming',<MdOutlineMonitor/>],
        ['Social Media',<BsInstagram/>],
        ['ShortForm Video',<AiFillVideoCamera/>],
        ['Real Money Gaming', <RiMoneyDollarBoxFill/>],
    ]

    const onlineretail = [
        ['Fashion', <GiClothes/> ],
        ['Home and Furniture',<BiChair/>],
        ['BabyCare',<FaBaby/>],
        ['Pharma',<AiFillMedicineBox/>],
        ['Horizontals',<MdTrolley/>],
        ['Electronics',<AiOutlineMobile/>],
        ['Beauty and Personal care',<GiLipstick/>],
        ['Quick Commerce',<AiOutlineShoppingCart/>],
        ['Slotted Delivery',<MdDeliveryDining/>],
        ['Next Day Morning',<BsSunFill/>],
        ['eLogistics', <FaTruck/>],

    ]

    const otherdata = [
        ['Insurance Tech', <MdOutlineMonitor/>],
        ['B2B Payments', <RiMoneyDollarBoxFill/>],
        ['Digtial Lending', <AiOutlineMobile/>],
        ['B2C Payment', <RiMoneyDollarBoxFill/>],
    ]

    const handleClick = (val, valString)=>{
        setSectorData(val)
        setActiveLink(valString)
    }

  return (
    <div>
        <Header/>
        <Titlediv>
        <span style={{color:'Red'}}>Sectors  </span> we cover
        </Titlediv>
        <div style={{textAlign:'center'}}>150+ KPIs of 20+ Internet Focussed Industries in India</div>
        <IndustryCategorydiv></IndustryCategorydiv>
        <Tabsdiv>
            <TabsInnerdiv  style={{ backgroundColor: activeLink === 'onlineretail' ? '#0099ff' : '#F2F2F2', color:activeLink === 'onlineretail' ? 'white':'black' }} onClick={()=>(handleClick(onlineretail, 'onlineretail'))}>Online Retail</TabsInnerdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'digitaldata' ? '#0099ff' : '#F2F2F2', color:activeLink === 'digitaldata' ? 'white':'black' }}  onClick={()=>(handleClick(digitaldata, 'digitaldata'))}>Media, Entertainment & Leisure</TabsInnerdiv>
            <TabsInnerdiv  style={{ backgroundColor: activeLink === 'grocerydata' ? '#0099ff' : '#F2F2F2', color:activeLink === 'grocerydata' ? 'white':'black' }} onClick={()=>(handleClick(grocerydata, 'grocerydata'))}>Online Services</TabsInnerdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'otherdata' ? '#0099ff' : '#F2F2F2', color:activeLink === 'otherdata' ? 'white':'black' }} onClick={()=>(handleClick(otherdata, 'otherdata'))}>Fintech and B2B</TabsInnerdiv>
        </Tabsdiv>
        <IndustryGriddiv>
            {sectorData.map((val, idx)=>(
                <IndustryGridInnerdiv key={idx}>
                    {val[1]}{val[0]}
                </IndustryGridInnerdiv>
            ))}
        </IndustryGriddiv>

    </div>
  )
}

export default Industries

const Titlediv = styled.div`
    font-size:33px;
    font-weight:600;
    text-align:center;
    padding:20px;
    @media(min-width: 768px){
        padding:30px;
        font-size:60px;
    }
`

const IndustryCategorydiv = styled.div`
    font-size:25px;
    font-weight:500;
    text-align:center;
    padding:20px;
    @media(min-width: 768px){
        padding:30px;
        font-size:32px;
    }
`

const Tabsdiv = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    gap:20px;
    line-height:45px;
    flex-wrap:wrap;
    /* height:20px; */
    /* background-color:lightgray; */
`


const TabsInnerdiv = styled.div`
    background-color:#F2F2F2;
    border-radius:10px;
    min-width:150px;
    padding:0px 10px;
    text-align:center;
    &:hover{
        cursor:pointer;
    }
`

const IndustryGriddiv = styled.div`
    padding:50px;
    display: grid; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 01fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 30px 20px; 
  grid-template-areas: 
    ". . . . . ."
    ". . . . . ."; 
`

const IndustryGridInnerdiv = styled.div`
    min-width:100px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-flow:column;
    gap:10px;
    padding: 20px;
    border-radius:10px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
`
import React from "react";
import styled from "styled-components";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

function Pagination({ currentPage, totalPage, setCurrentPage, width = 500 }) {
  return (
    <Wrapper width={width}>
      <ResponsivePagination
        current={currentPage}
        total={totalPage}
        onPageChange={setCurrentPage}
      />
    </Wrapper>
  );
}

export default Pagination;

const Wrapper = styled.div`
  width: ${(props) => props.width}px;
`;

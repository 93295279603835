import React, { useState, useEffect, useRef } from "react";
import TooltipLite from "react-tooltip-lite";
import "./index.css";
import styled from "styled-components";

const ToolTip = ({ value, toolTip, toolTipDirection = "right", gap= -10 }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const valueRef = useRef(null);

  const handleMoveOutside = (event) => {
    if (
      isTooltipVisible &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      valueRef.current &&
      !valueRef.current.contains(event.target)
    ) {
      setTooltipVisible(false);
    }
  };

  const handleMoveInside = (event) => {
    if (
      !isTooltipVisible &&
      valueRef.current &&
      valueRef.current.contains(event.target)
    ) {
      setTooltipVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMoveOutside);
    document.addEventListener("mousemove", handleMoveInside);
    return () => {
      document.removeEventListener("mousemove", handleMoveOutside);
      document.removeEventListener("mousemove", handleMoveInside);
    };
  }, [isTooltipVisible]);

  const shouldShowTooltip = toolTip !== null;
  return (
    <Wrapper>
      <TooltipLite
        content={
          <PopupWrap ref={isTooltipVisible ? tooltipRef : null}>
            {toolTip}
          </PopupWrap>
        }
        isOpen={isTooltipVisible && shouldShowTooltip}
        direction={toolTipDirection}
        padding={3}
        arrowSize={6}
        distance={gap}
      >
        <div ref={valueRef}>{value}</div>
      </TooltipLite>
    </Wrapper>
  );
};

export default ToolTip;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const PopupWrap = styled.div`
  padding: 8px;
  background-color: #ffffff;
  z-index: 5;
  box-shadow: 2px 2px 4px 2px #00000040;
  border: 0.75px solid #ededed;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #262e40;
  cursor: default;
`;

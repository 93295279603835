import React from "react";
import styled from "styled-components";
import { ScatterChart, Scatter, XAxis, YAxis, ReferenceLine } from "recharts";

export const ImageGraph = ({
  xmax = 100,
  ymax = 100,
  paramx = "",
  paramy = "",
  graphdata = [],
}) => {
  return (
    <GraphWrapper>
      <ScatterChart
        width={1000}
        height={600}
        margin={{ top: 20, right: 50, bottom: 30, left: 20 }}
      >
        <ReferenceLine
          x={(xmax + 0.2 * xmax) / 2}
          stroke="black"
          strokeDasharray="3 3"
        />
        <ReferenceLine
          y={(ymax + 0.2 * ymax) / 2}
          stroke="black"
          strokeDasharray="3 3"
        />
        <XAxis
          dataKey="x"
          type="number"
          domain={[0, Math.round((xmax + 0.2 * xmax) * 100) / 100]}
          allowDataOverflow={true}
          label={{ value: paramx, position: "insideBottom", offset: -10 }}
        />
        <YAxis
          dataKey="y"
          type="number"
          domain={[0, Math.round((ymax + 0.2 * ymax) * 100) / 100]}
          allowDataOverflow={true}
          label={{ value: paramy, angle: -90, position: "insideLeft" }}
        />
        <Scatter data={graphdata} shape={renderCustomShape} />
      </ScatterChart>
    </GraphWrapper>
  );
};

export default ImageGraph;

const renderCustomShape = ({ cx, cy, payload }) => {
  const imgSrc = payload.image;
  return (
    <image
      x={cx - 10}
      y={cy - 10}
      width="80px"
      height="80px"
      xlinkHref={imgSrc}
    />
  );
};

const GraphWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";
import ArrowIcon from "../../svg/ArrowIcon";
import { MEDIA_URL_ENDPOINT } from "../../../constants/constants";
import { media } from "../../../utils/media";

const Article = ({
  image_url_id,
  industry,
  date_created,
  docTypeName,
  title,
  description,
  onArticleClick,
}) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    axios
      .get(`${MEDIA_URL_ENDPOINT}/${image_url_id}`)
      .then((response) => response.data)
      .then((attachmentRes) => {
        setImageData(attachmentRes);
      })
      .catch((error) => {
        console.error("Error in fetching imageData -", error);
      });
  }, []);

  return (
    <ArticleContainer>
      <ArticleBody>
        <ImageContainer>
          <ImageBox src={imageData?.source_url || ""} />
        </ImageContainer>
        <MetaRow>
          <Text>{industry}</Text>
          <Text>
            {docTypeName} .{date_created}
          </Text>
        </MetaRow>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Descrrption
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <NavigationRow onClick={onArticleClick}>
          <LinkText>Read {docTypeName}</LinkText>
          <LinkIcon>
            <ArrowIcon />
          </LinkIcon>
        </NavigationRow>
      </ArticleBody>
    </ArticleContainer>
  );
};

export default Article;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: transparent;
  width: 100%;
${media.small`
   padding: 10px;
  
  `}
`;

const ArticleBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  ${media.small`
    padding: 10px;
     border-radius: 2px;

   `}
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 54%;
  position: relative;
`;

const ImageBox = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MetaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Text = styled.div`
  color: #444444;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0px;
  ${media.small`
      font-size: 10px;
  padding: 8px 0px;
    
    `}
`;

const Title = styled.div`
  color: #1f1f1f;
  min-height: 52px;
  max-height: 52px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 2px 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
   ${media.small`
      min-height: 35px;
  max-height: 35px;
  font-size: 14px;
  padding: 1px 0px;
    `}
`;

const Descrrption = styled.div`
  color: #444;
  min-height: 28px;
  max-height: 28px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 14px 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
   ${media.small`
    min-height: 28px;
  max-height: 28px;
  font-size: 12px;
  line-height: normal;
  margin: 14px 0px;
    `}
`;

const NavigationRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LinkText = styled.div`
  color: #0099ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
   ${media.small`
    font-size: 12px;
  line-height: 18px;

    `}
`;

const LinkIcon = styled.div`
  color: #0099ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
   ${media.small`
    font-size: 10px;
  line-height: 18px;

    `}
`;

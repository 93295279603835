import React from "react";
import styled from "styled-components";

const NavigationIcon = ({
  width = 48,
  height = 48,
  color = "#757575",
  rotate = 0,
}) => {
  return (
    <IconWrapper rotate={rotate}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M17.8504 23.9983C17.8504 23.7983 17.8837 23.615 17.9504 23.4483C18.017 23.2817 18.1337 23.115 18.3004 22.9483L28.2004 13.0483C28.467 12.7817 28.8254 12.6567 29.2754 12.6733C29.7254 12.69 30.0837 12.8317 30.3504 13.0983C30.6837 13.4317 30.8254 13.79 30.7754 14.1733C30.7254 14.5567 30.567 14.8983 30.3004 15.1983L21.5004 23.9983L30.3004 32.7983C30.567 33.065 30.717 33.4233 30.7504 33.8733C30.7837 34.3233 30.6337 34.6817 30.3004 34.9483C30.0337 35.2817 29.6837 35.4233 29.2504 35.3733C28.817 35.3233 28.4504 35.165 28.1504 34.8983L18.3004 25.0483C18.1337 24.8817 18.017 24.715 17.9504 24.5483C17.8837 24.3817 17.8504 24.1983 17.8504 23.9983Z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default NavigationIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
`;

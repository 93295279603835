import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { openDB } from 'idb';
import { saveToDb, getFromDb } from './idb'; 

const counterSlice = createSlice({
  name: 'counter',
  initialState: 0,
  reducers: {
    increment: state => state + 1,
    decrement: state => state - 1
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action) => action.payload,
    logoutUser: () => null
  }
});

export const newreportaccessSlice = createSlice({
  name:'newreportaccessdata',
  initialState:{
    value:[]
  },
  reducers:{
    setNewReportAccessData:(state,action)=>{
      state.value = action.payload
    }
}})

export const fetchData = createAsyncThunk('feature/fetchData', async () => {
  // Fetch the data (mock example here)
  const response = await fetch('https://api.example.com/data');
  const data = await response.json();
  return data;
});

export const fetchapiData = createAsyncThunk(
  'feature/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://api.example.com/data');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      // Save to IndexedDB upon successful fetch
      await saveToDb('feature', data);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const featureSlice = createSlice({
  name: 'feature',
  initialState: { data: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { increment, decrement } = counterSlice.actions;
export const { setUser, logoutUser } = userSlice.actions;

export const {setNewReportAccessData} = newreportaccessSlice.actions;
export const selectnewreportaccessData = (state) =>state.newreportaccessdata.value
export const newReportAccessReducer = newreportaccessSlice.reducer;


export const counterReducer = counterSlice.reducer;
export const userReducer = userSlice.reducer;
export const featureReducer = featureSlice.reducer;


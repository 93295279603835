import React, { useState } from 'react';

import Select from 'react-select';
 
const ExcelLikeTable = ({ data }) => {

  const [filteredData, setFilteredData] = useState(data);
 
  const handleFilterChange = (column, selectedOptions) => {

    const selectedValues = selectedOptions.map(option => option.value);

    const filtered = data.filter(row => selectedValues.includes(row[column]));

    setFilteredData(filtered);

  };
 
  const columnOptions = (column) => {

    const uniqueValues = [...new Set(data.map(row => row[column]))];

    return uniqueValues.map(value => ({ value, label: value }));

  };
 
  return (

    <div>

      <table>

        <thead>

          <tr>

            {Object.keys(data[0]).map(column => (

              <th key={column}>

                {column}

                <Select

                  isMulti

                  options={columnOptions(column)}

                  onChange={(selectedOptions) => handleFilterChange(column, selectedOptions)}

                />

              </th>

            ))}

          </tr>

        </thead>

        <tbody>

          {filteredData.map((row, index) => (

            <tr key={index}>

              {Object.values(row).map((cell, index) => (

                <td key={index}>{cell}</td>

              ))}

            </tr>

          ))}

        </tbody>

      </table>

    </div>

  );

};
 
export default ExcelLikeTable;

import React from "react";
import styled from "styled-components";
import NivoLineGraph from "../../../NivoLineGraph";
import NivoRibbonGraph from "../../../NivoRibbonGraph";
import NivoHundred from "../../../NivoHundred";
import NivoBarGraph from "../../../NivoBarGraph";
import { media } from "../../../../utils/media";
import { IMAGES } from "../../../../constants/constants";

const CombinedGraph = ({
  data,
  graphType,
  showlegend,
  topOption,
  allOption,
  legendOther,
  legendFilter,
  scaleValue,
  scaleLabel,
  formatCode = "hi",
  frequency = "Y",
  is_percent = false,
  currency_unit,
  includeOverall,
  dataType,
}) => {
  return data.length && graphType ? (
    <>
      <GraphDesktop>
        <NivoLineGraph
          visible={graphType === "line_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        <NivoBarGraph
          visible={graphType === "bar_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        <NivoRibbonGraph
          visible={graphType === "ribbon_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        <NivoHundred
          visible={graphType === "hundred_ribbon_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 7.5 : 0}
          fontSize={11}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        {legendFilter && <LegendHeading>{legendFilter.name}</LegendHeading>}
      </GraphDesktop>
      <GraphMobile>
        <NivoLineGraph
          visible={graphType === "line_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        <NivoBarGraph
          visible={graphType === "bar_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        <NivoRibbonGraph
          visible={graphType === "ribbon_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        <NivoHundred
          visible={graphType === "hundred_ribbon_graph"}
          data={data}
          showlegend={showlegend}
          showTotal={includeOverall && (dataType !== "Absolute" || !is_percent)}
          topOption={topOption}
          allOption={allOption}
          legendOther={legendOther}
          legendPadding={legendFilter ? legendFilter.name.length * 4 : 0}
          tickRotation={90}
          marginTop={70}
          marginRight={15}
          marginBottom={70}
          marginLeft={15}
          fontSize={7}
          legendDirection="column"
          legendX={30}
          bigSize={8}
          smallSize={3}
          scaleValue={scaleValue}
          scaleLabel={scaleLabel}
          formatCode={formatCode}
          frequency={frequency}
          is_percent={is_percent}
          currency_unit={currency_unit}
          thousandScaling={true}
        />
        {legendFilter && <LegendHeading>{legendFilter.name}</LegendHeading>}
      </GraphMobile>
    </>
  ) : (
    <NoDataMessage>
      <TechIssueIcon src={IMAGES.technical_issue} />
    </NoDataMessage>
  );
};

export default CombinedGraph;

const GraphDesktop = styled.div`
  width: 100%;
  height: 250px;
  min-height: 25vw;
  position: relative;
  ${media.small`
    display: none;
  `}
`;

const GraphMobile = styled.div`
  width: 100%;
  height: 300px;
  min-height: 25vw;
  position: relative;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;  
  `}
  ${media.xlarge`
    display: none;  
  `}
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;

const LegendHeading = styled.div`
  font-size: 13px;
  font-weight: 500;
  height: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  ${media.small`
    font-size: 8px;
    height: 8px;
  `}
`;

const TechIssueIcon = styled.img`
  width: 300px;
  height: 250px;
`;

import React from "react";
import styled from "styled-components";

const WarningIcon = ({ width = 11, height = 10, color = "#E93735" }) => {
  return (
    <IconWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 11 10"
        fill="none"
      >
        <path
          d="M0.219183 7.13001L4.382 0.645288C4.95561 -0.248264 6.29633 -0.205672 6.80993 0.72242L10.3986 7.20714C10.9084 8.12844 10.2293 9.25 9.16158 9.25H1.41013C0.301879 9.25 -0.37185 8.05071 0.219183 7.13001Z"
          fill={`${color}`}
        />
        <path
          d="M6.02657 2.20312L5.90764 5.7472H5.05929L4.93243 2.20312H6.02657ZM5.48743 6.51627C5.66714 6.51627 5.81779 6.5797 5.93936 6.70655C6.06621 6.82812 6.12964 6.97877 6.12964 7.15848C6.12964 7.33291 6.06621 7.48355 5.93936 7.61041C5.81779 7.73727 5.66714 7.8007 5.48743 7.8007C5.30771 7.8007 5.15443 7.73727 5.02757 7.61041C4.906 7.48355 4.84521 7.33291 4.84521 7.15848C4.84521 6.97877 4.906 6.82812 5.02757 6.70655C5.15443 6.5797 5.30771 6.51627 5.48743 6.51627Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
};

export default WarningIcon;

const IconWrapper = styled.div`
  display: flex;
`;

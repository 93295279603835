import React from "react";
import styled from "styled-components";

const AdminConsoleIcon = ({
  width,
  height,
  color = "#20a6ff",
  rotate = 0,
  onIconClick = () => null,
  className,
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      rotate={rotate}
      onClick={onIconClick}
      className={className}
    >
      <svg viewBox="0 0 1024 1024" width={`100%`} height={`100%`} version="1.1">
        <path
          d="M866.9 98.2H157c-33 0-60.1 27-60.1 60.1v486c0 33 27 60.1 60.1 60.1h324.9s0.1 0 0.1 0.1v131.1s0 0.1-0.1 0.1H192c-16.4 0-30.2 12.8-30.7 29.2-0.4 16.9 13.2 30.8 30 30.8H832c16.4 0 30.2-12.8 30.7-29.2 0.4-16.9-13.2-30.8-30-30.8H542s-0.1 0-0.1-0.1V704.3s0-0.1 0.1-0.1h324.9c33 0 60.1-27 60.1-60.1v-486c0-32.9-27.1-59.9-60.1-59.9z m0.1 545.9l-0.2 0.2H157.1l-0.2-0.2V158.4l0.2-0.2h709.7l0.2 0.2v485.7z"
          fill={`${color}`}
        />
        <path
          d="M281.8 230.2h-31.6c-11 0-20 9-20 20v31.6c0 11 9 20 20 20h31.6c11 0 20-9 20-20v-31.6c0-11-9-20-20-20zM372.8 230.2h-31.6c-11 0-20 9-20 20v31.6c0 11 9 20 20 20h31.6c11 0 20-9 20-20v-31.6c0-11-9-20-20-20zM463.9 230.2h-31.6c-11 0-20 9-20 20v31.6c0 11 9 20 20 20h31.6c11 0 20-9 20-20v-31.6c0-11-9-20-20-20z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default AdminConsoleIcon;

const IconWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : 18)}px;
  width: ${(props) => (props.width ? props.width : 18)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  }
`;

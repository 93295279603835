import { ResponsiveAreaBump } from "@nivo/bump";
import { ResponsiveStream } from "@nivo/stream";
import { ResponsiveLine } from "@nivo/line";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
const myColors = [
  "#0099FF",
  "#A962FF", // repeat3
  "#26CDCD", // repeat2
  "#9FC51E", // repeat1
  "#FF832B",
  "#FCBB53",
  "#21AF4A",
  "#66C2FF",
  "#4673FF",
  "#F28D8C",
  "#7D7DE8",
  "#26CDCD", // repeat2
  "#9FC51E", // repeat1
  "#A962FF", // repeat3
  "#F58CBD",
];

const NivoRibbonGraph = ({
  visible = true,
  data,
  showlegend = false,
  showTotal = false,
  topOption = [],
  allOption = [],
  legendPadding = 50,
  fontSize = 9,
  tickRotation = 0,
  marginTop = 50,
  marginRight = 50,
  marginBottom = 50,
  marginLeft = 50,
  legendDirection = "row",
  legendX = 20,
  bigSize = 15,
  smallSize = 5,
  scaleValue = 1,
  scaleLabel = null,
  formatCode = "hi",
  frequency = "M",
  is_percent = false,
  currency_unit,
  thousandScaling = false,
}) => {
  const [dataLimit, setDataLimit] = useState({
    M: 13,
    Q: 8,
    Y: 6,
    FY: 6,
  });
  const [visibility, setVisibility] = useState(true);
  const [transformedData, setTransformedData] = useState([]);
  const [transformedData2, setTransformedData2] = useState([]);

  const formatDate = (dataDate, frequency,maxDate) => {
    const dataDateObj = moment(dataDate, "YYYY-MM-DD");
    const dataDateObj2 = dataDateObj.clone().add(1, "year");

    const currentMoment = moment();
    const currentQuarter = Math.floor((maxDate.getMonth() + 3) / 3);
    const currentYear = currentMoment.year();
    const currentFinancialYear =
      currentMoment.month() >= 3 ? currentYear : currentYear - 1;

    if (frequency === "M") {
      return dataDateObj.format("MMM'YY");
    } else if (frequency === "Q") {
      const quarter = Math.floor((dataDateObj.month() + 3) / 3);
      let result = "";
      switch (quarter) {
        case 1:
          result = `JFM'${dataDateObj.format("YY")}`;
          break;
        case 2:
          result = `AMJ'${dataDateObj.format("YY")}`;
          break;
        case 3:
          result = `JAS'${dataDateObj.format("YY")}`;
          break;
        case 4:
          result = `OND'${dataDateObj.format("YY")}`;
          break;
        default:
          result = "Invalid Date";
      }
      if (quarter === currentQuarter && dataDateObj.year() === currentYear) {
        result += "(QTD)";
      }
      return result;
    } else if (frequency === "Y") {
      let result = `CY${dataDateObj.format("YY")}`;
      if (dataDateObj.year() === currentYear) {
        result += "(YTD)";
      }
      return result;
    } else if (frequency === "FY") {
      let result = `FY${dataDateObj2.format("YY")}`;
      if (
        (dataDateObj.month() >= 3 &&
          dataDateObj.year() === currentFinancialYear) ||
        (dataDateObj.month() < 3 &&
          dataDateObj.year() === currentFinancialYear + 1)
      ) {
        result += "(YTD)";
      }
      return result;
    } else {
      return "Invalid Frequency";
    }
  };

  useEffect(() => {
    let allDates = Array.from(
      new Set(data.flatMap((item) => item.data.map((point) => point.date)))
    ).sort();
    allDates = allDates.slice(-dataLimit[frequency]);

    let maxDate = new Date(
      Math.max(
        ...data.flatMap((item) => item.data.map((point) => new Date(point.date)))
      )
    );
    const transformed = data.map((item) => ({
      id: item.label,
      data:
        (topOption.length === 0 || topOption.includes(item.label)) &&
        item.label !== "Overall"
          ? allDates.map((date) => {
              const point = item.data.find((d) => d.date === date);
              return {
                x: formatDate(date, frequency,maxDate),
                y: point ? point.value : 0,
              };
            })
          : [],
    }));
    const transformed2 = data.map((item) => ({
      id: item.label,
      data: allDates.map((date) => {
        const point = item.data.find((d) => d.date === date);
        return {
          x: formatDate(date, frequency,maxDate),
          y: point ? point.value : null,
        };
      }),
    }));
    setTransformedData(transformed);
    setTransformedData2(transformed2);
    setVisibility(visible);
  }, [data, dataLimit]);

  const CustomTooltip = ({ slice }) => {
    const reversedData = [...slice.points].reverse();
    const topData = reversedData.filter(
      (obj) =>
        (topOption.length === 0 || topOption.includes(obj.serieId)) &&
        obj.serieId !== "Overall"
    );
    const totalValue = topData.reduce(
      (sum, dataPoint) => sum + dataPoint.data.y,
      0
    );
    const totalValueList = reversedData.filter(
      (obj) => obj.serieId === "Overall"
    );

    const otherData = reversedData.filter(
      (obj) => !topData.includes(obj) && obj.serieId !== "Overall"
    );
    return (
      <TootTipWrapper fontSize={fontSize}>
        {showTotal && totalValueList.length && (
          <TootTipRow>
            <TootTipLabel bold={true}>
              <SquareBox size={bigSize} color={"#cccccc"} bold={true} />
              Overall
            </TootTipLabel>
            <TootTipValue bold={true}>
              {/* {Number(totalValueList[0].data.yFormatted.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} */}
              {totalValueList[0].data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)} bold={true}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipRow>
        )}
        {topData.map((point, idx) => (
          <TootTipRow key={idx}>
            <TootTipLabel>
              <SquareBox size={bigSize} color={point.serieColor} />
              {point.serieId}
            </TootTipLabel>
            <TootTipValue>
              {point.data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipRow>
        ))}
        {otherData.map((point, idx) => (
          <TootTipOtherRow key={idx} margin={bigSize}>
            <TootTipLabel>- {point.serieId}</TootTipLabel>
            <TootTipValue>
              {point.data.yFormatted}
              {is_percent ? "%" : ""}
              <TootTipValueUnit fontSize={Number(fontSize * 0.8)}>
                {!is_percent ? currency_unit : ""}
              </TootTipValueUnit>
            </TootTipValue>
          </TootTipOtherRow>
        ))}
      </TootTipWrapper>
    );
  };

  const LabelLayer = ({ series }) => {
    let maxY = -10000;
    let decimalCount = 2;
    let cordinates = {};
    series.forEach(({ id, data, color, points }) => {
      data.data.forEach((point, idx) => {
        let newArry = [];
        if (cordinates[points[idx].x]) {
          newArry = cordinates[points[idx].x];
        }
        if (point.y > maxY) {
          maxY = point.y;
        }
        newArry.push(points[idx].y);
        cordinates[points[idx].x] = newArry.sort((a, b) => b - a);
      });
    });

    if (maxY > 100) {
      decimalCount = 1;
    }
    if (maxY > 1000) {
      decimalCount = 0;
    }

    let validCordinated = {};
    Object.keys(cordinates).forEach((x) => {
      let yCords = [];
      cordinates[x].forEach((y) => {
        if (yCords.length === 0 || yCords[yCords.length - 1] - y >= fontSize) {
          yCords.push(y);
        }
        if (yCords.length) {
        }
      });
      validCordinated[x] = yCords;
    });

    return (
      <g>
        {series.map(({ id, data, color, points }) => {
          return data.data.map((point, idx) => {
            let legendVisible = false;
            if (
              validCordinated[points[idx].x]?.filter(
                (yCord) => yCord === points[idx].y
              ).length &&
              Number(point.y.toFixed(decimalCount))
            ) {
              validCordinated[points[idx].x] = validCordinated[
                points[idx].x
              ].filter((yCord) => yCord !== points[idx].y);
              legendVisible = true;
            }
            const scaleThousand = thousandScaling && point.y > 1000;
            const pointDecimal = scaleThousand
              ? point.y > 10000
                ? 0
                : 1
              : decimalCount;
            return (
              <text
                key={`${id}-${point.x}`}
                x={points[idx].x}
                y={points[idx].y}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fill: "#262e40",
                  // fill: "#262e40",
                  fontSize: fontSize,
                  fontWeight: "normal",
                  // textShadow: "1px 1px 2px rgba(255,255,255,0.7)",
                  // textShadow:
                  //   "1px 1px 2px rgba(255,255,255,0.7), -1px -1px 2px rgba(255,255,255,0.7)",
                }}
              >
                {legendVisible
                  ? `${Number(
                      (scaleThousand ? point.y / 1000 : point.y).toFixed(
                        pointDecimal
                      )
                    ).toLocaleString(formatCode, {
                      minimumFractionDigits: pointDecimal,
                      maximumFractionDigits: pointDecimal,
                    })}${scaleThousand ? "K" : ""}${is_percent ? "%" : ""}`
                  : null}
              </text>
            );
          });
        })}
      </g>
    );
  };

  const CustomLegendLayer = ({ series }) => {
    const isRow = legendDirection === "row";
    let xPosition = legendPadding - marginLeft;
    let yPosition = -marginTop + bigSize / 2;
    return (
      <g>
        {showlegend &&
          series
            .filter(
              (obj) => topOption.length === 0 || topOption.includes(obj.id)
            )
            .map((item, index) => {
              const xCurrentPosition = xPosition;
              xPosition = (item.id.length * fontSize) / 2 + xPosition + 30;
              const yCurrentPosition = yPosition;
              yPosition = yPosition + 12;
              return (
                <g
                  key={item.id}
                  transform={
                    isRow
                      ? `translate(${xCurrentPosition},${
                          -marginTop + bigSize / 2
                        })`
                      : `translate(${
                          legendPadding - marginLeft
                        },${yCurrentPosition})`
                  }
                >
                  <circle cx={10} cy={0} r={bigSize / 2} fill={item.color} />
                  <text
                    x={20}
                    y={0}
                    textAnchor="start"
                    alignmentBaseline="middle"
                    fill="#000000"
                    fontSize={fontSize}
                  >
                    {item.id}
                  </text>
                </g>
              );
            })}
      </g>
    );
  };

  return (
    <Wrapper visible={visibility}>
      <PositionWrapper>
        <GraphWrapper>
          <ResponsiveAreaBump
            data={transformedData}
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            xOuterPadding={0.6}
            theme={{
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: tickRotation,
              legend: null,
              legendOffset: 36,
              legendPosition: "middle",
              truncateTickAt: 0,
            }}
            axisLeft={false}
            enableGridX={true}
            enableGridY={false}
            enableSlices="x"
            align="end"
            xPadding={0.9}
            // colors={{ scheme: "nivo" }}
            colors={myColors}
            blendMode="multiply"
            fillOpacity={0.8}
            inactiveFillOpacity={0.4}
            borderWidth={0}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.4]],
            }}
            startLabelPadding={11}
            endLabel={false}
            isInteractive={false}
            layers={[
              // "grid",
              "axes",
              "labels",
              "areas",
              "crosshair",
              "mesh",
              "legends",
              LabelLayer,
            ]}
            motionConfig="stiff"
          />
        </GraphWrapper>
        <GraphWrapper2>
          <ResponsiveLine
            data={transformedData2}
            colors={myColors}
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            theme={{
              background: "#ffffff00",
              text: {
                fontSize: fontSize,
                fill: "#333333",
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              tooltip: {
                wrapper: {},
                container: {
                  background: "#ffffff",
                  color: "#333333",
                  fontSize: fontSize,
                },
                basic: {},
                chip: { height: bigSize, width: bigSize },
                table: {},
                tableCell: {},
                tableCellValue: {},
              },
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            // yFormat=" >-.2f"
            yFormat={(value) =>
              `${Number(value.toFixed(2)).toLocaleString(formatCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            }
            axisTop={null}
            axisRight={null}
            // axisBottom={{
            //   tickSize: 5,
            //   tickPadding: 5,
            //   tickRotation: tickRotation,
            //   legend: null,
            //   legendOffset: 36,
            //   legendPosition: "middle",
            //   truncateTickAt: 0,
            // }}
            axisBottom={null} //this change
            axisLeft={false}
            enableGridX={false}
            enableGridY={false}
            lineWidth={0} //this change
            enablePoints={false} //this change
            pointSize={smallSize}
            pointColor={{ from: "color", modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "color", modifiers: [] }}
            enablePointLabel={true}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            areaOpacity={0}
            enableSlices="x"
            enableCrosshair={true}
            enableTouchCrosshair={true}
            useMesh={true}
            // legends={
            //   showlegend
            //     ? [
            //         {
            //           anchor: "top-left",
            //           itemDirection: "left-to-right",
            //           direction: legendDirection,
            //           justify: false,
            //           translateX: legendX,
            //           translateY: -marginTop,
            //           // itemWidth: 80,
            //           itemWidth: (d) => {console.log("dddddd-",d); return 80 },
            //           itemHeight: bigSize,
            //           itemsSpacing: 5,
            //           symbolSize: bigSize,
            //           symbolShape: "circle",
            //           itemTextColor: "#777",
            //           effects: [
            //             {
            //               on: "hover",
            //               style: {
            //                 itemBackground: "rgba(0, 0, 0, .03)",
            //                 itemOpacity: 1,
            //               },
            //             },
            //           ],
            //           titleAlign: "start",
            //           titleOffset: 4,
            //         },
            //       ]
            //     : []
            // }
            legends={[]}
            motionConfig="default"
            layers={[
              "grid",
              "markers",
              "areas",
              "lines",
              "slices",
              "points",
              "axes",
              "crosshair",
              CustomLegendLayer,
            ]}
            sliceTooltip={CustomTooltip}
          />
        </GraphWrapper2>
      </PositionWrapper>
    </Wrapper>
  );
};

export default NivoRibbonGraph;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
`;

const PositionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const GraphWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const GraphWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

// useEffect(() => {
//   const allDates = Array.from(
//     new Set(data.flatMap((item) => item.data.map((point) => point.date)))
//   ).sort();
//   const transformed = data.map((item) => ({
//     id: item.label,
//     data: allDates.map((date) => {
//       const point = item.data.find((d) => d.date === date);
//       return {
//         x: date,
//         y: point ? point.value : null,
//       };
//     }),
//   }));

//   setTransformedData(transformed);
// }, [data]);

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const TootTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 8px;
  border: 0.5px solid #cccccc;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  font-size: ${(props) => props.fontSize}px;
`;

const TootTipRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5px;
  flex-direction: row;
  justify-content: space-between;
`;

const TootTipOtherRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2.5px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${(props) => 12.5 + props.margin}px;
`;

const TootTipLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
`;

const SquareBox = styled.div`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border: ${(props) =>
    props.bold ? "1.5px solid #262e40" : "0px solid #262e40"};
`;

const TootTipValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${(props) => (props.bold ? "500" : "400")};
`;

const TootTipValueUnit = styled.div`
  font-size: ${(props) => props.fontSize}px;
  margin-left: 3px;
  color: #a0a6a9;
  font-weight: 350;
  font-weight: ${(props) => (props.bold ? "500" : "350")};
`;

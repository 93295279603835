import ChangeLogsIcon from "../svg/ChangeLogs";
import styled from "styled-components";
import Logtable from "./logtable";
import { useEffect, useState } from "react";
import axios from "axios";
import LastUpdated from "../svg/LastUpdated";

const ChangeLogComponent = ({ parent_props, url_key, selectedpage }) => {
  const [isLoaded, setisLoaded] = useState(false);
  const [lastUpdated, setlastUpdated] = useState("");
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const getabledata = async () => {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_WEBFORM_ENDPOINT}/tracking/?industry_id=${parent_props.industry_id}`
        );
        setisLoaded(true);
        const formattedDate = formatDate(response.data[0].created_time);

        setlastUpdated(formattedDate);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          setisLoaded(true);

          setlastUpdated("Unavailable");
        }
      }
    };
    getabledata();
  }, []);

  if (!parent_props.industry_id) return null;

  return (
    <>
      <ChangeLogs>
        <ChangeLogsIcon
          onClick={() => {
            window.location.href = `/changeLogs/?industry_id=${parent_props.industry_id}&industry_name=${selectedpage}&pass_key=${url_key}`;
          }}
        />
        <div
          style={{
            fontWeight: "600",
            fontStyle: "italic",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = `/changeLogs/?industry_id=${parent_props.industry_id}&industry_name=${selectedpage}&pass_key=${url_key}`;
          }}
        >
          Change Logs
        </div>
        <div style={{ fontWeight: "600" }}>&nbsp;|</div>
        {isLoaded ? (
          <>
            &nbsp;
            <LastUpdated />
            <div
              style={{
                fontWeight: "400",
                fontStyle: "italic",
                paddingBottom: "2%",
              }}
            >
              Last Updated on {lastUpdated}
            </div>
          </>
        ) : (
          <div style={{ fontStyle: "italic", color: "grey" }}>Loading...</div>
        )}
      </ChangeLogs>
    </>
  );
};

export default ChangeLogComponent;

const ChangeLogs = styled.div`
  display: flex;
`;

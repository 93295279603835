import React from "react";
import styled from "styled-components";

const Thumb = ({ width, height, rotate = 0, isUpward = true, className }) => {
  return (
    <IconWrapper
      rotate={rotate}
      disabled={false}
      width={width}
      height={height}
      className={className}
    >
      {isUpward ? (
        <svg width={`100%`} height={`100%`} viewBox="0 0 70 70" fill="none">
          <path
            d="M69.468 34.7344C69.468 53.9181 53.9174 69.4688 34.7338 69.4688C15.5509 69.4688 0 53.9181 0 34.7344C0 15.5507 15.5509 -3.7172e-06 34.7338 -3.7172e-06C53.9174 -3.7172e-06 69.468 15.5507 69.468 34.7344Z"
            fill="#21AF4A"
          />
          <path
            d="M15.2749 49.7305H24.3294V29.3587H15.2749V49.7305Z"
            fill="white"
          />
          <path
            d="M51.1974 32.0449C52.0918 31.4812 52.6914 30.4917 52.6914 29.356C52.6914 27.6001 51.2677 26.175 49.5104 26.175H39.7601C39.8743 24.1462 39.956 20.8991 39.6072 17.7554C39.0414 12.6628 33.6652 14.9272 33.6652 14.9272C36.4949 21.1513 27.7236 32.1855 27.7236 32.1855V48.6665H45.8739C47.6319 48.6665 49.0556 47.2414 49.0556 45.4841C49.0556 44.6117 48.7028 43.8206 48.1322 43.2459C49.6799 43.0295 50.8735 41.7147 50.8735 40.109C50.8735 39.2352 50.5207 38.4441 49.9501 37.8694C51.4978 37.653 52.6914 36.3381 52.6914 34.7325C52.6914 33.5968 52.0918 32.6073 51.1974 32.0449Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg width={`100%`} height={`100%`} viewBox="0 0 70 70" fill="none">
          <path
            d="M69.468 34.7344C69.468 15.5507 53.9174 0 34.7338 0C15.5509 0 0 15.5507 0 34.7344C0 53.9181 15.5509 69.4688 34.7338 69.4688C53.9174 69.4688 69.468 53.9181 69.468 34.7344Z"
            fill="#E93735"
          />
          <path
            d="M15.2749 19.7383H24.3294V40.11H15.2749V19.7383Z"
            fill="white"
          />
          <path
            d="M51.1974 37.4238C52.0918 37.9875 52.6914 38.9771 52.6914 40.1128C52.6914 41.8686 51.2677 43.2937 49.5104 43.2937H39.7601C39.8743 45.3225 39.956 48.5696 39.6072 51.7134C39.0414 56.806 33.6652 54.5415 33.6652 54.5415C36.4949 48.3174 27.7236 37.2832 27.7236 37.2832V20.8022H45.8739C47.6319 20.8022 49.0556 22.2274 49.0556 23.9846C49.0556 24.857 48.7028 25.6481 48.1322 26.2229C49.6799 26.4393 50.8735 27.7541 50.8735 29.3597C50.8735 30.2335 50.5207 31.0247 49.9501 31.5994C51.4978 31.8158 52.6914 33.1306 52.6914 34.7363C52.6914 35.8719 52.0918 36.8615 51.1974 37.4238Z"
            fill="white"
          />
        </svg>
      )}
    </IconWrapper>
  );
};

export default Thumb;

const IconWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : 40)}px;
  width: ${(props) => (props.width ? props.width : 40)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  }
`;

import React from 'react'
import styled from 'styled-components'
import { BsCheckCircle } from "react-icons/bs";
import { useState , useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import {Navigate} from 'react-router-dom';


const ApprovedRequest = () => {
  return (
    <div>
        <PageContainer>
        <SideImg>
        <img src = '/Images/signup.svg' alt = ''/>
        </SideImg>
         <Wrapper>
            <IconContainer>
                <CustomIcon />
            </IconContainer>
            <h4>Your Request has been received !</h4>
        </Wrapper>
    </PageContainer>

    </div>
  )
}

export default ApprovedRequest

const PageContainer = styled.div`
height:100vh;
display:flex;
@media (max-width:768px){
    overflow-x:hidden
}
`


const SideImg = styled.div`
/* background-color:#1C1C6C; */
width:40%;
overflow:hidden;
/* img{
  height:100%;
  width: 100%;
} */
`

const Wrapper = styled.div`
/* margin-top: 10vh; */
/* text-align:center; */
background-color:white;
width:60%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
gap:50px;
@media (max-width:768px){
  width:100%;
}
`

const FooterDiv = styled.div`
border-top :1px solid black;
margin-bottom: 10px;
/* flex-grow:1; */
height:7vh;
width:40vw;
color:blue;
`

const IconContainer = styled.div`
  background-color: green;
  border-radius:50%;
  border:0px;
`;

const CustomIcon = styled(BsCheckCircle)`
  font-size: 100px;
  color: white;
`;
import React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import MyContext from "../utils/contexts/MyContext";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { newreportaccess } from "../centralizedAPICalls/service";
import { saveToDb } from "../utils/redux/idb";
import { useDispatch } from "react-redux";
import { setNewReportAccessData } from "../utils/redux/slices";
import axios from "axios";
import { onUserLoginCT } from "../utils/clevertap";
import { EXCHANGE_RATE_DETAIL_ENDPOINT } from "../constants/constants"
import { onLandScapePageOpenCT } from "../utils/clevertap";

const RedirectionPage = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const dispatch = useDispatch();
  const client_id = new URLSearchParams(search).get("client_id");
  const backend_token = new URLSearchParams(search).get("backend_token");
  const pseudo_email = new URLSearchParams(search).get("pseudo_email");
  const user_name = new URLSearchParams(search).get("name");
  const email = new URLSearchParams(search).get("email");
  const gender_male = new URLSearchParams(search).get("gender_male");
  const source = new URLSearchParams(search).get("source");
  const [subscribedReports, setsubscribedReports] = useState([]);
  const subDictionary = {};
  subscribedReports.forEach((item) => {
    subDictionary[item["Report Id"]] = item;
  });

  subDictionary && window.localStorage.setItem(
    "subDictionary",
    JSON.stringify(subDictionary)
  );

  if (client_id) {
    window.localStorage.setItem("clientID", client_id);
    window.localStorage.setItem("loginStatus", "true");
  }

  if (gender_male) {
    window.localStorage.setItem("gender_male", gender_male);
  }

  if (backend_token) {
    window.localStorage.setItem("token", backend_token);
    window.localStorage.setItem("loginStatus", "true");
  }

  if (pseudo_email) {
    window.localStorage.setItem("pseudo_email", pseudo_email);
  }
  if (email) {
    window.localStorage.setItem("email", email);
  }

  if (user_name) {
    window.localStorage.setItem("user_name", user_name);
  }

  function findFirstSubscribedNode(data) {
    for (let node of data) {
      if (
        node.subscribed &&
        [9, 25, 280, 72, 67, 95].includes(node.key) === false
      ) {
        return node;
      }

      const result = findFirstSubscribedNode(node.nodes);
      if (result) {
        return result;
      }
    }

    return null;
  }

  function findFirstNSubscribedNodes(data, n) {
    let results = [];

    function recursiveSearch(node) {
      if (results.length >= n) return;

      if (
        node.subscribed &&
        [9, 25, 280, 72, 67, 95].includes(node.key) === false
      ) {
        results.push(node);
      }

      for (const child of node.nodes) {
        recursiveSearch(child);
      }
    }

    recursiveSearch(data);

    // If no subscribed nodes are found, add logic to fetch the first three nodes
    if (results.length === 0) {
      function fetchFirstThreeNodes(node, count = 0) {
        if (count >= 3 || !node) return;
        if ([9, 25, 280, 72, 67, 95].includes(node.key) === false) {
          results.push(node);
        }

        for (const child of node.nodes) {
          fetchFirstThreeNodes(child, results.length);
        }
      }

      fetchFirstThreeNodes(data);
    }

    return results;
  }

  useEffect(() => {
    let client_id = window.localStorage.getItem("clientID");
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/newreports/?rep_id=9 &client_id=${client_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const reportaccessdata = async () => {
          const result = await newreportaccess(client_id);
          dispatch(setNewReportAccessData(result));
          try {
            await saveToDb("newreportaccessdata", result);
          } catch (error) {
            console.log(error);
          }
          // let firstNode = findFirstSubscribedNode(res)
          const n = 3;
          const foundNodes = findFirstNSubscribedNodes(res[0], n);
          let firstNode = null;
          if (foundNodes.length > 0) {
            firstNode = foundNodes[0];
            window.localStorage.setItem("industry_id", firstNode.industry_id);
          } else {
            console.log("nvm");
          }
          try {
            await saveToDb("quicklinksnodesarr", foundNodes);
          } catch (error) {
            console.log("saving to db quicklinks failed due to", error);
          }

          let newsletter_key = window.localStorage.getItem("newsletter_key");
          if (source === "newsletter" && newsletter_key) {
            let val = window.localStorage.getItem("newsletter_val");
            let key = window.localStorage.getItem("newsletter_key");
            let filter = window.localStorage.getItem("newsletter_filter");
            let filter_value = window.localStorage.getItem(
              "newsletter_filter_value"
            );
            let id = window.localStorage.getItem("id");
            navigate(`/internet-landscape`);
            onLandScapePageOpenCT();
            // navigate(
            //   `/Report3/?val=${val}&key=${key}&filter=${filter}&filter_value=${filter_value}&id=${id}`
            // );
          } else if (firstNode == null) {
            navigate(`/internet-landscape`);
            onLandScapePageOpenCT();
            // navigate(
            //   "/Report3/?val=Online Retail In India&key=398&filter=null&filter_value=null"
            // );
          } else {
            navigate(`/internet-landscape`);
            onLandScapePageOpenCT();
            // navigate(
            //   `/Report3/?val=${firstNode.label}&key=${firstNode.key}&filter=${firstNode.filter}&filter_value=${firstNode.filter_value}&industry_id=${firstNode.industry_id}`
            // );
          }
        };

        reportaccessdata();
      });

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/new-report-api-temp/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        window.localStorage.setItem(
          "newReporTree",
          JSON.stringify(data[0].children)
        );

        function extractIndutryIds(newReportTree) {
          const idDictionary = {};

          function transverse(newReportTree) {
            newReportTree.forEach((node) => {
              if (node.level === 3 || node.level === 5) {
                idDictionary[node.id] = node
              }
              if (node.children && node.children.length > 0)
                transverse(node.children);
            });
          }

          transverse(newReportTree);

          return idDictionary;
        }
        window.localStorage.setItem(
          "IndustryDict",
          JSON.stringify(extractIndutryIds(data[0].children))
        );
      })
      .catch((error) => console.error("Error fetching tree data:", error));

      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/sub-data/?client_id=` +
          window.localStorage.getItem("clientID"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => setsubscribedReports(data))
        .catch((error) =>
          console.error("Error fetching subscriber data: ", error)
        );
    
    axios
      .get(`${EXCHANGE_RATE_DETAIL_ENDPOINT}`)
      .then((response) => response.data)
      .then((data) => {
        if (data.status === 200) {
          window.localStorage.setItem(
            "EXCHANGE_RATE",
            JSON.stringify((data.data))
          );
        }
      })
      .catch((error) => {
        console.error("Error in fetching exchange rate-", error);
      });

  }, []);

  useEffect(() => {
    /* after google sign in */
    if (backend_token) {
      onUserLoginCT(
        user_name !== undefined ? user_name : email.split("@")[0],
        email,
        gender_male === "true" ? "M" : "F",
        client_id
      );
    }
  }, [backend_token]);

  return (
    <Redirectdiv>
      <Dots />
    </Redirectdiv>
  );
};

export default RedirectionPage;

const Dots = () => {
  const [dots, setDots] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      if (dots.length < 3) {
        setDots((prevDots) => prevDots + ".");
      } else {
        setDots("");
      }
    }, 200); // Change the dots every 200ms

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [dots]);
  return <div>Redirecting {dots}</div>;
};

const Redirectdiv = styled.div`
  width: 100%;
  height: 100vh;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip, LineChart, Line, ResponsiveContainer } from "recharts";

const LineGraph = ({
  data,
  height = 50,
  dataKey = "yoy_growth",
  className,
}) => {
  const [containerHeight, setContainerHeight] = useState(height);
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(height);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [height]);

  useEffect(() => {
    const formatted = data.map((item) => ({
      ...item,
      [dataKey]: parseFloat(item[dataKey]),
    }));
    setFormattedData(formatted);
  }, [data, dataKey]);

  return (
    <GraphBackground className={className}>
      <ChartContainer style={{ height: containerHeight }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={formattedData}>
            <Line
              type="linear"
              dataKey={dataKey}
              stroke="#444444"
              dot={{ fill: "#0099FF", stroke: "#0099FF", strokeWidth: 1, r: 3 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </GraphBackground>
  );
};

export default LineGraph;

const GraphBackground = styled.div`
  background: #f5f3f3;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 100%;
  height: auto;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
`;

import React from 'react'
import Header from '../components/Header'
import styled from 'styled-components'
import { useState } from 'react'
import { useEffect } from 'react'
import { API_URL } from "../constants/constants";

const Companies = () => {
    const [playerData, setPlayerData] = useState([])
    const[activeLink, setActiveLink] = useState('Fashion')

    useEffect(()=>{
        const fetchData = async () => {
            try {
              const response = await fetch(`${API_URL}/player/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ report_id: 25 }),
              });;
              let result = await response.json();
              result.sort((a, b) => {
                if (a.orderBy === null && b.orderBy === null) {
                    return 0; 
                } else if (a.orderBy === null) {
                    return 1;
                } else if (b.orderBy === null) {
                    return -1; 
                } else {
                    return a.orderBy - b.orderBy; 
                }
            });
              setPlayerData(result);

            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
    }, [])

    const handleClick = async(report_id,val)=>{
        setActiveLink(val)
        const response = await fetch(`${API_URL}/player/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ report_id: report_id }),
          });

          // const jsondata = await response.json();
          let result = await response.json();
          result.sort((a, b) => {
            if (a.orderBy === null && b.orderBy === null) {
                return 0; 
            } else if (a.orderBy === null) {
                return 1;
            } else if (b.orderBy === null) {
                return -1; 
            } else {
                return a.orderBy - b.orderBy; 
            }
        });
          setPlayerData(result)
    }

  return (
    <div>
        <Header/>
        <Titlediv>
            <span style={{color:'Red'}}>Companies</span> we cover
        </Titlediv>
        <div style={{textAlign:'center'}}>100 + business KPIs of top 200 consumer internet companies in India</div>
        <IndustryCategorydiv></IndustryCategorydiv>
        <Tabsdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'Fashion' ? '#0099ff' : '#F2F2F2', color:activeLink === 'Fashion' ? 'white':'black' }} onClick={()=>(handleClick(25, 'Fashion'))}>Online Retail</TabsInnerdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'OTTVideo' ? '#0099ff' : '#F2F2F2', color:activeLink === 'OTTVideo' ? 'white':'black' }}onClick={()=>(handleClick(72, 'OTTVideo'))}>Media, Entertainment & Leisure</TabsInnerdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'QC' ? '#0099ff' : '#F2F2F2', color:activeLink === 'QC' ? 'white':'black' }} onClick={()=>(handleClick(95, 'QC'))}>Online Services</TabsInnerdiv>
            {/* <TabsInnerdiv style={{ backgroundColor: activeLink === 'EHealth' ? '#0099ff' : '#F2F2F2', color:activeLink === 'EHealth' ? 'white':'black' }}onClick={()=>(handleClick(54, 'EHealth'))}>E Health</TabsInnerdiv> */}
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'Other' ? '#0099ff' : '#F2F2F2', color:activeLink === 'Other' ? 'white':'black' }}onClick={()=>(handleClick(487, 'Other'))}>Fintech & B2B</TabsInnerdiv>
        </Tabsdiv>
        <CompaniesGridDiv>
           {playerData.filter((val)=>(val.image!==null)).map((val)=>(
            <>
              {val.image==null?null:<img style={{width:'40%', height:'auto'}} src = {val.image==null || val.image.file==null?'/Images/applemusic.png':val.image.file} alt = {val.image==null?'':val.image.name}/>}
            </>
           ))}
        </CompaniesGridDiv>
    </div>
  )
}

export default Companies

const Titlediv = styled.div`
    font-size:33px;
    font-weight:600;
    text-align:center;
    padding:20px;
    @media(min-width: 768px){
        padding:30px;
        font-size:60px;
    }
`

const IndustryCategorydiv = styled.div`
    font-size:25px;
    font-weight:500;
    text-align:center;
    padding:20px;
    @media(min-width: 768px){
        padding:30px;
        font-size:32px;
    }
`

const Tabsdiv = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    gap:20px;
    line-height:45px;
    flex-wrap:wrap;
    /* height:20px; */
    /* background-color:lightgray; */
`


const TabsInnerdiv = styled.div`
    background-color:#F2F2F2;
    border-radius:10px;
    min-width:150px;
    padding:0px 10px;
    text-align:center;
    &:hover{
        cursor:pointer;
    }
`

const CompaniesGridDiv = styled.div`
    display:grid;
    padding:70px;
    padding-left:100px;
    padding-right:50px;
    grid-template-rows:auto;
    grid-auto-rows:1fr;
    grid-auto-columns:1fr;
    gap:10px;
    grid-template-columns:1fr 1fr ;
    grid-template-areas:". .";
    @media(min-width: 768px){
        grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
         grid-template-areas:". . . . . . .";
    }
`
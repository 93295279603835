import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useMemo, useState, useRef } from 'react';
import axios from 'axios';
import { Flex } from 'antd';
import styled from 'styled-components';

const MyStackedBarChart = () => {

  const[activeTab, setActiveTab] = useState('Revenue');
  const[barData, setbarData] = useState([]);
  const  unitRef = useRef('Cr')
  const useQuery = () => new URLSearchParams(useLocation().search);
  const report_id = useQuery().get("key") || 492;

  function parseCurrencyString(value) {
    // Extract the unit by removing all numeric characters, commas, periods, parentheses, and spaces.
    if (typeof value !== 'string') {
      console.log('value = ', value)
      throw new Error('Input value must be a string.');
    }
    const unit = value.replace(/[\d,.()\s]/g, '').trim();
  
    const numericString = value.replace(/,/g, '').replace(/[^\d.-]/g, '').replace(/[()]/g, '');

    // Parse the cleaned string to a float.

    let number = parseFloat(numericString);
    const isNegative = value.startsWith('(') && value.endsWith(')');
    if (isNegative) {
      number *= -1;
    }
  
    return {
      number: number,
      unit: unit
    };
  }

function modifyObject(obj) {
  // Exclude 'name' key from entries
  let entries = Object.entries(obj)
    .filter(([key, _]) => key !== 'name')
    .map(([key, value]) => [key, value === '-' ? '0' : value]);

  // Sort the entries based on the numeric value
  entries.sort((a, b) => parseCurrencyString(b[1]).number - parseCurrencyString(a[1]).number);

  // Save the highest value and remove the entry from the array.
  const maxValEntry = entries.shift();
  const maxVal = parseCurrencyString(maxValEntry[1]).number;

  let result = {};
  let sumOfTopFour = 0;
  
  // Calculate the sum of the first 4 highest values if there are more than 5 keys left.
  if (entries.length >= 4) {
    for (let i = 0; i < 4; i++) {
      sumOfTopFour += parseCurrencyString(entries[i][1]).number;
    }
    // Add the 'Others' key with maxVal minus the sum of the top 4 values.
    result['Miscellaneous'] = (maxVal - sumOfTopFour).toFixed(2);
    if (result['Miscellaneous']<0 && result['Miscellaneous']>-1){
      result['Miscellaneous'] = 0
    }
  }

  // Add the remaining entries to the result and the 'name' entry if it exists.
  let count = 0;
  if (obj.hasOwnProperty('name')) {
    result['name'] = obj['name']; // Add the 'name' entry back to the result
  }
  entries.forEach(([key, value]) => {
    if (count < 4) {
      result[key] = value === '-' ? 0 : parseCurrencyString(value).number;
      count++;
    }
  });

  return result;
}

function extractCharacters(str) {
  const match = str.match(/([a-zA-Z]+)\)?$/);
  return match ? match[1] : null;
}

  const getbargraphdata = (data)=>{
    let unit = extractCharacters(Object.values(data[0])[0])
    unitRef.current=unit
    let res = []
    for(let i = 0;i<data.length;i++){
      let dic ={}

      let ans = modifyObject(data[i])
      // dic = {...dic,...data[Object.keys(data)[i]]}

      res.push({...dic,...ans})
    }
    return res
  }

  useEffect(() => {
    const getrevenuedata = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/rocdata/?report_id=${report_id}`
      );
      rocdataref.current = result.data[0].data;
      // yearref.current=result.data[0]['data']['Year']['Year']
      const uniqueKeys = new Set();
      let modData = getbargraphdata(result.data[0].data.Revenue);
      modData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== "name") uniqueKeys.add(key);
        });
      });
      uniquekeyref.current = uniqueKeys;
      setUniqKeys(Array.from(uniqueKeys));
      bardataref.current = modData;
      setbarData(modData);
    };

    getrevenuedata();
  }, []);

  const bardataref = useRef(null)
  const rocdataref = useRef(null)
  const uniquekeyref = useRef([])
  const [uniqKeys, setUniqKeys] = useState([])


  const data = [
    { name: 'Page A', part1: 4000, part2: 2400, part3: 2400 },
    { name: 'Page B', part1: 3000, part2: 1398, part3: 2210 },
    { name: 'Page C', part1: 2000, part2: 9800, part3: 2290 },
    // ...other data
  ];
  const colors = ['#0099ff', '#9842ff', '#9fc51e', '#fcbb53', '#e93735', '#a58729'];
  
  const getColor = (key_val, index) => {
    console.log('bardataref.current = ', bardataref.current)
    let listOfDicts = bardataref.current
    let x = key_val
    for (let i = 0; i < listOfDicts.length; i++) { 
      const keys = Object.keys(listOfDicts[i]); // Get keys of the current object
      const index = keys.indexOf(x); // Find the index of x in keys
      if (index !== -1) {
          // If x is found, return the index within the current object
          return colors[index-1]; 
      }
  }
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <ul>
            {payload.map((entry, index) => (
              <li key={`tooltip-entry-${index}`} className="tooltip-entry">
                <span className="value">{`${entry.value}`}</span>
                <span className="key">{`${entry.dataKey}`}</span>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  
    return null;
  };

  const handleTabSelect = (e) => {
    let val = e.target.innerText

    console.log(`Option selected:`, val, rocdataref.current, rocdataref.current[val])
    setActiveTab(val);
    const uniqueKeys = new Set();
    let bardata = getbargraphdata(rocdataref.current[val])
    bardata.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'name') uniqueKeys.add(key);
      });
    });
    console.log('uniq = ', uniqueKeys, bardata)
    uniquekeyref.current = uniqueKeys;
    setUniqKeys(Array.from(uniqueKeys));
    bardataref.current = bardata
    setbarData(bardata)
  };
  const vwToPixels = (value) => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * (value / 100);
const vhToPixels = (value) => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * (value / 100);

// Calculate 80vw and 50vh in pixels
const widthInPixels = vwToPixels(92);
const heightInPixels = vhToPixels(50);
  

  return (
    <div style={{margin:'3.5vw'}}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <h3>Income Statement</h3>
      </div>
      <div style={{backgroundColor:'white'}}>
        <div style={{display:'flex', justifyContent:'flex-end', padding:'15px', paddingBottom:'0px'}}>
          <ButtonTab onClick={handleTabSelect} selected={activeTab === 'Revenue'}>Revenue</ButtonTab>
          <ButtonTab onClick={handleTabSelect} selected={activeTab === 'Expense'}>Expense</ButtonTab>
          <ButtonTab onClick={handleTabSelect} selected={activeTab=== 'ProfitLoss'}>ProfitLoss</ButtonTab>
        </div>
        <BarChart
          width={widthInPixels}
          height={600}
          data={bardataref.current?.reverse()}
          margin={{
            top: 50, right: 30, left: 20, bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip cursor={{ fill: "transparent" }}/>
          {uniqKeys.map((key, index) => {
            return(
            <Bar key={index} dataKey={key} stackId="a" fill={colors[index % colors.length]}  barSize={200} />
          )})}
        </BarChart>
        <div style={{padding:'15px', paddingTop:'0px', display:'flex', justifyContent:'center', fontStyle:'italic'}}>* Unit in {unitRef.current}</div>
      </div>
    </div>
  );
};

export default MyStackedBarChart;

const ButtonTab = styled.button`
border:0px;
padding:10px;
border:1px solid black;

background-color: ${props => props.selected ? 'lightblue' : 'white'};
`
import React from "react";
import styled from "styled-components";

const Emailcon = ({
  width = 58,
  height = 60,
  color1 = "#0099FF",
  color2 = "#007ACC",
  color3 = "#003D66",
  color4 = "#F2F2F2",
  color5 = "#7F8FB3",
  rotate = 0,
}) => {
  return (
    <IconWrapper rotate={rotate}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 58 60"
        fill="none"
      >
        <path
          d="M0.421434 22.7514L55.8 22.3493L57.3437 60L0.000976562 59.4685L0.421434 22.7514Z"
          fill={`${color1}`}
        />
        <path
          d="M0.420898 22.75L28.5358 0.000362396L55.7994 22.3478L0.420898 22.75Z"
          fill={`${color2}`}
        />
        <path
          d="M0.000795466 59.4681C-0.0682411 59.4028 4.36507 54.5628 9.90172 48.6572C15.4409 42.75 19.9852 38.016 20.0543 38.0805C20.1233 38.1454 15.6908 42.985 10.1521 48.8922C4.61543 54.7969 0.069832 59.5326 0.000795466 59.4681Z"
          fill={`${color3}`}
        />
        <path
          d="M57.344 59.9994C57.2733 60.0622 52.9556 55.3195 47.7017 49.4073C42.4458 43.493 38.2437 38.6492 38.3149 38.5864C38.3856 38.5236 42.702 43.2655 47.9584 49.1794C53.2122 55.0916 57.4147 59.9366 57.344 59.9994Z"
          fill={`${color3}`}
        />
        <path
          d="M27.9914 44.5625L19.9645 38.212C20.0294 38.13 20.0601 38.0851 20.0539 38.0793C20.0535 38.0789 20.0526 38.0785 20.0518 38.0785C20.041 38.0785 19.9978 38.113 19.9241 38.1799L0.420898 22.7499L55.7994 22.3477L27.9914 44.5625Z"
          fill={`${color3}`}
        />
        <path
          d="M3.21007 24.8045L3.12939 8.69531L51.6322 7.50838L52.1616 25.1335L27.9888 44.2695L3.21007 24.8045Z"
          fill={`${color4}`}
        />
        <line
          x1="11.8525"
          y1="16.0273"
          x2="43.9795"
          y2="16.0273"
          stroke={`${color5}`}
        />
        <line
          x1="11.8525"
          y1="21.0195"
          x2="43.9795"
          y2="21.0195"
          stroke={`${color5}`}
        />
        <line
          x1="11.8525"
          y1="25.3867"
          x2="43.9795"
          y2="25.3867"
          stroke={`${color5}`}
        />
        <line
          x1="11.8525"
          y1="29.75"
          x2="43.9795"
          y2="29.75"
          stroke={`${color5}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default Emailcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
`;

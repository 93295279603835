import styled from "styled-components";
import React from "react";

const RedseerAnalysisCard = ({
  redseerAnalysis = {},
  onDetailClick = () => null,
}) => {
  return (
    <div>
      <Analysisdiv>Redseer Analysis</Analysisdiv>
      <AnalysisBoxWrapper>
        <AnalysisBox>
          {/<[^>]+>/i.test(redseerAnalysis.text) ? (
            <AnalysisBoxBody>
              <div
                dangerouslySetInnerHTML={{
                  __html: redseerAnalysis.text,
                }}
              />
            </AnalysisBoxBody>
          ) : (
            <AnalysisBoxBody>{redseerAnalysis.text}</AnalysisBoxBody>
          )}
          <AnalysisBoxLink onClick={onDetailClick}>
            View Analysis
          </AnalysisBoxLink>
        </AnalysisBox>
      </AnalysisBoxWrapper>
    </div>
  );
};

export default RedseerAnalysisCard;

const Analysisdiv = styled.div`
  padding: 3.5vw;
  padding-top: 50px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 25px;
  background-color: #eef9ff;
`;

const AnalysisBoxWrapper = styled.div`
  padding: 3.5vw;
  padding-top: 5px;
  position: relative;
  align-items: center;
  background-color: #eef9ff;
`;

const AnalysisBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
`;

const AnalysisBoxBody = styled.div`
  align-items: left;
  background-color: #ffffff;
  width: 100%;
`;
const AnalysisBoxLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0099ff;
  color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 50px;
  width: 200px;
  height: 50px;
`;

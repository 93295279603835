import React from "react";
import styled from "styled-components";
import CustomForm from "../components/CustomForm/CustomForm";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { ACCEPT_INVITE_ENDPOINT, SIGNIN_URL } from "../constants/constants";
import Loader from "../components/Loader";
import { RotatingLines } from "react-loader-spinner";

const InvitationUsed = () => {
  useEffect(() => {
    const Redirect = () => {
      window.location.href = SIGNIN_URL;
    };
    const timerId = setTimeout(Redirect, 5000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <InvitationWrapper>
      <div>This invitation has already been used!</div>
      <InvitationText>Redirecting to sign in page ...</InvitationText>
      <RotatingLines
        strokeColor="#0099FF"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    </InvitationWrapper>
  );
};

const InvitationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  align-items: center;
  height: calc(70vh - 100px);
  width: 100%;
  font-weight: bold;
  font-size: 25px;
`;

const InvitationText = styled.div`
  font-weight: bold;
  font-size: 25px;
  padding: 5px 0 40px;
`;

const Signup = () => {
  const search = useLocation().search;
  const reciever_name = new URLSearchParams(search).get("recipient_name");
  const reciever_email = new URLSearchParams(search).get("recipient_email");
  const [invitaionUsed, setInvitationUsed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState({
    Name: reciever_name,
    Email: reciever_email,
  });
  // const [formData, setFormData] = useState({

  // });
  const [allowSignUp, setAllowSignUp] = useState(false);
  const [dataSubmit, setDataSubmit] = useState(false);
  const [invalidData, setInvaliddata] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const key = new URLSearchParams(search).get("key");

  const fields = [
    {
      name: "Name",
      type: "text",
      allowEdit: !allowSignUp,
      style: { backgroundColor: !allowSignUp ? "white" : "#e5f4ff" },
    }, // Customize 'name' field width
    {
      name: "Email",
      type: "email",
      allowEdit: !allowSignUp,
      style: { backgroundColor: !allowSignUp ? "white" : "#e5f4ff" },
    }, // Customize 'email' field background color
    { name: "Phone", type: "text" }, 
    { name: "Company", type: "text" }, // Customize 'description' field height
    {name: "Are you an Investor? ", type: "radio",options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
  ]
}
  ];

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value })); //creating data object
  };

  const validateFormData = (formData) => {
    const nameRegex = /^[a-zA-Z-' ]+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^[0-9]{10}$/; // This is for Indian phone numbers
    const companyRegex = /^[a-zA-Z0-9-' ]+$/;

    if (!formData.name || !nameRegex.test(formData.name)) {
      return false;
    }

    if (!formData.email || !emailRegex.test(formData.email)) {
      return false;
    }

    // if (!formData.phone || !phoneRegex.test(formData.phone)) {
    //     return false;
    // }

    if (!formData.company || !companyRegex.test(formData.company)) {
      return false;
    }

    return true;
  };
  const [error, setError] = useState('');

  const handleSubmit = () => {
    // Send the formData to the API or perform further processing here
    console.log(formData);
    let isValid = validateFormData(formData);

    const radioField = fields.find(field => field.type === 'radio');
    if (radioField && (!formData[radioField.name] || formData[radioField.name].length === 0)) {
        setError(`Please select whether you are an Investor or not`);
        return;
    }

    // Clear error if no validation issues
    setError('');


    // if(isValid===false){
    //   setInvaliddata(true)
    //   return
    // }
    if (allowSignUp) {
      const uploadData = new FormData();
      uploadData.append("email", formData["Email"]);
      uploadData.append("fname", formData["Name"]);
      uploadData.append("lname", "");
      uploadData.append("phone", formData["Phone"]);
      uploadData.append("company", formData["Company"]);
      uploadData.append("is_investor",formData["Are you an Investor? "]=='yes'?1:0)
      uploadData.append("message", "message");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/signup/`, {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.token) {
            setSignIn(true);
            window.localStorage.setItem("loginStatus", "true");
            window.localStorage.setItem("token", data.token);
            window.localStorage.setItem("email", formData["Email"]);
            window.localStorage.setItem('user_name', formData["Name"])
            // take client id from back end even after sign up as it may be subject to change
            window.localStorage.setItem("clientID", 141);
          } else {
            setSignIn(false);
            setInvaliddata(true);
          }
        })
        .catch((error) => {
          setSignIn(false);
          alert("User already exists");
          console.log(error);
        });

      if (key) {
        fetch(`${ACCEPT_INVITE_ENDPOINT}/${key}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((data) => data.json())
          .then((data) => {
            console.log("data");
          })
          .catch((error) => console.error(error));
      }
    } else {
      const uploadData = new FormData();
      uploadData.append("email", formData["Email"]);
      uploadData.append("firstname", formData["Name"]);
      uploadData.append("lname", "");
      uploadData.append("phone", formData["Phone"]);
      uploadData.append("company", formData["Company"]);
      uploadData.append("message", "");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/requestaccess/`, {
        method: "POST",
        body: uploadData,
      })
        .then((data) => data.json())
        .then((data) => {
          console.log("data = ", data);
          setDataSubmit(true);
        });
    }
  };

  useEffect(() => {
    if (key) {
      setShowLoader(true);
      // valudate key and get corresponding email
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/validateinvitekey/?key=${key}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          // this.props.userLogin(data.token);
          if (data["email"] === "accepted") {
            setInvitationUsed(true);
          } else if (data["email"] !== "uninvited") {
            console.log("invited_email = ", data["email"]);
            setAllowSignUp(true);
          } else {
            setAllowSignUp(false);
          }
          setShowLoader(false);
          // this.props.navigate('/reportlist')
        })
        .catch((error) => {
          setShowLoader(false);
          console.error(error);
        });
    }
  }, [key]);

  if (signIn) {
    // below is always true
    console.log("signIn=", signIn);
    return (
      <Navigate to = '/redirect'/>
    );
  }

  return (
    <>
      <Signupdiv>
        {/* <Imgdiv>
          <img
            src="/Images/signup.svg"
            alt="sideimg"
          ></img>
           <Overlaytextdiv>
                Hello {reciever_name}, We have exciting news for you!
                <div style={{width:'33vw'}}>
                  You're one step of accessing the potential of data-driven
                  decision-making and embarking on a transformative journey with us at Benchmarks.</div>
              </Overlaytextdiv>
        </Imgdiv> */}
        <Img1div>
        <img style = {{height:'60vh', marginTop:'15vh', marginLeft:'10px'}}src = '/Images/elephant.png' alt='elephant'/>
        </Img1div>
        {dataSubmit ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "60vw",
              marginTop: "20vh",
            }}
          >
            <img
              src="/Images/BenchmarksNew.svg"
              alt="logo"
              height={"40px"}
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src="/Images/righttick.png"
              alt=""
              style={{ height: "15vw", width: "15vw", marginTop: "5vh" }}
            />
            <h4 style={{ textAlign: "center", marginTop: "5vh" }}>
              Request is sent !{" "}
            </h4>
            <h5 style={{ textAlign: "center", padding:'5vh', }}>
            We've received your request. We will share an invitation within 2 days once your request is reviewed.{" "}
            </h5>
          </div>
        ) : (
          <RHSdiv>
            <Benchmarksdiv>
              <img
                src="/Images/BenchmarksNew.svg"
                alt="logo"
                onClick={() => {
                  window.location.href = "/";
                }}
              ></img>
             
            </Benchmarksdiv>

            {!invitaionUsed ? (
              <>
                {allowSignUp ? (
                  <Signupmiddiv>Create Your Account</Signupmiddiv>
                ) : (
                  <Signupmiddiv>Unlock exclusive access by signing up now!</Signupmiddiv>
                )}
                {invalidData ? (
                  <Errordiv>Invalid Input</Errordiv>
                ) : (
                  <Errordiv></Errordiv>
                )}
                <FormDiv>
                  <CustomForm
                    fields={fields}
                    onChange={handleChange}
                    formData={formData}
                  />
                  {/* <CustomForm fields={fields} onChange={handleChange} /> */}
                  {error && <Investordiv>{error}</Investordiv>}
                  <button style={{ width: "100%" }} onClick={handleSubmit}>
                    Submit
                  </button>
                </FormDiv>
              </>
            ) : (
              <InvitationUsed />
            )}
          </RHSdiv>
        )}
      </Signupdiv>
      {showLoader && <Loader />}
    </>
  );
};

export default Signup;

const Signupdiv = styled.div`
  display: flex;
`;

const Img1div = styled.div`
/* display:flex;
justify-content:center;
align-items:center; */
height:100vh;
`

const Imgdiv = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    position:relative;
    img{
      display:block;
      height:100vh;
    }
  }
`;

const RHSdiv = styled.div`
  width: 100vw;
  @media (min-width: 768px) {
    width: 60vw;
  }
`;

const Benchmarksdiv = styled.div`
  padding: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Signupmiddiv = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  /* font-weight: bold; */
  text-align: center;
  font-size: 25px;
`;

const FormDiv = styled.div`
  padding-left: 50px;
  padding-right: 50px;

  @media (min-width: 768px) {
    padding-left: 200px;
    padding-right: 200px;
  }

  & button {
    background-color: #0099fe;
    line-height: 40px;
    border-radius: 50px;
    border: none;
    margin-top: 20px;
    color: white;
  }
`;

const Errordiv = styled.div`
  text-align: center;
  color: Red;
  height: 20px;
`;

const Investordiv = styled.div`
  text-align: left;
  color: Red;
  height: 20px;
`;

const Overlaytextdiv = styled.div`
  position: absolute;
  position: absolute;
  left: 15%;
  bottom: 15%;
  transform: translateY(50%);
  /* top: 85vh;
  left: 6vw; */
  /* transform: translate(-50%, -50%); */
  color: black;
  font-size: 14px;
  /* font-weight: bold; */
`;

// MyProvider.js
import React from 'react';
import MyContext from './MyContext';
import { useState } from 'react';
import { useEffect } from 'react';

const MyProvider = ({ children }) => {
  // Define the state or any data you want to share

  const [count, setCount] = useState(0);
  const [currentreportid, setcurrentreportid] = useState(9);
  const [reportplayers ,  setreportplayers] = useState([])
  const [reportaccessarr, setreportaccessarr]=useState([])
  const [lastActivity, setLastActivity] = useState(Date.now());

  // Define any functions or methods that can update the state
  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  const setcurr_reportid = (id) =>{
    setcurrentreportid(id)
  }


  // Create the context value with the state and functions
  const contextValue = {
    count,
    increment,
    decrement,
    currentreportid,
    setcurr_reportid,
    reportplayers,
    setreportplayers,
    reportaccessarr,
    setreportaccessarr,
    lastActivity,
    setLastActivity
  };

  

  // Provide the context value to the child components
  return (
  <MyContext.Provider value={contextValue}>
    {children}
  </MyContext.Provider>
  )
};


export default MyProvider;

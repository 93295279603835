import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import Bead from "./Bead";
import axios from "axios";
import { WEBFORM_TOKEN, VALUATION_ENDPOINT } from "../../constants/constants";
import { media } from "../../utils/media";

// use it
// <BeadGraph width={"100%"} height={"70vh"}/>

const calculateColorDifference = (color1, color2) => {
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const diff = Math.sqrt(
    Math.pow(rgb2.r - rgb1.r, 2) +
      Math.pow(rgb2.g - rgb1.g, 2) +
      Math.pow(rgb2.b - rgb1.b, 2)
  );

  return diff;
};

const getRandomHexColor = (existingColors) => {
  const letters = "0123456789ABCDEF";
  let newColor;

  do {
    newColor = "#";
    for (let i = 0; i < 6; i++) {
      newColor += letters[Math.floor(Math.random() * 16)];
    }
  } while (
    existingColors.some(
      (color) => calculateColorDifference(newColor, color) < 100
    )
  );

  return newColor;
};

const BeadGraph = ({
  width,
  height,
  industry_id,
  title = "Valuation Analysis",
}) => {
  const [parentWidth, setParentWidth] = useState(null);
  const [deals, setDeals] = useState([]);
  const [latestDealYear, setLatestDealYear] = useState(null);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState("");
  const [isDataProcessed, setDataProcessed] = useState(false);
  const [coloredCompany, setColoredCompany] = useState([]);
  const [paramters, setParameter] = useState([
    {
      key: "gmv_multiple",
      name: "GMV Multiple",
      lowest: 10000,
      highest: -10000,
    },
    {
      key: "revenue_multiple",
      name: "Revenue Multiple",
      lowest: 10000,
      highest: -10000,
    },
    {
      key: "pe_ratio",
      name: "EBITDA Multiple",
      lowest: 10000,
      highest: -10000,
    },
  ]);

  const parentRef = useRef(null);

  useEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };
    updateParentWidth();
    window.addEventListener("resize", updateParentWidth);
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, [parentRef, isDataLoaded]);

  useEffect(() => {
    const getDealsData = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${WEBFORM_TOKEN}`,
      };
      axios
        .get(`${VALUATION_ENDPOINT}?industry_id=${industry_id}`, { headers })
        .then((response) => response.data)
        .then((response) => {
          if (response.status === 200) {
            let dealsData = response.data;
            let latestDataYear = null;

            let allColor = [];
            let allColoredCompany = [];
            let updatedParameters = paramters;
            //filtering latest 3 years deals
            dealsData = dealsData
              .map((deal) => {
                const dealYear = new Date(deal.deal_date).getFullYear();
                if (latestDataYear == null) {
                  latestDataYear = dealYear;
                } else if (latestDataYear < dealYear) {
                  latestDataYear = dealYear;
                }
                return {
                  ...deal,
                  leftPadding: {
                    gmv_multiple: null,
                    revenue_multiple: null,
                    pe_ratio: null,
                  },
                };
              })
              .filter((deal) => {
                const dealYear = new Date(deal.deal_date).getFullYear();
                return (
                  dealYear <= latestDataYear &&
                  dealYear >= latestDataYear - 2 &&
                  paramters.reduce((acc, param) => {
                    return acc || Boolean(deal[param.key]);
                  }, false)
                );
              });

            //calculating range each parameter
            dealsData = dealsData.map((deal) => {
              if (parseFloat(deal.gmv_multiple) < updatedParameters[0].lowest) {
                updatedParameters[0].lowest = parseFloat(deal.gmv_multiple);
              }
              if (
                parseFloat(deal.gmv_multiple) > updatedParameters[0].highest
              ) {
                updatedParameters[0].highest = parseFloat(deal.gmv_multiple);
              }

              if (
                parseFloat(deal.revenue_multiple) < updatedParameters[1].lowest
              ) {
                updatedParameters[1].lowest = parseFloat(deal.revenue_multiple);
              }
              if (
                parseFloat(deal.revenue_multiple) > updatedParameters[1].highest
              ) {
                updatedParameters[1].highest = parseFloat(
                  deal.revenue_multiple
                );
              }

              if (parseFloat(deal.pe_ratio) < updatedParameters[2].lowest) {
                updatedParameters[2].lowest = parseFloat(deal.pe_ratio);
              }
              if (parseFloat(deal.pe_ratio) > updatedParameters[2].highest) {
                updatedParameters[2].highest = parseFloat(deal.pe_ratio);
              }

              const filteredComp = allColoredCompany.filter(
                (comp) =>
                  comp.company_id === deal.company_id &&
                  comp.company_type_id === deal.company_type_id
              );
              if (filteredComp.length) {
                const color = filteredComp[0].color;
                return { ...deal, color: color };
              } else {
                const newColor = getRandomHexColor(allColor);
                allColor.push(newColor);
                allColoredCompany = [
                  ...allColoredCompany,
                  {
                    company_id: deal.company_id,
                    company_name: deal.company_name,
                    company_type_id: deal.company_type_id,
                    color: newColor,
                  },
                ];
                return {
                  ...deal,
                  color: newColor,
                  leftPadding: {
                    gmv_multiple: null,
                    revenue_multiple: null,
                    pe_ratio: null,
                  },
                };
              }
            });
            setLatestDealYear(latestDataYear);
            setDeals(dealsData);
            setParameter(updatedParameters);
            setColoredCompany(allColoredCompany);
            setDataLoaded(true);
            setError("");
          } else {
            setError(
              `Oops! Something went wrong. Please report this issue to your POC. Error Code: ${error.code}`
            );
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          console.error("Error in fetching deals -", error);
          setError(
            `Oops! Something went wrong. Please report this issue to your POC. Error Code: ${error.code}`
          );
          setDataLoaded(true);
        });
    };
    getDealsData();
  }, []);

  useEffect(() => {
    if (parentWidth && isDataLoaded) {
      let dealsData = deals.map((deal) => {
        let leftPadding = {
          gmv_multiple: null,
          revenue_multiple: null,
          pe_ratio: null,
        };
        paramters.map((param, parId) => {
          if (Boolean(deal[param.key])) {
            const range = Math.max(param.highest - param.lowest, 5);
            const offCenter = param.lowest || 0;
            const padding = Math.max(parentWidth * 0.2, 80);
            const left = Number(
              (
                ((parentWidth - padding) * (deal[param.key] - offCenter)) /
                  range +
                padding / 2
              ).toFixed(2)
            );
            leftPadding[param.key] = left;
          }
        });
        return { ...deal, leftPadding: leftPadding };
      });

      setDeals(dealsData);
      setDataProcessed(true);
    }
  }, [parentWidth, isDataLoaded]);

  const currentYear = new Date().getFullYear();
  const lastThreeYears = Array.from(
    { length: 3 },
    (_, index) => latestDealYear - index
  ).reverse();

  return (
    <Wrapper width={width} height={height}>
      <Title>{title}</Title>
      <GraphShadow width={width} height={height}>
        <GraphDimension width={width} height={height}>
          {!isDataLoaded ? (
            <>
              <LoadingMessage>Loading...</LoadingMessage>
              <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
            </>
          ) : null}
          {isDataLoaded &&
            (error.length ? (
              <NoDataMessage>{error}</NoDataMessage>
            ) : (
              <>
                <CompanyWrapper>
                  {coloredCompany.map((comp, idx) => (
                    <CompanyBox key={idx}>
                      <BeadCircle color={comp.color} />{" "}
                      <CompanyName>{comp.company_name}</CompanyName>
                    </CompanyBox>
                  ))}
                </CompanyWrapper>

                {paramters.map((param, parId) => (
                  <ParContainer key={parId}>
                    <ParName>{param.name}</ParName>
                    <YeadDataContainer>
                      {lastThreeYears.map((year, idx) => {
                        const range = Math.max(param.highest - param.lowest, 5);
                        const offCenter = param.lowest || 0;
                        const padding = Math.max(parentWidth * 0.2, 80);
                        return (
                          <YearData key={idx}>
                            <YearName>
                              {year === currentYear ? `${year} (YTD)` : year}
                            </YearName>
                            <VerticleLine />
                            <LineContainer ref={parentRef}>
                              <HorizontalLine />
                              {isDataProcessed &&
                                (() => {
                                  const sortedDeals = deals
                                    .filter(
                                      (deal) =>
                                        new Date(
                                          deal.deal_date
                                        ).getFullYear() === year &&
                                        Boolean(deal[param.key])
                                    )
                                    .sort(
                                      (a, b) =>
                                        a.leftPadding[param.key] -
                                        b.leftPadding[param.key]
                                    );
                                  let leftVisiblePosition = -50;
                                  return sortedDeals.map((deal, compId) => {
                                    const showValue =
                                      deal.leftPadding[param.key] -
                                        leftVisiblePosition >=
                                      50;
                                    if (showValue)
                                      leftVisiblePosition =
                                        deal.leftPadding[param.key];
                                    return (
                                      <BeadConatiner
                                        left={
                                          Number(
                                            ((parentWidth - padding) *
                                              (deal[param.key] - offCenter)) /
                                              range
                                          ) +
                                          padding / 2
                                        }
                                        key={compId}
                                      >
                                        <Bead
                                          value={deal[param.key]}
                                          showValue={showValue}
                                          dealData={deal}
                                        />
                                      </BeadConatiner>
                                    );
                                  });
                                })()}
                            </LineContainer>
                          </YearData>
                        );
                      })}
                      <YearData>
                        {/* for extra spacing */}
                        <YearName />
                        <VerticleLine />
                        <LineContainer />
                      </YearData>
                    </YeadDataContainer>
                  </ParContainer>
                ))}
              </>
            ))}
        </GraphDimension>
      </GraphShadow>
    </Wrapper>
  );
};

export default BeadGraph;

const Wrapper = styled.div`
  // width: ${(props) => props.width};
  // height: ${(props) => props.height};
  // background-color: #f7fcff;
  box-sizing: border-box;
  padding: 10px;
`;

const LoadingMessage = styled.div`
  min-height: 360px;
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;

const Title = styled.div`
  color: #262e40;
  font-size: 25px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  ${media.small`
     font-size: 18px;
  height: 20px;
      margin-bottom: 10px;

    `
  }
`;

const GraphShadow = styled.div`
  // width: calc(${(props) => props.width} - 20px);
  // height: calc(${(props) => props.height} - 60px);
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px #00000040;
`;

const GraphDimension = styled.div`
  width: calc(${(props) => props.width} - 15px);
  height: calc(${(props) => props.height} - 100px);
  padding: 15px 40px 50px 15px;
  position: relative;
`;

const ParContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  padding: 0px 45px 30px;
`;

const CompanyBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const CompanyName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 22px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #9e9e9e;
  padding-left: 5px;
  padding-right: 20px;
`;

const BeadCircle = styled.div`
  background: linear-gradient(
    180deg,
    ${(props) => props.color} 100%,
    ${(props) => props.color} 100%
  );
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
`;

const ParName = styled.div`
  position: absolute;
  left: 100px;
  top: -15px;
`;

const YeadDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const YearData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const YearName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  padding-right: 5px;
  color: #757575;
  width: 45px;
  min-width: 45px;
  overflow: hidden;
  white-space: normal;
`;

const VerticleLine = styled.div`
  height: 100%;
  border: 1px;
  background: #d1d1d1;
  border: 1px solid #d1d1d1;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 3px;
  width: 100%;
  height: 60px;
  position: relative;
`;

const HorizontalLine = styled.div`
  border: 1px solid #d1d1d1;
  width: 100%;
`;

const BeadConatiner = styled.div`
  height: 60px;
  position: absolute;
  left: ${(props) => props.left}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import React from "react";
import styled from "styled-components";

const OptionsContainer = styled.div`
  display: flex;
`;

const OptionWrapper = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #262e40;
  background-color: ${(props) => (props.selected ? "#ADDDF4" : "white")};
  border: 0.5px solid #000000;
  &:not(:last-child) {
    border-right: 0px;
  }
`;

const OptionSelect = ({ options, selectedOption, onOptionSelect }) => {
  return (
    <OptionsContainer>
      {options.map((option) => (
        <OptionWrapper
          key={option.key}
          selected={selectedOption.key === option.key}
          onClick={() => onOptionSelect(option)}
        >
          {option.value}
        </OptionWrapper>
      ))}
    </OptionsContainer>
  );
};

export default OptionSelect;

import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import ArrowIcon from "../svg/ArrowIcon.js";
import CheckBox from "../CheckBox/index.js";
import { media } from "../../utils/media.js";

const MultiSelectGraph = ({
  id,
  labelName,
  variant = "normal",
  type,
  options = [
    {
      name: "Hotstar",
      id: 13,
      selected: false,
    },
    {
      name: "Zee5",
      id: 14,
      selected: false,
    },
  ],
  keyFieldName,
  valueFieldName,
  selectedOptions = [],
  onSelectedChange = () => {},
  mutileSelect = true,
  mutileSelectText,
  placeholder = "Select...",
  searchPlaceholder = "Search...",
  required,
  onBlur,
  disabled = false,
  is_compulsory = false,
  showCheckBox = true,
  sortOptions = false,
  selectAllText = "Select All",
  selectNoneText = "Select None",
  borderRadius = 5,
  className,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState([]);
  const wrapperRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    setVisibleOptions(
      sortOptions
        ? options
            .sort((a, b) => {
              return a[valueFieldName].localeCompare(b[valueFieldName]);
            })
            .sort((a, b) => b.selected - a.selected)
        : options
    );
  }, [options]);

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  useEffect(() => {
    if (!isOptionsOpen) {
      setSearchValue("");
      setVisibleOptions(
        sortOptions
          ? options
              .sort((a, b) =>
                a[valueFieldName].localeCompare(b[valueFieldName])
              )
              .sort((a, b) => b.selected - a.selected)
          : options
      );
    }
  }, [isOptionsOpen]);

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
    const filteredOption = options.filter((obj) => {
      const substring = e.target.value.toLowerCase();
      const string = obj[valueFieldName].toLowerCase();
      return string.includes(substring);
    });
    setVisibleOptions(
      sortOptions
        ? filteredOption
            .sort((a, b) => a[valueFieldName].localeCompare(b[valueFieldName]))
            .sort((a, b) => b.selected - a.selected)
        : filteredOption
    );
    if (e.key === "Delete" || e.key === "Backspace") {
      console.log("backsapce cliked");
    }
  };

  const onOptionSelectNew = (option, isSelected) => {
    const newSelectedKeySets = updateSelection(
      selectedOptions,
      option,
      isSelected
    );
    onSelectedChange(newSelectedKeySets);
  };

  const updateSelection = (selectedKeys, targetOption, isSelected) => {
    let newSelectedKeySets = new Set(selectedOptions);
    const updateKeys = (option, select) => {
      if (select) {
        newSelectedKeySets.add(option[keyFieldName]);
      } else {
        newSelectedKeySets.delete(option[keyFieldName]);
      }

      if (option.options && option.options.length > 0) {
        option.options.forEach((nestedOption) => {
          updateKeys(nestedOption, select);
        });
      }
    };

    // Update the target option and its nested options
    updateKeys(targetOption, !isSelected);
    // Update parent options
    const updateParentSelection = (options, targetOption) => {
      const parentOption = findParentOption(options, targetOption);
      if (parentOption) {
        const allChildrenSelected = parentOption.options.every((child) =>
          newSelectedKeySets.has(child[keyFieldName])
        );
        if (allChildrenSelected) {
          newSelectedKeySets.add(parentOption[keyFieldName]);
        } else {
          newSelectedKeySets.delete(parentOption[keyFieldName]);
        }

        updateParentSelection(options, parentOption);
      }
    };
    updateParentSelection(visibleOptions, targetOption);
    return Array.from(newSelectedKeySets);
  };

  const findParentOption = (options, childOption) => {
    for (let option of options) {
      if (option.options && option.options.includes(childOption)) {
        return option;
      }
      if (option.options && option.options.length) {
        const parent = findParentOption(option.options, childOption);
        if (parent) return parent;
      }
    }
    return null;
  };

  const onSelectAll = () => {
    let allKeys = [];
    const collectKeys = (option) => {
      allKeys.push(option[keyFieldName]);
      if (option.options && option.options.length > 0) {
        option.options.forEach((nestedOption) => {
          collectKeys(nestedOption);
        });
      }
    };
    visibleOptions.forEach((option) => collectKeys(option));
    onSelectedChange(allKeys);
  };

  const onSelectNone = () => {
    onSelectedChange([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOptionsOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsOptionsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOptionsOpen]);

  const renderOptions = (options, nested) => {
    let isNestedOption = nested;
    options.forEach((option) => {
      if (option.options && option.options.length) {
        isNestedOption = true;
      }
    });

    return options.map((option, index) => {
      const isSelected = selectedOptions.includes(option[keyFieldName]);
      const hasNestedOptions = option.options && option.options.length > 0;

      return (
        <DropdownWrapper
          key={index}
          isselected={showCheckBox ? false : isSelected}
        >
          {isNestedOption &&
            (hasNestedOptions ? (
              <IconArrow
                width={20}
                height={20}
                rotate={option.isOpen ? 90 : 0}
                onIconClick={() => toggleNested(option)}
              />
            ) : (
              <IconWrap />
            ))}
          <DropdownRow>
            <OptionContent>
              {showCheckBox && (
                <CheckBoxWrapper
                  onClick={() => onOptionSelectNew(option, isSelected)}
                >
                  <CheckBox value={isSelected} />
                </CheckBoxWrapper>
              )}
              <DropdownValue
                onClick={() => onOptionSelectNew(option, isSelected)}
              >
                {option[valueFieldName]}
              </DropdownValue>
            </OptionContent>
            {hasNestedOptions && option.isOpen && (
              <NestedOptions>
                {renderOptions(option.options, true, isSelected)}
              </NestedOptions>
            )}
          </DropdownRow>
        </DropdownWrapper>
      );
    });
  };

  const toggleNested = (option) => {
    const toggleOption = (opts) => {
      return opts.map((opt) => {
        if (opt[keyFieldName] === option[keyFieldName]) {
          return { ...opt, isOpen: !opt.isOpen };
        }
        if (opt.options && opt.options.length > 0) {
          return { ...opt, options: toggleOption(opt.options) };
        }
        return opt;
      });
    };
    const updated = toggleOption(visibleOptions);
    setVisibleOptions(updated);
  };

  switch (variant) {
    case "normal": {
      return (
        <Wrapper className={className}>
          <SelectedContainer
            id={id}
            type={type}
            onBlur={(e) => !disabled && onBlur && onBlur(e)}
            disabled={disabled}
            optionsopen={isOptionsOpen.toString()}
            ref={isOptionsOpen ? wrapperRef : null}
            borderRadius={borderRadius}
          >
            <SelectedOption onClick={() => toggleDropdown()}>
              {selectedOptions.length ? (
                <DropdownValue>
                  {mutileSelect
                    ? `${mutileSelectText || "Selected"} [${
                        selectedOptions.length
                      }]`
                    : selectedOptions[0]?.[valueFieldName]}
                </DropdownValue>
              ) : (
                <Placeholder>{placeholder}</Placeholder>
              )}
            </SelectedOption>
            <ArrowContainer
              disabled={disabled}
              ref={isOptionsOpen ? wrapperRef : null}
            >
              <IconArrow
                rotate={isOptionsOpen ? 270 : 90}
                disabled={disabled}
                onIconClick={() => !disabled && toggleDropdown()}
              />
              {isOptionsOpen && (
                <Dropdown borderRadius={borderRadius}>
                  <SearchBox
                    required={required}
                    value={searchValue}
                    onChange={onInputChange}
                    id={id}
                    placeholder={searchPlaceholder}
                    type={type}
                    onBlur={(e) => !disabled && onBlur && onBlur(e)}
                    disabled={disabled}
                    ref={searchRef}
                  ></SearchBox>
                  {mutileSelect && visibleOptions.length ? (
                    <ActionRow>
                      <Action onClick={onSelectAll}>{selectAllText}</Action>
                      <Action onClick={onSelectNone}>{selectNoneText}</Action>
                    </ActionRow>
                  ) : null}
                  <ScrollWrapper>
                    {visibleOptions.length ? <ScrollShadow /> : null}
                    <DropdownScroll>
                      {renderOptions(visibleOptions, false)}
                    </DropdownScroll>
                  </ScrollWrapper>
                </Dropdown>
              )}
            </ArrowContainer>
          </SelectedContainer>
        </Wrapper>
      );
    }
    default: {
      return <p>Invalid Variant Type</p>;
    }
  }
};

export default MultiSelectGraph;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 200px;
  width: 250px;
  height: 35px;
  font-size: 13px;
  ${media.medium`
    height: 30px;
    font-size: 12px;
  `}
  ${media.small`
    height: 20px;
    font-size: 11px;
  `}
`;

// const InputLabel = styled.div`
//   padding: 5px;
//   font-size: 0.8rem;
//   color: rgb(114, 114, 114);
// `;

// const LabelRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   .redtext {
//     color: #f44336;
//     font-size: 16px;
//   }
// `;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 24px);
  height: 100%;
  padding: 5px 5px 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.medium`
    width: calc(100% - 24px);
    padding: 5px 5px 5px 10px;
  `}

  ${media.small`
    width: calc(100% - 24px);
    padding: 5px 0px 5px 5px;
  `}
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: ${(props) => props.borderRadius}px;
  width: 100%;
  transition: 0.2s ease-out;
  transition-property: border;
  background-color: ${(props) => (props.disabled ? "#efefef4d" : "#FFFFFF")};
  position: relative;
`;

const SearchBox = styled.input`
  margin: 8px;
  padding: 5px;
  border-radius: 2px;
  height: 26px;
  transition: 0.2s ease-out;
  transition-property: border;
  border: 1px solid #cccccc;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  :focus {
    border: 1px solid #0099ff;
    outline: none;
  }
  :hover {
    border: 1px solid #0099ff;
    outline: none;
    box-shadow: 0 0 10px #d4eeff;
  }
  :disabled {
  }

  ${media.medium`
    margin: 7px;
    height: 24px;
    font-size: 14px;
  `}

  ${media.small`
    margin: 5px;
    height: 20px;
    font-size: 12px;
  `}
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 6px 7px;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding: 0px 8px;
  background-color: #f2f2f2;
  color: #262e40;
  height: 24px;
  border-radius: 12px;
  min-width: 50px;
  cursor: pointer;

  ${media.medium`
    font-size: 12px;
  `}

  ${media.small`
    font-size: 11px;
  `}
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 34px;
  height: 30px;

  ${media.medium`
    width: 30px;
    height: 24px;
  `}

  ${media.small`
    width: 24px;
    height: 20px;
  `}

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: ${(props) => props.borderRadius}px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 42px;
  width: calc(100% + 2px);
  left: -1px;
  z-index: 6;
  cursor: default;

  ${media.medium`
    top: 32px;
  `}

  ${media.small`
    top: 22px;
  `}
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

const ScrollShadow = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    transparent 100%
  );
  ${media.medium`
    height: 3px;
  `}

  ${media.small`
    height: 3px;
  `}
`;

const DropdownScroll = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 5px 0 0 0px;
  overflow: auto;
`;

const DropdownRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // align-items: center;
  width: 100%;
  // height: 38px;
  padding: 6px 7px 6px 7px;
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px;` : null};
  background-color: ${(props) => (props.isselected ? "#0099FF" : null)};
  color: ${(props) => (props.isselected ? "#FFFFFF" : "#000000")};
  &:hover {
    background-color: ${(props) =>
      props.isselected ? "#0099FF" : "#e5e5e560"};
    // cursor: pointer;
  }

  ${media.medium`
    // height: 35px;
    padding: 6px 7px;
  `}

  ${media.small`
    // height: 30px;
    padding: 5px;
  `}
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 100%;
`;

const NestedOptions = styled.div`
  // padding-left: 20px;
`;

const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
`;

const IconArrow = styled(ArrowIcon)`
  width: 20px;
  height: 20px;
`;

const CheckBoxIcon = styled(CheckBox)`
  width: 20px;
  height: 20px;

  ${media.medium`
    hwidth: 20px;
    height: 20px;
  `}

  ${media.small`
    width: 18px;
    height: 18px;
  `}
`;

const DropdownValue = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  color: #969799;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CheckBoxWrapper = styled.div`
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

import React from "react";
import styled from "styled-components";

const TriangleIcon2 = ({
  width,
  height,
  color = "#E93735",
  rotate = 0,
  disabled = false,
  className,
}) => {
  return (
    <IconWrapper
      rotate={rotate}
      disabled={disabled}
      width={width}
      height={height}
      className={className}
    >
      <svg width={`100%`} height={`100%`} viewBox="0 0 41 41" fill="none">
        <path
          d="M20.0451 40.9053L40.0449 0.905272H0.0449219L20.0451 40.9053Z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default TriangleIcon2;

const IconWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : 40)}px;
  width: ${(props) => (props.width ? props.width : 40)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  }
`;

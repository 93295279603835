import React, { useEffect, useRef } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import styled from "styled-components";

const PowerBiMain = ({ index, i, filter_arr }) => {
  useEffect(() => {
    if (!window.reports) {
      window.reports = [];
    }
  }, []);

  const handleReportLoad = async (event, report) => {
    const filters = [
      // {
      //   table: "content_data player",
      //   column: "player_name",
      //   value: window.localStorage.getItem("player_name"),
      // },
      // {
      //   table: "content_data main_data",
      //   column: "Players",
      //   value: window.localStorage.getItem("drop_dn_player_name"),
      // },
      {
        table: "Currency Table", // INR, USD
        column: "Currency",
        value: window.localStorage.getItem("currency"),
      },
      {
        table: "Currency input", // usd conversion ration, 65-80
        column: "Currency input",
        value:
          window.localStorage.getItem("conversion_type") === "Custom"
            ? Number(window.localStorage.getItem("currency_val")) || 80
            : null,
      },
      {
        table: "Currency_USD_Type", // Dynamic, Custom
        column: "Type",
        value: window.localStorage.getItem("conversion_type"),
      },
      {
        table: "Date Parameter", // CY, FY
        column: "Year_Type",
        value: window.localStorage.getItem("year"),
      },
    ];

    const createFilter = ({ table, column, value }) => {
      if (!table || !column || !value) {
        console.warn(
          `Invalid filter value for table: ${table}, column: ${column}, value: ${value}`
        );
        return null;
      }

      return {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: { table, column },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [{ operator: "Is", value }],
      };
    };

    const appliedFilters = filters.map(createFilter).filter(Boolean);

    const activePage = await report.getActivePage();
    const visuals = await activePage.getVisuals();

    visuals
      .filter((visual) => visual.type === "slicer")
      .forEach(async (slicer) => {
        const state = await slicer.getSlicerState();
        const targetFilter = appliedFilters.find(
          (filter) => filter.target.column === state.targets[0].column
        );
        if (targetFilter) {
          await slicer.setSlicerState({ filters: [targetFilter] });
        }
      });

    const fullWidth = window.innerWidth;
    const new_height =
      (activePage.defaultSize.height / activePage.defaultSize.width) *
      fullWidth;
    const new_width =
      (activePage.defaultSize.width / activePage.defaultSize.width) * fullWidth;

    const reportElement = document.querySelector(
      `.report-style-class-newreport${i}`
    );
    reportElement.style.height = `${new_height}px`;
    reportElement.style.width = `${new_width}px`;
    reportElement.style.backgroundColor = "#F9FDFF";
    reportElement.children[0].style.border = "0px";
    reportElement.children[0].style.backgroundColor = "#F9FDFF";

    const links = document.querySelectorAll(
      'link[href^="https://content.powerapps.com/resource/powerappsportal/dist/preform"]'
    );
    links.forEach((link) => link.remove());
  };

  const parentRef = useRef(null);

  useEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        const curr_report = window.reports.filter((obj) => obj.key === i);
        if (curr_report.length) {
          curr_report[0].embed_report.getActivePage().then((activePage) => {
            const reportElement = document.querySelector(
              `.report-style-class-newreport${i}`
            );

            let active_ht = activePage.defaultSize.height;
            let active_width = activePage.defaultSize.width;
            const fullWidth =
              parentRef.current.offsetWidth || window.innerWidth;
            let new_height = (active_ht / active_width) * fullWidth;
            reportElement.style.height = `${new_height}px`;
            reportElement.style.width = `${fullWidth}px`;
          });
        }
      }
    };
    updateParentWidth();
    window.addEventListener("resize", updateParentWidth);
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, [parentRef]);

  return (
    <Wrapper ref={parentRef}>
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: index.powerbi_report_id,
          embedUrl: index.url,
          accessToken: index.embed,
          tokenType: models.TokenType.Embed,
          filters: filter_arr,
          settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: { displayOption: models.DisplayOption.FitToPage },
            panes: { filters: { expanded: false, visible: false } },
            navContentPaneEnabled: false,
          },
        }}
        eventHandlers={
          new Map([
            ["loaded", handleReportLoad],
            ["error", (event) => console.log("powerbi_error=", event.detail)],
          ])
        }
        cssClassName={`report-style-class-newreport${i}`}
        getEmbeddedComponent={(embeddedReport) => {
          window.reports.push({ key: i, embed_report: embeddedReport });
        }}
      />
    </Wrapper>
  );
};

export default PowerBiMain;

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

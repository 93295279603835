import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CheckBox from "../CheckBox";

const FilterIcon = ({
  low = null,
  high = null,
  showCheckBox = true,
  filteredRange = [],
  onApplySelected = () => {},
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const iconRef = useRef(null);
  const filterApplied = filteredRange.length;

  const onOptionSelect = (option) => {
    const isSelectedClicked = Boolean(
      selectedOptions.filter((obj) => obj.id === option.id).length
    );
    if (isSelectedClicked) {
      const remainingSelected = selectedOptions.filter(
        (obj) => obj.id !== option.id
      );
      setSelectedOption(remainingSelected);
    } else {
      let result = selectedOptions.filter((obj) => obj.id !== option.id);
      result.push(option);
      setSelectedOption(result);
    }
  };

  const onApplyClick = () => {
    onApplySelected(selectedOptions);
    setPopupOpen(false);
  };

  const onResetClick = () => {
    setSelectedOption([]);
    onApplySelected([]);
    setPopupOpen(false);
  };

  useEffect(() => {
    if (low && high) {
      let range = Number(high - low);
      let stepSize = 1;
      range = Math.floor(range / 10);
      while (range) {
        stepSize = stepSize * 10;
        range = Math.floor(range / 10);
      }
      let start = Math.floor(low / stepSize) * stepSize;
      const end = (Math.floor(high / stepSize) + 1) * stepSize;
      let newOptions = [];
      let id = 1;
      while (start < end) {
        newOptions.push({
          id,
          name: `${start} - ${start + stepSize}`,
          low: start,
          high: start + stepSize,
          selected: false,
        });
        id++;
        start += stepSize;
      }
      setOptions(newOptions);
    }
  }, [low, high]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isPopupOpen &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <FilterWrapper>
      <IconWrap
        onClick={() => setPopupOpen(!isPopupOpen)}
        ref={isPopupOpen ? iconRef : null}
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          role="img"
          viewBox="0 0 512 512"
        >
          <path
            fill={filterApplied ? "#000000" : "#BFBFBF"}
            d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z"
          ></path>
        </svg>
      </IconWrap>
      {isPopupOpen && (
        <>
          <Triangle />
          <Dropdown ref={isPopupOpen ? iconRef : null}>
            {/* <SearchBox
            required={required}
            value={searchValue}
            onChange={onInputChange}
            id={id}
            placeholder={searchPlaceholder}
            type={type}
            onBlur={(e) => !disabled && onBlur && onBlur(e)}
            disabled={disabled}
            ref={searchRef}
          ></SearchBox> */}
            <ActionRow>Select Range (Mn)</ActionRow>
            <ScrollWrapper>
              <ScrollShadowTop />
              {options.length ? (
                <>
                  <DropdownScroll>
                    {options.map((option, index) => {
                      const isselected = selectedOptions.filter(
                        (obj) => obj.id === option.id
                      ).length;
                      return (
                        <DropdownOption
                          key={index}
                          isselected={showCheckBox ? false : isselected}
                          onClick={() => {
                            onOptionSelect(option);
                          }}
                        >
                          {showCheckBox && (
                            <CheckBoxWrapper>
                              {" "}
                              <CheckBox value={isselected} />
                            </CheckBoxWrapper>
                          )}
                          <DropdownValue>{option.name}</DropdownValue>
                        </DropdownOption>
                      );
                    })}
                  </DropdownScroll>
                  <ScrollShadowBot />
                </>
              ) : (
                <NoDataMessage>No Data Available</NoDataMessage>
              )}
            </ScrollWrapper>
            {options.length ? (
              <ActionRow>
                <ActionApply onClick={onApplyClick}>Apply</ActionApply>
                <ActionReset onClick={onResetClick}>Reset</ActionReset>
              </ActionRow>
            ) : null}
          </Dropdown>
        </>
      )}
    </FilterWrapper>
  );
};

export default FilterIcon;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
  font-weight: 400;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 18px;
  height: 18px;
`;

const Triangle = styled.div`
  content: "";
  position: absolute;
  height: 8px;
  width: 16px;
  top: 29px;
  z-index: 10;
  background-color: #ffffff;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  height: 40px;
`;

const InputLabel = styled.div`
  padding: 5px;
  font-size: 0.8rem;
  color: rgb(114, 114, 114);
`;

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .redtext {
    color: #f44336;
    font-size: 16px;
  }
`;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px 10px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: ${(props) =>
    props.optionsopen === "true" ? "5px 5px 0px 0px" : "5px"};
  border-radius: 5px;
  width: 100%;
  transition: 0.2s ease-out;
  transition-property: border;
  background-color: ${(props) => (props.disabled ? "#efefef4d" : "#FFFFFF")};
  position: relative;
`;

const SearchBox = styled.input`
  margin: 8px;
  padding: 5px;
  border-radius: 2px;
  height: 26px;
  transition: 0.2s ease-out;
  transition-property: border;
  border: 1px solid #cccccc;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  :focus {
    border: 1px solid #0099ff;
    outline: none;
  }
  :hover {
    border: 1px solid #0099ff;
    outline: none;
    box-shadow: 0 0 10px #d4eeff;
  }
  :disabled {
  }
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 6px 7px;
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;
const ActionApply = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 8px;
  background-color: #0099ff;
  color: #ffffff;
  border-radius: 24px;
  cursor: pointer;
`;

const ActionReset = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 8px;
  background-color: #ffffff;
  color: #0099ff;
  border-radius: 24px;
  border: 1px solid #0099ff;
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 34px;
  height: 30px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 5;
  cursor: default;
  position: absolute;
  top: 36px;
  right: -10px;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

const ScrollShadowTop = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    transparent 100%
  );
`;

const ScrollShadowBot = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  bottom: 0px;
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
`;

const DropdownScroll = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  overflow: auto;
`;

const DropdownOption = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 38px;
  padding: 6px 7px;
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px;` : null};
  background-color: ${(props) => (props.isselected ? "#0099FF" : null)};
  color: ${(props) => (props.isselected ? "#FFFFFF" : "#000000")};
  &:hover {
    background-color: ${(props) =>
      props.isselected ? "#0099FF" : "#e5e5e560"};
    cursor: pointer;
  }
`;

const DropdownValue = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Placeholder = styled.div`
  color: #969799;
  font-size: 13px;
  font-style: normal;
`;

const CheckBoxWrapper = styled.div`
  margin-right: 5px;
`;

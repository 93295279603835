import { withSuccess } from "antd/es/modal/confirm";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import "./customCircularProgressbar.css"; 
import { media } from "../../utils/media";

const RatingCircle = ({ props , height = 50, width = 50 }) => {
  return (
    <CircleBackground heigh={height} width={width} className="custom-circular-progressbar">
      <CircularProgressbarWithChildren
        value={props * 10}
        styles={buildStyles({
          pathColor: customColor(props),
          trailColor: "white",
          strokeLinecap:"square"
        })}
      >
        <div
          style={{
            fontSize: 0.4 * width,
            fontWeight: 600,
            color: customColor(props),
          }}
        >
          {props}
        </div>
      </CircularProgressbarWithChildren>
    </CircleBackground>
  );

  function customColor(props) {
    if (props >= 7) return "#21AF4A";
    else if (props >= 4.5) return "#FCBB53";
    else return "#DA1E28";
  }
};

export default RatingCircle;

const CircleBackground = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  ${media.small`
  height: ${({ height }) => height/1.4}px;
  width: ${({ width }) => width/1.4}px;
  
  
  `}
`;

import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { media } from "../../../utils/media.js";
import moment from "moment";
import TriangleIcon2 from "../../svg/TriangleIcon2.js";
import KpiCard from "./KpiCard/index.js";
import { cloneDeep } from "lodash";

const exchangeKey = (frequency) => {
  if (frequency === "M") {
    return "m_value";
  } else if (frequency === "Q") {
    return "q_value";
  } else if (frequency === "Y") {
    return "cy_value";
  } else if (frequency === "FY") {
    return "fy_value";
  } else {
    return "invalid_period_type";
  }
};

const getNextDate = (date, frequency) => {
  const dataDateObj = moment(date, "YYYY-MM-DD");
  if (frequency === "M") {
    return dataDateObj.add(1, "years").format("YYYY-MM-DD");
  } else if (frequency === "Q") {
    return dataDateObj.add(1, "years").format("YYYY-MM-DD");
  } else if (frequency === "Y" || frequency === "FY") {
    return dataDateObj.add(1, "years").format("YYYY-MM-DD");
  } else {
    return "invalid_period_type";
  }
};

const getArrayAvg = (numbers) => {
  const sum = numbers.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return sum / numbers.length;
};

const getArraySum = (numbers) => {
  return numbers.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
};

const getDataSummation = (
  dataList,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType
) => {
  let formattedData = {};
  const exchange_key = exchangeKey(selectedPeriodType);
  dataList.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      formattedData[labelValue][valObj.date] = formattedData[labelValue][
        valObj.date
      ]
        ? formattedData[labelValue][valObj.date] + valObj.value
        : valObj.value;
    });
  });

  let newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      let value = labelObj[date] / scaleValue;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[date]
          ? exchangeRate[date][exchange_key]
          : 80;
        value = value / exchange;
      }
      labeldata.push({ value: value, date: date });
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const getYoyData = (
  dataList,
  dataListMonth,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType,
  aggregation_type,
  topOption,
  include_overall
) => {
  let AbsoluteData = dataList.filter((data) => data.data_type === "Absolute");
  let AbsoluteDataMonth = dataListMonth.filter(
    (data) => data.data_type === "Absolute"
  );
  let formattedData = {};
  const exchange_key = exchangeKey(selectedPeriodType);
  AbsoluteData.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = 0;
      }
      let value = valObj.value;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[valObj.date]
          ? exchangeRate[valObj.date][exchange_key]
          : 80;
        value = value / exchange;
      }
      formattedData[labelValue][valObj.date] =
        formattedData[labelValue][valObj.date] + value;
    });
  });

  let formattedDataMonth = {};
  const exchange_key_month = exchangeKey("M");
  AbsoluteDataMonth.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedDataMonth[labelValue]) {
      formattedDataMonth[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedDataMonth[labelValue][valObj.date]) {
        formattedDataMonth[labelValue][valObj.date] = 0;
      }
      let value = valObj.value;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[valObj.date]
          ? exchangeRate[valObj.date][exchange_key_month]
          : 80;
        value = value / exchange;
      }
      formattedDataMonth[labelValue][valObj.date] =
        formattedDataMonth[labelValue][valObj.date] + value;
    });
  });

  let all_dates_month = Object.keys(formattedDataMonth).flatMap((label) =>
    Object.keys(formattedDataMonth[label])
  );
  const max_date_month = all_dates_month.sort((a, b) =>
    moment(b).diff(moment(a))
  )[0];

  let all_dates = Object.keys(formattedData).flatMap((label) =>
    Object.keys(formattedData[label])
  );
  const max_date = all_dates.sort((a, b) => moment(b).diff(moment(a)))[0];

  let overallAbsoluteData = {};
  const yoyFormattedData = [];
  Object.keys(formattedData).forEach((labelValue) => {
    const labelObj = formattedData[labelValue];
    const labeldata = [];
    const min_date = Object.keys(labelObj).sort((a, b) =>
      moment(a).diff(moment(b))
    )[0];
    Object.keys(labelObj).forEach((prevDate) => {
      const currDate = getNextDate(prevDate, selectedPeriodType);
      if (
        ((selectedPeriodType === "FY" || selectedPeriodType === "Y") &&
          prevDate === min_date) ||
        prevDate === max_date
      ) {
      } else if (
        (selectedPeriodType === "FY" ||
          selectedPeriodType === "Y" ||
          selectedPeriodType === "Q") &&
        currDate === max_date
      ) {
        // Create date pairs based on the period type
        if (Object.keys(formattedDataMonth).includes(labelValue)) {
          let datePairs = [];
          if (selectedPeriodType === "FY" || selectedPeriodType === "Y") {
            let prevDateMoment = moment(prevDate);
            let nextDateMoment = moment(currDate);
            // let endDateMoment = moment(currDate).add(1, 'year').subtract(1, 'month');

            while (prevDateMoment.isBefore(moment(currDate))) {
              datePairs.push({
                prev: prevDateMoment.format("YYYY-MM-DD"),
                curr: nextDateMoment.format("YYYY-MM-DD"),
              });
              prevDateMoment.add(1, "month");
              nextDateMoment.add(1, "month");
            }
          } else if (selectedPeriodType === "Q") {
            let prevQuarterStart = moment(prevDate).startOf("quarter");
            let currQuarterStart = moment(currDate).startOf("quarter");

            for (let i = 0; i < 3; i++) {
              datePairs.push({
                prev: prevQuarterStart.format("YYYY-MM-DD"),
                curr: currQuarterStart.format("YYYY-MM-DD"),
              });
              prevQuarterStart.add(1, "month");
              currQuarterStart.add(1, "month");
            }
          }

          // Aggregate data for the relevant periods
          let totalPrev = 0;
          let totalCurr = 0;

          let totalPrevOverall = 0;
          let totalCurrOverall = 0;
          let totalPrevOverallFound = false;
          let totalCurrOverallFound = false;
          for (let pair of datePairs) {
            if (moment(pair.curr).isSameOrBefore(max_date_month)) {
              let prevValue = formattedDataMonth[labelValue][pair.prev];
              let currValue = formattedDataMonth[labelValue][pair.curr];

              if (prevValue !== undefined && currValue !== undefined) {
                totalPrev += prevValue;
                totalCurr += currValue;
              }

              if (prevValue !== undefined) {
                totalPrevOverall += prevValue;
                totalPrevOverallFound = true;
              }
              if (currValue !== undefined) {
                totalCurrOverall += currValue;
                totalCurrOverallFound = true;
              }
            }
          }

          // Calculate YoY growth
          if (totalPrev !== 0) {
            let yoy_value =
              ((totalCurr - totalPrev) / Math.abs(totalPrev)) * 100; //
            labeldata.push({
              value: yoy_value,
              date: currDate,
            });
          }

          // YoY data for Overall
          if (
            datePairs.length &&
            (topOption.length === 0 || topOption.includes(labelValue))
          ) {
            if (!overallAbsoluteData[currDate]) {
              overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
            }
            if (totalCurrOverallFound) {
              overallAbsoluteData[currDate].currValue.push(totalCurrOverall);
            }
            if (totalPrevOverallFound) {
              overallAbsoluteData[currDate].prevValue.push(totalPrevOverall);
            }
          }
        }
      } else {
        // Calculate YoY growth
        if (currDate in labelObj && labelObj[prevDate]) {
          let valuePrev = labelObj[prevDate];
          let valueCurr = labelObj[currDate];
          let yoy_value = ((valueCurr - valuePrev) / Math.abs(valuePrev)) * 100; //
          labeldata.push({
            value: yoy_value,
            date: currDate,
          });
        }

        // YoY data for Overall
        if (topOption.length === 0 || topOption.includes(labelValue)) {
          if (!overallAbsoluteData[currDate]) {
            overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
          }
          if (currDate in labelObj) {
            overallAbsoluteData[currDate].currValue.push(labelObj[currDate]);
          }
          if (prevDate in labelObj) {
            // always true anyways
            overallAbsoluteData[currDate].prevValue.push(labelObj[prevDate]);
          }
        }
      }
    });
    if (labeldata.length) {
      yoyFormattedData.push({
        label: labelValue,
        data: labeldata,
      });
    }
  });

  const overallData = [];
  Object.keys(overallAbsoluteData).forEach((date) => {
    let totalCurrValue = null;
    let totalPrevValue = null;

    if (aggregation_type === "sum" || aggregation_type === "average of sum") {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArraySum(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArraySum(overallAbsoluteData[date].prevValue)
        : null;
    } else if (
      aggregation_type === "average" ||
      aggregation_type === "sum of average"
    ) {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArrayAvg(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArrayAvg(overallAbsoluteData[date].prevValue)
        : null;
    }

    if (totalCurrValue !== null && totalPrevValue !== null) {
      overallData.push({
        value:
          ((totalCurrValue - totalPrevValue) / Math.abs(totalPrevValue)) * 100, //
        date: date,
      });
    }
  });
  if (include_overall && overallData.length) {
    yoyFormattedData.push({
      label: "Overall",
      data: overallData,
    });
  }
  return yoyFormattedData;
};

const getYoyCustomData = (
  absoluteData,
  absoluteDataMonth,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType,
  aggregation_type,
  topOption,
  include_overall
) => {
  let formattedData = {};
  absoluteData.forEach(({ data, label }) => {
    if (!formattedData[label]) {
      formattedData[label] = {};
    }
    data.forEach((valObj) => {
      if (!formattedData[label][valObj.date]) {
        formattedData[label][valObj.date] = 0;
      }
      let value = valObj.value;
      formattedData[label][valObj.date] =
        formattedData[label][valObj.date] + value;
    });
  });

  let formattedDataMonth = {};
  absoluteDataMonth.forEach(({ data, label }) => {
    if (!formattedDataMonth[label]) {
      formattedDataMonth[label] = {};
    }
    data.forEach((valObj) => {
      if (!formattedDataMonth[label][valObj.date]) {
        formattedDataMonth[label][valObj.date] = 0;
      }
      let value = valObj.value;
      formattedDataMonth[label][valObj.date] =
        formattedDataMonth[label][valObj.date] + value;
    });
  });

  let all_dates_month = Object.keys(formattedDataMonth).flatMap((label) =>
    Object.keys(formattedDataMonth[label])
  );
  const max_date_month = all_dates_month.sort((a, b) =>
    moment(b).diff(moment(a))
  )[0];

  let all_dates = Object.keys(formattedData).flatMap((label) =>
    Object.keys(formattedData[label])
  );
  const max_date = all_dates.sort((a, b) => moment(b).diff(moment(a)))[0];

  let overallAbsoluteData = {};
  const yoyFormattedData = [];
  Object.keys(formattedData).forEach((labelValue) => {
    const labelObj = formattedData[labelValue];
    const labeldata = [];
    const min_date = Object.keys(labelObj).sort((a, b) =>
      moment(a).diff(moment(b))
    )[0];
    Object.keys(labelObj).forEach((prevDate) => {
      const currDate = getNextDate(prevDate, selectedPeriodType);
      if (
        ((selectedPeriodType === "FY" || selectedPeriodType === "Y") &&
          prevDate === min_date) ||
        prevDate === max_date
      ) {
      } else if (
        (selectedPeriodType === "FY" ||
          selectedPeriodType === "Y" ||
          selectedPeriodType === "Q") &&
        currDate === max_date
      ) {
        // Create date pairs based on the period type
        if (Object.keys(formattedDataMonth).includes(labelValue)) {
          let datePairs = [];
          if (selectedPeriodType === "FY" || selectedPeriodType === "Y") {
            let prevDateMoment = moment(prevDate);
            let nextDateMoment = moment(currDate);
            // let endDateMoment = moment(currDate).add(1, 'year').subtract(1, 'month');

            while (prevDateMoment.isBefore(moment(currDate))) {
              datePairs.push({
                prev: prevDateMoment.format("YYYY-MM-DD"),
                curr: nextDateMoment.format("YYYY-MM-DD"),
              });
              prevDateMoment.add(1, "month");
              nextDateMoment.add(1, "month");
            }
          } else if (selectedPeriodType === "Q") {
            let prevQuarterStart = moment(prevDate).startOf("quarter");
            let currQuarterStart = moment(currDate).startOf("quarter");

            for (let i = 0; i < 3; i++) {
              datePairs.push({
                prev: prevQuarterStart.format("YYYY-MM-DD"),
                curr: currQuarterStart.format("YYYY-MM-DD"),
              });
              prevQuarterStart.add(1, "month");
              currQuarterStart.add(1, "month");
            }
          }

          // Aggregate data for the relevant periods
          let totalPrev = 0;
          let totalCurr = 0;

          let totalPrevOverall = 0;
          let totalCurrOverall = 0;
          let totalPrevOverallFound = false;
          let totalCurrOverallFound = false;
          for (let pair of datePairs) {
            if (moment(pair.curr).isSameOrBefore(max_date_month)) {
              let prevValue = formattedDataMonth[labelValue][pair.prev];
              let currValue = formattedDataMonth[labelValue][pair.curr];

              if (prevValue !== undefined && currValue !== undefined) {
                totalPrev += prevValue;
                totalCurr += currValue;
              }

              if (prevValue !== undefined) {
                totalPrevOverall += prevValue;
                totalPrevOverallFound = true;
              }
              if (currValue !== undefined) {
                totalCurrOverall += currValue;
                totalCurrOverallFound = true;
              }
            }
          }

          // Calculate YoY growth
          if (totalPrev !== 0) {
            let yoy_value =
              ((totalCurr - totalPrev) / Math.abs(totalPrev)) * 100; //
            labeldata.push({
              value: yoy_value,
              date: currDate,
            });
          }

          // YoY data for Overall
          if (
            datePairs.length &&
            (topOption.length === 0 || topOption.includes(labelValue))
          ) {
            if (!overallAbsoluteData[currDate]) {
              overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
            }
            if (totalCurrOverallFound) {
              overallAbsoluteData[currDate].currValue.push(totalCurrOverall);
            }
            if (totalPrevOverallFound) {
              overallAbsoluteData[currDate].prevValue.push(totalPrevOverall);
            }
          }
        }
      } else {
        // Calculate YoY growth
        if (currDate in labelObj && labelObj[prevDate]) {
          let valuePrev = labelObj[prevDate];
          let valueCurr = labelObj[currDate];
          let yoy_value = ((valueCurr - valuePrev) / Math.abs(valuePrev)) * 100; //
          labeldata.push({
            value: yoy_value,
            date: currDate,
          });
        }

        // YoY data for Overall
        if (topOption.length === 0 || topOption.includes(labelValue)) {
          if (!overallAbsoluteData[currDate]) {
            overallAbsoluteData[currDate] = { prevValue: [], currValue: [] };
          }
          if (currDate in labelObj) {
            overallAbsoluteData[currDate].currValue.push(labelObj[currDate]);
          }
          if (prevDate in labelObj) {
            // always true anyways
            overallAbsoluteData[currDate].prevValue.push(labelObj[prevDate]);
          }
        }
      }
    });
    if (labeldata.length) {
      yoyFormattedData.push({
        label: labelValue,
        data: labeldata,
      });
    }
  });

  const overallData = [];
  Object.keys(overallAbsoluteData).forEach((date) => {
    let totalCurrValue = null;
    let totalPrevValue = null;

    if (aggregation_type === "sum" || aggregation_type === "average of sum") {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArraySum(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArraySum(overallAbsoluteData[date].prevValue)
        : null;
    } else if (
      aggregation_type === "average" ||
      aggregation_type === "sum of average"
    ) {
      totalCurrValue = overallAbsoluteData[date].currValue.length
        ? getArrayAvg(overallAbsoluteData[date].currValue)
        : null;
      totalPrevValue = overallAbsoluteData[date].prevValue.length
        ? getArrayAvg(overallAbsoluteData[date].prevValue)
        : null;
    }

    if (totalCurrValue !== null && totalPrevValue !== null) {
      overallData.push({
        value:
          ((totalCurrValue - totalPrevValue) / Math.abs(totalPrevValue)) * 100, //
        date: date,
      });
    }
  });
  if (include_overall && overallData.length) {
    yoyFormattedData.push({
      label: "Overall",
      data: overallData,
    });
  }
  return yoyFormattedData;
};

const getDataAverage = (
  dataList,
  labelKey,
  scaleValue,
  is_usd,
  is_currency,
  custUsdExchange,
  exchangeRate,
  selectedPeriodType
) => {
  let formattedData = {};
  const exchange_key = exchangeKey(selectedPeriodType);
  dataList.forEach((dataObj) => {
    const labelValue = dataObj[labelKey] || "Value";
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = [];
      }
      formattedData[labelValue][valObj.date].push(valObj.value);
    });
  });

  const newformattedData = Object.keys(formattedData).map((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      let value = getArrayAvg(labelObj[date]) / scaleValue;
      if (is_usd && is_currency) {
        let exchange = custUsdExchange
          ? custUsdExchange
          : exchangeRate[date]
          ? exchangeRate[date][exchange_key]
          : 80;
        value = value / exchange;
      }
      labeldata.push({
        value: value,
        date: date,
      });
    });
    return {
      label: key,
      data: labeldata,
    };
  });
  return newformattedData;
};

const getDataDivision = (data1, data2, scaleValue, is_usd) => {
  let formattedData = {};
  data1.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value1"] = valObj.value;
    });
  });

  data2.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value2"] = valObj.value;
    });
  });

  const newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      if (labelObj[date].value2) {
        labeldata.push({
          value: labelObj[date].value1 / (labelObj[date].value2 * scaleValue),
          date: date,
        });
      }
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const getDataDivisionPercentage = (data1, data2, scaleValue, is_usd) => {
  let formattedData = {};
  data1.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value1"] = valObj.value;
    });
  });

  data2.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value2"] = valObj.value;
    });
  });

  const newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      if (labelObj[date].value2) {
        labeldata.push({
          value:
            (labelObj[date].value1 / (labelObj[date].value2 * scaleValue)) *
            100,
          date: date,
        });
      }
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const getDataMultiplication = (data1, data2, scaleValue) => {
  let formattedData = {};
  data1.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value1"] = valObj.value;
    });
  });

  data2.forEach((dataObj) => {
    const labelValue = dataObj["label"];
    if (!formattedData[labelValue]) {
      formattedData[labelValue] = {};
    }
    dataObj.data.forEach((valObj) => {
      if (!formattedData[labelValue][valObj.date]) {
        formattedData[labelValue][valObj.date] = { value1: null, value2: null };
      }
      formattedData[labelValue][valObj.date]["value2"] = valObj.value;
    });
  });

  const newformattedData = [];
  Object.keys(formattedData).forEach((key) => {
    const labelObj = formattedData[key];
    const labeldata = [];
    Object.keys(labelObj).forEach((date) => {
      if (labelObj[date].value2 != null && labelObj[date].value1 != null) {
        labeldata.push({
          value: (labelObj[date].value1 * labelObj[date].value2) / scaleValue,
          date: date,
        });
      }
    });
    if (labeldata.length) {
      newformattedData.push({
        label: key,
        data: labeldata,
      });
    }
  });
  return newformattedData;
};

const KpiWrap = ({
  is_usd = false,
  custUsdExchange,
  exchangeRate,
  yearPeriod = "CY",

  currentSlicer = null,
  slicerList = [],
  dataList = [],
  className,
}) => {
  const [allDataList, setAllDataList] = useState([]);
  const [selectedSlicer, setSelectedSlicer] = useState(null);
  const [allSlicer, setAllSlicer] = useState(null);
  const [selectedPeriodType, setSeletedPeriodType] = useState(null);

  const [selectedDataType, setSeletedDataType] = useState(null);
  const [selectedDataType2, setSeletedDataType2] = useState(null);

  const [graphData, setGraphData] = useState([]);
  const [graphData2, setGraphData2] = useState([]);
  const [latestDate, setLatestDate] = useState(null);
  const [value, setValue] = useState(null);
  const [increment, setIncrement] = useState(null);
  const [isScale, setScale] = useState(false);
  const [isCurrency, setCurrency] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      setAllDataList(dataList);
      setAllSlicer(slicerList);
      if (currentSlicer) {
        setError("");
        setSelectedSlicer(currentSlicer);
        setSeletedPeriodType(
          currentSlicer.period_type.filter((a) => a !== "FY").length > 0
            ? currentSlicer.period_type.filter((a) => a !== "FY")[0]
            : null
        );

        setSeletedDataType(
          currentSlicer.data_type.length > 0 ? currentSlicer.data_type[0] : null
        );
        setSeletedDataType2(
          currentSlicer.data_type.length > 1 ? currentSlicer.data_type[1] : null
        );
        setIsLoading(false);
      } else {
        console.error("No visible Slicer Found");
        setError(
          "Oops! Something went wrong. Please report this issue to your POC."
        );
        setIsLoading(false);
      }
    } catch (err) {
      console.error(`Error in page render, err-${err}`);
      setError(
        "Oops! Something went wrong. Please report this issue to your POC."
      );
      setIsLoading(false);
    }
  }, [currentSlicer, dataList]);

  const applyFilters = (dataList, filterResult) => {
    const filters = filterResult.reduce((acc, filter) => {
      acc[filter.key] = new Set(filter.value);
      return acc;
    }, {});

    return dataList.filter((data) => {
      return Object.keys(filters).every((key) => {
        return !filters[key].size || filters[key].has(data[key]);
      });
    });
  };

  useEffect(() => {
    try {
      if (isLoading) {
        return;
      }
      const data_type = selectedSlicer?.data_type;
      if (!data_type) {
        console.error("Datatypes not found, in card-", selectedSlicer?.title);
        setError(
          "Oops! Something went wrong. Please report this issue to your POC."
        );
        return;
      }
      setError("");
      if (allDataList.length === 0) {
        setGraphData([]);
        return;
      }

      let make_calulation = false;
      if (selectedSlicer) {
        make_calulation = selectedSlicer.custom_data?.skip_data;
      } else {
        return;
      }
      if (make_calulation) {
        // custom calculation
        let dependeds = [];
        let calculation_type = "";
        let is_result_scaled = false;
        let is_result_currency = false;

        dependeds = selectedSlicer.custom_data.slicer_list;
        calculation_type = selectedSlicer.custom_data.calculation_type;
        is_result_scaled = selectedSlicer?.is_scaled || false;
        is_result_currency = selectedSlicer?.is_currency || false;

        if (dependeds.length !== 2) {
          setGraphData([]);
          return;
        }
        let data1 = [];
        let data2 = [];

        let filtered_object = [];
        if (selectedDataType) {
          filtered_object.push({
            key: "data_type",
            value: [
              selectedDataType.name === "100%"
                ? "Absolute"
                : selectedDataType.name,
            ],
          });
        }

        // this will be used in case of yoy
        let filtered_object_month = cloneDeep(filtered_object);
        filtered_object_month.push({
          key: "period_type",
          value: ["M"],
        });

        if (selectedPeriodType) {
          filtered_object.push({
            key: "period_type",
            value: [
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType,
            ],
          });
        }

        const scaleValue =
          is_result_scaled && selectedDataType.name === "Absolute"
            ? is_usd
              ? 1000000
              : 10000000
            : 1;
        //new calculations
        let filtered_object1;
        let is_currency1;
        let aggregation_type1;

        let filtered_object2;
        let is_currency2;
        let aggregation_type2;

        // only slicer
        //first filter
        filtered_object1 = cloneDeep(filtered_object);
        const slicer_dep1 = allSlicer.filter(
          (obj) => obj.name === dependeds[0]
        );
        if (slicer_dep1.length === 0) {
          throw new Error(
            `Dependency in custom_data.slicer_list "${
              dependeds[0]
            }" not found in all slicer  - ${JSON.stringify(
              allSlicer.map((obj) => obj.name)
            )}`
          );
        }
        is_currency1 = slicer_dep1[0].is_currency;
        aggregation_type1 = slicer_dep1[0].aggregation_type || "";
        filtered_object1.push({
          key: "slicer",
          value: [dependeds[0]],
        });

        //second filter
        filtered_object2 = cloneDeep(filtered_object);
        const slicer_dep2 = allSlicer.filter(
          (obj) => obj.name === dependeds[1]
        );
        if (slicer_dep2.length === 0) {
          throw new Error(
            `Dependency in custom_data.slicer_list "${
              dependeds[1]
            }" not found in all slicer  - ${JSON.stringify(
              allSlicer.map((obj) => obj.name)
            )}`
          );
        }
        is_currency2 = slicer_dep2[0].is_currency;
        aggregation_type2 = slicer_dep2[0].aggregation_type || "";
        filtered_object2.push({
          key: "slicer",
          value: [dependeds[1]],
        });

        //first data
        const filteredDataList1 = applyFilters(allDataList, filtered_object1);
        if (
          aggregation_type1 === "sum" ||
          aggregation_type1 === "average of sum"
        ) {
          data1 = getDataSummation(
            filteredDataList1,
            null,
            1,
            is_usd,
            is_currency1 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        } else if (
          aggregation_type1 === "average" ||
          aggregation_type1 === "sum of average"
        ) {
          data1 = getDataAverage(
            filteredDataList1,
            null,
            1,
            is_usd,
            is_currency1 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        }

        //second data
        const filteredDataList2 = applyFilters(allDataList, filtered_object2);
        if (
          aggregation_type2 === "sum" ||
          aggregation_type2 === "average of sum"
        ) {
          data2 = getDataSummation(
            filteredDataList2,
            null,
            1,
            is_usd,
            is_currency2 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        } else if (
          aggregation_type2 === "average" ||
          aggregation_type2 === "sum of average"
        ) {
          data2 = getDataAverage(
            filteredDataList2,
            null,
            1,
            is_usd,
            is_currency2 && selectedDataType.name === "Absolute",
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType
          );
        }

        let data = [];
        if (calculation_type === "division") {
          data = getDataDivision(data1, data2, scaleValue);
        } else if (calculation_type === "division_percentage") {
          data = getDataDivisionPercentage(data1, data2, scaleValue);
        } else if (calculation_type === "multiplication") {
          data = getDataMultiplication(data1, data2, scaleValue);
        }

        if (
          selectedDataType.name === "100%" ||
          selectedDataType.name === "Absolute"
        ) {
          setGraphData(data);
          // calculate the yoy part as well
          let filtered_object1_month;
          let is_currency1_month;
          let aggregation_type1_month;

          let filtered_object2_month;
          let is_currency2_month;
          let aggregation_type2_month;

          // only slicer
          // first filter
          filtered_object1_month = cloneDeep(filtered_object_month);
          const slicer_dep1 = allSlicer.filter(
            (obj) => obj.name === dependeds[0]
          );
          if (slicer_dep1.length === 0) {
            throw new Error(
              `Dependency in custom_data.slicer_list "${
                dependeds[0]
              }" not found in all slicer  - ${JSON.stringify(
                allSlicer.map((obj) => obj.name)
              )}`
            );
          }
          is_currency1_month = slicer_dep1[0].is_currency;
          aggregation_type1_month = slicer_dep1[0].aggregation_type || "";
          filtered_object1_month.push({
            key: "slicer",
            value: [dependeds[0]],
          });

          //second filter
          filtered_object2_month = cloneDeep(filtered_object_month);
          const slicer_dep2 = allSlicer.filter(
            (obj) => obj.name === dependeds[1]
          );
          if (slicer_dep2.length === 0) {
            throw new Error(
              `Dependency in custom_data.slicer_list "${
                dependeds[1]
              }" not found in all slicer  - ${JSON.stringify(
                allSlicer.map((obj) => obj.name)
              )}`
            );
          }
          is_currency2_month = slicer_dep2[0].is_currency;
          aggregation_type2_month = slicer_dep2[0].aggregation_type || "";
          filtered_object2_month.push({
            key: "slicer",
            value: [dependeds[1]],
          });

          //first data
          const filteredDataList1 = applyFilters(
            allDataList,
            filtered_object1_month
          );
          if (
            aggregation_type1_month === "sum" ||
            aggregation_type1_month === "average of sum"
          ) {
            data1 = getDataSummation(
              filteredDataList1,
              null,
              1,
              is_usd,
              is_currency1_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          } else if (
            aggregation_type1_month === "average" ||
            aggregation_type1_month === "sum of average"
          ) {
            data1 = getDataAverage(
              filteredDataList1,
              null,
              1,
              is_usd,
              is_currency1_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          }

          //second data
          const filteredDataList2 = applyFilters(
            allDataList,
            filtered_object2_month
          );
          if (
            aggregation_type2_month === "sum" ||
            aggregation_type2_month === "average of sum"
          ) {
            data2 = getDataSummation(
              filteredDataList2,
              null,
              1,
              is_usd,
              is_currency2_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          } else if (
            aggregation_type2_month === "average" ||
            aggregation_type2_month === "sum of average"
          ) {
            data2 = getDataAverage(
              filteredDataList2,
              null,
              1,
              is_usd,
              is_currency2_month && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType
            );
          }

          //custom data
          let data_month = [];
          if (calculation_type === "division") {
            data_month = getDataDivision(data1, data2, scaleValue);
          } else if (calculation_type === "division_percentage") {
            data_month = getDataDivisionPercentage(data1, data2, scaleValue);
          } else if (calculation_type === "multiplication") {
            data_month = getDataMultiplication(data1, data2, scaleValue);
          }
          const data_yoy = getYoyCustomData(
            data,
            data_month,
            null,
            1,
            is_usd,
            false, // is currency
            custUsdExchange,
            exchangeRate,
            selectedPeriodType === "Y" && yearPeriod === "FY"
              ? "FY"
              : selectedPeriodType,
            "sum", //aggregation_type
            [],
            false
          );
          setGraphData2(data_yoy);
        }

        setScale(is_result_scaled);
        setCurrency(is_result_currency);
      } else {
        // calculation not required
        if (selectedDataType2.name === "YOY Growth") {
          //true always
          let filtered_object = [];
          let aggregation_type = "";
          let is_scaled = false;
          let is_currency = false;
          if (selectedSlicer) {
            filtered_object.push({
              key: "slicer",
              value: [selectedSlicer.name],
            });
            aggregation_type = selectedSlicer.aggregation_type || "";
            is_scaled = selectedSlicer?.is_scaled || false;
            is_currency = selectedSlicer?.is_currency || false;
          }

          let filtered_object_month = cloneDeep(filtered_object);
          filtered_object_month.push({
            key: "period_type",
            value: ["M"],
          });

          if (selectedPeriodType) {
            filtered_object.push({
              key: "period_type",
              value: [
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType,
              ],
            });
          }

          setScale(is_scaled);
          setCurrency(is_currency);
          const filteredDataList1 = applyFilters(allDataList, filtered_object);
          const filteredDataList1Month = applyFilters(
            allDataList,
            filtered_object_month
          );
          setGraphData2(
            getYoyData(
              filteredDataList1,
              filteredDataList1Month,
              null,
              1,
              is_usd,
              is_currency && selectedDataType.name === "Absolute",
              custUsdExchange,
              exchangeRate,
              selectedPeriodType === "Y" && yearPeriod === "FY"
                ? "FY"
                : selectedPeriodType,
              aggregation_type,
              [],
              false
            )
          );
        }

        if (true) {
          // non YOY case

          let filtered_object = [];
          let aggregation_type = "";
          let is_scaled = false;
          let is_currency = false;
          if (selectedSlicer) {
            filtered_object.push({
              key: "slicer",
              value: [selectedSlicer.name],
            });
            aggregation_type = selectedSlicer.aggregation_type || "";
            is_scaled = selectedSlicer?.is_scaled || false;
            is_currency = selectedSlicer?.is_currency || false;
          }
          if (selectedPeriodType) {
            filtered_object.push({
              key: "period_type",
              value: [
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType,
              ],
            });
          }
          if (selectedDataType) {
            filtered_object.push({
              key: "data_type",
              value: [
                selectedDataType.name === "100%"
                  ? "Absolute"
                  : selectedDataType.name,
              ],
            });
          }

          setScale(is_scaled);
          setCurrency(is_currency);
          const filteredDataList = applyFilters(allDataList, filtered_object);
          const scaleValue =
            is_scaled && selectedDataType.name === "Absolute"
              ? is_usd
                ? 1000000
                : 10000000
              : 1;
          if (
            aggregation_type === "sum" ||
            aggregation_type === "average of sum"
          ) {
            setGraphData(
              getDataSummation(
                filteredDataList,
                null,
                scaleValue,
                is_usd,
                is_currency && selectedDataType.name === "Absolute",
                custUsdExchange,
                exchangeRate,
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType
              )
            );
          } else if (
            aggregation_type === "average" ||
            aggregation_type === "sum of average"
          ) {
            setGraphData(
              getDataAverage(
                filteredDataList,
                null,
                scaleValue,
                is_usd,
                is_currency && selectedDataType.name === "Absolute",
                custUsdExchange,
                exchangeRate,
                selectedPeriodType === "Y" && yearPeriod === "FY"
                  ? "FY"
                  : selectedPeriodType
              )
            );
          } else {
            setGraphData([]);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching data -", err);
      setError(
        "Oops! Something went wrong. Please report this issue to your POC."
      );
    }
  }, [
    allSlicer,
    allDataList,
    selectedSlicer,
    selectedPeriodType,
    yearPeriod,
    selectedDataType,
    selectedDataType2,
    is_usd,
    custUsdExchange,
    exchangeRate,
  ]);

  useEffect(() => {
    let dates_1 = graphData.flatMap((item) =>
      item.data.map((point) => point.date)
    );
    let dates_2 = graphData2.flatMap((item) =>
      item.data.map((point) => point.date)
    );

    let all_dates = [...dates_1, ...dates_2];
    const max_date = all_dates.sort((a, b) => moment(b).diff(moment(a)))[0];
    setLatestDate(max_date);
    if (
      graphData.length &&
      graphData[0].data.filter((obj) => obj.date === max_date).length
    ) {
      setValue(
        graphData[0].data.filter((obj) => obj.date === max_date)[0].value
      );
    }
    if (
      graphData2.length &&
      graphData2[0].data.filter((obj) => obj.date === max_date).length
    ) {
      setIncrement(
        graphData2[0].data.filter((obj) => obj.date === max_date)[0].value
      );
    }
  }, [graphData, graphData2]);

  return (
    <Wrapper className={className}>
      <KpiCard
        name={selectedSlicer?.title}
        unitText={
          isCurrency
            ? isScale
              ? ` (${is_usd ? "USD" : "INR"} ${is_usd ? "Mn" : "Cr"})`
              : ` (${is_usd ? "USD" : "INR"})`
            : isScale
            ? ` (${is_usd ? "Mn" : "Cr"})`
            : ``
        }
        value={value || null}
        increment={increment || null}
        date={latestDate}
        formatCode={is_usd ? "en-US" : "hi"}
      />
    </Wrapper>
  );
};

export default KpiWrap;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  ${media.medium`
    padding: 10px;
  `}
  ${media.small`
    padding: 5px;
  `}
`;

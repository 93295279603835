// store.js
import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from "./counterSlice";
import { counterReducer, userReducer } from './slices';
import { newReportAccessReducer } from "./slices";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    newreportaccessdata:newReportAccessReducer,
  }
});

import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";
import { media } from "../../utils/media";

const QueryFooter = (props) => {
  const [queryData, setQueryData] = useState("");
  const [authorData, setAuthorData] = useState(null);
  const [querySubmit, setQuerySubmit] = useState(true);
  const [error, setError] = useState("");
  const [isValidData, setValidData] = useState(false);
  const handleInputChange = (event) => {
    setQueryData(event.target.value);
    if (event.target.value.length < 10) {
      setError("Include more information to submit.");
      setValidData(false);
    } else {
      setError("");
      setValidData(true);
    }
  };
  const sendQuery = () => {
    console.log(queryData);
    console.log("report= ", window.localStorage.getItem("report"));
    const uploadData = new FormData();
    uploadData.append("email", window.localStorage.getItem("email"));
    uploadData.append("query", queryData);
    uploadData.append("report", window.localStorage.getItem("rep_id"));
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/userquery/`, {
      method: "POST",
      body: uploadData,
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("data = ", data);
      });

    setQuerySubmit(!querySubmit);
  };

  useEffect(() => {
    let report_id = window.localStorage.getItem("rep_id");
    if (!report_id) {
      report_id = 43;
    }
    const getauthordata = async () => {
      let req = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/author/?report_id=${report_id}`
      );
      let data = req.data;
      if (data.length > 0) {
        data = data[0];
        setAuthorData(data);
      }
    };
    getauthordata();
  }, []);

  return (
    <div>
      {querySubmit ? (
        <div>
          {/* <QueryTextdiv> Query / Feedbacks</QueryTextdiv> */}
          {authorData && (
            <QueryFooterSurrounddiv>
              <Title>Contact our analyst</Title>
              <QueryFooterdiv>
                <AuthorBox>
                  <Introdiv>
                    <div>
                      <img
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                        }}
                        src={authorData.image.file}
                        alt=""
                      />
                    </div>
                    <div>
                      <h4>{authorData.name}</h4>
                      <div>{authorData.position}</div>
                    </div>
                  </Introdiv>
                  <AuthorDisplayText>{authorData.displaytext}</AuthorDisplayText>
                </AuthorBox>

                <QueryBoxWrapper>
                  {/* <h3>Contact our analyst </h3> */}
                  <QueryBoxdiv
                    onChange={handleInputChange}
                    placeholder="Write here..."
                  />
                  <QueryButtondiv>
                    {error && <div>{error}</div>}
                    <button onClick={() => sendQuery()} disabled={!isValidData}>
                      Send Query
                    </button>
                  </QueryButtondiv>
                </QueryBoxWrapper>
              </QueryFooterdiv>
            </QueryFooterSurrounddiv>
          )}
        </div>
      ) : (
        <QuerySubmitdiv>Your query has been submitted</QuerySubmitdiv>
      )}
    </div>
  );
};

export default QueryFooter;

const QueryFooterdiv = styled.div`
  padding: 20px;
  box-shadow: 2px 2px 4px 0px #00000040;
  /* padding-left:1.4vw;
    padding-right:3.5vw; */
  /* background-color:#EEF9FF; */
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  gap: 3vw;
  justify-content: space-between;
  ${media.small`
    flex-direction: column;
  `}
`;

const Title = styled.div`
  color: #262e40;
  font-size: 25px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  ${media.small`
  font-size: 18px; 
    margin-bottom: 10px;
  height: 20px;

  `}
`;

const QueryTextdiv = styled.div`
  font-size: 25px;
  padding-left: 3.5vw;
  font-weight: 500;
  padding-bottom: 10px;
`;

const AuthorBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  ${media.small`
    width: 100%;
  `}
`;

const QueryBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  ${media.small`
    width: 100%;
  `}
`;

const QueryBoxdiv = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid black;
  ${media.small`
      padding: 5px;
  height: 60px;
  border-radius: 8px;
    
    `}
`;

const QueryButtondiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: end;
  justify-content: flex-end;
  button {
    background-color: #0099ff;
    color: white;
    border: none;
    border-radius: 10px;
    width: 120px;
    height: 40px;
    :disabled {
      background-color: #f2f2f2;
      border: 1px solid #d9d9d9;
      color: #757575;
      cursor: not-allowed;
    }
      ${media.small`
        border-radius: 12px;
        width: 80px;
        height: 20px;
        font-size: 9px;
        `}
  }
  div {
    color: #e93735;
    padding-right: 20px;
  }
`;

const QuerySubmitdiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
`;
const QueryFooterSurrounddiv = styled.div`
  background-color: #eef9ff;
  padding: 3.5vw;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #f7fcff;
  background-color: #eef9ff;
  ${media.medium`
    padding-top: 5px;
    padding-bottom: 5px;
  `}
  ${media.small`
    padding-top: 5px;
    padding-bottom: 5px;
  `}
`;

const Introdiv = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const AuthorDisplayText=styled.div`

${media.small`
  font-size:12px;
  
  `}

`
import React from "react";
import styled from "styled-components";

const PlayerLink = () => {
  return (
    <IconWrapper>
      <svg width="11" height="11" viewBox="0 0 13 13" fill="none">
        <path
          d="M9.75 7.04167V10.2917C9.75 10.579 9.63586 10.8545 9.4327 11.0577C9.22953 11.2609 8.95398 11.375 8.66667 11.375H2.70833C2.42102 11.375 2.14547 11.2609 1.9423 11.0577C1.73914 10.8545 1.625 10.579 1.625 10.2917V4.33333C1.625 4.04602 1.73914 3.77047 1.9423 3.5673C2.14547 3.36414 2.42102 3.25 2.70833 3.25H5.95833"
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.125 1.625H11.375V4.875"
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.41699 7.58333L11.3753 1.625"
          stroke="#4A4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};
export default PlayerLink;

const IconWrapper = styled.div`
  margin-left: 5px;
`;

import styled from "styled-components";
import React, { useState, useEffect, useRef, Suspense } from "react";
import DynamicComponent from "../../pages/DynamicComponent";
import DjangoEmbed from "../../pages/DjangoEmbed";

const RedseerAnalysisDetail = ({ pages = [], reportName = "" }) => {
  return (
    <div>
      {pages.map((page, i) => {
        if (page.page_type === "component") {
          const Component = DynamicComponent(page.address);
          console.log("others");
          return (
            <Suspense key={page} fallback={<div>Loading...</div>}>
              <Component val={page.component_variable} />
            </Suspense>
          );
        } else if (page.page_type === "httpsAddress") {
          // not usefull, 2-3 dummy pages are there
          let url = "";
          if (page.component_variable) {
            const jsonString = page.component_variable.replace(/'/g, '"');
            // Parse the JSON string into an object
            const componentObject = JSON.parse(jsonString);
            if (componentObject.name.startsWith("$")) {
              // Replace the value with the desired value, e.g., 'Maaza'
              componentObject.name = reportName;
            }
            // Create a query string from the componentObject
            const queryParams = new URLSearchParams(componentObject).toString();
            console.log("query_params = ", queryParams);
            url = `${page.address}?${queryParams}`;
          } else {
            url = page.address;
          }

          // const url = queryParams ? `${page.address}?${queryParams}` : page.address;
          console.log("url = ", url);
          return <DjangoEmbed address={url} />;
        } else if (page.page_type === "pdf") {
          return (
            <iframe
              src={page["url"]}
              width="100%"
              height="800px"
              frameBorder="0"
              scrolling="yes"
              allowFullScreen="yes"
              title="Analysis ppt merged.pdf"
            ></iframe>
          );
        }
      })}
    </div>
  );
};

export default RedseerAnalysisDetail;

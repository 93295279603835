import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import Loader from "../Loader";
import axios from "axios";
import MasterTable from "../MasterTable";
import {
  WEBFORM_TOKEN,
  READY_RECKONER_ENDPOINT,
  PARAMETER_GROUP_ENDPOINT,
} from "../../constants/constants";
import OptionSelect from "../OptionSelect";
import TimeTrendGraph from "./TimeTrendGraph";
import VectorIcon from "../svg/VectorIcon";
import { media } from "../../utils/media";

// use it
// <ReadyReckoner width={"100%"} height={"70vh"}/>
const BoxData = ({
  value,
  paramMaxValue = 0,
  isMore,
  isIncreased,
  indComparison,
}) => {
  const maxWidth = `${paramMaxValue}`.length * 8;
  return (
    <BoxWrapper>
      <BoxValue
        color={!indComparison ? "#000000" : isMore ? "#21AF4A" : "#DA1E28"}
        fontWeight={indComparison ? 400 : 400}
        width={maxWidth}
      >
        {value}
      </BoxValue>
      <IconWrapper color={isIncreased ? "#D3EFDB" : "#F8D2D4"}>
        <VectorIcon
          width={9}
          height={13}
          color={isIncreased ? "#21AF4A" : "#DA1E28"}
          rotate={isIncreased ? 0 : 180}
        />
      </IconWrapper>
    </BoxWrapper>
  );
};
const BoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BoxValue = styled.div`
  text-align: right;
  font-size: 16px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  width: ${(props) => props.width}px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 24px;
  background-color: ${(props) => props.color};
`;

const ReadyReckoner = ({
  width,
  height,
  company_id,
  frequency = "monthly", //monthly or quarterly
  title = "Ready Reckoner",
  start_date = null,
  end_date = moment(moment().startOf("month").subtract(2, "month")).format(
    "YYYY-MM-DD"
  ), // "2023-12-01",
}) => {
  // const [parentWidth, setParentWidth] = useState(null);
  const [options, setOptions] = useState([]);
  const [seletedOption, setSelectedOption] = useState(null);
  const [paramsList, setParamsList] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  /* sorting hooks */
  const [sortedColKey, setSortedColKey] = useState(null);
  const [sortedDirection, setSortedDirection] = useState("");

  const getLabel = (dataDate) => {
    const dataDateObj = moment(dataDate, "YYYY-MM-DD");
    if (frequency === "monthly") {
      return dataDateObj.format("MMM'YY");
    } else if (frequency === "quarterly") {
      const quarter = Math.floor((dataDateObj.month() + 3) / 3);
      switch (quarter) {
        case 1:
          return `JFM'${dataDateObj.format("YY")}`;
        case 2:
          return `AMJ'${dataDateObj.format("YY")}`;
        case 3:
          return `JAS'${dataDateObj.format("YY")}`;
        case 4:
          return `OND'${dataDateObj.format("YY")}`;
        default:
          return "Invalid Date";
      }
    }
  };

  const getBoxChartData = (
    companyName,
    paramName,
    subHeading,
    arrayData,
    lineChart,
    curr_date,
    paramMaxValue,
    is_ind_specific
  ) => {
    const heading = `${
      is_ind_specific ? "" : `${companyName}'s `
    }${paramName} in ${subHeading}`;
    const toolTip = (
      <TimeTrendGraph
        heading={heading}
        companyName={!is_ind_specific ? companyName : null}
        data={arrayData}
        lineChart={lineChart}
        barDataKey="value"
        lineDataKey="value2"
      />
    );
    const currData = arrayData.filter((data) => data.start_date === curr_date);
    return {
      value: currData.length ? (
        <BoxData
          value={
            typeof currData[0].value === "number"
              ? Number(currData[0].value.toFixed(2)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : currData[0].value
          }
          paramMaxValue={
            typeof paramMaxValue === "number"
              ? Number(paramMaxValue.toFixed(2)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : paramMaxValue
          }
          isMore={currData[0].value - currData[0].value2 >= 0}
          isIncreased={
            currData[0].increment == null || currData[0].increment >= 0
          }
          indComparison={currData[0].value2 && lineChart}
        />
      ) : null,
      sortingValue: currData.length ? currData[0].sortingValue : null,
      toolTip: toolTip,
    };
  };
  useEffect(() => {
    const getTabledata = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${WEBFORM_TOKEN}`,
      };
      axios
        .post(
          PARAMETER_GROUP_ENDPOINT,
          {
            table_type: "ready_reckoner",
            profile: "company_profile",
            company_id: company_id,
            data_frequency: frequency,
            end_date: end_date,
          },
          {
            headers: headers,
          }
        )
        .then((response) => response.data)
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            if (responseData.data_category === "city_and_category_wise") {
              setOptions([
                { key: "city", value: "City" },
                { key: "category", value: "Category" },
              ]);
              setSelectedOption({ key: "city", value: "City" });
            } else if (responseData.data_category === "city_wise") {
              setOptions([{ key: "city", value: "City" }]);
              setSelectedOption({ key: "city", value: "City" });
            } else if (responseData.data_category === "category_wise") {
              setOptions([{ key: "category", value: "Category" }]);
              setSelectedOption({ key: "category", value: "Category" });
            }
            let updatedParameters = [];

            const updatedParameterDict = {};
            responseData.parameter_list
              .sort((a, b) => a.sequence - b.sequence)
              .forEach((parObj) => {
                updatedParameters.push({
                  key: parObj.parameter,
                  value: (
                    <HeaderWrapper>
                      <HeaderText>{parObj.name}</HeaderText>
                      {parObj.name2 ? (
                        <HeaderText>{parObj.name2}</HeaderText>
                      ) : null}
                    </HeaderWrapper>
                  ),
                  sorting: parObj.sorting,
                  industry_comparison: parObj.parameter_2 ? true : false,
                  range_filter: parObj.range_filter,
                  range_filter_data: parObj.range_filter_data,
                  value_filter: parObj.value_filter,
                  min_width: parObj.col_width,
                });
                updatedParameterDict[parObj.parameter] = {
                  name: parObj.name,
                  sorting: parObj.sorting,
                  industry_comparison: parObj.parameter_2 ? true : false,
                  range_filter: parObj.range_filter,
                  range_filter_data: parObj.range_filter_data,
                  value_filter: parObj.value_filter,
                  min_width: parObj.col_width,
                  is_ind_specific: parObj.is_ind_specific,
                };
              });
            setParamsList(updatedParameters);

            const cityDataDict = {};
            const categoryDataDict = {};
            const paramMaxValDict = {};
            let latestDate = null;
            updatedParameters.forEach((param) => {
              responseData.players_data[param.key].map((data) => {
                data = { ...data, label: getLabel(data.start_date) };
                //storing param-wise max value
                if (paramMaxValDict[param.key] == null) {
                  paramMaxValDict[param.key] = data.value;
                } else if (paramMaxValDict[param.key] < data.value) {
                  paramMaxValDict[param.key] = data.value;
                }
                const start_date = moment(data.start_date, "YYYY-MM-DD");
                if (!latestDate || latestDate.isBefore(start_date)) {
                  latestDate = start_date;
                }
                if (data.city) {
                  if (!cityDataDict[data.city]) {
                    cityDataDict[data.city] = {};
                  }
                  let newParamdata = cityDataDict[data.city][param.key] || [];
                  newParamdata.push(data);
                  cityDataDict[data.city][param.key] = newParamdata;
                }
                if (data.category) {
                  if (!categoryDataDict[data.category]) {
                    categoryDataDict[data.category] = {};
                  }
                  let newParamdata =
                    categoryDataDict[data.category][param.key] || [];
                  newParamdata.push(data);
                  categoryDataDict[data.category][param.key] = newParamdata;
                }
              });
            });
            latestDate = latestDate
              ? latestDate.format("YYYY-MM-DD")
              : end_date;
            const cityStyledRow = [];
            for (let city in cityDataDict) {
              let rowData = {
                city: {
                  value: city,
                  sortingValue: city,
                  toolTip: null,
                },
              };
              for (let param in cityDataDict[city]) {
                rowData[param] = getBoxChartData(
                  responseData.player.player_name,
                  updatedParameterDict[param].name,
                  city,
                  cityDataDict[city][param],
                  updatedParameterDict[param].industry_comparison,
                  latestDate,
                  paramMaxValDict[param],
                  updatedParameterDict[param].is_ind_specific
                );
              }
              cityStyledRow.push(rowData);
            }
            setCityData(cityStyledRow);

            const categoryStyledRow = [];
            for (let category in categoryDataDict) {
              let rowData = {
                city: {
                  value: category,
                  sortingValue: category,
                  toolTip: null,
                },
              };
              for (let param in categoryDataDict[category]) {
                rowData[param] = getBoxChartData(
                  responseData.player.player_name,
                  updatedParameterDict[param].name,
                  category,
                  cityDataDict[category][param],
                  updatedParameterDict[param].industry_comparison,
                  latestDate,
                  paramMaxValDict[param]
                );
              }
              categoryStyledRow.push(rowData);
            }
            setCategoryData(categoryStyledRow);
            setLoading(false);
            setError("");
          } else {
            console.error(
              "Error in fetching parameter group in admin console -",
              response.error.message
            );
            setLoading(false);
            setError(
              `Oops! Something went wrong. Please report this issue to your POC. Error Code: ${response.status}`
            );
          }
        })
        .catch((error) => {
          console.error(
            "Error in fetching parameter group for admin console -",
            error
          );
          setLoading(false);
          setError(
            `Oops! Something went wrong. Please report this issue to your POC. Error Code: ${error.code}`
          );
        });
    };
    setLoading(true);
    getTabledata();
  }, []);

  const accessMonthDataFilter = (dataDate) => {
    const queryMonthObj = moment(end_date, "YYYY-MM-DD");
    const dateObj = moment(dataDate, "YYYY-MM-DD");
    if (queryMonthObj.year() !== dateObj.year()) {
      return false;
    }
    if (frequency === "monthly") {
      if (queryMonthObj.month() !== dateObj.month()) {
        return false;
      } else {
        return true;
      }
    } else if (frequency === "quarterly") {
      const dataMonth = dateObj.month(); // zero-based months
      const queryMonthNo = queryMonthObj.month();
      if (queryMonthNo <= 2) {
        return dataMonth === 0;
      } else if (queryMonthNo <= 5) {
        return dataMonth === 3;
      } else if (queryMonthNo <= 8) {
        return dataMonth === 6;
      } else {
        return dataMonth === 9;
      }
    }
    return false;
  };

  useEffect(() => {
    if (sortedColKey) {
      setCityData(
        [...cityData].sort((a, b) => {
          const aVal = a[sortedColKey]?.sortingValue;
          const bVal = b[sortedColKey]?.sortingValue;
          if (aVal == null && bVal == null) {
            return 0;
          } else if (aVal == null) {
            return 1;
          } else if (bVal == null) {
            return -1;
          }

          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
          } else if (typeof aVal === "string" && typeof bVal === "string") {
            return sortedDirection === "asc"
              ? aVal.localeCompare(bVal)
              : bVal.localeCompare(aVal);
          } else {
            return 0;
          }
        })
      );

      setCategoryData(
        [...categoryData].sort((a, b) => {
          const aVal = a[sortedColKey]?.sortingValue;
          const bVal = b[sortedColKey]?.sortingValue;
          if (aVal == null && bVal == null) {
            return 0;
          } else if (aVal == null) {
            return 1;
          } else if (bVal == null) {
            return -1;
          }

          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
          } else if (typeof aVal === "string" && typeof bVal === "string") {
            return sortedDirection === "asc"
              ? aVal.localeCompare(bVal)
              : bVal.localeCompare(aVal);
          } else {
            return 0;
          }
        })
      );
    }
  }, [sortedColKey, sortedDirection]);

  // useEffect(() => {
  //   const makeRowsData = () => {
  //     if (seletedOption.key === "city") {
  //       let rowsData = [];
  //       cityData.forEach((city) => {
  //         let rowData = {
  //           city: {
  //             value: city.value,
  //             sortingValue: city.value,
  //             toolTip: null,
  //           },
  //         };
  //         paramsList.forEach((paramAllData) => {
  //           const paramCityData = paramAllData.data
  //             .filter((data) => data.city_id === city.key)
  //             .sort((a, b) => moment(a.start_date).diff(moment(b.start_date)));
  //           const limitedData =
  //             paramCityData.length > 5
  //               ? paramCityData.slice(paramCityData.length - 5)
  //               : paramCityData;
  //           const graph = (
  //             <TimeTrendGraph
  //               companyName={companyName}
  //               paramName={paramAllData.value}
  //               umbrellaName={city.value}
  //               data={limitedData}
  //               lineChart={paramAllData.indComparison}
  //             />
  //           );
  //           const paramCityRecentData = paramCityData.filter((data) =>
  //             accessMonthDataFilter(data.start_date)
  //           );
  //           if (paramCityRecentData.length) {
  //             rowData[paramAllData.key] = {
  //               value: (
  //                 <BoxData
  //                   value={paramCityRecentData[0].value}
  //                   isMore={
  //                     paramCityRecentData[0].value -
  //                       paramCityRecentData[0].industry_avg >=
  //                     0
  //                   }
  //                   isIncreased={
  //                     paramCityRecentData[0].increment == null ||
  //                     paramCityRecentData[0].increment >= 0
  //                   }
  //                   indComparison={paramAllData.indComparison}
  //                 />
  //               ),
  //               sortingValue: paramCityRecentData[0].value,
  //               toolTip: graph,
  //             };
  //           }
  //         });
  //         if (Object.keys(rowData).length > 1) {
  //           rowsData.push(rowData);
  //         }
  //       });
  //       setStyledRowsData(
  //         rowsData.sort((a, b) => {
  //           const aVal = a[sortedColKey]?.sortingValue || 0;
  //           const bVal = b[sortedColKey]?.sortingValue || 0;
  //           if (typeof aVal === "number" && typeof bVal === "number") {
  //             return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
  //           } else if (typeof aVal === "string" && typeof bVal === "string") {
  //             return sortedDirection === "asc"
  //               ? aVal.localeCompare(bVal)
  //               : bVal.localeCompare(aVal);
  //           } else {
  //             return 0;
  //           }
  //         })
  //       );
  //     } else {
  //       let rowsData = [];
  //       categoryData.forEach((category) => {
  //         let rowData = {
  //           category: {
  //             value: category.value,
  //             sortingValue: category.value,
  //             toolTip: null,
  //           },
  //         };
  //         paramsList.forEach((paramAllData) => {
  //           const paramCategoryData = paramAllData.data
  //             .filter((data) => data.category_id === category.key)
  //             .sort((a, b) => moment(a.start_date).diff(moment(b.start_date)));
  //           const limitedData =
  //             paramCategoryData.length > 5
  //               ? paramCategoryData.slice(paramCategoryData.length - 5)
  //               : paramCategoryData;
  //           const graph = (
  //             <TimeTrendGraph
  //               companyName={companyName}
  //               paramName={paramAllData.value}
  //               umbrellaName={category.value}
  //               data={limitedData}
  //               lineChart={paramAllData.indComparison}
  //             />
  //           );
  //           const paramCategoryRecentData = paramCategoryData.filter((data) =>
  //             accessMonthDataFilter(data.start_date)
  //           );
  //           if (paramCategoryRecentData.length) {
  //             rowData[paramAllData.key] = {
  //               value: (
  //                 <BoxData
  //                   value={paramCategoryRecentData[0].value}
  //                   isMore={
  //                     paramCategoryRecentData[0].value -
  //                       paramCategoryRecentData[0].industry_avg >=
  //                     0
  //                   }
  //                   isIncreased={
  //                     paramCategoryRecentData[0].increment == null ||
  //                     paramCategoryRecentData[0].increment >= 0
  //                   }
  //                   indComparison={paramAllData.indComparison}
  //                 />
  //               ),
  //               sortingValue: paramCategoryRecentData[0].value,
  //               toolTip: graph,
  //             };
  //           }
  //         });
  //         if (Object.keys(rowData).length > 1) {
  //           rowsData.push(rowData);
  //         }
  //       });
  //       setStyledRowsData(rowsData);
  //     }
  //   };

  //   makeRowsData();
  // }, [paramsList, seletedOption, sortedColKey, sortedDirection]);

  return (
    <Wrapper width={width} height={height}>
      <Title>{title}</Title>
      <GraphShadow width={width} height={height}>
        <GraphDimension width={width} height={height}>
          {isLoading ? (
            <LoadingMessage>Loading...</LoadingMessage>
          ) : error.length ? (
            <NoDataMessage>{error}</NoDataMessage>
          ) : (
            <>
              <HeaderSection>
                <TableMenuLeft>
                  For the {frequency === "quarterly" ? "Quarter" : "Month"} of{" "}
                  {getLabel(end_date)}
                </TableMenuLeft>
                {seletedOption && options.length > 1 && (
                  <OptionSelect
                    options={options}
                    selectedOption={seletedOption}
                    onOptionSelect={setSelectedOption}
                  />
                )}
              </HeaderSection>

              {seletedOption && (
                <MasterTable
                  columnsData={[
                    {
                      key: seletedOption.key,
                      value: seletedOption.value,
                      sorting: true,
                      range_filter: false,
                      align: "left",
                    },
                    ...paramsList,
                  ]}
                  rowsData={
                    seletedOption.key === "city" ? cityData : categoryData
                  }
                  sortedColKey={sortedColKey}
                  setSortedColKey={setSortedColKey}
                  sortedDirection={sortedDirection}
                  setSortedDirection={setSortedDirection}
                  maxHeight={"70vh"}
                  sortOptions={true}
                  toolGap={10}
                />
              )}
            </>
          )}
          {isLoading && (
            <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
          )}
        </GraphDimension>
      </GraphShadow>
    </Wrapper>
  );
};

export default ReadyReckoner;

const Wrapper = styled.div`
  // width: ${(props) => props.width};
  // height: ${(props) => props.height};
  // background-color: #f7fcff;
  box-sizing: border-box;
  padding: 10px;
  ${media.small`
    font-size:12px;
    `}
`;

const Title = styled.div`
  color: #262e40;
  font-size: 25px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  ${media.small`
      font-size: 18px;  
    `}
`;

const TableMenuLeft = styled.div`

${media.small`
  font-size:12px;
  `}
`;

const GraphShadow = styled.div`
  // width: calc(${(props) => props.width} - 20px);
  // height: calc(${(props) => props.height} - 60px);
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px #00000040;
`;

const LoadingMessage = styled.div`
  min-height: 360px;
`;
const GraphDimension = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height} - 100px);
  padding: 15px;
  position: relative;
  ${media.small`
      padding: 10px;
    `}
`;

const HeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 30px;
  ${media.small`
      padding: 0px 0px 10px;

    
    `}
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 700px;
${media.small`
    font-size: 12px;
  `}
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;

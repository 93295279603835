import React from "react";
import styled from "styled-components";

const GridIcon = ({
  width = 12,
  height = 12,
  color = "#262E40",
  rotate = 0,
  disabled = true,
}) => {
  return (
    <IconWrapper rotate={rotate} disabled={disabled}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M9 1V13M1 5H12.3333M1 9H12.3333M4.33333 1V13"
          stroke={`${color}`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

export default GridIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "../../utils/media";

const QuickLink = ({ industry_id }) => {
  const [filteredIds, setFilteredIds] = useState([]);
  let subDictionary = JSON.parse(window.localStorage.getItem("subDictionary"));
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const PriorityQuicLink_id = [70, 87, 81, 84];

  useEffect(() => {
    let newFilteredIds = [];
    if (IndustryDict) {
      for (let i = 0; i < Object.keys(subDictionary).length; i++) {
        if (
          IndustryDict[Object.keys(subDictionary)[i]] &&
          IndustryDict[Object.keys(subDictionary)[i]].industry_id &&
          IndustryDict[Object.keys(subDictionary)[i]].industry_id !==
            industry_id
        ) {
          newFilteredIds.push(Object.keys(subDictionary)[i]);
        }
        if (newFilteredIds.length === 3) {
          break;
        }
      }

      for (let i = 0; i < PriorityQuicLink_id.length; i++) {
        if (newFilteredIds.length === 3) {
          break;
        }
        if (IndustryDict[PriorityQuicLink_id[i]].industry_id !== industry_id) {
          newFilteredIds.push(PriorityQuicLink_id[i]);
        }
      }
    }
    setFilteredIds(newFilteredIds);
  }, []);

  const handleLinkClick = (id) => {
    const url = `/Report3/?val=${IndustryDict[id].report_name}&key=${IndustryDict[id].id}&filter=${IndustryDict[id].filter}&filter_value=${IndustryDict[id].filter_value}`;
    window.open(url);
  };
  return (
    <>
      {
        <LinkWrap>
          <div style={{ color: "#212529" }}>Quick Links:&nbsp;</div>
          {IndustryDict &&
            filteredIds.map((id, index) => (
              <div key={index}>
                <CursorPoint onClick={() => handleLinkClick(id)}>
                  {IndustryDict[id].report_name==="Beauty and Personal Care"?"BPC":IndustryDict[id].report_name}
                </CursorPoint>
                {index < filteredIds.length - 1 && " | "}
              </div>
            ))}
        </LinkWrap>
      }
    </>
  );
};

export default QuickLink;

const LinkWrap = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #0099ff;
  white-space: nowrap;
  ${media.small`
     font-size: 13px;
    
    `}
`;

const CursorPoint = styled.a`
  cursor: pointer;
`;

import React from "react";
import TickIcon from "../svg/TickeIcon";
import styled, { useTheme } from "styled-components";

const CheckBox = ({
  value = false,
  onChange,
  disabled = false,
  width = 20,
  height = 20,
  className,
}) => {
  const theme = useTheme();

  return (
    <CheckWrapper checked={value} onClick={onChange} className={className}>
      <TickIcon
        width={width}
        height={height}
        color={value ? "#FFFFFF" : "#FFFFFF"}
      />
    </CheckWrapper>
  );
};

export default CheckBox;

const CheckWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.checked ? "#0099FF" : "#CCCCCC")};
`;

import React from "react";
import styled from "styled-components";

const SortIcon = ({ sorting = "", onSortingClick }) => {
  const isAscending = sorting === "asc";
  const isDescending = sorting === "desc";

  return (
    <FilterWrapper onClick={onSortingClick}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        role="img"
        viewBox="0 0 320 512"
      >
        <path
          fill={isDescending ? "#000000" : "#BFBFBF"}
          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
        ></path>
        <path
          fill={isAscending ? "#000000" : "#BFBFBF"}
          d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
        ></path>
      </svg>
    </FilterWrapper>
  );
};

export default SortIcon;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 12px;
  height: 18px;
  cursor: pointer;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import Sub from "../../pages/Sub";

const NewsletterSlides = ({ industry_id, rep_id, client_id }) => {
  const [newsletterSlidesdata, setNewsletterSlidesdata] = useState(null);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/newsletterslides/?client_id=${client_id}&rep_id=${rep_id}&industry_id=${industry_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setNewsletterSlidesdata(res);
      })
      .catch((error) => {
        console.error("Error in fetching newsletter", error);
      });
  }, [industry_id]);

  return (
    <NewsletterSlidesContainer>
      {newsletterSlidesdata ? (
        window.localStorage.getItem("clientID") !== "141" ? (
          <StyledCarousel
            showThumbs={false}
            infiniteLoop={true}
            showIndicators={newsletterSlidesdata.length > 1}
          >
            {newsletterSlidesdata.map((val, i) => (
              <NewsletterContainer key={i}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: val.htmltext,
                  }}
                />
              </NewsletterContainer>
            ))}
          </StyledCarousel>
        ) : (
          <StyledCarousel
            showThumbs={false}
            infiniteLoop={true}
            showIndicators={newsletterSlidesdata.length > 1}
          >
            {newsletterSlidesdata.map((val, i) => (
              <NewsletterContainer key={i}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: val.htmltext,
                  }}
                />
              </NewsletterContainer>
            ))}
            <Sub />
          </StyledCarousel>
        )
      ) : (
        <LoadingMessage>Loading...</LoadingMessage>
      )}
    </NewsletterSlidesContainer>
  );
};

export default NewsletterSlides;

const NewsletterContainer = styled.div``;

const NewsletterSlidesContainer = styled.div`
  background-color: white;
  margin: 4vh 3.5vw;
  margin-bottom: 2vh;
  box-shadow: 2px 2px 4px 0px #00000040;
`;
const LoadingMessage = styled.div`
  min-height: 360px;
`;

const StyledCarousel = styled(Carousel)`
  margin: 0;
  .control-dots {
    background: yellow;
    height: 0px;
    bottom: 12px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

import React from "react";
import styled from "styled-components";

const ListIcon = ({
  width = 12,
  height = 12,
  color = "#262E40",
  rotate = 0,
  disabled = true,
}) => {
  return (
    <IconWrapper rotate={rotate} disabled={disabled}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 14 10"
        fill="none"
      >
        <path
          d="M1 1H13M1 5H9M1 9H13"
          stroke={`${color}`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

export default ListIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;

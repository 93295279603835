import React from 'react'
import styled from 'styled-components'
import { useState , useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import {Navigate} from 'react-router-dom';


const NewFigmaLogin = () => {
    const [ email, setEmail ] = useState('');
    const [fname,setFName] = useState('')
    const [lname, setLName]=useState('')
    const [company, setcompany]=useState('')
    const [Phone, setPhone] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [signIn, setSignIn] = useState(false);
    const [allowSignUp, setAllowSignUp] = useState(false)
    const search = useLocation().search;
    const key= new URLSearchParams(search).get('key')

    useEffect(()=>{
        if (key){
            // valudate key and get corresponding email
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/validateinvitekey/?key=${key}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
              })
              .then(
                  data => data.json(),        )
              .then(
                data => {
                  // this.props.userLogin(data.token);
                  if(data['email']!=='uninvited'){
                    console.log('invited_email = ', data['email'])
                    setAllowSignUp(true)
                  }else{
                    setAllowSignUp(false)
                  }
                  // this.props.navigate('/reportlist')
                }
              )
              .catch( error => console.error(error))
        }
    },[key])


    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
      }

    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

    let login = (e)=>{
        e.preventDefault();
        // console.log(email)
        // setLoggedIn(true)
        if(allowSignUp){       
        const uploadData = new FormData();
        uploadData.append('email', email);
        uploadData.append('fname', fname);
        uploadData.append('lname', lname);
        uploadData.append('phone', Phone);
        uploadData.append('company', company)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/signup/`, {
          method: 'POST',
          body: uploadData
        }).then(data => data.json())
        .then( data => {
              console.log(data)
              if(data.token){
              setSignIn(true);
              window.localStorage.setItem('loginStatus', 'true')
              window.localStorage.setItem("token", data.token)
              window.localStorage.setItem("email", email)
              // take client id from back end even after sign up as it may be subject to change
              window.localStorage.setItem("clientID", 141)
              }else{
                setSignIn(false);
              }
          })
        .catch(error => {
          setSignIn(false);
          alert('System Error.Contact Admin')
          console.log(error)
      })

        }else{
          const uploadData = new FormData();
          uploadData.append('email', email);
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/sendinvitemail/`, {
            method: 'POST',
            body: uploadData
          }).then(data => data.json())
          .then( data => {
                console.log(data)
                alert('Access request Sent')
            })
          .catch(error => {
            alert('System Error.Contact Admin')
            console.log(error)
        })
        }
    }

    let inputChanged = (e) => {
        setEmail(e.target.value);
      }


      const handleLinkedInAuth = () => {
        // Redirect the user to the LinkedIn authentication page
        window.location.href = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=8658fm7b644ues&redirect_uri=https://api.benchmarks.digital/linkedin/callback&state=STATE&scope=r_liteprofile%20r_emailaddress';
      };
    

      if(signIn){
        // below is always true
        console.log('signIn=',signIn)
        return <Navigate to = "/newreport"/>
    }

  return (
    width>768 ?(<PageContainer>
        <SideImg>
        <img src = '/Images/sideimg.png' alt = ''/>
        </SideImg>
         <Wrapper>
            <ImgDiv>
                <img src = '/Images/benchmark_logo.png' alt = ''/>
            </ImgDiv>
            <LoginForm>
            <form onSubmit={(e)=>login(e)}>
                {allowSignUp?<h4>Create Your Account</h4>:<h4>Details required for Invitation</h4>}
                <div className="form-group">
                    <label></label>
                    <input type = 'text' name = 'fullname' id='fullname' placeholder='First Name' className="form-control" value={fname}  onChange={(e) => setFName(e.target.value)}/>
                    <label></label>
                    <input type = 'text' name = 'lastname' id='lastname' placeholder='Last Name' className="form-control" value={lname}  onChange={(e) => setLName(e.target.value)}/>
                    <label></label>
                    <input type = 'text' name = 'email' id='email' placeholder='Email' className="form-control" value={email}  onChange={(e) => inputChanged(e)}/>
                    <label></label>
                    <input type = 'text' name = 'phone' id='phone' placeholder='Your Phone Number' className="form-control" value={Phone}  onChange={(e) => setPhone(e.target.value)}/>
                    <label></label>
                    <input type = 'text' name = 'signeecompany' id='signeecompany' placeholder='Company' className="form-control" value={company}  onChange={(e) => setcompany(e.target.value)}/>
                </div>
                {allowSignUp?<button type="submit" className="btn btn-primary btn-block" style={{backgroundColor:'blue' , border:'None'}} >Sign Up</button>:<button type="submit" className="btn btn-primary btn-block" style={{backgroundColor:'blue' , border:'None'}}>Request Access</button>}
            </form>
            </LoginForm>
            <SocialLogin>
                Sign Up with <button onClick={handleLinkedInAuth}><img src='/Images/linkedin.png'/></button> 
            </SocialLogin>
            <SignIn>
                Already have an account? <a href='/'>Sign In</a>
            </SignIn>
            <FooterDiv>
                Terms and Conditions | Privacy Policy  | Contact Us
            </FooterDiv>
        </Wrapper>
    </PageContainer>):(
    <PageContainer>
         <Wrapper>
            <ImgDiv>
                <img src = '/Images/benchmark_logo.png' alt = ''/>
            </ImgDiv>
            <LoginForm>
            <form onSubmit={(e)=>login(e)}>
                <h4>Create Free Account</h4>
                <div className="form-group">
                    <label></label>
                    <input type = 'text' name = 'fullname' id='fullname' placeholder='Full Name' className="form-control" value={email}  onChange={(e) => inputChanged(e)}/>
                    <label></label>
                    <input type = 'text' name = 'email' id='email' placeholder='Email' className="form-control" value={email}  onChange={(e) => inputChanged(e)}/>
                    <label></label>
                    <input type = 'text' name = 'phone' id='phone' placeholder='Your Phone Number' className="form-control" value={Phone}  onChange={(e) => inputChanged(e)}/>
                </div>
                <button type="submit" className="btn btn-primary btn-block" style={{backgroundColor:'blue' , border:'None'}} >Sign Up</button>
            </form>
            </LoginForm>
            <SocialLogin>
                {/* Sign Up with <button><img src = '/Images/google_icon.png'/></button> &nbsp;<button><img src = '/Images/ms_icon.png'/></button> */}
            </SocialLogin>
            <SignIn>
                Already have an account? <a>Sign In</a>
            </SignIn>
            <FooterDiv>
                {/* Terms and Conditions | Privacy Policy  | Contact Us */}
            </FooterDiv>
        </Wrapper>
    </PageContainer>
    )
  )
}

export default NewFigmaLogin

const PageContainer = styled.div`
height:100vh;
display:flex;
@media (max-width:768px){
    overflow-x:hidden
}
`

const Wrapper = styled.div`
/* margin-top: 10vh; */
text-align:center;
background-color:white;
width:40%;
display:flex;
flex-direction:column;
align-items:center;
@media (max-width:768px){
  width:100%;
}
`

const ImgDiv = styled.div`
/* border :1px solid red; */
margin-bottom: 40px;
margin-top:35px;
width:65vw;
flex-grow:1;
display:flex;
align-items:center;
justify-content:center;
img{

}
`

const LoginForm = styled.div`
margin-bottom: 10px;
/* flex-grow:1; */
width: 450px;
margin: auto;
margin-bottom: 20px;

@media (max-width:768px){
    width: 320px;
    margin: auto;
}
`

const SocialLogin = styled.div`
margin-bottom: 10px;
/* flex-grow:1; */
height:15vh;
button{
    background-color:white;
    border: None;
    img{
        height:30px;
    }
}
@media (max-width:768px){
    margin-top:20px;
}

`

const SignIn = styled.div`
margin-bottom: 10px;
flex-grow:1;
`

const FooterDiv = styled.div`
border-top :1px solid black;
margin-bottom: 10px;
/* flex-grow:1; */
height:7vh;
width:40vw;
color:blue;

`

const LoginInner = styled.div`
  width: 450px;
  margin: auto;
  background: #ffffff;
  /* padding: 40px 55px 45px 55px; */
  img{
    position:absolute;
    /* width: 210px;
    height: 90px; */
    left: 61vw;
    /* top: 160px; */
    top:22vh;
  }
  @media (max-width:768px){
    width: 400px;
    margin: auto;
    padding: 40px 55px 45px 55px;
    img{
      position:absolute;
      width: 176px;
      height: 45px;
      left: 30vw;
      top: 160px;
    }
}
`

const SideImg = styled.div`
background-color:#1C1C6C;
width:60%;
overflow:hidden;
img{
  height:100%;
  width: 100%;
}
`
import React from "react";
import styled from "styled-components";
import { media } from "../../utils/media";

const ExternalLinkIcon = ({
  width = 32,
  height = 32,
  color = "#FFFFFF",
  rotate = 0,
  onIconClick = () => null,
}) => {
  return (
    <IconWrapper rotate={rotate} onClick={onIconClick}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M24 17.3333V25.3333C24 26.0406 23.719 26.7189 23.219 27.219C22.7189 27.719 22.0406 28 21.3333 28H6.66667C5.95942 28 5.28115 27.719 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V10.6667C4 9.95942 4.28095 9.28115 4.78105 8.78105C5.28115 8.28095 5.95942 8 6.66667 8H14.6667"
          stroke={`${color}`}
          strokeWidth="2.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 4H28V12"
          stroke={`${color}`}
          strokeWidth="2.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.333 18.6667L27.9997 4"
          stroke={`${color}`}
          strokeWidth="2.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

export default ExternalLinkIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: pointer;
  }

  svg{
    width: ${props => props.width}px;
    height: ${props => props.height}px;

    ${media.small`
      width: 16px;
      height: 16px;
    `}
  }
`;

import React from "react";
import styled from "styled-components";

const CheckIcon = ({ width = 12, height = 10, color = "#21AF4A" }) => {
  return (
    <IconWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 12 10"
        fill="none"
      >
        <path
          d="M0 6.04601L1.56858 4.20082L4.33052 6.5L10.2023 0L12 1.51732L4.33052 10L0 6.04601Z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default CheckIcon;

const IconWrapper = styled.div`
  display: flex;
`;

import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Select from "react-select";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { media } from "../../utils/media";
import QuickLink from "../QuickLink";
import LastUpdated from "../svg/LastUpdated";

const Logtable = (props) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const url_industry_id = useQuery().get("industry_id");
  const url_industry_name = useQuery().get("industry_name");
  const url_pass_key = useQuery().get("pass_key");

  const [tabledata, settabledata] = useState([]);
  const tabeledataref = useRef(null);
  const [players, setplayers] = useState([]);
  const [breadcrumbArr, setbreadcrumbArr] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  useEffect(() => {
    const getabledata = async () => {
      let industry_id = url_industry_id;
      if (!industry_id) {
        industry_id = 72;
      }
      let response = await axios.get(
        `${process.env.REACT_APP_WEBFORM_ENDPOINT}/tracking/?industry_id=${industry_id}`
      );

      const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString("en-US", options);
      };

      response.data = response.data.map((object) => ({
        ...object,
        display: `${object.operation} ${object.parameter_name} from ${object.old_value} to ${object.new_value}`,
        created_date: formatDate(object.created_date),
      }));
      tabeledataref.current = response.data;
      settabledata(response.data);
      const uniqueNames = new Set(
        response.data.map((item) => item.player_name)
      );
      let arr = Array.from(uniqueNames).map((player_name) => ({
        value: player_name,
        label: player_name,
      }));
      setplayers(arr);
      setIsDataLoaded(true);
    };
    getabledata();
  }, []);

  useEffect(() => {
    let pass_key = url_pass_key;
    if (!pass_key) {
      pass_key = 448;
    }
    let arr = [IndustryDict[pass_key]];

    arr.push({ id: 3, report_name: "Logs" });
    arr.splice(0, 0, { id: 1, report_name: "Sectors" });
    setbreadcrumbArr(arr);
  }, [url_pass_key]);

  const handleSelectOptions = (option) => {
    let arr = tabeledataref.current.filter(
      (val) => val.player_name === option.value
    );
    settabledata(arr);
  };

  let handleClickTree = (i) => {
    if (i > 0 && i < breadcrumbArr.length - 1) {
      let node = breadcrumbArr[i];
      window.location.href = `/Report3/?val=${node.report_name}&key=${node.id}&filter=${node.filter}&filter_value=${node.filter_value}&industry_id=${node.industry_id}`;
    }
  };

  return (
    <div style={{width:"100%"}}>
      <Header />

      <Header1>
        <LHSTitlediv>
          <div style={{ whiteSpace: "nowrap" }}>
            {" "}
            {url_industry_name} - Change Logs
          </div>
          <BreadCrumbDiv>
            <>
              {breadcrumbArr?.map((obj, i) =>
                i !== 2 ? (
                  <BreadCrumbSpan
                    onClick={(e) => {
                      handleClickTree(i);
                    }}
                    id={i}
                  >
                    {obj.report_name} /
                  </BreadCrumbSpan>
                ) : (
                  <BreadCrumbSpan
                    onClick={(e) => {
                      handleClickTree(i);
                    }}
                    id={i}
                  >
                    {obj.report_name}
                  </BreadCrumbSpan>
                )
              )}
            </>
          </BreadCrumbDiv>
        </LHSTitlediv>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {isDataLoaded && tabledata.length>0 ? (
            <>
              <div style={{ display: "flex" }}>
                <LastUpdated />{" "}
                <LastUpdatedWrap
                >
                  Last Updated on {tabledata[0].created_date}
                </LastUpdatedWrap>
              </div>
            </>
          ) : null}
          <QuickLink industry_id={url_industry_id} />
        </div>
      </Header1>
      <Outerdiv>
          <Lowerdiv>
            <Welcomesty>
              <Welcomdiv1> Welcome to {url_industry_name} - Change Logs!</Welcomdiv1>
              <Welcomdiv2>
                {" "}
                Here you can find the information about changes in most recent
                changes that have been made to this report including data
                changes new features and other improvements.
              </Welcomdiv2>
            </Welcomesty>
            <div style={{ display: "flex" }}>
                <Select
                  name="columns"
                  options={players}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectOptions}
                  isSearchable={true}
                  placeholder="Select Players"
                />
            </div>
          </Lowerdiv>
          <div style={{ position: "relative" ,width:"100%"}}>
            {isDataLoaded ? (
              <Styletable>
                <thead>
                  <tr>
                    <Styledth>Release Date</Styledth>
                    <Styledth>Change Type</Styledth>
                    <Styledth>Players</Styledth>
                    <Styledth>Details</Styledth>
                  </tr>
                </thead>
                <Styledtbody>
                  {tabledata.map((i) => {
                    return (
                      <tr>
                        <Styledtd>{i.created_date}</Styledtd>
                        <Styledtd>{i.operation}</Styledtd>
                        <Styledtd>{i.player_name}</Styledtd>
                        <Styledtd>{i.display}</Styledtd>
                      </tr>
                    );
                  })}
                </Styledtbody>
              </Styletable>
            ) : (
              <>
                <LoadingMessage>Loading...</LoadingMessage>
                <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
              </>
            )}
          </div>

      </Outerdiv>
    </div>
  );
};

export default Logtable;

const Styledth = styled.th`
  border: 1px solid #dbdbdb;
  padding: 15px;
min-width: 250px;

  ${media.small`
      min-width: 90px;
  padding: 10px;
    
    `}
`;

const Styledtd = styled.td`
  padding: 15px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;
  border-top: 0px;
  ${media.small`
  padding: 10px;
    `}
`;

const Styledtbody = styled.tbody`
  border: 1px solid #dbdbdb;
`;

const BreadCrumbSpan = styled.span`
  color: grey;
  &:hover {
    color: ${(props) => (props.id === 1 ? "#20a6ff" : "grey")};
    cursor: pointer;
  }
`;

const LoadingMessage = styled.div`
  min-height: 360px;
  padding: 15px 15px 15px;
`;

const Header1 = styled.div`
  background-color: white;
  width:100%;
  padding: 3.5vw;
  padding-top: 1vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  ${media.small`
  flex-direction:column;`}
`;

const LHSTitlediv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  font-weight: 500;
  font-size: 30px;
  ${media.small`
  font-size: 20px;
  width:100%;
`}
`;

const BreadCrumbDiv = styled.div`
  margin-bottom: 10px;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 500;
  height: auto;
  line-height: 24px;
  ${media.small`
    font-size: 10px;
  `}
  &:hover {
    color: ${(props) => (props.id >= 0 ? "#20a6ff" : "grey")};
    cursor: pointer;
  }
`;

const Outerdiv = styled.div`
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
//   background-color: #e5f5ff;
  width: 100%;
`;

const Middlediv = styled.div`
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  background-color: white;
  margin-top: 10px;
  width: 100%;
`;

const Lowerdiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width:100%;

  ${media.small`
      margin-bottom: 20px;
    
    
    `}
`;

const Styletable = styled.table`
  width: 100%;
  font-size: 15px;
  ${media.small`
    width:50%;
font-size:10px;

    `}
`;

const Welcomesty = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  ${media.small`
font-size:10px;

    `}
`;

const Welcomdiv1=styled.div`
font-weight:600;
font-size:18px;
${media.small`
    font-size:12px;
    
        `}
`;

const Welcomdiv2=styled.div`
font-size: 15px;
  ${media.small`
font-size:10px;
display:none;
    `}
`;

const LastUpdatedWrap=styled.div`
    font-weight: 400;
    font-style: italic;
    padding-bottom: 2%;
    font-size: 15px;
  ${media.small`
font-size:13px;
    `}

`

import React from "react";
import styled from "styled-components";

const HambergurMenu = ({
  width,
  height,
  color = "#A0A6A9",
  isOpen = false,
  rotate = 0,
  onIconClick = () => null,
  className,
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      rotate={rotate}
      onClick={onIconClick}
      className={className}
    >
      {isOpen ? (
        <svg width={`100%`} height={`100%`} viewBox="0 0 29 29" fill="none">
          <path
            d="M21.75 7.25L7.25 21.75"
            stroke={`${color}`}
            strokeWidth="2.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.25 7.25L21.75 21.75"
            stroke={`${color}`}
            strokeWidth="2.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg width={`100%`} height={`100%`} viewBox="0 0 29 29" fill="none">
          <path
            d="M3.625 14.5H25.375"
            stroke={`${color}`}
            strokeWidth="2.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.625 7.25H25.375"
            stroke={`${color}`}
            strokeWidth="2.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.625 21.75H25.375"
            stroke={`${color}`}
            strokeWidth="2.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconWrapper>
  );
};

export default HambergurMenu;

const IconWrapper = styled.div`
  display: block;
  height: ${(props) => (props.height ? props.height : 32)}px;
  width: ${(props) => (props.width ? props.width : 32)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: pointer;
  }
`;
import React from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import { useEffect } from 'react'
import { useState } from 'react'

const Brands = () => {
    const [playerData, setPlayerData] = useState([])
    const [alldata, setalldata]  = useState([])
    const[activeLink, setActiveLink] = useState('Electronics')

    useEffect(()=>{
        const fetchData = async () => {
            try {
              const response = await fetch(`https://api.benchmarks.digital/displaybrands/`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              const result = await response.json();
              setalldata(result);
            //   let player_data = alldata.filter((i)=>i.brand_type==='Electronics')
              let player_data = result.filter((i)=>i.brand_type==='Electronics')
              setPlayerData(player_data)
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          
          fetchData();
    }, [])
    
    const handleClick = (brand_type)=>{
        let player_data = alldata.filter((i)=>i.brand_type===brand_type)
        setPlayerData(player_data)
        setActiveLink(brand_type)
    }

  return (
    <div>
        <Header/>
        <Titlediv>
             <span style={{color:'Red'}}>Brands </span>we cover
        </Titlediv>
        <div style={{textAlign:'center'}}>100+ business KPIs of top 200 consumer internet companies in India</div>
        <IndustryCategorydiv></IndustryCategorydiv>
    
        <Tabsdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'Electronics' ? '#0099ff' : '#F2F2F2', color:activeLink === 'Electronics' ? 'white':'black' }} onClick={()=>(handleClick('Electronics'))}>Electronics</TabsInnerdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'Fashion and BPC' ? '#0099ff' : '#F2F2F2', color:activeLink === 'Fashion and BPC' ? 'white':'black' }}onClick={()=>(handleClick('Fashion and BPC'))}>Fashion and BPC</TabsInnerdiv>
            <TabsInnerdiv style={{ backgroundColor: activeLink === 'Beverage' ? '#0099ff' : '#F2F2F2', color:activeLink === 'Beverage' ? 'white':'black' }} onClick={()=>(handleClick('Beverage'))}>Beverage</TabsInnerdiv>
        </Tabsdiv>
        <CompaniesGridDiv>
           {alldata.length>0 && playerData.filter((val)=>(val.image!==null)).map((val, idx)=>(
            <div key = {idx}>
              {val.image==null?null:<img style={{width:'60%', height:'auto'}} src = {val.image==null || val.image.file==null?'/Images/applemusic.png':val.image.file} alt = {val.image==null?'':val.image.name}/>}
            </div>
           ))}
        </CompaniesGridDiv>
    </div>
  )
}

export default Brands

const Titlediv = styled.div`
    font-size:33px;
    font-weight:600;
    text-align:center;
    padding:20px;
    @media(min-width: 768px){
        padding:30px;
        font-size:60px;
    }
`

const IndustryCategorydiv = styled.div`
    font-size:25px;
    font-weight:500;
    text-align:center;
    padding:20px;
    @media(min-width: 768px){
        padding:30px;
        font-size:32px;
    }
`

const Tabsdiv = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    gap:20px;
    line-height:45px;
    flex-wrap:wrap;
    /* height:20px; */
    /* background-color:lightgray; */
`


const TabsInnerdiv = styled.div`
    background-color:#F2F2F2;
    border-radius:10px;
    min-width:150px;
    text-align:center;
    &:hover{
        background-color:#0099FE;
        color: white;
    }
`


const CompaniesGridDiv = styled.div`
    display:grid;
    padding:70px;
    gap:10px;
    padding-left:100px;
    padding-right:50px;
    grid-template-rows:auto;
    grid-auto-rows:1fr;
    grid-auto-columns:1fr;
    grid-template-columns:1fr 1fr ;
    grid-template-areas:". .";
    @media(min-width: 768px){
        grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
         grid-template-areas:". . . . . .";
    }
`
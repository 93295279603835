import React from 'react'
import ArialStacked from '../components/ArialStacked'
import Swot from '../components/Swot/Swot'
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import QueryFooter from '../components/QueryFooter/QueryFooter';
import CustomScatterChart from '../components/ScatterGraph/CustomScatterChart';
import MyStackedBarChart from '../components/Bargraph/bars';
import Logtable from '../components/ChangeLogs/logtable';
import WaterFallWalletShare from '../components/WaterFallWalletShare/WaterFallWalletShare';

const RevenuePool = () => {
  return (
    <div>
      hello
     {/* <CustomScatterChart/> */}
     <Logtable/>
     {/* {<MyStackedBarChart width={"100%"} height={'70vh'}/>} */}
     {/* <WaterFallWalletShare width={"100%"} height={'70vh'}/> */}
    </div>
  )
}

export default RevenuePool
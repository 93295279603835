import { css } from "styled-components";

const sizes = {
  small: { min: "0px", max: "768px" },
  medium: { min: "768.01px", max: "1024px" },
  large: { min: "1024.01px", max: "1440px" },
  xlarge: { min: "1440.01px", max: "7680px" },
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label].min}) and (max-width: ${sizes[label]
        .max}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

import React from "react";
import styled from "styled-components";

const ArrowIcon = ({
  width,
  height,
  color = "#0099FF",
  rotate = 0,
  disabled=false,
  onIconClick = () => null,
  className,
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      rotate={rotate}
      disabled={disabled}
      onClick={onIconClick}
      className={className}
    >
      <svg width={`100%`} height={`100%`} viewBox="0 0 24 24" fill="none">
        <path
          d="M15.0748 12.0008C15.0748 12.1008 15.0581 12.1925 15.0248 12.2758C14.9915 12.3592 14.9331 12.4425 14.8498 12.5258L9.89981 17.4758C9.76648 17.6092 9.58731 17.6717 9.36231 17.6633C9.13731 17.655 8.95815 17.5842 8.82481 17.4508C8.65815 17.2842 8.58731 17.105 8.61231 16.9133C8.63731 16.7217 8.71648 16.5508 8.84981 16.4008L13.2498 12.0008L8.84981 7.60083C8.71648 7.46749 8.64148 7.28833 8.62481 7.06333C8.60815 6.83833 8.68315 6.65916 8.84981 6.52583C8.98315 6.35916 9.15815 6.28833 9.37481 6.31333C9.59148 6.33833 9.77481 6.41749 9.92481 6.55083L14.8498 11.4758C14.9331 11.5592 14.9915 11.6425 15.0248 11.7258C15.0581 11.8092 15.0748 11.9008 15.0748 12.0008Z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default ArrowIcon;

const IconWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : 24)}px;
  width: ${(props) => (props.width ? props.width : 24)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  }
`;

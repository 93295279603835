import React from "react";
import styled from "styled-components";

const CrossIcon = ({
  width = 24,
  height = 24,
  color = "#444444",
  rotate = 0,
  onIconClick = () => null,
}) => {
  return (
    <IconWrapper rotate={rotate} onClick={onIconClick}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18.7279 6.27208L6 19"
          stroke={`${color}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7279 18.7279L6 6"
          stroke={`${color}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

export default CrossIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: pointer;
  }
`;

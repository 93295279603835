import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CheckBox from "../CheckBox";

const SortFilterIcon = ({
  apply_sorting = false,
  sorting_direction = "",
  onSortingClick,

  apply_range_filter = false,
  low = null,
  high = null,
  filteredRange = [],
  onApplySelected = () => {},
  rangeHeader = "Select Range",

  apply_value_filter = false,
  value_filter_data = [],
  onApplyValueFilter = () => {},
  valueFilterHeader = "Select Values",

  //meta data
  showCheckBox = true,
  width = 25,
  height = 25,
  removetext=false
}) => {
  const iconRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [valueFilteOoptions, setValueFilteOoptions] =
    useState(value_filter_data);

  const isFilterApplied =
    Boolean(filteredRange.length) ||
    Boolean(value_filter_data.filter((opt) => opt.selected).length);
  const isSortApplied = sorting_direction !== "";
  const isAscending = sorting_direction === "asc";
  const isDescending = sorting_direction === "desc";

  const onOptionSelect = (option) => {
    const isSelectedClicked = Boolean(
      selectedOptions.filter((obj) => obj.id === option.id).length
    );
    if (isSelectedClicked) {
      const remainingSelected = selectedOptions.filter(
        (obj) => obj.id !== option.id
      );
      setSelectedOption(remainingSelected);
    } else {
      let result = selectedOptions.filter((obj) => obj.id !== option.id);
      result.push(option);
      setSelectedOption(result);
    }
  };

  const onValueFilterSelect = (selectedOpt) => {
    setValueFilteOoptions(
      valueFilteOoptions.map((option) => ({
        ...option,
        selected:
          selectedOpt.id === option.id ? !option.selected : option.selected,
      }))
    );
  };

  const onApplyClick = () => {
    if (apply_range_filter) {
      onApplySelected(selectedOptions);
    }
    if (apply_value_filter) {
      onApplyValueFilter(valueFilteOoptions);
    }
    setPopupOpen(false);
  };

  const onResetClick = () => {
    if (apply_range_filter) {
      setSelectedOption([]);
      onApplySelected([]);
    }

    if (apply_value_filter) {
      onApplyValueFilter(
        valueFilteOoptions.map((opt) => ({ ...opt, selected: false }))
      );
    }
    setPopupOpen(false);
  };

  useEffect(() => {
    if (low && high) {
      let range = Number(high - low);
      let stepSize = 1;
      range = Math.floor(range / 10);
      while (range) {
        stepSize = stepSize * 10;
        range = Math.floor(range / 10);
      }
      let start = Math.floor(low / stepSize) * stepSize;
      const end = (Math.floor(high / stepSize) + 1) * stepSize;
      let newOptions = [];
      let id = 1;
      while (start < end) {
        newOptions.push({
          id,
          name: `${start} - ${start + stepSize}`,
          low: start,
          high: start + stepSize,
          selected: false,
        });
        id++;
        start += stepSize;
      }
      setOptions(newOptions);
    }
  }, [low, high]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isPopupOpen &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  useEffect(() => {
    setValueFilteOoptions(value_filter_data);
  }, [value_filter_data]);

  return (
    <FilterWrapper ref={isPopupOpen ? iconRef : null}>
      <IconWrap
        onClick={() => {
          setPopupOpen(!isPopupOpen);
        }}
      >
        {!isSortApplied && !isFilterApplied ? (
          isPopupOpen ? (
            //popupOpen Icon
            <svg
              width={`${width}px`}
              height={`${height}px`}
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M12.5012 9.29731C12.6054 9.29731 12.7008 9.31467 12.7876 9.3494C12.8745 9.38412 12.9613 9.44488 13.0481 9.53169L18.2043 14.6879C18.3432 14.8268 18.4083 15.0135 18.3996 15.2478C18.3909 15.4822 18.3172 15.6688 18.1783 15.8077C18.0047 15.9813 17.818 16.0551 17.6184 16.0291C17.4187 16.003 17.2408 15.9206 17.0845 15.7817L12.5012 11.1984L7.91785 15.7817C7.77896 15.9206 7.59233 15.9987 7.35796 16.0161C7.12358 16.0334 6.93695 15.9553 6.79806 15.7817C6.62445 15.6428 6.55067 15.4605 6.57671 15.2348C6.60275 15.0091 6.68521 14.8181 6.8241 14.6619L11.9543 9.53169C12.0411 9.44488 12.1279 9.38412 12.2147 9.3494C12.3015 9.31467 12.397 9.29731 12.5012 9.29731Z"
                fill="#444444"
              />
            </svg>
          ) : (
            //popupOpen Close
            <svg
              width={`${width}px`}
              height={`${height}px`}
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M12.4988 15.7027C12.3946 15.7027 12.2992 15.6853 12.2124 15.6506C12.1255 15.6159 12.0387 15.5551 11.9519 15.4683L6.79569 10.3121C6.6568 10.1732 6.59169 9.98654 6.60038 9.75217C6.60906 9.51779 6.68284 9.33116 6.82173 9.19227C6.99534 9.01866 7.18197 8.94487 7.38163 8.97092C7.58128 8.99696 7.75923 9.07942 7.91548 9.21831L12.4988 13.8016L17.0821 9.21831C17.221 9.07942 17.4077 9.0013 17.642 8.98394C17.8764 8.96658 18.063 9.0447 18.2019 9.21831C18.3755 9.3572 18.4493 9.53949 18.4233 9.76519C18.3973 9.99088 18.3148 10.1819 18.1759 10.3381L13.0457 15.4683C12.9589 15.5551 12.8721 15.6159 12.7853 15.6506C12.6985 15.6853 12.603 15.7027 12.4988 15.7027Z"
                fill="#D1D1D1"
              />
            </svg>
          )
        ) : !isSortApplied ? (
          // only filter apllied
          <svg
            width={`${width}px`}
            height={`${height}px`}
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6.11698 14.1846C6.06194 14.1846 6.01149 14.1754 5.96562 14.1571C5.91975 14.1387 5.87389 14.1066 5.82802 14.0607L3.1036 11.3363C3.03022 11.2629 2.99582 11.1643 3.0004 11.0405C3.00499 10.9166 3.04398 10.818 3.11736 10.7446C3.20909 10.6529 3.3077 10.6139 3.4132 10.6277C3.51869 10.6415 3.61271 10.685 3.69527 10.7584L6.11698 13.1801L8.53868 10.7584C8.61207 10.685 8.71068 10.6437 8.83452 10.6346C8.95835 10.6254 9.05697 10.6667 9.13035 10.7584C9.22208 10.8318 9.26107 10.9281 9.24731 11.0474C9.23355 11.1666 9.18998 11.2675 9.11659 11.3501L6.40593 14.0607C6.36006 14.1066 6.3142 14.1387 6.26833 14.1571C6.22247 14.1754 6.17202 14.1846 6.11698 14.1846Z"
              fill="#444444"
            />
            <path
              d="M13.9374 9.29649V14.014C13.9374 14.1886 13.7629 14.3094 13.5994 14.248L10.9744 13.2613C10.8769 13.2246 10.8124 13.1314 10.8124 13.0272V9.29726L7.69978 5.40617C7.56883 5.24247 7.68538 5 7.895 5H16.8547C17.0643 5 17.1809 5.24249 17.0499 5.40618L13.9374 9.29649Z"
              fill="#444444"
            />
          </svg>
        ) : !isFilterApplied ? (
          // only sorting apllied
          isAscending ? (
            <svg
              width={`${width}px`}
              height={`${height}px`}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.51639 14.4346C7.46135 14.4346 7.4109 14.4254 7.36503 14.4071C7.31917 14.3887 7.2733 14.3566 7.22744 14.3107L4.50302 11.5863C4.42963 11.5129 4.39523 11.4143 4.39982 11.2905C4.40441 11.1666 4.44339 11.068 4.51678 10.9946C4.60851 10.9029 4.70712 10.8639 4.81261 10.8777C4.9181 10.8915 5.01213 10.935 5.09468 11.0084L7.51639 13.4301L9.9381 11.0084C10.0115 10.935 10.1101 10.8937 10.2339 10.8846C10.3578 10.8754 10.4564 10.9167 10.5298 11.0084C10.6215 11.0818 10.6605 11.1781 10.6467 11.2974C10.633 11.4166 10.5894 11.5175 10.516 11.6001L7.80534 14.3107C7.75948 14.3566 7.71361 14.3887 7.66775 14.4071C7.62188 14.4254 7.57143 14.4346 7.51639 14.4346Z"
                fill="#444444"
              />
              <path
                d="M13.1494 6.5L13.1494 14"
                stroke="#444444"
                strokeLinecap="round"
              />
              <path
                d="M13.1494 5.25L15.3145 8.0625H10.9844L13.1494 5.25Z"
                fill="#444444"
              />
            </svg>
          ) : (
            <svg
              width={`${width}px`}
              height={`${height}px`}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.61698 14.0596C7.56194 14.0596 7.51149 14.0504 7.46562 14.0321C7.41975 14.0137 7.37389 13.9816 7.32802 13.9357L4.6036 11.2113C4.53022 11.1379 4.49582 11.0393 4.5004 10.9155C4.50499 10.7916 4.54398 10.693 4.61736 10.6196C4.70909 10.5279 4.8077 10.4889 4.9132 10.5027C5.01869 10.5165 5.11271 10.56 5.19527 10.6334L7.61698 13.0551L10.0387 10.6334C10.1121 10.56 10.2107 10.5187 10.3345 10.5096C10.4584 10.5004 10.557 10.5417 10.6304 10.6334C10.7221 10.7068 10.7611 10.8031 10.7473 10.9224C10.7335 11.0416 10.69 11.1425 10.6166 11.2251L7.90593 13.9357C7.86006 13.9816 7.8142 14.0137 7.76833 14.0321C7.72247 14.0504 7.67202 14.0596 7.61698 14.0596Z"
                fill="#444444"
              />
              <path
                d="M13.25 5.5L13.25 13"
                stroke="#444444"
                strokeLinecap="round"
              />
              <path
                d="M13.25 14.25L11.0849 11.4375H15.4151L13.25 14.25Z"
                fill="#444444"
              />
            </svg>
          )
        ) : isAscending ? ( // both sorting and filter
          <svg
            width={`${width}px`}
            height={`${height}px`}
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.1246 11.5L7.4499 5.65617C7.31896 5.49247 7.43551 5.25 7.64513 5.25L16.6048 5.25C16.8144 5.25 16.931 5.49249 16.8 5.65618L12.1246 11.5Z"
              fill="#444444"
            />
            <path
              d="M10.5625 9.25C10.5625 9.11193 10.6744 9 10.8125 9H13.4375C13.5756 9 13.6875 9.11193 13.6875 9.25V14.264C13.6875 14.4386 13.513 14.5594 13.3495 14.498L10.7245 13.5113C10.6271 13.4746 10.5625 13.3814 10.5625 13.2772V9.25Z"
              fill="#444444"
            />
            <path
              d="M5.25 6.5L5.25 14"
              stroke="#444444"
              strokeLinecap="round"
            />
            <path
              d="M5.25 5.25L7.41506 8.0625H3.08494L5.25 5.25Z"
              fill="#444444"
            />
          </svg>
        ) : (
          // both sorting and filter
          <svg
            width={`${width}px`}
            height={`${height}px`}
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.2251 11.25L7.55049 5.40617C7.41955 5.24247 7.53609 5 7.74571 5L16.7054 5C16.915 5 17.0316 5.24249 16.9006 5.40618L12.2251 11.25Z"
              fill="#444444"
            />
            <path
              d="M10.6631 9C10.6631 8.86193 10.775 8.75 10.9131 8.75H13.5381C13.6762 8.75 13.7881 8.86193 13.7881 9V14.014C13.7881 14.1886 13.6136 14.3094 13.4501 14.248L10.8251 13.2613C10.7276 13.2246 10.6631 13.1314 10.6631 13.0272V9Z"
              fill="#444444"
            />
            <path
              d="M5.35059 5.625L5.35059 13.125"
              stroke="#444444"
              strokeLinecap="round"
            />
            <path
              d="M5.35059 14.375L3.18552 11.5625H7.51565L5.35059 14.375Z"
              fill="#444444"
            />
          </svg>
        )}
      </IconWrap>
      {isPopupOpen && (
        <>
          <Triangle />
          <Dropdown>
            {/* <SearchBox
            required={required}
            value={searchValue}
            onChange={onInputChange}
            id={id}
            placeholder={searchPlaceholder}
            type={type}
            onBlur={(e) => !disabled && onBlur && onBlur(e)}
            disabled={disabled}
            ref={searchRef}
          ></SearchBox> */}
            {apply_sorting ? (
              <>
                <SelectionRow
                  onClick={() => {
                    onSortingClick("asc");
                    setPopupOpen(false);
                  }}
                  isselected={isAscending}
                >
                  Sort by A to Z
                </SelectionRow>
                <SelectionRow
                  onClick={() => {
                    onSortingClick("desc");
                    setPopupOpen(false);
                  }}
                  isselected={isDescending}
                >
                  Sort by Z to A
                </SelectionRow>
              </>
            ) : (
              <FilterHeading>
                {apply_range_filter ? rangeHeader : valueFilterHeader}
              </FilterHeading>
            )}

            {apply_range_filter || apply_value_filter ? (
              <>
                <ScrollWrapper>
                  <ScrollShadowTop />
                  <>
                    <DropdownScroll>
                      {apply_sorting ? (
                        <FilterHeading>
                          {apply_range_filter ? rangeHeader : valueFilterHeader}
                        </FilterHeading>
                      ) : null}
                      {apply_range_filter
                        ? options.map((option, index) => {
                            const isselected = selectedOptions.filter(
                              (obj) => obj.id === option.id
                            ).length;
                            return (
                              <SelectionRow
                                key={index}
                                isselected={showCheckBox ? false : isselected}
                                onClick={() => {
                                  onOptionSelect(option);
                                }}
                              >
                                {showCheckBox && (
                                  <CheckBoxWrapper>
                                    {" "}
                                    <CheckBox value={isselected} />
                                  </CheckBoxWrapper>
                                )}
                                <DropdownValue>{option.name}</DropdownValue>
                              </SelectionRow>
                            );
                          })
                        : valueFilteOoptions.map((option, index) => {
                            return (
                              <SelectionRow
                                key={option.id}
                                isselected={
                                  showCheckBox ? false : option.selected
                                }
                                onClick={() => {
                                  onValueFilterSelect(option);
                                }}
                              >
                                {showCheckBox && (
                                  <CheckBoxWrapper>
                                    {" "}
                                    <CheckBox value={option.selected} />
                                  </CheckBoxWrapper>
                                )}
                                <DropdownValue>{option.name}</DropdownValue>
                              </SelectionRow>
                            );
                          })}
                    </DropdownScroll>
                    <ScrollShadowBot />
                  </>
                </ScrollWrapper>
                <HeadingRow>
                  <ActionApply onClick={onApplyClick}>Apply</ActionApply>
                  <ActionReset onClick={onResetClick}>Reset</ActionReset>
                </HeadingRow>
              </>
            ) : null}
          </Dropdown>
        </>
      )}
    </FilterWrapper>
  );
};

export default SortFilterIcon;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  font-weight: 400;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25px;
  height: 25px;
`;

const Triangle = styled.div`
  content: "";
  position: absolute;
  height: 8px;
  width: 16px;
  top: 29px;
  left: 4px;
  z-index: 10;
  background-color: #ffffff;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  height: 40px;
`;

const InputLabel = styled.div`
  padding: 5px;
  font-size: 0.8rem;
  color: rgb(114, 114, 114);
`;

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .redtext {
    color: #f44336;
    font-size: 16px;
  }
`;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 5px 5px 10px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: ${(props) =>
    props.optionsopen === "true" ? "5px 5px 0px 0px" : "5px"};
  border-radius: 5px;
  width: 100%;
  transition: 0.2s ease-out;
  transition-property: border;
  background-color: ${(props) => (props.disabled ? "#efefef4d" : "#FFFFFF")};
  position: relative;
`;

const SearchBox = styled.input`
  margin: 8px;
  padding: 5px;
  border-radius: 2px;
  height: 26px;
  transition: 0.2s ease-out;
  transition-property: border;
  border: 1px solid #cccccc;
  font-size: 16px;
  outline: none;
  background-color: transparent;
  :focus {
    border: 1px solid #0099ff;
    outline: none;
  }
  :hover {
    border: 1px solid #0099ff;
    outline: none;
    box-shadow: 0 0 10px #d4eeff;
  }
  :disabled {
  }
`;

const FilterHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 160px;
  padding: 6px 7px;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 500;
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 6px 7px 0px 7px;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 500;
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;
const ActionApply = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 8px;
  background-color: #0099ff;
  color: #ffffff;
  border-radius: 24px;
  cursor: pointer;
`;

const ActionReset = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 8px;
  background-color: #ffffff;
  color: #0099ff;
  border-radius: 24px;
  border: 1px solid #0099ff;
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 34px;
  height: 30px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  z-index: 5;
  cursor: default;
  position: absolute;
  top: 36px;
  right: -10px;
  padding: 8px 0 8px;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

const ScrollShadowTop = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    transparent 100%
  );
`;

const ScrollShadowBot = styled.div`
  display: flex;
  width: 100%;
  height: 5px;
  bottom: 0px;
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
`;

const DropdownScroll = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  overflow: auto;
`;

// const SelectionRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: start;
//   width: 100%;
//   padding: 6px 8px;
//   background-color: ${(props) => (props.isselected ? "#0099FF" : null)};
//   color: ${(props) => (props.isselected ? "#FFFFFF" : "#000000")};
//   &:hover {
//     background-color: ${(props) =>
//       props.isselected ? "#0099FF" : "#e5e5e560"};
//     cursor: pointer;
//   }
// `;

const SelectionRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 38px;
  padding: 6px 10px 6px 8px;
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px;` : null};
  background-color: ${(props) => (props.isselected ? "#0099FF" : null)};
  color: ${(props) => (props.isselected ? "#FFFFFF" : "#000000")};
  &:hover {
    background-color: ${(props) =>
      props.isselected ? "#0099FF" : "#e5e5e560"};
    cursor: pointer;
  }
`;

const DropdownValue = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Placeholder = styled.div`
  color: #969799;
  font-size: 13px;
  font-style: normal;
`;

const CheckBoxWrapper = styled.div`
  margin-right: 5px;
`;

import React from "react";
import styled from "styled-components";

const PartitionLine = ({
  width = 100,
  height = 2,
  color = "#000000",
  rotate = 0,
}) => {
  return (
    <IconWrapper rotate={rotate}>
      <svg
        width={`${width}%`}
        height={`${height}px`}
        viewBox="0 0 1039 2"
        fill="none"
      >
        <path opacity="0.2" d="M0 1H1039" stroke={`${color}`} />
      </svg>
    </IconWrapper>
  );
};

export default PartitionLine;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
`;

import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/media';

const ScoreBargraph_two = ({ height = 50, width = 160, props }) => {
  const data = [
    // {
    //   name: 'Topline',
    //   value: Math.round(props.value * 10) / 10,
    // },
    // {
    //   name: 'Customer love',
    //   value: Math.round(props.value2 * 10) / 10,
    // },
    {
      name: 'Financial',
      value: Math.round(props.value3 * 10) / 10,
    },
    {
      name: 'Management',
      value: Math.round(props.value4 * 10) / 10,
    },
  ];

  const DataRow = ({ name, value }) => {
    const lineWidth = (value / 10) * width;

    // Segment positions: 20%, 40%, 60%, 80% of the width (160px)
    const segmentPositions = [];
    let t = 0;
    for (let i = 0.2; i <= 0.8; i += 0.2) {
      segmentPositions[t] = i * width;
      t++;
    }

    return (
      <OuterStyled>
        <NameSpan width={width}>{name}</NameSpan>
        <BarContainer width={width}>
          <BackgroundLine />
          <ValueBar lineWidth={lineWidth} value={value} />
          {segmentPositions.map((position, index) => (
            <SegmentMarker key={index} position={position} />
          ))}
        </BarContainer>
        <ValueSpan>{value}</ValueSpan>
      </OuterStyled>
    );
  };

  return (
    <div>
      {data.map((item, index) => (
        <DataRow key={index} name={item.name} value={item.value} />
      ))}
    </div>
  );
};

export default ScoreBargraph_two;

const OuterStyled = styled.div`
padding-left:3%;
  display: flex; 
  align-items: center;
  margin-bottom: 5px;

  ${media.small`
    margin-bottom: 3px;
  `}
`;

const NameSpan = styled.span`
  margin-right: 20px;
  font-size:13px;
  width: ${({ width }) => 0.625 * width}px;
  white-space: nowrap;
  color: #444444;
  ${media.small`
  margin-right: 10px;
  width: ${({ width }) => 0.425 * width}px;
  font-size:9px;

`}
`;

const BarContainer = styled.div`
  position: relative;
  width: ${({ width }) => width}px;
  height: 3px;
  ${media.small`
  height: 1px;
`}

`;

const BackgroundLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  position: absolute;
  top: 1px;
`;

const ValueBar = styled.div`
  width: ${({ lineWidth }) => lineWidth}px;
  height: 3px;
  background-color: ${({ value }) =>
    value < 2 ? '#DA1E28' : value < 6 ? '#FCBB53' : '#21AF4A'};
  border-radius: 1.5px;
  position: absolute;
  top: 0px;

  ${media.small`
  height: 2px;
  border-radius: 1.5px;
`}
`;

const SegmentMarker = styled.div`
  position: absolute;
  top: 0px;
  width: 1px;
  height: 3px;
  background-color: white;
  left: ${({ position }) => position}px;
  ${media.small`
  width: 0.5px;
  height: 2px;
`}

`;

const ValueSpan = styled.span`
  margin-left: 10px;
  color: #444444;
  font-size:15px;
  ${media.small`
  font-size:10px;
  `}
`;

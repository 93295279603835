import React from "react";
import styled from "styled-components";
import {
  ComposedChart,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

const TimeTrendGraph = ({
  paramName,
  data = [],
  dataKey = "value",
  width = 0,
  height = 0,
}) => {
  return (
    <PopupWrap>
      {/* <Title>
        {paramName}
      </Title> */}
      <PopupBody>
        <ComposedChart
          width={width}
          height={height}
          data={data}
          barSize={50}
          margin={{ top: 25, right: 0, left: 0, bottom: 10 }}
        >
          <XAxis dataKey="start_date_label" />
          {/* <YAxis /> */}
          <Tooltip />
          <CartesianGrid stroke="#D1D1D1" horizontal={true} vertical={false} />
          <Bar dataKey={dataKey} fill="#0099FF" name="Value">
            <LabelList
              dataKey={dataKey}
              position="top"
              dy={-4}
              // dx={-30}
              fill="#444444"
              fontSize={12}
              fontWeight="bold"
            />
          </Bar>
        </ComposedChart>
      </PopupBody>
    </PopupWrap>
  );
};

export default TimeTrendGraph;

const PopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 5;
  text-align: center;
  color: #262e40;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 5px 10px 10px;C
`;
const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

import React from "react";
import styled from "styled-components";
import {
  ComposedChart,
  LabelList,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
} from "recharts";
import { media } from "../../../../utils/media";

const TimeTrendGraph = ({
  heading,
  companyName,
  lineChart = false,
  data = [],
  barDataKey = "value",
  lineDataKey = "industry_avg",
}) => {
  return (
    <PopupWrap>
      <Title>{heading}</Title>
      <PopupBody>
        <CompanyWrapper>
          {companyName && (
            <CompanyBox>
              <BeadCircle color="#0099FF" />{" "}
              <CompanyName>{companyName}</CompanyName>
            </CompanyBox>
          )}
        </CompanyWrapper>
        <WebView>
          <ComposedChart
            width={500}
            height={250}
            data={data}
            barSize={30}
            margin={{ top: 25, right: 13, left: 13, bottom: 10 }}
          >
            <XAxis dataKey="date" />
            <Tooltip />
            <CartesianGrid
              stroke="#D1D1D1"
              horizontal={true}
              vertical={false}
            />
            <Bar
              dataKey={barDataKey}
              fill="#66B3FF"
              name={`${companyName} Value`}
            >
              <LabelList
                dataKey={barDataKey}
                position="center"
                dy={0}
                // dx={-30}
                fill="#003D66"
                fontSize={12}
                fontWeight="normal" //bold
              />
            </Bar>
          </ComposedChart>
        </WebView>
        <MobileView>
          <ComposedChart
            width={350}
            height={200}
            data={data}
            barSize={15}
            margin={{ top: 25, right: 15, left: 15, bottom: 10 }}
          >
            <XAxis dataKey="label" />
            {/* <YAxis /> */}
            <Tooltip />
            <CartesianGrid
              stroke="#D1D1D1"
              horizontal={true}
              vertical={false}
            />
            <Bar
              dataKey={barDataKey}
              fill="#66B3FF"
              name={`${companyName} Value`}
              width={10}
            >
              <LabelList
                dataKey={barDataKey}
                position="center"
                dy={0}
                // dx={-30}
                fill="#003D66"
                fontSize={10}
                fontWeight="normal" //bold
              />
            </Bar>
            {/* {lineChart && (
            <Line
              dot={{ fill: "#FF3232", stroke: "#FFFFFF", strokeWidth: 2, r: 4 }}
              strokeWidth={2}
              strokeLinecap="round"
              type="linear"
              dataKey={lineDataKey}
              stroke="#FF3232"
              legendType="rect"
              name="Industry Average"
            >
              <LabelList
                dataKey={lineDataKey}
                position="right"
                dy={-20}
                fill="#DA1E28"
                fontSize={10}
                fontWeight="normal"//bold
              />
            </Line>
          )} */}
          </ComposedChart>
        </MobileView>
      </PopupBody>
    </PopupWrap>
  );
};

export default TimeTrendGraph;

const PopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 5;
  text-align: center;
  color: #262e40;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 5px 10px 10px;
  ${media.small`
      font-size: 12px;
  padding: 2.5px 5px 5px;

    `}
`;
const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1.5px solid #f0f0f0;
  padding: 5px 10px;
  ${media.small`
      border-radius: 5px;
  border: 1px solid #f0f0f0;
  padding: 4px 8px;
    `}
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  width: 100%;
`;

const CompanyBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const CompanyName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 22px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #9e9e9e;
  padding-left: 5px;
  padding-right: 20px;
  ${media.small`
      height: 11px;
  font-size: 9px;
  line-height: 10px;
  padding-left: 2.5px;
  padding-right: 10px;
  
    `}
`;

const BeadCircle = styled.div`
  background: linear-gradient(
    180deg,
    ${(props) => props.color} 100%,
    ${(props) => props.color} 100%
  );
  width: 16px;
  height: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  ${media.small`
      width: 8px;
  height: 8px;
  border-radius: 4px;
    `}
`;

const WebView = styled.div`
  display: block;
  ${media.small`
  display:none;
  `}
`;

const MobileView = styled.div`
  display: none;
  ${media.small`
  display:block;
  `}
`;

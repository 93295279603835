import React, { useState, useEffect } from "react";
import { event } from "react-ga";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { onSectorReportOpenCT } from "../../utils/clevertap";
import ColumnDropDown from "./ColumnDropDown";

const TreeDropDn = ({ isOpen = false, setIsOpen = () => null }) => {
  let newReporTree = JSON.parse(window.localStorage.getItem("newReporTree"));
  let subDictionary = JSON.parse(window.localStorage.getItem("subDictionary"));

  let handleClick = (
    val,
    key,
    filter,
    filter_value,
    subscribed,
    industry_id
  ) => {
    onSectorReportOpenCT(val, key, subscribed);
    try {
      filter_value = filter_value.replace(/&/g, "and");
    } catch (err) {
      console.log("& absent");
    }
    window.localStorage.setItem("industry_id", industry_id);
    window.localStorage.setItem("report", val);
    window.open(
      `/Report3/?val=${val}&key=${key}&filter_value=${filter_value}&filter=${filter}&industry_id=${industry_id}`
    );
  };

  const ParentTreeNode = ({ node }) => {
    const [isHovered, setIsHovered] = useState(false);

    const newNode = {
      ...node,
      children: node.level === 3 ? node.children?.[0]?.children : node.children,
    };
    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <div
        className="ParentGrid"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          boxSizing: "border-box",
          cursor: "pointer",
          position: "relative",
          color: isHovered ? "white" : "black",
          paddingRight: "3px",
        }}
      >
        <div
          onClick={() => {
            handleClick(
              newNode.report_name,
              newNode.id,
              newNode.filter,
              newNode.filter_value,
              newNode.subscribed,
              newNode.industry
            );
          }}
        >
          {newNode.report_name}
          {newNode.id in subDictionary ? (
            <span
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                backgroundColor: "#0a99fe",
                fontSize: "10px",
                color: "white",
                padding: "2px",
                borderRadius: "6px",
              }}
            >
              Subscribed
            </span>
          ) : null}
        </div>

        {isHovered && newNode.children && newNode.level <= 2 && (
          <ChildNodes>
            {newNode.children.map((child) => (
              <ParentTreeNode key={child.id} node={child} />
            ))}
          </ChildNodes>
        )}
      </div>
    );
  };

  return (
    //Orginal logic
    // <ParentCollapseGridDiv isOpen={isOpen}>
    //   {newReporTree != null
    //     ? newReporTree.map((node) => (
    //         <ParentTreeNode key={node.id} node={node} />
    //       ))
    //     : null}
    // </ParentCollapseGridDiv>
    <Wrapper>
      {newReporTree && subDictionary ? (
        <ColumnDropDown
          isOpen={isOpen}
          node_data={newReporTree}
          subDictionary={subDictionary}
          setIsOpen={setIsOpen}
        />
      ) : null}
    </Wrapper>
  );
};

export default TreeDropDn;

const Wrapper = styled.div``;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ParentCollapseGridDiv = styled.div`
  background-color: #ffffff;
  grid-area: ParentCollapseGridDiv;
  display: ${(props) => (props.isOpen ? "grid" : "none")};
  font-size: 14px;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  .ParentGrid {
    border: 0.5px solid transparent;
    padding-left: 7px;
    z-index: 5;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgb(0, 153, 255);
      animation: ${fadeIn} 0.3s ease;
    }
    a {
      color: black;
      text-decoration: none;
      &:hover {
        color: white;
        cursor: pointer;
      }
    }
  }
`;

const ChildNodes = styled.div`
  display: grid;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  left: calc(100% + 0px);
  top: 0;
  z-index: -2;
  white-space: nowrap;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { HOME_URL } from "../../constants/constants";
import SearchIcon from "../../components/svg/SearchIcon";
import MultiSelect from "../../components/MultiSelect";
import StyledTable from "../../components/StyledTable";
import ListGridToggle from "../../components/ListGridToggle";
import CompanyListView from "./CompanyListView";
import CompanyGridView from "./CompanyGridView";
import Loader from "../../components/Loader";
import axios from "axios";

const CompanyBoard = () => {
  // API whole data
  const [parameterTypes, setParameterTypes] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [parametersIdList, setParameterIdList] = useState([]);
  const [isParameterDataLoaded, setParamaterDataLoaded] = useState(false);

  const [indReportMapping, setIndReportMapping] = useState([]);
  const [indWiseCompReport, setIndWiseCompReport] = useState([]);
  const [isIndReportMappingLoaded, setIndReportMappingLoaded] = useState(false);

  const [companiesDataList, setCompaniesDataList] = useState([]);
  const [industryIdList, setIndustryIdList] = useState([]);
  const [isCompanyDataLoaded, setCompanyDataLoaded] = useState(false);

  // const [parametersValue, setParametersValue] = useState([]);
  const [isParameterValueLoaded, setParameterValueLoaded] = useState(false);
  const [dataMonth, setDataMonth] = useState(null);

  // user cutomized data
  const [listGridToogle, setListGridToggle] = useState("left");
  const useQuery = () => new URLSearchParams(useLocation().search);
  const url_industry_id = Number(useQuery().get("industry_id")) || null;
  const [compSearchString, setCompSearchString] = useState(
    useQuery().get("company") || ""
  );
  const [tags, setTags] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  // const [visibleParameter, setVisibleParameter] = useState([]);
  const [visibaleCompDataList, setVisibaleCompDataList] = useState([]);
  const [sortedParameter, setSortedParameter] = useState(null);
  const [sortedDirection, setSortedDirection] = useState("");

  // Grid Graph Data
  const [pairedParams, setPairedParams] = useState([]);
  const [iconData, setIconData] = useState([]);

  const treeziparr = [
    { key: 1, name: "Home" },
    { key: 2, name: "Platform Listing" },
  ];
  const navigate = useNavigate();

  let handleClickTree = (index) => {
    if (treeziparr[index].key === 1) {
      navigate(HOME_URL);
    }
  };

  // const onSelectParameter = (selectedParam) => {
  //   setVisibleParameter(selectedParam);
  // };

  const setSelectedIndustry = (selectedIndustry) => {
    setIndustryList(
      industryList.map((ind) => {
        return {
          ...ind,
          selected: selectedIndustry.some(
            (selectedInd) => selectedInd.industry_id === ind.industry_id
          ),
        };
      })
    );
  };

  useEffect(() => {
    const fetchTags = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_WEBFORM_ENDPOINT}/tag`
      );
      const tagsData = response.data;
      const userCustomTag = window.localStorage.getItem("comp_selected_tags");
      if (userCustomTag?.length) {
        const userCustomTagArray = userCustomTag.split(",");
        setTags(
          tagsData.map((obj) => {
            return {
              ...obj,
              selected: userCustomTagArray.includes(String(obj.id)),
            };
          })
        );
      } else {
        setTags(
          tagsData.map((obj) => {
            return { ...obj, selected: false };
          })
        );
      }
    };
    fetchTags();
  }, []);

  const onTagClick = (tagId) => {
    const updatedTags = tags.map((obj) => {
      return {
        ...obj,
        selected: obj.id === tagId ? !obj.selected : obj.selected,
      };
    });
    const commaSeperatedTagList = updatedTags
      .filter((obj) => obj.selected)
      .map((obj) => obj.id)
      .join(",");
    window.localStorage.setItem("comp_selected_tags", commaSeperatedTagList);

    setTags(
      tags.map((obj) => {
        return {
          ...obj,
          selected: obj.id === tagId ? !obj.selected : obj.selected,
        };
      })
    );
  };

  const setSortedColumn = (sortedCol) => {
    setSortedParameter(sortedCol);
  };

  useEffect(() => {
    if (sortedParameter) {
      const size = companiesDataList.length;
      setCompaniesDataList(
        companiesDataList.slice(0, size).sort((a, b) => {
          const aValueData =
            a.paramData[sortedParameter.parameter]?.current_value || 0;
          const bValueData =
            b.paramData[sortedParameter.parameter]?.current_value || 0;
          if (sortedDirection === "asc") {
            return aValueData - bValueData;
          } else {
            return bValueData - aValueData;
          }
        })
      );
    }
  }, [sortedParameter, sortedDirection]);

  const stringContains = (target, query) => {
    const targetString = target.replace(/\s/g, "").toLowerCase();
    const queryString = query.replace(/\s/g, "").toLowerCase();
    return targetString.includes(queryString);
  };

  const tagsCompare = (comTags, selectedTag) => {
    return comTags.some((obj1) =>
      selectedTag.some((obj2) => obj1.id === obj2.id)
    );
  };

  useEffect(() => {
    let selectedTags = tags.filter((tag) => tag.selected) || tags;
    const selectedIndustry = industryList
      .filter((ind) => ind.selected)
      .map((ind) => ind.industry_id);
    setVisibaleCompDataList(
      companiesDataList
        .filter((comp) => stringContains(comp.name, compSearchString))
        .filter((comp) =>
          selectedTags.length === 0
            ? true
            : tagsCompare(comp.tags, selectedTags)
        )
        .filter((comp) =>
          selectedIndustry.length === 0
            ? true
            : selectedIndustry.includes(comp.industry_id)
        )
    );
  }, [tags, companiesDataList, compSearchString, industryList]);

  const fetchParameterValues = async (parametersIdList, industryIdList) => {
    const postData = {
      param_arr: parametersIdList,
      industry_id_list: industryIdList,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_WEBFORM_ENDPOINT}/parametervaluesall/`,
      postData
    );

    let parametersData = response.data;
    let data_date = new Date(parametersData[0].start_date);
    const data_month = data_date.toLocaleString("default", { month: "short" });
    const data_year = data_date.getFullYear().toString().slice(-2);
    let month_name = `${data_month}'${data_year}`;
    setDataMonth(month_name);

    const indWiseParamSums = {};
    const companyWiseParams = {};
    const parWiseRange = {};
    parametersData.forEach((entry) => {
      const value = parseFloat(entry.current_value) || 0;
      if (!parWiseRange[entry.parameter]) {
        parWiseRange[entry.parameter] = { high: null, low: null };
      }
      if (
        !parWiseRange[entry.parameter].high ||
        parWiseRange[entry.parameter].high < value
      ) {
        parWiseRange[entry.parameter].high = value;
      }
      if (
        !parWiseRange[entry.parameter].low ||
        parWiseRange[entry.parameter].low > value
      ) {
        parWiseRange[entry.parameter].low = value;
      }

      if (!indWiseParamSums[entry.industry_id]) {
        indWiseParamSums[entry.industry_id] = {};
      }
      if (!indWiseParamSums[entry.industry_id][entry.parameter]) {
        indWiseParamSums[entry.industry_id][entry.parameter] = 0;
      }
      indWiseParamSums[entry.industry_id][entry.parameter] += parseFloat(
        entry.current_value
      );
      const company_cust_id = `${entry.player}_7`;
      if (!companyWiseParams[company_cust_id]) {
        companyWiseParams[company_cust_id] = {};
      }
      companyWiseParams[company_cust_id][entry.parameter] = entry;
    });

    setParameters(
      parameters.map((obj) => ({
        ...obj,
        high: parWiseRange[obj.parameter]
          ? parWiseRange[obj.parameter].high
          : obj.low,
        low: parWiseRange[obj.parameter]
          ? parWiseRange[obj.parameter].low
          : obj.low,
      }))
    );

    parametersData = parametersData.map((entry) => {
      const sumForParameter =
        indWiseParamSums[entry.industry_id][entry.parameter];
      if (entry.current_value === 0) {
        entry.market_share = 0;
      } else {
        entry.market_share =
          (parseFloat(entry.current_value) / sumForParameter) * 100;
      }
      return entry;
    });

    // setParametersValue(parametersData);
    setParameterValueLoaded(true);
    setCompaniesDataList(
      companiesDataList.map((comp) => {
        const company_cust_id = `${comp.id}_${comp.type}`;
        const reportData =
          indWiseCompReport[comp.industry_id]?.filter((indReport) =>
            indReport.label.toLowerCase().includes(comp.name.toLowerCase())
          )?.[0] || null;
        return {
          ...comp,
          paramData: companyWiseParams[company_cust_id] || {},
          reportData: reportData,
        };
      })
    );
  };
  const fetchAltParameterValues = async (industryIdList) => {
    const postData = {
      industry_id_list: industryIdList,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_WEBFORM_ENDPOINT}/alternateparametertypeall/`,
      postData
    );

    let altParametersData = response.data;
    setPairedParams(altParametersData);

    const playerIds = new Set();
    playerIds.add(202);
    companiesDataList.forEach((entry) => {
      if (entry.type === 7) {
        playerIds.add(entry.id);
      }
    });
    let player_arr = JSON.stringify(Array.from(playerIds));
    const iconresponse = await axios.get(
      `https://api.benchmarks.digital/icons/?player_arr=${encodeURIComponent(
        player_arr
      )}`
    );
    let icondata = iconresponse.data;
    setIconData(icondata);
  };

  useEffect(() => {
    if (
      isParameterDataLoaded &&
      isCompanyDataLoaded &&
      isIndReportMappingLoaded
    ) {
      fetchParameterValues(parametersIdList, industryIdList);
      fetchAltParameterValues(industryIdList);
    }
  }, [
    isParameterDataLoaded,
    isCompanyDataLoaded,
    parametersIdList,
    industryIdList,
    isIndReportMappingLoaded,
  ]);

  useEffect(() => {
    const formatCompanyName = (input) => {
      const words = input.replace(/_/g, " ").split(" ");
      const formattedWords = words.map((word) => {
        if (word.length > 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      });
      const formattedName = formattedWords.join(" ");
      return formattedName;
    };

    const getsectorcompanies = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_WEBFORM_ENDPOINT}/sectors-companies-tag/`
      );
      let sectorsCompany = response.data;
      const industry_id_set = new Set();
      let playerList = [];
      let industryDataList = [];

      sectorsCompany.map((obj) => {
        industry_id_set.add(obj.industry_id);
        industryDataList.push({
          industry_id: obj.industry_id,
          industry_name: obj.industry_name,
          selected: url_industry_id === obj.industry_id ? true : false,
        });
        playerList.push(
          ...obj.companies
            .filter((comp) => comp.is_authentic)
            .map((comp) => ({
              ...comp,
              name: formatCompanyName(comp.name),
              industry_id: obj.industry_id,
              industry_name: obj.industry_name,
              paramData: {},
            }))
        );
        return { ...obj, selected: false };
      });
      setCompaniesDataList(playerList);
      const industry_id_list = Array.from(industry_id_set);
      setIndustryIdList(industry_id_list);
      setIndustryList(industryDataList);
      setCompanyDataLoaded(true);
    };
    getsectorcompanies();
  }, []);

  useEffect(() => {
    const getdropdown = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_WEBFORM_ENDPOINT}/parametertype/`
      );
      let paramtersData = response.data;
      let parameter_id_set = new Set();
      setParameters(
        paramtersData.map((obj) => {
          parameter_id_set.add(obj.parameter);
          return {
            ...obj,
            selected: obj.default_selected || false,
            high: null,
            low: null,
          };
        })
      );
      // setVisibleParameter(
      //   paramtersData
      //     .filter((obj) => obj.default_selected)
      //     .map((obj) => {
      //       return { ...obj, selected: true };
      //     })
      // );
      const parameter_id_list = Array.from(parameter_id_set);
      setParameterIdList(parameter_id_list);
      const uniqueParameterTypes = Array.from(
        new Set(paramtersData.map((obj) => obj.parameter_type))
      ).map((parameter_type) => ({
        value: parameter_type,
        label: parameter_type,
      }));
      setParameterTypes(uniqueParameterTypes);
      setParamaterDataLoaded(true);
    };
    getdropdown();
  }, []);

  // Fetching CP powerbi profiles
  useEffect(() => {
    const savedTreeData = JSON.parse(localStorage.getItem("windowTreeData"));
    const sectorCategoryNodes = savedTreeData?.[0]?.nodes;
    let newIndustryReportMapping = [];
    if (sectorCategoryNodes) {
      sectorCategoryNodes.forEach((category) => {
        category.nodes?.forEach((node) => {
          if (node.industry_id) {
            newIndustryReportMapping.push(node);
          }
        });
      });
      setIndReportMapping(newIndustryReportMapping);
    }

    // API CALL
    let newIndustryWiseCompanyReport = {};
    newIndustryReportMapping.forEach((indReportData) => {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/nodechildren/?key=${indReportData.key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.length >= 1) {
            const newRes = res.map((obj) => ({
              ...obj,
              sectorNode: indReportData,
            }));
            newIndustryWiseCompanyReport[indReportData.industry_id] = newRes;
          }
        });
    });
    setIndWiseCompReport(newIndustryWiseCompanyReport);
    setIndReportMappingLoaded(true);
  }, []);

  return (
    <div>
      <Header />
      <PageWrapper>
        <div style={{ backgroundColor: "#FFFFFF", minHeight: "10vh" }}>
          <Header1>
            <LHSTitlediv>Platform Listing</LHSTitlediv>
            <RHSTitlediv></RHSTitlediv>
          </Header1>
          <div
            style={{
              marginLeft: "3.5vw",
              marginBottom: "10px",
              color: "grey",
            }}
          >
            <>
              {treeziparr?.map((obj, i) => (
                <BreadCrumbSpan
                  onClick={(e) => {
                    handleClickTree(i, obj.key);
                  }}
                  key={i}
                >
                  {obj.name} /{" "}
                </BreadCrumbSpan>
              ))}
            </>
          </div>
        </div>
        <CompanySearch>
          <CompanyInputWrapper>
            <CompanyInput
              placeholder="Search Company Name"
              type="search"
              value={compSearchString}
              onChange={(e) => {
                setCompSearchString(e.target.value);
              }}
            ></CompanyInput>
            <SearchWrapper>
              <SearchIcon />
            </SearchWrapper>
          </CompanyInputWrapper>
        </CompanySearch>
        <TagsWrapper>
          {tags.map((tag) => (
            <TagDiv
              id={tag.id}
              key={tag.id}
              selected={tag.selected}
              onClick={() => {
                onTagClick(tag.id);
              }}
            >
              {tag.name}
            </TagDiv>
          ))}
        </TagsWrapper>
        <GridWraper>
          {isParameterValueLoaded ? (
            <>
              {/* <ListGridToggle
                onToggle={setListGridToggle}
                value={listGridToogle}
              /> */}
              <ShadowWrap>
                {listGridToogle === "left" ? (
                  <>
                    <CompanyListView
                      allParameters={parameters}
                      dataMonth={dataMonth}
                      allParameterTypes={parameterTypes}
                      visibaleCompDataList={visibaleCompDataList}
                      industryList={industryList}
                      setSelectedIndustry={setSelectedIndustry}
                      sortedParameter={sortedParameter}
                      setSortedParameter={setSortedColumn}
                      sortedDirection={sortedDirection}
                      setSortedDirection={setSortedDirection}
                    />
                  </>
                ) : (
                  <CompanyGridView
                    visibaleCompDataList={visibaleCompDataList}
                    industryList={industryList}
                    setSelectedIndustry={setSelectedIndustry}
                    pairedParams={pairedParams}
                    iconData={iconData}
                  />
                )}
              </ShadowWrap>
            </>
          ) : (
            <Loader />
          )}
        </GridWraper>
      </PageWrapper>
    </div>
  );
};

export default CompanyBoard;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7fcff;
  // height: calc(100vh - 48px);
`;

const CompanySearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 30px;
  align-items: center;
  background-color: #ffffff;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 60%;
  height: 60px;
  position: relative;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 22px;
  top: 18px;
`;

const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 20% 24px;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: center;
`;

const TagDiv = styled.div`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 10px 6px 10px;
  background-color: ${(props) => (props.selected ? "#0099FF" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#262e40")};
  margin: 6px 6px;
  border-radius: 24px;
  cursor: pointer;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 60px 50px;
`;

const ShadowWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
`;
const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const LHSTitlediv = styled.div`
  font-weight: 500;
  font-size: 30px;
`;

const RHSTitlediv = styled.div`
  display: flex;
  gap: 10px;
`;

const TableMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  min-height: 56px;
  background-color: #ffffff;
  align-items: center;
`;

const TableMenuLeft = styled.div`
  margin-left: 24px;
`;

const TableMenuRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const SelectWraper = styled.div`
  margin-left: 10px;
`;

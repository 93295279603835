import React from "react";
import styled from "styled-components";

const TriangleIcon = ({
  width,
  height,
  color = "#FFFFFF",
  rotate = 0,
  disabled = false,
  className,
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      rotate={rotate}
      disabled={disabled}
      className={className}
    >
      <svg width={`100%`} height={`100%`} viewBox="-1 -3 20 20" fill="none">
        <path
          d="M5 11.2338V2.76619C5 1.9889 5.84797 1.50878 6.5145 1.9087L13.5708 6.1425C14.2182 6.53091 14.2182 7.46909 13.5708 7.85749L6.5145 12.0913C5.84797 12.4912 5 12.0111 5 11.2338Z"
          stroke={`${color}`}
          strokeWidth="2"
          strokeLinecap="round"
          shapeRendering="crispEdges"
        />
      </svg>
    </IconWrapper>
  );
};

export default TriangleIcon;

const IconWrapper = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : 20)}px;
  width: ${(props) => (props.width ? props.width : 22)}px;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
  }
`;

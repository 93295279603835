import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { HOME_URL } from "../../../constants/constants";
import SearchIcon from "../../../components/svg/SearchIcon";
import MultiSelect from "../../../components/MultiSelect";
import StyledTable from "../../../components/StyledTable";
import Pagination from "../../../components/Pagination";
import axios from "axios";

const CompanyListView = ({
  allParameters = [],
  dataMonth,
  allParameterTypes = [],
  visibaleCompDataList = [],
  industryList = [],
  setSelectedIndustry = () => null,
  sortedParameter,
  setSortedParameter = () => null,
  sortedDirection,
  setSortedDirection = () => null,
}) => {
  // API whole data
  const [parameters, setParameters] = useState([]);
  const [parameterTypes, setParameterTypes] = useState([]);
  const [parameterRange, setParameterRange] = useState({});
  const [filteredCompDataList, setFilteredCompDataList] = useState([]);
  const [visibleParameter, setVisibleParameter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const dataPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    setParameters(allParameters);
  }, [allParameters]);

  useEffect(() => {
    const userCustomParam = window.localStorage.getItem(
      "comp_selected_parameter"
    );
    if (userCustomParam?.length) {
      const userCustomParamArray = userCustomParam.split(",");
      setVisibleParameter(
        parameters.filter((obj) =>
          userCustomParamArray.includes(String(obj.parameter))
        )
      );
    } else {
      setVisibleParameter(parameters.filter((obj) => obj.selected));
    }
  }, [parameters]);

  const onRangeFilterApply = (parameter_id, selectedRange) => {
    setParameterRange({ ...parameterRange, [parameter_id]: selectedRange });
  };

  useEffect(() => {
    setFilteredCompDataList(
      visibaleCompDataList.filter((comp) => {
        let allParameterInRange = true;
        for (const key in parameterRange) {
          if (parameterRange[key] && parameterRange[key].length) {
            if (!comp.paramData) {
              /* have filter but no data */
              return false;
            } else if (!comp.paramData[key]) {
              /* have filter but no data */
              return false;
            } else {
              /* check if this parameter in range */
              if (
                !parameterRange[key].some(
                  (range) =>
                    comp.paramData[key].current_value >= range.low &&
                    comp.paramData[key].current_value <= range.high
                )
              ) {
                /* not found in any range */
                allParameterInRange = false;
              }
            }
          }
        }
        return allParameterInRange;
      })
    );
  }, [visibaleCompDataList, parameterRange]);

  useEffect(() => {
    setParameterTypes(allParameterTypes);
  }, [allParameterTypes]);

  useEffect(() => {
    setCurrentPage(1);
    setTotalPage(Math.ceil(filteredCompDataList.length / dataPerPage));
  }, [filteredCompDataList]);

  const onSelectParameter = (selectedParam) => {
    const commaSeperatedList = selectedParam
      .map((obj) => obj.parameter)
      .join(",");
    window.localStorage.setItem("comp_selected_parameter", commaSeperatedList);
    setVisibleParameter(selectedParam);
  };

  const startIndex = (currentPage - 1) * dataPerPage;
  const currentCompData = filteredCompDataList.slice(
    startIndex,
    startIndex + dataPerPage
  );

  const onCompanyClick = (reportData) => {
    const sector_node = reportData.sectorNode;
    localStorage.setItem("company_profile_data", JSON.stringify(reportData));
    navigate(
      `/Report3/?val=${sector_node.label}&key=${sector_node.key}&filter=${sector_node.filter}&filter_value=${sector_node.filter_value}`
    );
  };

  return (
    <Wrapper>
      <ShadowWrap>
        <ShadowBox>
          <TableMenu>
            {dataMonth && (
              <TableMenuLeft>For the month of {dataMonth}</TableMenuLeft>
            )}
            <TableMenuRight>
              <Label>Select Sectors:</Label>
              <MultiSelect
                options={industryList}
                onSelectedChange={setSelectedIndustry}
                keyFieldName="industry_id"
                valueFieldName="industry_name"
                selectedOptions={industryList.filter((obj) => obj.selected)}
                mutileSelect={true}
                placeholder="Select Sectors..."
              />
              <Label>Select Parameters:</Label>
              <MultiSelect
                options={parameters}
                keyFieldName="id"
                valueFieldName="parameter_display_name"
                optionTypes={parameterTypes}
                optionMapKey="parameter_type"
                optionTypeMapKey="value"
                onSelectedChange={onSelectParameter}
                selectedOptions={visibleParameter}
                mutileSelect={true}
                placeholder="Select Parameters..."
              />
            </TableMenuRight>
          </TableMenu>
          <StyledTable
            visiblePlayer={currentCompData}
            visibleParameter={visibleParameter}
            sortedParameter={sortedParameter}
            sortedDirection={sortedDirection}
            setSortedColumn={setSortedParameter}
            setSortedDirection={setSortedDirection}
            parameterRange={parameterRange}
            onRangeFilterApply={onRangeFilterApply}
            onFirstColumnDataClick={onCompanyClick}
          />
        </ShadowBox>
      </ShadowWrap>
      <PaginationWrapper>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default CompanyListView;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ShadowWrap = styled.div`
  min-height: 400px;
  max-width: 100%;
`;

const ShadowBox = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px #00000014;
`;

const CompanySearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 30px;
  align-items: center;
  background-color: #ffffff;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 60%;
  height: 60px;
  position: relative;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 22px;
  top: 18px;
`;

const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 20% 24px;
  flex-wrap: wrap;
  background-color: #ffffff;
  justify-content: center;
`;

const TagDiv = styled.div`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 10px 6px 10px;
  background-color: ${(props) => (props.selected ? "#0099FF" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#262e40")};
  margin: 6px 6px;
  border-radius: 24px;
  cursor: pointer;
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 60px 50px;
`;

const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const LHSTitlediv = styled.div`
  font-weight: 500;
  font-size: 30px;
`;

const RHSTitlediv = styled.div`
  display: flex;
  gap: 10px;
`;

const TableMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  min-height: 56px;
  background-color: #ffffff;
  align-items: center;
`;

const TableMenuLeft = styled.div`
  margin-left: 15px;
`;

const TableMenuRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const Label = styled.div`
  margin: 0 5px 0px 10px;
`;

const SelectWraper = styled.div`
  margin-left: 10px;
`;

const PaginationWrapper = styled.div`
  padding: 20px 0 10px;
`;

import React from "react";
import styled from "styled-components";
import { media } from "../../utils/media";

const TabOptions = ({
  tabs = [],
  selectedTab = "",
  onTabChange = () => null,
  className,
}) => {
  return (
    <Wrapper className={className}>
      {tabs.map((tab, idx) => (
        <Tab
          key={idx}
          selected={selectedTab === tab}
          onClick={() => (selectedTab !== tab ? onTabChange(tab) : null)}
        >
          <HoverArea>{tab}</HoverArea>
        </Tab>
      ))}
    </Wrapper>
  );
};

export default TabOptions;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
`;

const Tab = styled.div`
  height: 35px;
  padding: 3px;
  border: 0.54px solid #000000;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  background-color: ${(props) => (props.selected ? "#ADDDF4" : "#FFFFFF")};
  :hover {
    background-color: "#ADDDF4";
  }

  ${media.medium`
    height: 30px;
  `}

  ${media.small`
    height: 20px;
  `}
`;

const HoverArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  padding: 0 10px;
  font-size: 15px;
  font-weight: 400;

  :hover {
    cursor: pointer;
  }

  ${media.medium`
    font-size: 14px;
    padding: 0 8px;
  `}

  ${media.small`
    font-size: 12px;
    padding: 0 5px;
  `}
`;

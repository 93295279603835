import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  USER_VALIDATE_ENDPOINT,
  VALIDATE_INVITE_EMAIL_ENDPOINT,
} from "../../../constants/constants";
import Loader from "../../../components/Loader";
import { media } from "../../../utils/media";

const InvitationCard = ({
  invitationNumber,
  index,
  name,
  email,
  errorName,
  errorEmail,
  enabled,
  sentInvitationsList,
  onValueChange,
  onInviteClick,
}) => {
  const [nameError, setNameError] = useState("");
  const [considerNameError, setConsiderNameError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [considerEmailError, setConsiderEmailError] = useState(false);

  const [isValidForm, setValidForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCancelToken, setCurrentCancelToken] = useState(null);
  const [check1, setCheck1] = useState(null);
  const [check2, setCheck2] = useState(null);

  const admin =
    window.localStorage.getItem("is_admin") === "true" ? true : false;
  const emailRegex = admin
    ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    : /^(?!.*@(?:gmail\.|yahoo\.)|.*\.\@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onNameChange = (e) => {
    onValueChange(index, "name", e.target.value);
    setConsiderNameError(true);
  };
  const onEmailChange = (e) => {
    onValueChange(index, "email", e.target.value);
    setConsiderEmailError(true);
  };

  const onEmailChecksLoaded = () => {
    if (check1.valid && check2.valid) {
      setValidForm(true);
      setEmailError("");
    } else if (!check1.valid) {
      setValidForm(false);
      setEmailError(check1.error);
    } else if (!check2.valid) {
      setValidForm(false);
      setEmailError(check2.error);
    } else {
      setValidForm(false);
      setEmailError(`${check1.error}<br/>${check2.error}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (check1 && check2) {
      onEmailChecksLoaded();
    }
  }, [check1, check2]);

  const checkEmail = (queryEmail, source) => {
    // here name and email already varified, just need to check is alerady signd up or not
    axios
      .get(`${USER_VALIDATE_ENDPOINT}/?email=${queryEmail}`, {
        cancelToken: source.token,
      })
      .then((response) => response.data)
      .then((data) => {
        // last api call for valid email
        const currentEmail = email;
        if (queryEmail === currentEmail) {
          // in case of updated email is wrong after api call
          if (data["status"] === 404) {
            // in case of new user
            setCheck1({ valid: true, error: "" });
          } else {
            setCheck1({
              valid: false,
              error: "This email address already signed up !",
            });
          }
        } else {
          setCheck1({ valid: false, error: "" });
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          console.error("Error in fetching industries:", error);
          setCheck1({ valid: false, error: "" });
        }
      });

    axios
      .get(`${VALIDATE_INVITE_EMAIL_ENDPOINT}/?email=${queryEmail}`, {
        cancelToken: source.token,
      })
      .then((response) => response.data)
      .then((data) => {
        // last api call for valid email
        const currentEmail = email;
        if (queryEmail === currentEmail) {
          // in case of updated email is wrong after api call
          if (data["status"] === 200) {
            if (data["data"]["invitationSent"]) {
              setCheck2({
                valid: false,
                error: "This email address already invited by a user!",
              });
            } else {
              setCheck2({ valid: true, error: "" });
            }
          } else {
            setCheck2({
              valid: false,
              error: "Can't invite this email, please contact admin !",
            });
          }
        } else {
          setCheck2({ valid: false, error: "" });
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          console.error("Error in fetching industries:", error);
          setCheck2({ valid: false, error: "" });
        }
      });
  };

  useEffect(() => {
    setValidForm(false);
    const isValidEmail = emailRegex.test(email);
    const isValidName = name.length >= 1;
    setNameError(!isValidName && considerNameError ? "Not a valid name" : "");

    const newSource = axios.CancelToken.source();
    if (currentCancelToken) {
      // If a email is changed, cancel any previous api call
      currentCancelToken.cancel("API request canceled due to new input");
      setIsLoading(false);
    }

    if (isValidEmail) {
      const isDublicateEmail =
        sentInvitationsList.filter(
          (invitation) => invitation.email.toLowerCase() === email.toLowerCase()
        ).length > 0;
      if (isDublicateEmail) {
        setEmailError("This email address already invited by you !");
      } else {
        setCurrentCancelToken(newSource); // Store the new cancel token.
        setIsLoading(true);
        checkEmail(email, newSource);
        setEmailError("Verifying ...");
      }
    } else {
      considerEmailError &&
        setEmailError("Enter a valid corporate email address!");
    }
  }, [name, email]);

  return (
    <InvitationCardWrap>
      <Row>
        <CardTitle>Invitation {invitationNumber}</CardTitle>
      </Row>
      <Row>
        <ColWeb>
          <InputBox
            placeholder="Full Name"
            value={name}
            onChange={onNameChange}
          />
          <ErrorBox>{nameError}</ErrorBox>
        </ColWeb>
        <ColMob>
          <InputBox
            placeholder="Full Name"
            value={name}
            onChange={onNameChange}
          />
          <ErrorBox>{nameError}</ErrorBox>
        </ColMob>
        <ColWeb>
          <InputBox
            placeholder="Enter Email Address"
            value={email}
            onChange={onEmailChange}
          />
          <ErrorBox>{emailError}</ErrorBox>
        </ColWeb>
        <ColMob>
          <InputBox
            placeholder="Enter Email"
            value={email}
            onChange={onEmailChange}
          />
          <ErrorBox>{emailError}</ErrorBox>
        </ColMob>
        <SendButtonBox onClick={onInviteClick} disabled={!isValidForm}>
          Send Invite
        </SendButtonBox>
      </Row>
    </InvitationCardWrap>
  );
};

export default InvitationCard;

const InvitationCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 145px;
  padding: 25px;
  background-color: #ffffff;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const ColWeb = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  ${media.small`
    display:none;
    `}
`;
const ColMob = styled.div`
    display:none;

${media.small`
  
    display: flex;
  flex-direction: column;
  width: 30%;
  `}

`;

const CardTitle = styled.div`
  color: var(--secondary-dark-color, #54595e);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  ${media.small`
      font-size: 8px;

    `}
`;

const InputBox = styled.input`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 14px 32px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  background: var(--white, #fff);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }

  ${media.small`
      height: 30px;
  padding: 8px 16px;
      font-size: 10px;
  ::placeholder {
    color: #bfbfbf;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
     :focus {
    border: 0.5px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }

    `}
`;

const ErrorBox = styled.div`
  width: 100%;
  height: 18px;
  color: #e93735;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${media.small`
      height: 9px;
  font-size: 10px;
    `}
`;

const SendButtonBox = styled.button`
  display: flex;
  width: 300px;
  height: 60px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  white-space:nowrap;
  flex-shrink: 0;
  border-radius: 50px;
  background: #09f;
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin-bottom: 18px;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.6;
    background: #c4d3dd;
    cursor: not-allowed;
  }
    ${media.small`
        width: 80px;
  height: 20px;
  padding: 3x 5px;
  gap: 3px;
  border-radius: 25px;
  font-size: 10px;
  margin-bottom: 9px;
      
      
      
      `}
`;

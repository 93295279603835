import React from 'react'
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { RxTriangleRight } from "react-icons/rx";
import axios from 'axios';



const Swot = () => {

    const [swotdata, setswotdata] = useState([]);

    const swotData = {
        Strengths: [
          'Strong Brand Presence: Nykaa has established itself as a leading beauty and cosmetics brand in India with a strong online and offline presence.',
          'Wide Product Range: The company offers a vast range of beauty and cosmetic products, including both international and domestic brands, catering to various customer preferences.',
          // ... other strengths
        ],
        Weakness: [
          'Competition: The beauty and cosmetics industry is highly competitive, with numerous players, including both domestic and international brands.',
          // ... other weaknesses
        ],
        Opportunity: [
            'Competition: The beauty and cosmetics industry is highly competitive, with numerous players, including both domestic and international brands.',
        ],
        Threat: [
            'Competition: The beauty and cosmetics industry is highly competitive, with numerous players, including both domestic and international brands.',
        ],
      };
    
    const titlearray = ['Helpful', 'Harmful', 'Internal', 'External']

    useEffect(()=>{
        let player_id = window.localStorage.getItem('player_id')
        if(!player_id){
            player_id = 1
        }
        let getswotdata = async(player_id)=>{
            let res = await axios.get(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/swot/?player_id=${player_id}`)
            setswotdata(res.data)
            console.log('swotdata = ', res.data[0])
        }
        getswotdata(player_id)
    },[])

  return (
    <div style={{'background':'#eef9ff', 'paddingLeft':'3.5vw', 'paddingRight':'3.5vw'}}>
    {swotdata.length>0 && <SWOTContainer>

        <Helpful>Helpful</Helpful>
        <Harmful>Harmful</Harmful>
        <Internal>Internal</Internal>
        <External>External</External>
        <Strength>
        <SectionTitle>Strength</SectionTitle>
            {swotdata[0]['strength'].map((item, index) => (
              <ListItem key={index}><CheckIcon/>{item}</ListItem>
            ))}

        </Strength>
        <Weakness>
        <SectionTitle>Weakness</SectionTitle>
            {swotdata[0]['weakness'].map((item, index) => (
              <ListItem key={index}><CheckIcon/>{item}</ListItem>
            ))}
        </Weakness>
        <Opportunity>
            <SectionTitle>Opportunity</SectionTitle>
            {swotdata[0]['opportunities'].map((item, index) => (
              <ListItem key={index}><CheckIcon/>{item}</ListItem>
            ))}
        </Opportunity>
        <Threat>
            <SectionTitle>Threat</SectionTitle>
            {swotdata[0]['threats'].map((item, index) => (
              <ListItem key={index}><CheckIcon/>{item}</ListItem>
            ))}
        </Threat>
    
    </SWOTContainer>}

    </div>
  )
}

export default Swot

const SWOTContainer = styled.div`
background-color:#eef9ff;
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 0.1fr 1.45fr 1.45fr; 
  grid-template-rows: 0.2fr 1.4fr 1.4fr; 
  gap: 10px 10px; 
  grid-template-areas: 
    ". Helpful Harmful"
    "Internal Strength Weakness"
    "External Opportunity Threat"; 
`;

const Helpful = styled.div`
    grid-area: Helpful;
    text-align:center;
    font-weight:600;
    font-size:20px;
`
const Harmful = styled.div`
    grid-area:Harmful;
    text-align:center;
    font-weight:600;
    font-size:20px;
`

const Internal = styled.div`
    grid-area:Internal;
    /* transform: rotate(-90deg); */
    writing-mode: vertical-lr;
text-orientation: mixed;
transform: rotate(-180deg);
text-align:center;
    font-weight:600;
    font-size:20px;
`
const External = styled.div`
grid-area:External;
/* transform: rotate(-90deg); */
writing-mode: vertical-lr;
text-orientation: mixed;
transform: rotate(-180deg);
text-align:center;
    font-weight:600;
    font-size:20px;
`

const Strength  =styled.div`
grid-area:Strength;
background-color:white;
flex: 1 1 40%; 
  padding: 20px;
  box-shadow: 2px 2px 4px 0px #00000040;
`
const Weakness = styled.div`
grid-area:Weakness;
background-color:white;
flex: 1 1 40%; 
  padding: 20px;
  box-shadow: 2px 2px 4px 0px #00000040;
`
const Opportunity = styled.div`
    grid-area:Opportunity;
    background-color:white;
    flex: 1 1 40%; 
  padding: 20px;
  box-shadow: 2px 2px 4px 0px #00000040;
`
const Threat = styled.div`
    grid-area:Threat;
    background-color:white;
    flex: 1 1 40%; 
  padding: 20px;
  box-shadow: 2px 2px 4px 0px #00000040;
`


const SwotSection1 = styled.div`
    
`

const SWOTSection = styled.div`
  flex: 1 1 40%; /* Adjust the flex-basis value as per your layout needs */
  margin: 2px;
  padding: 20px;
  box-shadow: 2px 2px 4px 0px #00000040;
`;

const SectionTitle = styled.div`
  color: #e9e8eb;
  text-align:center;
  font-weight:300;
    font-size:20px;
`;

const ListItem = styled.li`
   list-style: none; /* Removes the default list-style */
  padding-left: 1.5em; /* Provide enough space for the custom bullet */
  margin-bottom: 10px;
  position: relative; /* Needed for absolute positioning of the bullet */
`;

const CheckIcon = styled(RxTriangleRight)`
  color: green; /* Set the color of the icon */
  position: absolute;
  left: 0; /* Align with the start of the list item */
  font-size: 24px; /* Set the size of the icon */
`;
import React from "react";
import styled from "styled-components";

const ProfilePlaceholder = ({ width = 23, height = 23, color = "#C2C2C2" }) => {
  return (
    <IconWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 23 23"
        fill="none"
      >
        <circle cx="11.5" cy="11.5" r="11.5" fill={`${color}`} />
        <path
          d="M15.8884 15.9992V14.8881C15.8884 14.2988 15.6543 13.7336 15.2376 13.3169C14.8209 12.9001 14.2557 12.666 13.6663 12.666H9.22211C8.63277 12.666 8.06757 12.9001 7.65084 13.3169C7.23411 13.7336 7 14.2988 7 14.8881V15.9992"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4443 10.4442C12.6715 10.4442 13.6664 9.44934 13.6664 8.22211C13.6664 6.99487 12.6715 6 11.4443 6C10.217 6 9.22217 6.99487 9.22217 8.22211C9.22217 9.44934 10.217 10.4442 11.4443 10.4442Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapper>
  );
};

export default ProfilePlaceholder;

const IconWrapper = styled.div`
  display: flex;
`;

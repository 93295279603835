import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Loader from "../Loader";
import axios from "axios";
import { REPORT_DATASETS_ENDPOINT } from "../../constants/constants";
import MultiSelect from "../MultiSelect";
import TimeTrendGraph from "./TimeTrendGraph";
import { media } from "../../utils/media";

// use it
// <RelatedDataSets width={"100%"} height={"70vh"}/>

const RelatedDataSets = ({
  width,
  height,
  report_id = null,
  title = "Related Data Sets",
}) => {
  const [isLoading, setLoading] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const [selectedDataSet, setSelectedDataSet] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const graphRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const onDataSetChange = (filteredDataSets) => {
    setSelectedDataSet(filteredDataSets[0]);
    const options = [];
    let idCounter = 1;
    const dataList = filteredDataSets[0].data;
    if (dataList.length > 0) {
      for (const key in dataList[0]) {
        if (key !== "start_date" && key !== "start_date_label") {
          const newObj = {
            id: idCounter,
            name: key,
          };
          idCounter++;
          options.push(newObj);
        }
      }
    }
    setCategories(options);
    setSelectedCategory(options[0]);
  };

  const onFilterOptionChange = (filteredData) => {
    setSelectedCategory(filteredData[0]);
  };

  useEffect(() => {
    if (graphRef.current) {
      const width = graphRef.current.offsetWidth;
      const height = graphRef.current.offsetHeight;
      setDimensions({ width, height });
    }
  }, []);

  useEffect(() => {
    const getDataSets = async () => {
      axios
        .get(`${REPORT_DATASETS_ENDPOINT}/${report_id}/`)
        .then((response) => response.data.datasets)
        .then((dataSets) => {
          setDataSets(
            dataSets.map((obj, idx) => {
              console.log("idx", idx, typeof idx);
              return {
                ...obj,
                selected: false,
              };
            })
          );
          if (dataSets.length) {
            setSelectedDataSet(dataSets[0]);
            const options = [];
            let idCounter = 1;
            const dataList = dataSets[0].data;
            if (dataList.length > 0) {
              for (const key in dataList[0]) {
                if (key !== "start_date" && key !== "start_date_label") {
                  const newObj = {
                    id: idCounter,
                    name: key,
                  };
                  idCounter++;
                  options.push(newObj);
                }
              }
            }
            setCategories(options);
            setSelectedCategory(options[0]);
            // setSelectedDataSet(dataSets[0]);
          } else {
            setError(
              `Oops! It seems there are no datasets available to display.`
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error in fetching data sets -", error);
          setError(
            `Oops! Something went wrong. Please report this issue to your POC. Error Code: ${error.code}`
          );
          setLoading(false);
        });
    };
    if (report_id) {
      setLoading(true);
      getDataSets();
      setError("");
    } else {
      setError(
        "Oops! Something went wrong. please report this issue to your POC. Error Code: DATA_MISSING"
      );
    }
  }, [report_id]);

  function getFrequencyDisplayName(frequency) {
    switch (frequency) {
      case "monthly":
        return "Monthly";
      case "quarterly":
        return "Quarterly";
      case "cy_yearly":
        return "Yearly";
      case "fy_yearly":
        return "Fiscal Yearly";
      case "last_12_month":
        return "Last 12 Months";
      case "overall":
        return "Overall";
      default:
        return frequency;
    }
  }

  return (
    <Wrapper width={width} height={height}>
      <Title>{title}</Title>
      <GraphShadow width={width} height={height}>
        <GraphDimension width={width} height={height}>
          {isLoading ? (
            <>
              <LoadingMessage>Loading...</LoadingMessage>
              <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
            </>
          ) : error.length ? (
            <NoDataMessage>{error}</NoDataMessage>
          ) : (
            <>
              <TableMenu>
                <TableMenuLeft>
                  {selectedDataSet.display_name}
                  {selectedDataSet.unit ? ` (${selectedDataSet.unit}), ` : ", "}
                  {getFrequencyDisplayName(selectedDataSet.frequency)}
                </TableMenuLeft>
                <TableMenuRight>
                  <TableRightFilter>
                  <Label>Select Dataset:</Label>
                  <MultiSelect
                    options={dataSets}
                    keyFieldName="id"
                    valueFieldName="name"
                    onSelectedChange={onDataSetChange}
                    selectedOptions={[selectedDataSet]}
                    mutileSelect={false}
                    placeholder="Select Dataset.."
                    showCheckBox={false}
                  />
                  </TableRightFilter>
                  <TableRightFilter>
                  <Label>Filter By:</Label>
                  <MultiSelect
                    options={categories}
                    keyFieldName="id"
                    valueFieldName="name"
                    onSelectedChange={onFilterOptionChange}
                    selectedOptions={[selectedCategory]}
                    mutileSelect={false}
                    placeholder="Select Dataset.."
                    showCheckBox={false}
                  />
                  </TableRightFilter>
                </TableMenuRight>
              </TableMenu>
              <GraphWrapper ref={graphRef}>
                <TimeTrendGraph
                  data={selectedDataSet.data?.filter(
                    (obj) => obj[selectedCategory?.name]
                  )}
                  dataKey={selectedCategory?.name}
                  width={dimensions.width}
                  height={dimensions.height}
                />
              </GraphWrapper>
              <BottomMenu>{selectedDataSet.description}</BottomMenu>
            </>
          )}
        </GraphDimension>
      </GraphShadow>
    </Wrapper>
  );
};

export default RelatedDataSets;

const Wrapper = styled.div`
  // width: ${(props) => props.width};
  // height: ${(props) => props.height};
  // background-color: #f7fcff;
  box-sizing: border-box;
  padding: 3vw 10px 10px;
`;

const LoadingMessage = styled.div`
  min-height: 360px;
`;

const Title = styled.div`
  color: #262e40;
  font-size: 25px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  ${media.small`
     font-size: 18px;
  height: 20px;
  margin-bottom: 10px;
    
    `}
`;

const GraphShadow = styled.div`
  // width: calc(${(props) => props.width} - 20px);
  // height: calc(${(props) => props.height} - 60px);
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px #00000040;
`;

const NoDataMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 20px 10px;
`;

const GraphDimension = styled.div`
  width: calc(${(props) => props.width} - 0px);
  height: calc(${(props) => props.height} - 0px);
  padding: 15px 15px 30px 15px;
  position: relative;
`;


const GraphWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  height: 50vh;
`;


const TableMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  min-height: 56px;
  padding: 0px 0px 20px;
  background-color: #ffffff;
  align-items: center;
  ${media.small`
      min-height: 20px;
      flex-direction:column;
        align-items: right;

    `}
`;

const BottomMenu = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 16px;
  width: 100%;
  padding-top: 10px;
  background-color: #ffffff;
  align-items: center;
  ${media.small`
    
      font-size: 10px;
    `}
`;

const TableMenuLeft = styled.div`
  font-size: 20px;
  font-weight: 500;

   ${media.small`
       font-size: 14px;
    `}
`;

const TableMenuRight = styled.div`
  display: flex;
  align-items: center;
  ${media.small`
  flex-direction:column;
    align-items: right;

  `}

`;
const TableRightFilter=styled.div`
display:flex;
  ${media.small`
padding-bottom:1%;
  `}
`

const Label = styled.div`
  margin: 0 5px 0px 10px;
   ${media.small`
    font-size:12px;
  margin: 0 2.5px 0px 5px;
    `}
`;

import React from 'react'
import { ScatterChart, Scatter, XAxis, YAxis, ReferenceLine,Text } from 'recharts';
import styled from 'styled-components';
import { useState } from 'react';

const SChart = (props) => {

    const [renderedDots, setRenderedDots] = useState([]);

    const doesOverlap = (newDot, existingDots) => {
        // Simple overlap detection logic
        for (let dot of existingDots) {
          if (Math.abs(dot.cx - newDot.cx) < dot.size / 2 + newDot.size / 2 &&
              Math.abs(dot.cy - newDot.cy) < dot.size / 2 + newDot.size / 2) {
            return true; // Overlap detected
          }
        }
        return false;
      };
  
      const doesTextOverlap = (newDot, existingDots, textSize = 20) => {
        // console.log('checking text overlap', existingDots)
        const estimatedWidth = textSize * newDot.name.length * 0.6; // Estimate text width
        const estimatedHeight = textSize; // Estimate text height based on font size
      
        for (let dot of existingDots) {
          const dotWidth = textSize * dot.name.length * 0.6;
          const dotHeight = textSize;
          console.log(dot.name, newDot.name,  dotWidth, estimatedWidth)
          if (Math.abs(dot.cx - newDot.cx) < (dotWidth + estimatedWidth) / 2 &&
              Math.abs(dot.cy - newDot.cy) < (dotHeight + estimatedHeight) / 2) {
            return true; // Text overlap detected
          }
        }
        return false;
      };
      
      const adjustPosition = (dot, existingDots) => {
        // Adjust the position of the dot to avoid overlap
        // This is a placeholder for the actual logic you would need to implement based on your requirements
        let newX = dot.cx;
        while (doesTextOverlap({ ...dot, cx: newX }, existingDots)) {
          newX += 10; // Move the dot down by 10 pixels to avoid overlap; adjust this logic as needed
        }
        return newX;
      };
      
      const renderCustomShape = (propss) => {
          console.log('render')
          const { cx, cy, payload } = propss;
          const imgSrc = payload.image
          let playerColor = payload.color
          let name = payload.name
          const textSize = 20;
          const newDot = { cx, cy, name};
          // console.log(newDot, renderedDots, textSize, renderednameDots)
          if (doesTextOverlap(newDot, renderedDots, textSize)) {
            console.log('newDotx = ', newDot.cx)
            newDot.cx = adjustPosition(newDot, renderedDots);
            console.log('adjusting....', newDot.cx)
          }
        
  
          // const adjustedPosition = adjustPosition({ ...payload, cx, cy }, renderedDots, textSize);
          // const newDot = { ...payload, cx: adjustedPosition.x, cy: adjustedPosition.y };
      
          
          // // Add the new, adjusted dot to the tracker
        //   setRenderednameDots([...renderednameDots, newDot]);
      
      
      
          // return <image x={cx - 10} y={cy - 10} width="30px" height="50px" xlinkHref={imgSrc} />;
          // return <FaCircle x={cx - 10} y={cy - 10} width="10px" height="10px" color= {playerColor} />;
          return (
            <Text
              x={cx}
              y={cy}
              fontSize={'20px'} // Use calculated size for font size
              textAnchor="middle"
              verticalAnchor="middle"
            >
              {payload.name} 
            </Text>)
      }

  return (
    <div>
        <ScatterChartWrapperdiv>
                <ScatterChart width={1200} height={600}   margin={{ top: 20, right: 50, bottom: 30, left: 20 }}>
                    <ReferenceLine x={(-5+props.xmax+0.2*(props.xmax))/2} stroke="black" strokeDasharray="3 3" />
                    <ReferenceLine y={(-5+props.ymax+0.2*(props.ymax))/2} stroke="black" strokeDasharray="3 3" />
                    <XAxis dataKey="x" type="number" domain={[-5, Math.round((props.xmax+0.2*(props.xmax))*100)/100]} allowDataOverflow={true}  label={{ value: props.paramx, position: 'insideBottom',offset:-10 }} />
                    <YAxis dataKey="y" type="number" domain={[-5, Math.round((props.ymax+0.2*(props.ymax))*100)/100]} allowDataOverflow={true} label={{ value: props.paramy, angle: -90, position: 'insideLeft' }}/>
                    <Scatter data={props.graphdata}  shape={renderCustomShape}/>
                </ScatterChart>
        </ScatterChartWrapperdiv>
    </div>
  )
}

export default SChart

const ScatterChartWrapperdiv = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:white;
`
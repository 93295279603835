import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getrevpool } from "../../centralizedAPICalls/service";
import axios from "axios";

// use it
// <ArialStacked width={"100%"} height={"73vh"}/>
const data = [
  {
    id: 1,
    name: "FY19",
    logistics: 25,
    platform: 45,
    brand: 30,
  },
  {
    id: 2,
    name: "FY20",
    logistics: 35,
    platform: 35,
    brand: 30,
  },
  {
    id: 3,
    name: "FY21",
    logistics: 20,
    platform: 42,
    brand: 38,
  },
  {
    id: 4,
    name: "FY22",
    logistics: 25,
    platform: 48,
    brand: 27,
  },
  {
    id: 5,
    name: "FY 23",
    logistics: 25,
    platform: 37,
    brand: 38,
  },
  {
    id: 6,
    name: "FY 24",
    logistics: 15,
    platform: 70,
    brand: 15,
  },
];

const ArialStacked = ({ width, height }) => {

  const [revdata, setrevdata] = useState(null)
  const [graphkeys,setgraphkeys] = useState([])

  useEffect(()=>{
    const getrevenuedata = (async()=>{
      let industry_id = window.localStorage.getItem('industry_id')
      if(!industry_id){
        industry_id = 10
      }
      const result = await axios.get(`${process.env.REACT_APP_WEBFORM_ENDPOINT}/revpool/?industry_id=${industry_id}`)
      console.log('res = ', result)
      setrevdata(result.data)
      setgraphkeys(Object.keys(result.data[0]).slice(2))
    })
    
    getrevenuedata()
  },[])
  
  return (
    <Wrapper width={width} height={height}>
      <Title>Wallet Share</Title>
      {graphkeys.length>0 && <GraphShadow width={width} height={height}>
        <OptionBar>
          {graphkeys[0]?<RadioIcon color="#E93735" />:null}
          <OptionLabel>{graphkeys[0]}</OptionLabel>
          {graphkeys[1]?<RadioIcon color="#0099FF" />:null}
          <OptionLabel>{graphkeys[1]}</OptionLabel>
          {graphkeys[2]?<RadioIcon color="#9FC51D" />:null}
          <OptionLabel>{graphkeys[2]}</OptionLabel>
        </OptionBar>
        <GraphDimension width={width} height={height}>
          {revdata && <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={revdata}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip displayName={graphkeys[0]} />
              <Area
                type=""
                dataKey={graphkeys[0]}
                stackId="1"
                stroke="#E93735"
                fill="#E93735"
              />
              <Area
                type=""
                dataKey={graphkeys[1]}
                stackId="1"
                stroke="#0099FF"
                fill="#0099FF"
              />
              <Area
                type=""
                dataKey={graphkeys[2]}
                stackId="1"
                stroke="#9FC51D"
                fill="#9FC51D"
              />
            </AreaChart>
          </ResponsiveContainer>}
          <div style={{backgroundColor:'White', textAlign:'center', height:'20px'}}>% breakup of GMV into revenue for different types of providers.</div>
        </GraphDimension>

      </GraphShadow>}
      
    </Wrapper>
  );
};

export default ArialStacked;

const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: 73vh;
  background-color: #EEF9FF;
  box-sizing: border-box;
  padding: 3.5vw;
  padding-top:0vw;
  padding-bottom:0vw;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  height: 55px;
  letter-spacing: 0em;
  text-align: left;
  background-color:#EEF9FF;
  padding-bottom: 15px;
`;

const GraphShadow = styled.div`
  width: calc(${(props) => props.width});
  height: calc(73vh - 50px);
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px #00000040;
`;

const OptionBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 15px 60px 20px 55px;
`;

const GraphDimension = styled.div`
  width: calc(${(props) => props.width} - 60px);
  height: calc(${(props) => props.height} - 135px);
  padding-left: 0px;
`;

const RadioIcon = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
  border: 0.2px solid #262e40;
  margin-right: 8px;
  border-radius: 8px;
`;

const OptionLabel = styled.div`
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: #000000;
  background-color: #ffffff;
  margin-right: 30px;
`;

import React from "react";
import styled, { useTheme } from "styled-components";
import { media } from "../../../../utils/media.js";
import moment from "moment";
import Thumb from "../../../svg/Thumb.js";

const KpiCard = ({
  report = "",
  name = "",
  unitText1 = "",
  unitText2 = "",
  latestMonth = moment(moment().startOf("month").subtract(2, "month")).format(
    "YYYY-MM-DD"
  ),
  monthValue,
  increment,
  yearValue,
  date = moment(moment().startOf("month").subtract(2, "month")).format(
    "YYYY-MM-DD"
  ),
  formatCode = "hi",
  insightQuarter=null,
}) => {
  const decimalCount = monthValue ? (Number(monthValue) > 1000 ? 0 : 2) : 0;
  return (
    <Wrapper>
      <IconWrapper>
        <IconThumb isUpward={!(increment && increment < 0)} />
      </IconWrapper>
      <BodyWrapper>
       { 
        insightQuarter===null?
        <Title>In {moment(latestMonth, "YYYY-MM-DD").format("MMM YYYY")}</Title>
       :<Title>In {latestMonth}</Title>
       }
        <div>
          <SubTitle>
            The entire {report}{" "}
            {increment
              ? Number(increment) >= 0
                ? "grew by"
                : "declined by"
              : "grew by"}{" "}
            {increment
              ? `${increment > 0 ? "" : ""}${Number(increment).toLocaleString(
                  formatCode,
                  {
                    maximumFractionDigits: 2,
                  }
                )}%`
              : ""}{" "}
            year on year, reaching {name} of {unitText1}{" "}
            {monthValue
              ? Number(monthValue).toLocaleString(formatCode, {
                  maximumFractionDigits: decimalCount,
                })
              : "-"}{" "}
            {unitText2}
          </SubTitle>
          <SubTitle>
            The {report} crossed the value of {unitText1}{" "}
            {yearValue
              ? Number(yearValue).toLocaleString(formatCode, {
                  maximumFractionDigits: decimalCount,
                })
              : "-"}{" "}
            {unitText2} YTD
          </SubTitle>
        </div>
      </BodyWrapper>
    </Wrapper>
  );
};

export default KpiCard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  ${media.medium`
    padding: 5px 10px;
  `}
  ${media.small`
    padding: 5px;
  `}
`;

const IconThumb = styled(Thumb)`
  width: 80px;
  height: 80px;
  ${media.medium`
    width: 50px;
    height: 50px;
  `}
  ${media.small`
    width: 40px;
    height: 40px;
  `}
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.medium`
    font-size: 22px;
  `}
  ${media.small`
    font-size: 18px;
  `}
`;

const SubTitle = styled.div`
  font-size: 18px;
  ${media.medium`
    font-size: 16px;
  `}
  ${media.small`
    font-size: 13px;
  `}
`;

import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";
import Article from "./Article";
import moment from "moment";
import { INDUSTRIES_URL_ENDPOINT } from "../../constants/constants";
import NavigationIcon from "../svg/NavigationIcon";
import { media } from "../../utils/media";

function Pagination({ currentPage, totalPages, onNextPage, onPreviousPage }) {
  const wrapperStyle = {
    display: "flex",
    flexDirection: "row",
    width: "124px",
  };
  return (
    <div style={wrapperStyle}>
      <CircularButton onClick={onPreviousPage} disabled={currentPage === 1}>
        <NavigationIcon color={currentPage === 1 ? "#0000004D" : "#757575"} />
      </CircularButton>
      &nbsp;&nbsp;
      <CircularButton
        onClick={onNextPage}
        disabled={currentPage === totalPages}
      >
        <NavigationIcon
          rotate={180}
          color={currentPage === totalPages ? "#0000004D" : "#757575"}
        />
      </CircularButton>
    </div>
  );
}

function ProgressBar({ currentPage, totalArticles, articlesPerPage }) {
  const progressPercentage2 =
    (Math.min(currentPage * articlesPerPage, totalArticles) * 100) /
    totalArticles;

  const barStyle = {
    width: `${progressPercentage2}%`,
    backgroundColor: "#757575",
    height: "10px",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <div
      style={{
        backgroundColor: "#F2F2F2",
        height: "10px",
        width: "calc(100% - 16px)",
      }}
    >
      <div style={barStyle}></div>
    </div>
  );
}

const ArticlesList = ({ pages = [], setSelectedArticle }) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [isIndustryLoading, setIndustryLoading] = useState(true);
  // pagination
  const articlesPerPage = 3;
  const articlesPerPageMobile=1;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesMob,setTotalPagesMob]=useState(1);
  const startIndex = (currentPage - 1) * articlesPerPage;
  const startIndexMobile = (currentPage - 1) * articlesPerPageMobile;
  const visibleArticles = articles.slice(
    startIndex,
    startIndex + articlesPerPage
  );
  const visibleArticlesMobile = articles.slice(
    startIndexMobile,
    startIndexMobile + articlesPerPageMobile
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleNextPageMob = () => {
    if (currentPage <= totalPagesMob) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const updatedArticles = [];

    const fetchPageData = async (page) => {
      try {
        const response = await axios.get(page.url);
        const docResponse = response.data;
        const structuredDocArray = docResponse.map((doc) => ({
          type: page.url.includes("newsletters")
            ? "article"
            : page.url.includes("reports")
            ? "report"
            : "document",
          typeName: page.url.includes("newsletters")
            ? "Article"
            : page.url.includes("reports")
            ? "Report"
            : "Content",
          data: doc,
        }));
        updatedArticles.push(...structuredDocArray);
      } catch (error) {
        console.error(
          `Error in fetching documents from url - ${page.url}`,
          error
        );
      }
    };

    const fetchAllPages = async () => {
      setIsLoading(true);
      await Promise.all(pages.map((page) => fetchPageData(page)));
      setArticles([...articles, ...updatedArticles]);
      setIsLoading(false);
    };

    fetchAllPages();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(articles.length / articlesPerPage));
    setTotalPagesMob(Math.ceil(articles.length / articlesPerPageMobile));

  }, [articles]);

  useEffect(() => {
    axios
      .get(INDUSTRIES_URL_ENDPOINT)
      .then((response) => response.data)
      .then((industries) => {
        setIndustries(industries);
        setIndustryLoading(false);
      })
      .catch((error) => {
        console.error("Error in fetching industries-", error);
        setIndustryLoading(false);
      });
  }, []);
  return articles.length > 0 ? (
    <ArticlesContainer>
      <Title>Recommended Content</Title>
      {isLoading || isIndustryLoading ? (
        "Loading..."
      ) : (
        <>
          <ArticlesRowWebsite>
            {visibleArticles.map((article, index) => (
              <ArticleSpace key={index}>
                <Article
                  key={index}
                  image_url_id={article?.data?.featured_media || ""}
                  industry={
                    article?.data?.industry?.[0]
                      ? industries.filter(
                          (item) => item.id === article?.data?.industry?.[0]
                        )?.[0]?.name || ""
                      : ""
                  }
                  date_created={
                    article?.data?.date
                      ? moment(article?.data?.date).format("MMMM D, YYYY")
                      : ""
                  }
                  docTypeName={article.typeName || ""}
                  title={article?.data?.title?.rendered || ""}
                  description={article?.data?.excerpt?.rendered || ""}
                  onArticleClick={() => {
                    setSelectedArticle(article);
                  }}
                />
              </ArticleSpace>
            ))}
          </ArticlesRowWebsite>
          <ArticlesRowMobile>
            {visibleArticlesMobile.map((article, index) => (
              <ArticleSpace key={index}>
                <Article
                  key={index}
                  image_url_id={article?.data?.featured_media || ""}
                  industry={
                    article?.data?.industry?.[0]
                      ? industries.filter(
                          (item) => item.id === article?.data?.industry?.[0]
                        )?.[0]?.name || ""
                      : ""
                  }
                  date_created={
                    article?.data?.date
                      ? moment(article?.data?.date).format("MMMM D, YYYY")
                      : ""
                  }
                  docTypeName={article.typeName || ""}
                  title={article?.data?.title?.rendered || ""}
                  description={article?.data?.excerpt?.rendered || ""}
                  onArticleClick={() => {
                    setSelectedArticle(article);
                  }}
                />
              </ArticleSpace>
            ))}
          </ArticlesRowMobile>
          <PagWeb>
          {articles.length > articlesPerPage && (
            <PaginationRow>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
              />
              <ProgressBar
                currentPage={currentPage}
                totalArticles={articles.length}
                articlesPerPage={articlesPerPage}
              />
            </PaginationRow>
          )}
          </PagWeb>
          <PagMob>
          {articles.length > articlesPerPageMobile && (
            <PaginationRow>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPagesMob}
                onNextPage={handleNextPageMob}
                onPreviousPage={handlePreviousPage}
              />
              <ProgressBar
                currentPage={currentPage}
                totalArticles={articles.length}
                articlesPerPage={articlesPerPageMobile}
              />
            </PaginationRow>
          )}
          </PagMob>
        </>
      )}
    </ArticlesContainer>
  ) : null;
};

export default ArticlesList;

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(3.2vw - 16px);
  background-color: transparent;
  width: 100%;
`;

const Title = styled.div`
  font-size: 25px;
  padding-left: 16px;
  padding-top: 15px;
  font-weight: 500;
  line-height: 38px;
  ${media.small`
    
      font-size: 18px;
  padding-left: 10px;
  padding-top: 10px;
  line-height: 20px;
    `}
`;

const ArticlesRowWebsite = styled.div`
  display: flex;
  flex-direction: row;
  ${media.small`
    display:none;

    `}
`;
const ArticlesRowMobile = styled.div`
display:none;
  ${media.small `
      display: flex;
  flex-direction: row;
    
    `}
`;


const ArticleSpace = styled.div`
  width: calc(100% / 3);
   ${media.small`
      width: calc(100%);

   `}
`;

const PaginationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
`;

const CircularButton = styled.div`
  width: 48px;
  heigth: 48px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: #f2f2f2;
  border-radius: 24px;
  ${media.small`
      width: 24px;
  heigth: 24px;
  background-color: #f2f2f2;
  border-radius: 12px;
opacity: ${(props) => (props.disabled ? 0 : 1)};
pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

    `}
`;

const PagWeb=styled.div`
  display:block;
${media.small`
  display:none;
  `}
`

const PagMob=styled.div`
  display:none;
${media.small`
  display:block;
  `}
`
import React from "react";
import styled from "styled-components";

const SearchIcon = ({
  width = 24,
  height = 24,
  color = "#757575",
  rotate = 0,
}) => {
  return (
    <IconWrapper rotate={rotate}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.45 15.7766C7.6473 15.7766 6.12163 15.1516 4.87298 13.9016C3.62433 12.6516 3 11.1432 3 9.37656C3 7.6099 3.625 6.10156 4.875 4.85156C6.125 3.60156 7.6375 2.97656 9.4125 2.97656C11.1875 2.97656 12.6958 3.60156 13.9375 4.85156C15.1792 6.10156 15.8 7.61115 15.8 9.38031C15.8 10.0945 15.6833 10.7849 15.45 11.4516C15.2167 12.1182 14.8667 12.7432 14.4 13.3266L20.45 19.3266C20.6 19.4692 20.675 19.6506 20.675 19.871C20.675 20.0914 20.6 20.2766 20.45 20.4266C20.3 20.5766 20.1148 20.6516 19.8945 20.6516C19.6741 20.6516 19.4926 20.5766 19.35 20.4266L13.325 14.4016C12.825 14.8349 12.242 15.1724 11.576 15.4141C10.91 15.6557 10.2014 15.7766 9.45 15.7766ZM9.425 14.2766C10.7792 14.2766 11.9302 13.7974 12.8781 12.8391C13.826 11.8807 14.3 10.7266 14.3 9.37656C14.3 8.02656 13.826 6.8724 12.8781 5.91406C11.9302 4.95573 10.7792 4.47656 9.425 4.47656C8.05695 4.47656 6.8941 4.95573 5.93645 5.91406C4.97882 6.8724 4.5 8.02656 4.5 9.37656C4.5 10.7266 4.97882 11.8807 5.93645 12.8391C6.8941 13.7974 8.05695 14.2766 9.425 14.2766Z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default SearchIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
`;

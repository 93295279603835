import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TriangleIcon from "../../svg/TriangleIcon";
import moment from "moment";

const Bead = ({ value, showValue = true, dealData }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [quadrant, setQuadrant] = useState(1);
  const popupRef = useRef(null);
  const beadRef = useRef(null);
  const toggleDropdown = () => {
    setPopupOpen(!isPopupOpen);
  };

  const updateQuadrant = () => {
    if (beadRef.current) {
      const rect = beadRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let newQuadrant = 1;

      if (centerX < windowWidth / 2) {
        newQuadrant = centerY < windowHeight / 2 ? 1 : 3;
      } else {
        newQuadrant = centerY < windowHeight / 2 ? 2 : 4;
      }
      setQuadrant(newQuadrant);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isPopupOpen &&
        beadRef.current &&
        !beadRef.current.contains(event.target) &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        setPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  useEffect(() => {
    const handleScroll = () => {
      updateQuadrant();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mouseclick", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mouseclick", handleScroll);
    };
  }, [beadRef]);

  return (
    <BeadCircle
      ref={beadRef}
      onClick={() => toggleDropdown()}
      color={dealData.color}
    >
      {isPopupOpen && (
        <>
          <PopupWrap ref={popupRef} quadrant={quadrant}>
            <PopupBody>
              <Row>
                <Heading>Company</Heading>
                <Value>{dealData.company_name}</Value>
              </Row>
              <Row>
                <Heading>Funding Date</Heading>
                <Value>{moment(dealData.deal_date).format("MMM YYYY")}</Value>
              </Row>
              <Row>
                <Heading>Series/ Round</Heading>
                <Value>{dealData.series_name}</Value>
              </Row>
              <Row>
                <Heading>Pre Money Valuation</Heading>
                <Value>
                  {dealData.pre_valuation
                    ? `${Number(dealData.pre_valuation).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )} Cr INR`
                    : "-"}
                </Value>
              </Row>
              <Row>
                <Heading>Investment Raised</Heading>
                <Value>
                  {dealData.investment_value
                    ? `${Number(dealData.investment_value).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )} Cr INR`
                    : "-"}
                </Value>
              </Row>
              <Row>
                <Heading>Post Money Valuation</Heading>
                <Value>
                  {dealData.post_valuation
                    ? `${Number(dealData.post_valuation).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )} Cr INR`
                    : "-"}
                </Value>
              </Row>
              <Row>
                <Heading>Investors</Heading>
                <Value>{dealData.top_3_investors}</Value>
              </Row>
            </PopupBody>
          </PopupWrap>
          <PopupValue quadrant={quadrant}>
            {Number(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            X
          </PopupValue>
        </>
      )}
      {isPopupOpen && (
        <BlackBeadWrapper>
          <TriangleIcon />
        </BlackBeadWrapper>
      )}
      {!isPopupOpen && showValue && (
        <BeadValue>
          {Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          X
        </BeadValue>
      )}
    </BeadCircle>
  );
};

export default Bead;
//  background: ${(props) => props.color + "66"};
const BeadCircle = styled.div`
  background: ${(props) => props.color};
  border: 1.5px solid ${(props) => props.color};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
`;

const BlackBeadWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 1.5px solid #ffffff;
  border-radius: 25px;
  box-shadow: 2px 2px 4px 0px #00000040;
  background: linear-gradient(180deg, #4d4d4d 47.92%, #000000 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  position: absolute;
  z-index: 10;
  left: -2.5px;
  top: -2.5px;
`;

const BeadValue = styled.div`
  height: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #262e40;
  position: absolute;
  top: -15px;
  left: -3px;
  white-space: nowrap;
`;

const PopupWrap = styled.div`
  padding: 4.5px;
  background-color: #ffffff;
  z-index: 5;
  box-shadow: 2px 2px 4px 0px #00000040;
  border: 0.75px solid #ededed;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #262e40;
  position: absolute;
  cursor: default;
  ${(props) => props.quadrant === 1 && `top: -8px;`}
  ${(props) => props.quadrant === 1 && `left: -8px;`}

  ${(props) => props.quadrant === 2 && `top: -8px;`}
  ${(props) => props.quadrant === 2 && `right: -8px;`}

  ${(props) => props.quadrant === 3 && `bottom: -8px;`}
  ${(props) => props.quadrant === 3 && `left: -8px;`}

  ${(props) => props.quadrant === 4 && `bottom: -8px;`}
  ${(props) => props.quadrant === 4 && `right: -8px;`}
`;

const PopupValue = styled.div`
  height: 15px;
  width: max-content;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #262e40;
  position: absolute;
  z-index: 10;
  cursor: default;
  ${(props) => props.quadrant === 1 && `top: 4px;`}
  ${(props) => props.quadrant === 1 && `left: 26px;`}

  ${(props) => props.quadrant === 2 && `top: 4px;`}
  ${(props) => props.quadrant === 2 && `right: 26px;`}

  ${(props) => props.quadrant === 3 && `bottom: 4px;`}
  ${(props) => props.quadrant === 3 && `left: 26px;`}

  ${(props) => props.quadrant === 4 && `bottom: 4px;`}
  ${(props) => props.quadrant === 4 && `right: 26px;`}
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  border-radius: 10px;
  border: 1.5px solid #f0f0f0;
  padding: 25px 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 5px 0;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 40%;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #9e9e9e;
`;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 60%;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #262e40;
`;

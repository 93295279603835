import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import CustomForm from '../components/CustomForm/CustomForm'
import MyContext from '../utils/contexts/MyContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { onNewsletterClickCT, onUserLoginCT } from "../utils/clevertap";

const Otp3 = () => {
    const [formData, setFormData] = useState({});
    const [wrongOTP, setWrongOTP] = useState(false);
    const { treeData, setTreeData } = React.useContext(MyContext);
    const [signIn, setSignIn] = useState(false);
    const search = useLocation().search;
    const url_source = new URLSearchParams(search).get('source')
    const url_email = new URLSearchParams(search).get('email')
    const url_newsletter_name = new URLSearchParams(search).get('email')
    const url_newsletter_month = new URLSearchParams(search).get('email')
    const url_newsletter_user = new URLSearchParams(search).get('email')
    const url_newsletter_email = new URLSearchParams(search).get('email')

    const source = url_source || window.localStorage.getItem('source') || "";
    const email = url_email || window.localStorage.getItem('email') || "";
    const newsletter_name = url_newsletter_name || window.localStorage.getItem('newsletter_name') || "";
    const newsletter_month = url_newsletter_month || window.localStorage.getItem('newsletter_month') || "";
    const newsletter_user = url_newsletter_user || window.localStorage.getItem('newsletter_user') || "";
    const newsletter_email = url_newsletter_email || window.localStorage.getItem('newsletter_email') || "";
    const navigate = useNavigate();

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (
          newsletter_name &&
          newsletter_month &&
          newsletter_user &&
          newsletter_email
        ) {
          onNewsletterClickCT(
            newsletter_name,
            newsletter_month,
            "clicked",
            newsletter_email,
            "Otp Page"
          );
        }
      }, 1000);
      window.localStorage.setItem('email', email)
      return () => {
        window.localStorage.removeItem("newsletter_name");
        window.localStorage.removeItem("newsletter_month");
        window.localStorage.removeItem("newsletter_user");
        window.localStorage.removeItem("newsletter_email");
        clearTimeout(timeoutId);
      };
    }, []);

    const fields = [
        { name: 'OTP', type: 'text', style: { backgroundColor: 'white' } }, // Customize 'email' field background color
      ];

      const handleChange = (name, value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };



      function findFirstSubscribedNode(data) {
        for (let node of data) {
            if (node.subscribed && node.label!=='Sectors') {
                return node;
            }
            
            const result = findFirstSubscribedNode(node.nodes);
            if (result) {
                return result;
            }
        }
        
        return null;
    }

      const handleSubmit = (e) => {
        // Send the formData to the API or perform further processing here
        e.preventDefault();
        console.log(formData);
        let otp = formData['OTP']
        const uploadData = new FormData();
        let client_id = window.localStorage.getItem("clientID")
        uploadData.append('email', email);
        uploadData.append('OTP', otp);
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/authorise/login/`, {
            method: 'POST',
            body: uploadData
          }).then(data => data.json())
          .then( data => {
            if (data.token){
              // fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreports/?rep_id=9&client_id=${client_id}`, {
              //   method:'GET',
              //   headers:{
              //     'Content-Type': 'application/json',
              //   },
              // })
              // .then(res=>res.json())
              // .then(
              //   res=>{
              //     setTreeData(res)
              //     console.log(res)
              //     setSignIn(true);
              //   }
              // )
              setSignIn(true);
                window.localStorage.setItem('loginStatus', 'true')
                window.localStorage.setItem("token", data.token)
                window.localStorage.setItem('phone', data.phone)
                // setSignIn(true);
                setWrongOTP(false)
            }
            else{
                setSignIn(false);
                setWrongOTP(true)
                // alert('Wrong OTP')
            }
            })
          .catch(error => {
            setSignIn(false);
            alert('System Error.Contact Admin')
            console.log(error)
        })
      };

      useEffect (()=>{
        if(source){
          const handleSubmitEmail = () => {
            // Send the formData to the API or perform further processing here
          console.log('workssubmit')
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/authorise/login/?email=${email}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
          })
          .then(
              data => data.json(),        )
          .then(
            data => {
              // this.props.userLogin(data.token);
              if(data['otp_access']===true){
                console.log('data=',data['pseudo_email'])
              //   props.userLogin(data['pseudo_email'])
                window.localStorage.setItem("pseudo_email", data['pseudo_email'])
              //   props.setClientID(data['client_id'])
                window.localStorage.setItem("clientID", data['client_id'])
                window.localStorage.setItem('unregistered',data['unregistered'])
                window.localStorage.setItem('user_name', data['user_name'])
                if (data["investor_id"]) {
                  window.localStorage.setItem("investor_id", data["investor_id"]);
                }
                if(data['gender_male']!==true){
                  window.localStorage.setItem('gender_male' , 'false')
                }else{
                  window.localStorage.setItem('gender_male' , 'true')
                }
              }else{
                alert('You need to sign in with microsoft or gmail')
              }
            }
          )
          .catch( error => console.error(error))
    
          };
          handleSubmitEmail()
        }
      },[])

    

      if(window.localStorage.getItem('loginStatus')=='true'){
        onUserLoginCT(
          window.localStorage.getItem("user_name") !== undefined
            ? window.localStorage.getItem("user_name")
            : email.split("@")[0],
          email,
          window.localStorage.getItem("gender_male") === "true" ? "M" : "F",
          window.localStorage.getItem("clientID")
        );
        if(source){
          if (
            newsletter_name &&
            newsletter_month &&
            newsletter_user &&
            newsletter_email
          ) {
            onNewsletterClickCT(
              newsletter_name,
              newsletter_month,
              "clicked",
              newsletter_email,
              "Otp Submit Page"
            );
            window.localStorage.removeItem("newsletter_name");
            window.localStorage.removeItem("newsletter_month");
            window.localStorage.removeItem("newsletter_user");
            window.localStorage.removeItem("newsletter_email");
          }
        }
        // below is always true
        console.log('signIn=',signIn)
        let player_name = window.localStorage.getItem("player_name")
        if (player_name){
          window.localStorage.setItem("ReportName", "Sectors_Company_Profile")
          return <Navigate to = "/report"/>
        }else{
          if(source==='newsletter'){
            navigate('/redirect/?source=newsletter')
          }else{
            navigate('/redirect')
          }
         
        }
    }


  return (
    <SignIndiv>
        <Imgdiv>
            <img  src = '/Images/signin.svg' alt = 'sideimg'></img>
        </Imgdiv>
        <RHSdiv>
            <Benchmarksdiv>
                <img src = '/Images/BenchmarksNew.svg' alt = 'logo' height={'30px'}></img>
            </Benchmarksdiv>
            <Signupmiddiv>
              An OTP has been sent to your registered email id .
            </Signupmiddiv>
            {wrongOTP?<div style={{color:'red', textAlign:'center'}}>Wrong OTP</div>:<div></div>}
            <FormDiv>
                <form onSubmit={(e)=>handleSubmit(e)}>
                  <CustomForm fields={fields} onChange={handleChange} />
                  <button type="submit" style = {{width:'100%'}}>Submit</button>
                </form>
            </FormDiv>
        </RHSdiv>
    </SignIndiv>
  )
}

export default Otp3

const SignIndiv = styled.div`
    display:flex;
`

const Imgdiv = styled.div`
display:none;
@media(min-width:768px){
display:block;
}
`

const RHSdiv = styled.div`

    width:100vw;
    @media(min-width:768px){
    width:70vw
}

`

const Benchmarksdiv = styled.div`
    padding:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:15vh;
`

const Signupmiddiv = styled.div`
    padding:20px;
    /* font-weight:bold; */
    text-align:center;
    font-size:25px;
    margin-bottom:30px;
`


const FormDiv = styled.div`
    padding-left:50px;
    padding-right:50px;
    @media (min-width: 768px){
        padding-left:200px;
        padding-right:200px; 
    };

    & button{
        background-color:#0099FE;
        line-height:40px;
        border-radius:50px;
        border:none;
        margin-top:20px;
        color:white;
    }
`
import React from "react";
import styled from "styled-components";

const VectorIcon = ({
  width = 24,
  height = 24,
  color = "#0099FF",
  rotate = 0,
  onIconClick = () => null,
}) => {
  return (
    <IconWrapper rotate={rotate} onClick={onIconClick}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 10 13"
        fill="none"
      >
        <path
          d="M5.12604 2.60937L5.12604 12.4062C5.12604 12.5778 5.06997 12.7196 4.95781 12.8318C4.84566 12.9439 4.70382 13 4.53229 13C4.36076 13 4.21892 12.9439 4.10677 12.8318C3.99462 12.7196 3.93854 12.5778 3.93854 12.4062L3.93854 2.60937L1.00937 5.53854C0.890625 5.65729 0.752083 5.71337 0.59375 5.70677C0.435416 5.70017 0.296875 5.6375 0.178125 5.51875C0.0593747 5.4 -3.38262e-07 5.26146 -3.45183e-07 5.10313C-3.52104e-07 4.94479 0.0593746 4.80625 0.178125 4.6875L4.11667 0.748958C4.18264 0.682986 4.24861 0.636805 4.31458 0.610416C4.38056 0.584028 4.45312 0.570833 4.53229 0.570833C4.61146 0.570833 4.68403 0.584028 4.75 0.610416C4.81597 0.636805 4.88194 0.682986 4.94792 0.748958L8.86667 4.66771C8.98542 4.78646 9.04479 4.925 9.04479 5.08333C9.04479 5.24167 8.98542 5.38021 8.86667 5.49896C8.74792 5.61771 8.60608 5.67708 8.44115 5.67708C8.27622 5.67708 8.13438 5.61771 8.01563 5.49896L5.12604 2.60937Z"
          fill={`${color}`}
        />
      </svg>
    </IconWrapper>
  );
};

export default VectorIcon;

const IconWrapper = styled.div`
  display: flex;
  transform: rotate(${(props) => props.rotate}deg);
  :hover {
    cursor: pointer;
  }
`;

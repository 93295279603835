import React from "react";
import styled from "styled-components";
import { media } from "../../utils/media";

const Toggle = ({ onChangeToggle, isEnabled = false, label }) => {
  return (
    <ToggleContainer>
      {label && <Label>{label}:</Label>}
      <ToggleSwitch>
        <Input
          type="checkbox"
          checked={isEnabled}
          onChange={() => onChangeToggle(!isEnabled)}
        />
        <Slider />
      </ToggleSwitch>
    </ToggleContainer>
  );
};

export default Toggle;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: 450;
  margin-right: 5px;
  ${media.small`
    font-size: 10px;
    margin-right: 3px;
  `}
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  display: inline-block;
  margin-bottom: 0px;
  width: 34px;
  height: 17px;
  ${media.small`
    width: 26px;
    height: 13px;
  `}
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #0099ff;
  }

  &:checked + span:before {
    transform: translateX(17px);
    ${media.small`
      transform: translateX(13px);
    `}
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  transition: 0.4s;
  border-radius: 17px;
  ${media.small`
    border-radius: 13px;
  `}

  &:before {
    position: absolute;
    content: "";
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    height: 13px;
    width: 13px;
    border-radius: 13px;
    ${media.small`
      height: 9px;
      width: 9px;
      border-radius: 9px;
    `}
  }
`;

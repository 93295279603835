import React from "react";
import Fintable from "../components/FinTable/Fintable";
import CustomScatterChart from "../components/ScatterGraph/CustomScatterChart";
import { useState } from "react";
import { Switch } from "antd";
import ExcelLikeTable from "./test";
import QuickLink from "../components/QuickLink";
import styled from "styled-components";
import { media } from "../utils/media";
import Toggle from "../components/Toggle";

const Testing = ({
  industry_id,
  report,
  dropDownData,
  onPlayerClick = () => null,
  listing_name,
}) => {
  const [showgrid, setshowgrid] = useState(true);

  const onChange = (checked) => {
    setshowgrid(checked);
  };
  const data = [
    { Name: "John Doe", Age: 30, Email: "john@example.com" },
    { Name: "Jane Doe", Age: 25, Email: "jane@example.com" },
  ];
  return (
    <div>
      <div
        style={{ padding: "3.5vw", paddingBottom: "0px", paddingTop: "10px" }}
      >
        <ListingName>{listing_name}</ListingName>
        <Rowrap>
          <MagicWrap>
            <MagicQuad>      Magic Quadrants</MagicQuad>
              <div style={{display:"flex",alignContent:"center"}}><Toggle isEnabled={showgrid} onChangeToggle={onChange} /></div>
              <ListQuad>     List</ListQuad>
          </MagicWrap>
          <QuickLink industry_id={industry_id} />
        </Rowrap>
      </div>
      {showgrid ? (
        <Fintable
          ind_id={industry_id}
          report={report}
          dropDownData={dropDownData}
          onPlayerClick={onPlayerClick}
        />
      ) : (
        <CustomScatterChart ind_id={industry_id} />
      )}
      {/* <ExcelLikeTable data = {data}/> */}
    </div>
  );
};

export default Testing;

const MagicWrap = styled.div`
  font-size: 16px;
white-space:nowrap;
display:flex;
  ${media.small`
  font-size:13px;
  `}
`;
const Switchsty = styled.span`
 padding-left:1px;
`;
const ListingName = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 15px;
  ${media.small`
font-size: 18px;
  margin-bottom: 10px;

      `}
`;
const MagicQuad=styled.div`
padding-right:2%;
`

const ListQuad=styled.div`
padding-left:2%;
`

const Rowrap=styled.div`
display: flex;
justify-content: space-between;
${media.small`
  flex-direction:column;
  `}
`

const ToggleWrap=styled.div`
display:flex;
align-content:center;
${media.small`
align-content:flex-end;
  `}
`
import React from "react";
import styled from "styled-components";

const DotIcon = ({ width = 2, height = 2, color = "#1F1F1F" }) => {
  return (
    <IconWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 2 2"
        fill="none"
      >
        <circle cx="1" cy="1" r="1" fill={`${color}`} />
      </svg>
    </IconWrapper>
  );
};

export default DotIcon;

const IconWrapper = styled.div`
  display: flex;
`;

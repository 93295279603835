import React from "react";
import styled from "styled-components";

const ThumbIcon = ({ width = 14, height = 14, color = "#21AF4A" }) => {
  return (
    <IconWrapper>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 14 14"
        fill="none"
      >
        <g clipPath="url(#clip0_2834_3888)">
          <path
            d="M4.08341 6.41797L6.41675 1.16797C6.88088 1.16797 7.326 1.35234 7.65418 1.68053C7.98237 2.00872 8.16675 2.45384 8.16675 2.91797V5.2513H11.4684C11.6375 5.24939 11.805 5.28426 11.9593 5.35351C12.1136 5.42276 12.251 5.52473 12.362 5.65235C12.473 5.77997 12.5549 5.9302 12.602 6.09262C12.6492 6.25503 12.6605 6.42576 12.6351 6.59297L11.8301 11.843C11.7879 12.1212 11.6466 12.3747 11.4322 12.557C11.2178 12.7392 10.9448 12.8378 10.6634 12.8346H4.08341M4.08341 6.41797V12.8346M4.08341 6.41797H2.33341C2.024 6.41797 1.72725 6.54089 1.50846 6.75968C1.28966 6.97847 1.16675 7.27522 1.16675 7.58464V11.668C1.16675 11.9774 1.28966 12.2741 1.50846 12.4929C1.72725 12.7117 2.024 12.8346 2.33341 12.8346H4.08341"
            stroke={`${color}`}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2834_3888">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
};

export default ThumbIcon;

const IconWrapper = styled.div`
  display: flex;
`;
